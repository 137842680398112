$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;

.video-chat-pre-join-header {
  margin: 0 auto;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 24px;

    @media screen and (max-width: #{$media-sm}) {
      justify-content: center;
    }
  }

  &__user {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 12px;
    line-height: 1.33;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--theme-color--blue);

    @media screen and (max-width: #{$media-sm}) {
      display: none;
    }
  }
}
