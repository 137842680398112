$sbt: '.search-by-offering';
$mls: '.multi-level-select';

#{$sbt} {
  #{$mls} {
    position: relative;
    top: unset;
    right: unset;
    height: auto;
    z-index: auto;

    .cascader-list__list {
      overflow: auto;

      li {
        cursor: pointer;
      }
    }

    .category__header {
      display: none;
    }
  }
}