.password-recommend-modal {
  background-color: get-colour('white');
  padding: 16px;
  border-radius: $modal-border-radius;
  max-width: 500px;

  font-family: $font-family-default;
  line-height: $default-line-height;
  color: get-colour('blue');

  &__title {
    text-transform: uppercase;
    font-weight: get-font-weight('bold');
    font-size: get-font-size('s-2');
  }

  &__list {
    list-style: disc;
    padding-left: 20px;
    font-size: get-font-size('s-3');
    font-weight: get-font-weight('medium');
  }
}
