$ab: '.action-button';
$cb: '.calendar-button';
$chb: '.chat-button';

#{$ab} {
  display: flex;
  align-items: center;

  padding: 4px 12px;

  background-color: var(--theme-color--white);
  border-radius: var(--theme-borderRadius--default);
  border: none;
  box-shadow: rgba(#000000, 0.15) 0 2px 8px;

  cursor: pointer;
  transition: 0.2s background-color;

  font-weight: var(--theme-fontWeight--semiBold);
  font-size: 12px;
  line-height: 1.5;
  color: var(--theme-color--blue);

  &__icon {
    width: 18px;
    height: auto;
    fill: transparent;
    transition: 0.2s fill;

    path {
      fill-opacity: 1;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.625;
    font-weight: var(--theme-fontWeight--bold);
  }

  &--mobile-hidden {
    @media screen and (max-width: 575px) {
      #{$ab}__text {
        display: none;
      }
    }
  }

  &:hover,
  &:active {
    background-color: var(--theme-color--ultraLightBlue);

    #{$ab}__icon {
      fill: rgba(#272835, 0.35);
    }
  }

  & > * + * {
    margin-left: 8px;
  }

  & > * {
    pointer-events: none;
  }
}

#{$chb} {
  &.disabled {
    cursor: not-allowed;
  }
}
