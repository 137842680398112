// TODO: refactor these styles
$lve: '.live-virtual-event';

$lve-gradient: linear-gradient(to right, #ffffff00 0%, #ffffff 50%, #ffffff00 100%);
$lve-card-border-image: linear-gradient(to bottom, #ffffff, rgba(0, 0, 0, 0)) 1 100%;
$lve-shadow: drop-shadow(0 20px 60px rgba(0, 0, 0, 0.6));

#{$lve} {
  &__card {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 605px;
    min-height: 605px;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-image: $lve-card-border-image;
    border-image-slice: 1;
    border-top: 0;
    filter: $lve-shadow;

    @media only screen and (max-width: 767px) {
      max-height: unset;
      min-height: 900px;
      border-image: $lve-gradient;
      border-image-slice: 1;
      border-top: 0;
      border-left: none;
      border-right: none;
    }

    &__top-border {
      display: table;
      position: absolute;
      top: 0;
      width: 100%;

      @media only screen and (max-width: 767px) {
        display: block;
        height: 2px;
        background: $lve-gradient;
      }

      &:before,
      &:after {
        content: '';
        width: 10%;
        border-top: 2px solid #ffffff;
      }

      &:before,
      &:after,
      &.top-border {
        display: table-cell;
        white-space: nowrap;
      }
    }
  }
}