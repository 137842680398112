.message-input {
  &__wrapper {
    position: relative;
  }

  &__field {
    width: 100%;
    height: 100%;

    resize: none;
    border: none;

    font-size: 16px;
    font-weight: var(--theme-fontWeight--medium);

    line-height: 1.86;

    padding: 10px 50px 10px 15px;

    background: var(--theme-color--white);

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: var(--theme-color--blueText);
    }
  }

  &__icon {
    position: absolute;

    bottom: 15px;
    right: 17px;

    &.active {
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.mobile-view {
  &.message-input {
    &__wrapper {
      border: var(--theme-border--default);
    }
  }
}