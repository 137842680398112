// TODO: refactor CommercialEngagementsRequest & subcomponents
$cer: '.commercial-engagements-request';

#{$cer} {
  padding-top: 30px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    padding-bottom: 0;
  }

  ._title {
    font-size: 42px;

    @media screen and (max-width: 767px) {
      font-size: 38px;
    }
    @media screen and (max-width: 575px) {
      font-size: 30px;
    }
    @media screen and (max-width: 425px) {
      font-size: 23px;
    }
  }

  .fullName {
    font-size: 60px;
    
    @media screen and (max-width: 767px) {
      font-size: 54px;
    }
    @media screen and (max-width: 575px) {
      font-size: 42px;
    }
    @media screen and (max-width: 425px) {
      font-size: 30px;
    }
  }
}