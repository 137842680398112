$tibt-c: '.talent-info-block__tab--charities';

#{$tibt-c} {
  &__block {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr;

    &-item {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: var(--theme-fontWeight--semiBold);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}