.offline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #1c1c26 0%, #333446 50%, #272835 100%);
}

.offline svg {
  margin-bottom: 40px;
}

.offline h3 {
  margin-bottom: 16px;
  color: #ffffff;
  font-family: 'Playfair Display';
  font-size: 28px;
  line-height: 1.3;
  font-weight: 900;
}

.offline p {
  margin-bottom: 40px;
  color: #babbcd;
  font-family: 'Montserrat';
  font-size: 16px;
  line-height: 1.6;
  font-weight: 500;
}

.offline button {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  color: #ffffff;
  background: linear-gradient(309.69deg, #636583 18%, #272835 70%);
  border: 2px solid;
  border-radius: 8px;
  border-image-source: linear-gradient(360deg, #272835 0%, #636583 100%);
  font-family: 'Montserrat';
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  appearance: none;
  cursor: pointer;
}
