.dropdown-wrapper {
  cursor: pointer;
  .label-wrapper {
    display: flex;
    align-items: center;
    padding: 6px;
    .dropdown-label {
      font-size: 12px;
      font-weight: var(--theme-fontWeight--bold);
      line-height: 1em;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    svg {
      margin-left: 4px;
    }

    @at-root .dark-theme #{&} {
      color: var(--theme-color--blueText);

      svg {
        stroke: var(--theme-color--blueText);
      }
    }

    @at-root .light-theme #{&} {
      color: var(--theme-color--grey);

      svg {
        stroke: var(--theme-color--grey);
      }
    }
  }

  &.active {
    .label-wrapper {
      @at-root .dark-theme #{&} {
        color: var(--theme-color--lightGrey);

        svg {
          transform: rotate(-180deg);
          stroke: var(--theme-color--lightGrey);
        }
      }

      @at-root .light-theme #{&} {
        color: var(--theme-color--blue);

        svg {
          transform: rotate(-180deg);
          stroke: var(--theme-color--blue);
        }
      }
    }
  }
  .menu-wrapper {
    position: absolute;
    left: 50%;
    top: 100%;
    min-width: 240px;
    padding: 16px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    transform: translate(-50%, 10px);
  }
}