$file: '.file';

#{$file} {
  display: flex;
  align-items: center;
  padding: 16px 0;

  &__preview {
    width: 60px !important;
    height: 80px !important;
  }

  &__name {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 16px;
    line-height: 1.626;
    color: var(--theme-color--blue);
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;

    margin-left: 16px;
  }
}
