.user-info {
  display: flex;

  &__avatar {
    position: relative;

    width: 72px;
    height: 72px;
    min-width: 72px;

    margin-right: 16px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__chat-link {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);

    .action-button__text {
      white-space: nowrap;
    }
  }

  &__fullname {
    font-weight: var(--theme-fontWeight--bold);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blue);
  }

  &__user-link {
    color: var(--theme-color--blue);

    &:hover {
      text-decoration: underline;
    }
  }

  &__stats {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: 8px;

    .user-star-rating {
      flex-shrink: 0;
    }

    @media only screen and (max-width: 350px) {
      flex-direction: column;
      align-items: baseline;
    }
  }

  &__reviews-link {
    margin-left: 4px;

    font-size: 14px;
    line-height: 1.5;
    color: var(--theme-color--grey);
    font-weight: var(--theme-fontWeight--medium);

    &:hover {
      text-decoration: underline;
    }
  }


  &__bg-report {
    display: flex;
    align-items: center;

    font-size: 14px;
    line-height: 1.5;
    color: var(--theme-color--blue);
    font-weight: var(--theme-fontWeight--medium);

    &:hover {
      text-decoration: underline;
    }

    &-icon {
      margin-right: 8px;
    }
  }
}
