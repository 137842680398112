// TODO: refactor styles
$public-view: '.public-view';
$vc: '.video-chat';

#{$vc} {
  padding: 0;

  .content {
    @media screen and (max-width: 991px) {
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: row;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  #{$public-view} {
    &-description--charity-list {
      margin-bottom: 32px;

      &__title {
        font-weight: var(--theme-fontWeight--bold);
      }
    }

    &-card--bordered {
      .description {
        padding: 32px 0;

        @media screen and (max-width: 991px) {
          padding: 0;
        }

        @media screen and (max-width: 768px) {
          text-align: left;
          padding-bottom: 0;
        }

        @media screen and (max-width: 767px) {
          text-align: center;
          margin-top: 40px;
        }

        .tip {
          @media screen and (max-width: 768px) {
            text-align: center;
          }
        }
      }
    }

    &__price .price-wrapper div span {
      padding-left: 0;
    }
  }

  .taboos-wrapper {
    display: block;

    &--mobile {
      display: none;
    }

    @media screen and (max-width: 991px) {
      display: none;

      &--mobile {
        display: block;
        margin-top: 24px;
        width: 100%;
      }
    }
  }

  &__calendar-and-price {
    padding: 32px 0;

    &__wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 16px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }

      .public-view__price {
        padding: 0;
        flex-direction: column;
        align-items: start;

        @media screen and (max-width: 767px) {
          align-items: center;
        }
      }

      .price-wrapper {
        margin-bottom: 24px;
      }
    }
  }
}

