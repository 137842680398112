.password-input {
  position: relative;

  &__eye {
    @include position-absolute(null, 30px, 19px ,null);
    transition: all .3s linear;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      transition: all .3s ease-in-out;
      content: '';
      position: absolute;
      width: 2px;
      background-color: get-colour('light-grey');
      top: -1px;
      left: 50%;
      z-index: 100;
      height: 110%;
      pointer-events: none;
      transform: rotate(45deg) translateX(-50%);
    }

    &--close:before {
      opacity: 0;
    }

    &--open:before {
      opacity: 1;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      height: 22px;
    }
  }

  .input {
    padding-right: 64px;
  }
}


.error-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 40px;

  padding-left: 20px;

  list-style: disc;

  margin: 20px 0;

  &__item {
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: get-font-size('s-1');
    line-height: 1.625;

    transition: color .3s linear;

    &--grey {
      color: get-colour('light-grey');
    }

    &--black {
      color: get-colour('black');
    }

    &--red {
      color: red;
    }
  }
}
