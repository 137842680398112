.how-it-works {
  @media screen and (max-width: 480px) {
    margin-top: -40px;
  }

  &__wrapper {
    @extend .wrapper;
    @extend .content-wrapper;

    padding-bottom: 64px;
  }

  &__header {
    font-family: $font-family-primary;
    font-weight: get-font-weight('black');
    font-size: get-font-size('l-1');
    line-height: 1.5;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

    color: get-colour('white');
    margin-bottom: 24px;
    margin-top: 30px;
    padding-top: 20px;
  }

  &__offers {
    padding-bottom: 50px;
  }

  @media screen and (max-width: 991px) {

    &__header {
      text-align: center;
      font-size: get-font-size('l-2');
      padding-top: 0;
      margin-bottom: 64px;
    }
  }

  @media screen and (max-width: 480px) {

    &__wrapper {
      margin-bottom: 0;
      padding-bottom: 84px;
    }

    &__header {
      font-size: get-font-size('m-1');
      margin-bottom: 48px;
    }
  }
}

.hiw-accordion {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;

  &__title {
    font-family: $font-family-primary;
    font-weight: get-font-weight('black');
    font-feature-settings: 'case' on, 'liga' off;

    font-size: get-font-size('l-2');
    line-height: 1.3;

    cursor: pointer;
    transition: all .3s linear;
    position: relative;

    margin-top: 45px;

    @media screen and (max-width: 991px) {
      margin-top: 64px;
    }

    @media screen and (max-width: 480px) {
      padding-top: 0;
    }

    &-link {
      color: get-colour('light-grey');
    }

    &:first-child {
      margin-top: 135px;
      @media screen and (max-width: 1200px) {
        margin-top: 100px;
      }
    }

    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 120px;

      @include position-absolute(50%, null, null, -140px);
      transform: translateY(-50%);

      background: -webkit-gradient(radial, 0% 2%, 0, 50% 50%, 553, from(transparent), to(#ffffff));
      opacity: 0;
      transition: all .3s linear;
    }

    &--active {

      .hiw-accordion__title-link {
        color: get-colour('white');
      }

      &:after {
        opacity: 1 !important;
      }
    }
  }

  &__content {

    grid-column: 2 / 3;
    grid-row: 1 / 7;

    &--hidden {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {

    &__title {
      font-size: 36px;
      line-height: 48px;
    }
  }

  @media screen and (max-width: 1024px) {

    &__title {
      font-size: 32px;
      line-height: 40px;
    }
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;

    &__title {
      font-size: get-font-size('m-2');

      &:first-child {
        margin-top: 0;
      }

      &:after {
        opacity: 1;
        left: auto;
        right: 0;
      }
    }

    &__content {

      &--active {
        grid-column: auto;
        grid-row: auto;
      }
    }
  }

  @media screen and (max-width: 480px) {
    &__title {
      &:not(:first-of-type) {
        margin-top: 64px;
      }
      &:after {
        opacity: 1;
        @include position-absolute(50%, -16px, auto, auto);
        width: 110px;
      }
    }
  }
}

.hiw-content {
  margin-top: -80px;
  @media screen and (max-width: 991px) {
    margin-top: 0;
  }

  &__item {
    display: grid;
    grid-template: '. bg-number' 50px
      'img header' 80px
      'img content' 1fr / 1fr 572px;
    //margin-bottom: 100px;
    grid-column-gap: 62px;
    &:not(:last-child) {
      margin-bottom: 165px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 90px;
      }
      @media screen and (max-width: 991px) {
        margin-bottom: 0;
      }
    }
  }

  &__bg-number {
    grid-area: bg-number;
    align-self: center;
    justify-self: left;

    color: rgba(get-colour('dark-grey'), .15);
    font-family: $font-family-primary;
    font-weight: get-font-weight('bold');
    font-size: 12.5em;
    line-height: 1.13;
    font-feature-settings: 'case' on;
  }

  &__img {
    grid-area: img;
    align-self: flex-start;
    justify-self: right;
    padding-top: 8px;

    svg {
      z-index: 1;
      width: 160px;
      height: 160px;
      @media screen and (max-width: 1366px) {
        width: 120px;
        height: 120px;
      }
      path {
        fill: get-colour('white');
      }
    }
  }

  &__header {
    grid-area: header;
    align-self: flex-start;

    font-family: $font-family-primary;
    font-weight: get-font-weight('black');
    font-feature-settings: 'case' on, 'liga' off;
    color: get-colour('white');

    font-size: get-font-size('l-2');
  }

  &__content {
    grid-area: content;

    font-family: $font-family-default;
    font-weight: get-font-weight('normal');
    font-size: 16px;
    line-height: 26px;

    color: get-colour('blue-text');
  }

  &__list {
    padding-left: 24px;
    list-style: disc;
  }

  @media screen and (max-width: 1200px) {

    &__item {
      grid-template: '. bg-number' 50px
        'img header' 80px
        'img content' 1fr / 1fr 400px;
      grid-column-gap: 30px;
    }

    &__bg-number {
      font-size: 11.25em;
    }

    &__header {
      font-size: get-font-size('l-3');
    }

    &__img svg {
      width: 90px;
      height: 90px;
    }
  }

  @media screen and (max-width: 1024px) {

    &__item {
      grid-template: '. bg-number' 50px
        'img header' 80px
        'img content' 1fr / 1fr 360px;
    }

    &__bg-number {
      font-size: 10em;
    }
  }

  @media screen and (max-width: 991px) {

    &__item {
      grid-template: 'bg-number img' 50px
        'header img' 50px
        'content content' 1fr / 1fr 80px;
      margin-bottom: 0;
      padding-top: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__img {
      align-self: end;
      padding-right: 32px;

      svg {
        width: 60px;
        height: auto;
      }
    }

    &__header {
      align-self: end;
      font-size: 20px;
      line-height: 28px;
    }

    &__bg-number {
      font-size: 72px;
      line-height: 80px;
      justify-self: left;
    }

    &__content {
      padding-top: 24px;
    }
  }

  @media screen and (max-width: 480px) {
    &__img {
      align-self: flex-end;
      width: 80px;
      padding-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: auto;;
      }
    }

    &__content {
      padding-top: 16px;
    }
  }
}

