.dash-button {
  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--medium);
  font-size: 14px;
  line-height: 1.5;
  text-align: right;
  color: var(--theme-color--grey);

  border: none;
  border-bottom: 1px dashed var(--theme-color--grey);

  padding: 0;
  background: transparent;
  cursor: pointer;
  align-self: end;
}
