.disabled {
    background-color: #e9eaf0;
    color: #9495a7;
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
        border-color: #babbcd;
    }

    &:focus {
        pointer-events: none;
    }
}