.new-offerings-container {
  text-align: center;
  margin-bottom: 72px;
  h1 {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 900;
    font-size: 3.75em;
    line-height: 75px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: #272835;
    margin-bottom: 10px;
    max-width: 800px;
    margin: auto;
  }
  .offerings-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 40px -17px 0;
    .plate-container {
      display: flex;
      flex-direction: column;
      background: $grey2;
      margin: 17px;
      width: calc(25% - 34px);
      box-shadow: -15px -15px 40px #ffffff, 15px 15px 40px #e5e4e7;
      text-align: center;
      border: solid 8px transparent;
      transition: all .3s ease-in;

      &:hover {
        border: solid 8px #636583;
      }

      &--disabled {
        opacity: .65;

        &:hover {
          border: solid 8px transparent;
        }
      }
      @media screen and (max-width: 1200px) {
        width: calc(33% - 34px);
      }
      @media screen and (max-width: 768px) {
        width: calc(50% - 34px);
      }
      @media screen and (max-width: 480px) {
        width: calc(100% - 34px);
      }
      .image-wrapper {
        display: flex;
        flex-grow: 1;
        background: $grey2;
        .image {
          display: block;
          width: 100%;
          height: auto;
          max-height: 250px;
          margin: auto;
          object-fit: contain;
        }
      }
      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 24px 0;
        height: 150px;
        @media screen and (max-width: 1366px) {
          margin: 20px 16px 0;
          height: 130px;
        }
        .icon {
          width: 88px;
          @media screen and (max-width: 1366px) {
            width: 78px;
          }
        }
      }
      .icon.special-icon {
        width: auto;
        @media screen and (max-width: 1366px) {
          width: 126px;
        }
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Playfair Display';
        font-size: 23px;
        font-weight: 900;
        line-height: 29px;
        color: $blue;
        margin-top: 6px;
        min-height: 58px;
        padding: 0 24px;
        @media screen and (max-width: 1366px) {
          padding: 0 16px;
        }
      }
      .description {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        color: $grey;
        padding: 12px 24px;
        @media screen and (max-width: 1366px) {
          padding: 12px 16px;
        }
      }
      .buttons-wrapper {
        display: flex;
        margin-top: auto;
        padding: 24px 24px 32px;
        @media screen and (max-width: 1366px) {
          padding: 24px 16px;
        }
        .button {
          width: calc(50% - 8px);
        }
      }
      .buttons-wrapper.single {
        justify-content: center;
      }
      .buttons-wrapper.multi {
        justify-content: space-between;
      }
    }
    .special-plate {
      border: solid 8px #636583;
      .icon-wrapper {
        margin: 12px 16px 0;
      }
      .buttons-wrapper {
        padding: 24px 16px;
        @media screen and (max-width: 1366px) {
          padding: 24px 8px 16px;
        }
      }
    }
  }
  .hint {
    margin-top: 55px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
  }
  .complete-button {
    margin: 24px auto 0;
  }
}
