.modal-with-controls {
  width: 560px;
  background-color: var(--theme-color--white);
  padding: 16px 40px 40px;
  border-radius: var(--theme-borderRadius--modal);

  display: flex;
  flex-direction: column;

  &__loader {
    margin: 0 auto;
  }

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    color: var(--theme-color--blue);
    font-size: 32px;
    line-height: 1.625;
    text-align: center;

    @media screen and (max-width: 1280px) {
      font-size: 28px;
    }

    @media screen and (max-width: 765px) {
      font-size: 24px;
    }
  }

  &__message {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blue);
    text-align: center;
  }

  &__controls {
    display: flex;
    align-items: center;

    margin-top: 16px;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;

      & > * {
        margin-bottom: 10px;
        margin-right: 0;

        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    border-radius: 0;
    width: 100%;
    padding: 12px 20px 20px;
    justify-content: center;
  }
}