.my-media-upload-wrapper {
  margin-left: auto;
}

.my-media-upload {
  display: inline-block;
  border-radius: var(--theme-borderRadius--default);
  background-image: linear-gradient(0, var(--theme-color--blue) 0, var(--theme-color--grey) 97.83%);
  padding: 2px;
  width: 100%;
  
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    
    outline: none;
    border-radius: var(--theme-borderRadius--default);
    padding: 15px;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0);
    background-image: linear-gradient(309.69deg, var(--theme-color--grey) 17.05%, var(--theme-color--blue) 69.2%);

    font-weight: var(--theme-fontWeight--bold);
    font-family: var(--theme-fontFamily--default);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    color: var(--theme-color--white);
    text-transform: uppercase;
  }

  &__input {
    display: none;
  }
}