$border: rgba(255, 255, 255, 0.2);

.cookies-details {
  > * {
    border-bottom: 1px solid $border;

    &:last-of-type {
      border-bottom: none;
    }

    > .controlled-collapse {
      margin: 16px;
      margin-top: 0;

      .collapse + .collapse {
        margin-top: 16px;
      }
    }
  }

  &-item {
    margin-bottom: 16px;
  }
}