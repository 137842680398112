// vm - is for video message

.vm {
  &--button-wrapper {
    margin-top: auto;

    .button {
      margin-top: 12px;
    }
  }

  &-download-button {
    svg {
      fill: white;
    }
  }

  &--column-text {
    flex: 1;
    align-items: center;
    display: flex;
  }
}
.social-buttons {
  display: flex;
  justify-content: space-between;
 @media only screen and (max-width: 725px) {
    display : block;
  }
}
.shareVideoModel{
  @media only screen and (max-width: 725px) {
    margin-right: 15px;
    margin-bottom: 15px;
    width: 100%;
  }
}

.social-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.social-button:hover {
  background-color: #0056b3;
}
.share-label {
  margin-bottom: 10px !important;
}
