.entourage-required {
  display: flex;
  align-items: center;

  margin: 0 auto;
  padding: 0 16px;

  max-width: 580px;
  width: 100%;

  @media screen and (max-width: 765px) {
    flex-direction: column;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__text {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--black);

    margin-left: 16px;

    a {
      text-decoration: none;
      color: var(--theme-color--blue);
      font-weight: var(--theme-fontWeight--bold);

      &:hover {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 765px) {
      margin-left: 0;

      text-align: center;
    }
  }
}
