.remove-item-modal {
  width: 600px;
  height: 100%;
  // max-height: 400px;
  padding: 12px 40px 40px;
  background-color: get-colour('white');
  border-radius: $modal-border-radius;

  &__header {
    padding-bottom: 12px;
  }

  &__file {
    @include display-flex(row, center, center);
  }

  &__content {
    @extend .font-caption;
    padding: 24px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-button {
    @extend .font-body-1-bold;
    padding: 4px 12px;
    border: none;
    border-radius: 4px;

    color: #272835;
    background: #e9eaf0;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .remove-item-agree {
    margin-right: 16px;
  }

  &__button-container {
    @include display-flex(row, center, flex-end);
  }

  &-remove-text {
    @extend .font-small;
  }

  // queries
  @media screen and (max-width: 1366px), screen and (max-height: 700px) {
    width: 418px;
    max-height: 644px;

    &__header {
      margin-bottom: 21px;
      font-size: 32px;
    }

    &__media-wrapper {
      margin-bottom: 30px;
    }

    .media-uploader__inner {
      height: 102px;
    }
  }

  @media screen and (max-width: 1280px), screen and (max-height: 1000px) {
    width: 602px;
    max-height: 940px;

    &__header {
      margin-bottom: 29px;
    }

    &__media-wrapper {
      margin-bottom: 44px;
    }

    .media-uploader__inner {
      height: 146px;
    }

    @media screen and (max-height: 820px) {
      max-height: 720px;
    }
  }

  @media screen and (max-width: 1024px), screen and (max-height: 700px) {
    width: 418px;
    max-height: 654px;

    &__header {
      margin-bottom: 21px;
    }

    &__media-wrapper {
      margin-bottom: 30px;
    }

    .media-uploader__inner {
      height: 102px;
    }
  }

  @media screen and (max-width: 800px), screen and (max-height: 620px) {
    width: 418px;
    max-height: 554px;

    &__header {
      margin-bottom: 14px;
    }

    &__media-wrapper {
      margin-bottom: 16px;
    }

    .media-uploader__inner {
      height: 102px;
    }
  }

  @media screen and (max-width: 600px) {
    max-height: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 32px 16px 16px;

    &__header {
      margin-bottom: 24px;
    }

    &__library {
      margin-bottom: 26px;
      margin-left: 0; // for shadow
      flex: 1;
      overflow: hidden;
    }

    &__media-wrapper {
      margin-bottom: 16px;
    }

    .media-uploader__inner {
      height: 129px;
    }
  }
}

// remove file
.remove-file {

  &__image,
  &__video {
    display: block;
    margin: 0 auto;
  }

  &--horizontal {
    width: 100%;
    height: auto;
  }

  &--vertical {
    width: auto;
    height: 100%;
    max-height: 240px;
  }

  @media screen and (max-width: 600px) {
    &__image,
    &__video {
      display: block;
      margin: 0 auto;
    }

    max-height: none;
    border-radius: 0;
    padding: 32px 16px 16px;
  }
}
