.faqs-container {
  max-width: 690px;
  margin: 0 auto 115px;
  padding: 20px;

  @media (max-width: 480px) {
    max-width: 100%;
    margin-bottom: 64px;
  }
}

.faqs-header {
  position: relative;
  font-size: 42px;
  font-family: var(--theme-fontFamily--primary);
  font-weight: var(--theme-fontWeight--black);
  font-style: normal;
  line-height: 54px;
  background: transparent;

  margin-top: 90px;
  margin-bottom: 130px;

  @media (max-width: 480px) {
    font-size: 34px;
    line-height: 1.2;
    margin-bottom: 64px;
  }

  &:after {
    content: 'FAQs';
    position: absolute;
    top: 0;
    left: -5%;
    bottom: 0;
    right: 0;
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 7em;
    color: var(--theme-color--lightGrey);
    opacity: 0.08;

    @media (max-width: 768px) {
      left: -32px;
      font-size: 6em;
    }

    @media (max-width: 480px) {
      left: -16px;
      top: -16px;
      font-size: 4em;
    }
  }

  @media (max-width: 480px) {
    margin-top: 20px;
    margin-bottom: 60px;
  }
}

.faqs-tab {
  h3 {
    font-size: 23px;
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-style: normal;
    line-height: 29px;
    cursor: pointer;
    overflow-wrap: anywhere;
  }

  p {
    font-size: 16px;
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    line-height: 26px;
    overflow-wrap: anywhere;

    padding-top: 30px;
    padding-bottom: 30px;

    transition: opacity 1s ease-out;
    opacity: 1;
    height: auto;

    ul,
    ol {
      padding-left: 12px;
    }

    ul {
      list-style: inside;
    }

    ol {
      list-style: decimal inside;
    }

    pre {
      font-family: monospace;
    }

    em,
    i {
      color: inherit;
      font-family: inherit;
      text-decoration: none;
      font-style: italic;
    }

    blockquote {
      border-left: 2px solid var(--theme-color--grey);
      padding-left: 12px;
    }

    h1,
    strong,
    b,
    a {
      font-weight: var(--theme-fontWeight--bold);
      color: inherit;
      font-family: inherit;
      text-decoration: none;
    }
  }

  .hidden {
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding-top: 0;
  }

  .border {
    margin-bottom: 30px;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(39, 40, 53, 1),
      rgba(39, 40, 53, 0)
    );
  }

  @media (max-width: 480px) {
    &:last-child {
      margin-bottom: 32px;
    }
  }
}
