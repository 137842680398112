.charities {

  &__charity {
    position: relative;
    margin-bottom: 16px;
  }

  &__percent {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 26px;
    grid-gap: 20px;

    .form-row__remove--white {
      top: 37px;
      right: 0;
    }

    .lessons-field {
      margin-bottom: 0;
    }
  }

  &__list {
    .remove-button {
      @media screen and (max-width: 991px) {
        right: 0;
      }
      @media screen and (max-width: 768px) {
        top: auto;
        bottom: 15px;
        transform: translateY(0);
      }
    }
  }
}

.charities-separator {
  height: 1px;
  background-image: linear-gradient(to right, rgba(39, 40, 53, 0), white, rgba(39, 40, 53, 0));
  margin: 24px 0;
}

.video-message,
.video-chat,
.video-message-commercial-use,
.create-exp,
.live-virtual-events {

  .react-select__control {
    height: 60px;
  }

  .input-mask {
    height: 60px;
  }
}

.create-experience-form,
.lesson-wrap {

  .charities {
    .react-select__control {
      height: 50px;
    }
  }
}

.video-message,
.video-chat,
.create-experience-form,
.video-message-commercial-use,
.create-exp,
.live-virtual-events {
  .charities {

    &__inner {
      display: grid;
      grid-template-columns: 391px 150px;
      align-items: end;
      grid-gap: 20px;
      margin-bottom: 40px;
      padding-bottom: 0;
    }

    &__charity {
      margin-bottom: 0;
    }

    &__percent {
      grid-template-columns: 1fr;

      .form-row__remove--black {
        right: -50px;
        top: 42px;
      }
    }

    @media screen and (max-width: 1024px) {
      &__inner {
        grid-template-columns: 2fr 1fr;
        padding-right: 45px;
      }
    }

    @media screen and (max-width: 768px) {
      &__inner {
        grid-template-columns: 1fr;
        grid-template-rows: 60px 60px;
        padding-right: 0;
      }

      &__percent {
        grid-template-columns: 1fr 40px;

        .form-row__remove--black {
          right: 0;
        }
      }
    }
  }

  .multiple-location__button {
    color: black;
    padding-top: 0;
  }
}
