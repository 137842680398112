.notifications-page {

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 60px;
    line-height: 1.25;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: var(--theme-color--blue);
    text-align: center;

    margin-bottom: 40px;
  }

  .notifications-table {
    padding-bottom: 100px;
  }

  @media screen and (max-width: 768px) {
    &__header {
      font-size: 48px;
    }

    .first-line-wrapper {
      display: none !important;
    }
  }
}

.notifications-setting-controls {
  margin-left: auto;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;

    width: 100%;

    &__select {
      margin-right: 0 !important;
    }

    &__select,
    &__button {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
