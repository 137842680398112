.live-virtual-events {
  &__submit-wrapper {
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 24px;
    @media screen and (max-width: 575px) {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 12px;
    }
  }
}