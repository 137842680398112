.order-media {
  display: grid;
  grid-template-columns: 60px 1fr 24px;
  height: 80px;
  gap: 16px;

  align-items: center;

  margin-bottom: 12px;

  &__remove-btn {
    border: none;
    cursor: pointer;
    background: none;
    padding: 0;
  }
}
