$bn-p-i: '.bottom-navigation__panel__item';
$active: '.active';

$counter-size: 16px;


#{$bn-p-i} { 
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--theme-color--lightGrey);
  transition: color 0.2s;
  cursor: pointer;

  &__icon {
    position: relative;

    .user-notifications-counter {
      top: calc(-#{$counter-size} / 2);
      right: calc(-#{$counter-size} / 2);
      width: $counter-size;
      height: $counter-size;

      &__number {
        top: 0;
        right: 0;
        background: var(--theme-color--white);
        color: var(--theme-color--blue);
      }
    }

    .unread-messages.active {
      top: calc(-#{$counter-size} / 4);
      right: calc(-#{$counter-size} / 4);
      width: calc(#{$counter-size} / 2);
      height: calc(#{$counter-size} / 2);
      position: absolute;
      display: block;
      background: var(--theme-color--white);
      border-radius: 50%;
    }
  }

  svg {
    height: 20px;
    width: auto;
    margin-bottom: 2px;

    path {
      fill: var(--theme-color--lightGrey);
      transition: fill 0.2s;
    }
  }

  &__label {
    font-size: 11px;
    line-height: 2;
    font-weight: var(--theme-fontWeight--medium);
  }

  &#{$active},
  &:hover {
    color: var(--theme-color--white);

    svg {
      path {
        fill: var(--theme-color--white);
      }
    }
  }
}