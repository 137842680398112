._selected-item {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-column-gap: 22px;
  align-items: center;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  &__image {
    width: 47px;
    height: 47px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: get-font-size('s-3');
    line-height: 1.33;
    text-transform: uppercase;
    color: get-colour('blue');
  }

  &__remove-button {
    margin-left: auto;
    position: relative;
    background-color: rgba(get-colour('white'), 0);
    padding: 0;
    width: 14px;
    height: 14px;
    border: none;
    outline: none;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 2px;
      background-color: get-colour('light-grey');
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

._menu-item-list {
  background: get-colour('white');
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: absolute;
  top: 120%;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

._menu-item {
  padding: 6px 16px;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 12px;
  align-items: center;
  cursor: pointer;
  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: get-colour('ultra-light-blue');
  }

  &__image {
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: get-font-size('s-1');
    line-height: 1.625;
    color: get-colour('dark-grey');
  }
}

._multi-select {

  &__label {
    span {
      font-family: $font-family-default;
      font-weight: get-font-weight('bold');
      font-size: get-font-size('s-2');
      line-height: 1.5;
      text-transform: uppercase;
      color: get-colour('light-grey');
    }
  }

  &__list {
    margin-top: 24px;
  }
}

._no-option {
  padding: 6px 16px;
}

._menu {
  background: get-colour('white');
  padding: 8px 0;
}
