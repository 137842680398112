$whm: '.working-hours-modal';
$whf: '.working-hours-form';

#{$whm} {
  padding: 16px 40px 40px;
  width: 548px;

  border-radius: var(--theme-borderRadius--modal);
  background-color: var(--theme-color--white);

  .modal-header,
  .modal-text {
    margin-bottom: 16px;
  }

  .days-list {
    justify-content: space-between;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    border-radius: 0;
    height: 100%;
    padding: 12px 20px 20px;
  }

  &__wrapper {
    height: 100vh;
  }
}


#{$whf} {
  &__form-group {
    margin-bottom: 24px;

    &--two-col {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    &__form-group--two-col {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }

    .days-list {
      justify-content: start;
    }
  }
}
