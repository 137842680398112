$tpv-image: '.tpv-image-layer';

#{$tpv-image} {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 878px;
  max-height: 1400px;
  margin: 0 auto;

  @media only screen and (max-width: 1366px) {
    max-width: 544px;
  }
  
  @media only screen and (max-height: 849px) and (min-width: 1024px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-height: 900px;
  }
    
  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}