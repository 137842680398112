$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;

.aficionado-profile {
  margin-top: 40px;
  margin-bottom: 60px;
  display: flex;

  @media screen and (max-width: #{$media-lg}) {
    flex-direction: column;
  }

  &__full-name {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 42px;
    line-height: 1.28;
    font-feature-settings: 'case' on, 'liga' off;
    color: var(--theme-color--white);
    display: inline-block;
    margin-bottom: 16px;

    @media only screen and (max-width: #{$media-dt}) {
      font-size: 42px;
    }

    @media only screen and (max-width: #{$media-lg}) {
      font-size: 36px;
    }

    @media only screen and (max-width: #{$media-md}) {
      font-size: 28px;
    }

    @media only screen and (max-width: #{$media-sm}) {
      font-size: 24px;
    }
  }

  &__main {
    width: 100%;
    max-width: 500px;

    @media screen and (max-width: #{$media-dt}) {
      max-width: 400px;
    }

    @media screen and (max-width: #{$media-md}) {
      max-width: 375px;
    }

    @media screen and (max-width: #{$media-sm}) {
      max-width: 340px;
    }
  }

  &__info,
  &__photos-videos {
    flex: 1 1 50%;
  }

  &__info {
    display: flex;

    @media only screen and (max-width: #{$media-sm}) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__photos-videos {
    h3 {
      font-family: var(--theme-fontFamily--primary);
      font-weight: var(--theme-fontWeight--black);
      font-size: 28px;
      line-height: 1.36;
      text-align: center;
      font-feature-settings: 'case' on;
      color: var(--theme-color--white);
    }

    @media screen and (max-width: #{$media-lg}) {
      margin-top: 40px;
    }
  }

  &__avatar-wrapper {
    text-align: center;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    flex-direction: column;
    margin-right: 60px;

    @media only screen and (max-width: #{$media-dt}) {
      margin-right: 40px;
    }

    @media only screen and (max-width: #{$media-sm}) {
      margin: 0 auto 24px;
    }

    .avatar {
      width: 182px;
      height: 182px;
      background: #1e1f28;
      border: 8px solid #2a2c39;
      box-shadow: 14px 17px 35px #16171f, -7px -10px 20px 7px #4d516a;

      @media only screen and (max-width: #{$media-dt}) {
        width: 112px;
        height: 112px;
        border: 4px solid #2a2c39;
        margin: 0;
      }

      @media only screen and (max-width: #{$media-md}) {
        width: 140px;
        height: 140px;
      }
    }
  }

  &__message-btn {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blue);
    border: none;
    cursor: pointer;
    position: static;
    z-index: 1;

    background: var(--theme-color--white);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: var(--theme-borderRadius--default);
    padding: 5px 12px;

    margin-top: -18px;
  }

  &__edit-btn {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 12px;
    line-height: 2.5;
    color: var(--theme-color--white);
    letter-spacing: 0.07em;
    text-transform: uppercase;

    border: 2px solid var(--theme-color--white);
    border-radius: var(--theme-borderRadius--default);
    padding: 2px 20px;

    margin-top: 24px;
  }

  &__background-reports {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 16px;
    line-height: 1.625;
    display: flex;
    align-items: center;
    color: var(--theme-color--white);

    margin-bottom: 32px;

    svg {
      margin-right: 16px;
    }
  }

  &__separator {
    margin-bottom: 20px;
    margin-top: 16px;
  }

  &__no-information {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blueText);

    margin-bottom: 16px;
  }

  .social-list {
    margin-top: 12px;
  }

  .base-icon {
    margin: 0 2px;
  }
}


