.pdf {
  &-wrapper {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    > h1 {
      margin-bottom: 48px;
      text-align: center;
    }
  }

  &-document {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &-page {
    margin-bottom: 16px;
    box-shadow: var(--theme-boxShadow--modal);
    border-radius: var(--theme-borderRadius--modal);
    overflow: hidden;
  }
}