.calendar-sidebar {
  padding: 16px;

  &-heading {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    span {
      min-width: 14px;
      height: 14px;
      display: block;
      border-radius: 4px;
      margin: 4px;
    }

    h6 {
      font-size: 16px;
      line-height: 1.625em;
      color: var(--theme-color--blue);
      font-weight: var(--theme-fontWeight--bold);
      margin-left: 12px;
      overflow-wrap: break-word;
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }

  .button {
    margin-top: 24px;
  }
}

.addeventatc {
  margin-top: 24px;
  width: 100%;
  text-align: center;

  &.fat {
    padding: 18px;
    margin-top: 0px;
    margin-bottom: 24px;

    .addeventatc_icon {
      top: calc(50% - 11px)
    }
  }
}