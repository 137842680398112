.notification-menu {
  position: absolute;
  z-index: 2;
  top: 100%;
  right: 0;

  width: 540px;

  border-radius: var(--theme-borderRadius--default);
  box-shadow: var(--theme-boxShadow--modal);
  background: var(--theme-color--white);
  cursor: default;

  transition: all .3s ease-in-out;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;

  &--active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  &__header {
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 16px;

    border-bottom: 1px solid var(--theme-color--ultraLightBlue);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__count {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 14px;
    line-height: 1.4;
    color: var(--theme-color--grey);
  }

  &__setting-link {
    margin-left: 26px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer-link {
    width: 100%;
    padding: 20px;
    display: block;
    position: relative;
    z-index: 1;

    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
    color: var(--theme-color--grey);

    border-top: 1px solid var(--theme-color--ultraLightBlue);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.15);
  }
}

.notification-list {
  max-height: 356px;
  overflow-y: scroll;

  &__loader {
    height: 20px;
    display: flex;
    justify-content: center;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
  }
}
