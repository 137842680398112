$exp-pv: '.experience-pv-wrapper';

// TODO: Add color to css variables
$exp-pv-card-border-color: #575b6c;

// TODO: add shadow to css variables
$exp-pv-card-image-shadow: 0 20px 60px rgba(var(--theme-color--black), 0.6);

#{$exp-pv} {
  &__card {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 800px;
    min-height: 605px;
    border: 1px solid $exp-pv-card-border-color;
    filter: drop-shadow($exp-pv-card-image-shadow);
    border-radius: 4px;

    &__image {
      display: flex;
      flex-direction: row;
      align-items: center;
      object-fit: cover;
      width: 100%;
      height: auto;
      min-height: 620px;
    }
  }
}