.date-divider {
  text-align: center;
  flex: 1 1;

  p {
    margin-bottom: 10px;
    margin-top: 22px;
    padding: 0 16px;

    &:before,
    &:after {
      content: '';
      border-top: 1px solid var(--theme-color--blueText);
      width: 50%;
    }

    &:before,
    &:after,
    span {
      display: table-cell;
      white-space: nowrap;

      font-weight: var(--theme-fontWeight--bold);
      font-size: 13px;
      line-height: 1.7;
    }

    span {
      color: var(--theme-color--blueText);
      text-align: center;
      padding: 0 10px;
      position: relative;
      top: -11px;
    }
  }
}