.phone-container {
  @include display-flex(column);

  &__container {
    @include display-flex(column, flex-start);
  }

  &__wrapper {
    position: relative;
  }

  &__wrapper:first-child:not(:last-child) {
    margin-bottom: 50px;
  }

  &__inner {
    @include display-flex(row, center, space-between);
    width: 100%;
    button {
      width: 150px;
    }

    @media only screen and (max-width: 540px) {
      flex-direction: column;

      .button {
        margin-top: 12px;
        margin-left: 0 !important;
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }

  .ml-16 {
    margin-left: 16px;
  }
}
