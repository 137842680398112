$tpv: '.tpv-page';
$vcm: '.view-calendar-mode';

#{$tpv} {
  &__wrapper {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 1472px;
    margin: 0 auto;
    padding: 0 30px 30px;

    @media screen and (max-width: 575px) {
      padding: 0 16px;
    }
  }

  &__title-wrapper {
    position: relative;

    .title--default {
      font-weight: var(--theme-fontWeight--bold);
      font-size: 105px;
      line-height: 1;
      text-transform: capitalize;

      @media only screen and (max-width: 1366px) {
        font-size: 68px;
      }

      @media only screen and (max-width: 575px) {
        font-size: 46px;
      }

      span {
        font-weight: var(--theme-fontWeight--black);
      }
    }

    .buttons {
      @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
      }
    }

    .saved {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 9998;

      @media screen and (max-width: 1024px) {
        position: static;
        transform: translateY(0);
      }
    }

    .view-calendar {
      margin-bottom: 0;
      margin-right: 24px;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    @media screen and (max-width: 1024px) {
      margin-bottom: 24px;
    }
  }

  &__content {
    width: 100%;
    padding: 0 15px;
    margin: 20px auto 0;

    @media only screen and (max-width: 1366px) {
      max-width: 1020px;
    }

    @media only screen and (max-width: 1024px) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }

    .talent-reviews {
      @media only screen and (max-width: 1024px) {
        margin-top: 40px;
      }

      @media only screen and (max-width: 991px) {
        margin-top: 0;
      }
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 80px;

      @media screen and (max-width: 1366px) {
        padding: 0 70px;
      }

      &__column {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__description {
    max-width: 550px;
    margin: 0;
    color: var(--theme-color--black);
    font-family: var(--theme-fontFamily--primary), sans-serif;
    font-weight: var(--theme-fontWeight--medium);
    font-size: 60px;
    line-height: 1.25;
    text-align: right;
    font-feature-settings: 'case' on;
    text-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
    z-index: 2;

    &:after {
      content: '\201D';
    }

    &:before {
      content: '\201C';
    }

    @media screen and (max-width: 1366px) {
      font-size: 48px;
      max-width: 400px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 42px;
      max-width: 100%;
    }

    @media screen and (max-width: 767px) {
      font-size: 36px;
      max-width: 100%;
    }
  }
}

#{$vcm} {
  margin-bottom: 40px;
  pointer-events: auto;

  svg.action-button__icon {
    margin: -4px auto;
    fill: var(--theme-color--blue);
  }

  &:hover {
    > svg.action-button__icon {
      fill: var(--theme-color--blue);
    }
  }
}
.center-button{
    justify-content: center;
    display: block;
    margin: auto 0;
}
.calendar-button-height{
  height: 43px;
}
.calendar-button-color{
  display: flex;
  fill: var(--theme-color--blue)
}
