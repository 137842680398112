.Toastify__toast {
  width: fit-content !important;
  max-width: unset !important;
  border-radius: 4px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: unset !important;
  padding: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: 500!important;
  font-size: 16px!important;
  line-height: 26px!important;
  background: linear-gradient(360deg, #272835 0%, #636583 97.83%)!important;
  box-shadow: 0 4px 32px rgba(31, 35, 70, 0.2)!important;
  color: #ffffff;
}

.Toastify__close-button {
  align-self: center !important;
  margin-left: 10px !important;
  margin-top: 3px !important;
}

.Toastify__toast-body {
  font-family: 'Montserrat', sans-serif !important;
  margin: unset !important;
  margin-right: 8px !important;
  width: max-content;
}

.Toastify__toast--error {
  i {
    color: #d72638;
  }
}

.Toastify__toast--warning {
  i {
    color: yellow;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin: 0 auto;
  }

  .Toastify__toast-body {
    width: auto;
  }
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
}
