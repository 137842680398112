$ce-book: '.commercial-engangement-book';

#{$ce-book} {
  pointer-events: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 15px;
  margin-left: 32px;

  .icon {
    svg {
      width: 50px;
      height: 54px;
    }
  }

  .text {
    margin-top: 18px;
    margin-bottom: 12px;
    color: var(--theme-color--grey);
    font-size: 18px;
    font-weight: var(--theme-fontWeight--semiBold);
    line-height: 1.625;
      
    @media screen and (min-width: 1024px) {
      br {
        display: none;
      }
    }
  }
}