.social-list {
  display: flex;
  align-items: center;

  &__item {
    cursor: pointer;

    svg path {
      transition: fill .3s ease-in-out;
    }

    &:hover svg path {
      fill: var(--theme-color--white);
    }

    &:not(:last-child) {
      margin-right: 18px;
    }
  }
}
