.form-row {
  display: flex;
  justify-content: center;

  &__item {

    &:first-child {
      flex: 0 0 35%;
      margin-right: 72px;
      margin-top: 12px;
    }

    &:last-child {
      flex: 0 0 65%;
      margin-bottom: 50px;
      @media #{$sm} {
        margin-bottom: 24px;
      }
    }

    @media screen and (max-width: 1286px) {

      &:first-child {
        margin-right: 56px;
      }
    }

    @media screen and (max-width: 1028px) {

      &:first-child {
        margin-right: 56px;
      }
    }
  }

  &__width {
    position: relative;
    max-width: 561px;

    @media screen and (max-width: 1024px) {
      max-width: 388px;
    }

    @media screen and (max-width: 768px) {
      max-width: none;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &__button {
    font-family: $font-family-default;
    font-style: normal;
    font-weight: get-font-weight('bold');
    font-size: get-font-size('s-1');
    line-height: 1.5;

    /* identical to box height, or 162% */

    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: get-colour('black');
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;

    &--white {
      color: get-colour('white');
    }

    &--black {
      color: get-colour('black');
    }
  }

  &__remove {
    position: absolute;
    right: -52px;
    top: 50%;
    transform: translateY(-50%);

    padding: 0;
    height: 26px;
    width: 26px;

    outline: none;
    cursor: pointer;
    background: transparent;
    border: none;

    // background: linear-gradient(360deg, #272835 0%, #636583 97.83%) !important;
    border-radius: 50%;

    i {
      font-size: 26px;
    }

    &::after,
    &::before {
      content: ' ';
      height: 16px;
      width: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &--black {
      background: linear-gradient(360deg, #272835 0%, #636583 97.83%) !important;

      &::after,
      &::before {
        background: white;
      }
    }

    &--white {
      background: get-colour('white') !important;

      &::after,
      &::before {
        background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
      }
    }

    @media #{$md-lg-sizes} {
      right: 0;
    }
  }
}


// label component
.label {
  @include h4;
  color: $blue;
  text-align: right;
  margin-left: auto;
  max-width: 320px;

  @media screen and (max-width: 1024px) {
    max-width: 220px;
  }

  @media screen and (max-width: 768px) {
    text-align: left;
    align-self: end;
    align-content: end;
    padding-bottom: 10px;
    max-width: none;
  }
}

.tip {
  @include Small;
  text-align: end;
  color: $blue;
  max-width: 220px;
  margin-left: auto;

  @media screen and (max-width: 768px) {
    text-align: left;
    max-width: none;
    padding-bottom: 8px;
  }
}

// radio group component
.radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;

  .radio {
    margin-right: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__input {
      appearance: none;
      height: 26px;
      width: 26px;
      border-radius: 50%;

      border: 2px solid $light_grey;
      transition: 0.2s all linear;
      margin-right: 5px;

      position: relative;
      // top: 4px;
      outline: none;

      &:checked {
        border: 6px solid $light_grey;
      }
    }

    &__label {
      @include Body2;
      color: $blue;
    }
  }
}

// radio button component
.radio-buttons {

  &__inner {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: -10px 0 0 -10px;
    width: 820px;

    @media #{$size-xxl-only} {
      width: 561px;
    }

    @media #{$md-lg-sizes} {
      width: 100%;
      flex-direction: column;
    }
  }

  .radio-button {
    margin: 10px 0 0 10px;

    &__input {
      opacity: 0;
      position: fixed;
      width: 0;

      &:checked + label {
        background-color: $blue;
        color: #ffffff;
      }

      &:disabled:not(:checked) {
        color: red;
        & + .radio-button__text {
          cursor: not-allowed;
          color: $blue_text;
        }
      }

      @media #{$md-lg-sizes} {
        width: 100%;
      }
    }

    &__text {
      cursor: pointer;
      display: flex;
      align-items: center;
      @include Input_Style;
      color: $grey;
      background: $ultra_light_blue;
      border-radius: 4px;
      padding: 6px 18px;
      transition: all .3s linear;
      // margin-right: 20px;
      // margin-bottom: 20px;
      margin-right: 0;
      margin-bottom: 0;
      height: 45px;

      @media #{$md-lg-sizes} {
        width: 100%;
      }
    }

    &__tip {
      font-size: 12px;
    }

    @media #{$md-lg-sizes} {
      width: 100%;
    }
  }
}

// error message component
.error-message-container {
  position: absolute;
  top: 100%;
}

.error-message {
  color: red;
  display: block;
  padding-top: 5px;
}

// input component
.input-container {
  &.dollar,
  &.percent {
    .experience-input {
      padding-left: 28px;
    }
  }

  &.percent::before {
    content: '%';
    @include Input_Style;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    left: 12px;
  }

  &.dollar::before {
    content: '$';
    @include Input_Style;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    left: 12px;
  }
}
.experience-input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  // width: 561px;
  padding: 0 18px;
  height: 60px;
  @include grey_border;
  border-radius: 4px;
  @include Input_Style;
  color: $blue;
  background: transparent;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: get-colour('light-grey');
    opacity: 1; /* Firefox */
  }

  &:disabled {
    background-color: $ultra_light_blue;
  }


  // remove arrow
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

  &.error {
    border: 2px solid red;
  }
  @media (max-width: 1200px) {
    // width: 481px;
  }
  @media (max-width: 992px) {
    // width: 361px;
  }
  @media (max-width: 767px) {
    // max-width: none;
  }
}

// text area component
.text-area-group {
  &__field {
    @extend .input;
    @extend .input-default;
    resize: none;
  }

  &__tip {
    @include Small;
    color: $blue;
    display: block;
    text-align: end;

    position: absolute;
    top: 100%;
    right: 0;
  }
}

// async select component
.basic-multi-select {

  > div:first-child {
    @include grey_border;
    @include Input_Style;
    color: $light_grey;
  }

  &.dark .select__multi-value {
    background: transparent;
    &__label {
      color: #ffffff;
    }
  }

  &.auto-height .select__control {
    height: auto!important;
  }
}

// select component
.experience-select-wrapper {
  display: flex;
  flex-direction: column;
}

// label tip
.label-tip {
  @include Small;
  color: $blue;
  display: block;
  text-align: end;
  margin-left: auto;
  max-width: 500px;
  background-color: rgba(get-colour('white'), 0);
  border: none;

  strong {
    font-weight: bold;
  }

  @media #{$md-lg-sizes} {
    text-align: start;
  }
}

.justify {
  text-align: justify;
}

.notification {

  .term-experience__inner {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #626473;
  }
}

// term
.term-experience {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__link {
    @include Small;
    color: $blue;
    text-decoration: underline;
  }

  @include Small;
  color: $blue;

  input[type=checkbox] {
    @include Body2;
    color: $grey;
    margin-right: 16px;
    width: 26px;
    height: 26px;
  }

  a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
  }
}

.required-information {
  display: block;
  margin-bottom: get-spacer('5');
}

// occurrences limit
.occurrences-limit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .container {
    margin-bottom: 0;
    color: $grey;
  }

  &__item {
    height: 60px;
    @include grey_border;
    border-radius: 4px;
    width: 225px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .occurrences-limit-span {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input:disabled {
      + .occurrences-limit-span {
        color: $blue_text;
        background-color: #fafafa;
      }
    }

    input {
      padding: 0 18px;
      height: 100%;
      box-sizing: border-box;
      display: block;
      width: 40%;
      @include Input_Style;
      color: $light_grey;
      text-align: start;
    }
  }

  &__checkbox {
    @include Body2;
    color: $grey;
    margin-right: 16px;
    margin-top: 15px;
  }

  span {
    @extend .experience-time-controller__label;
  }
}

// multiple location
.multiple-location {
  &__button {
    padding-top: 24px;
    @include Body2_bold;
    color: $blue;
    border: none;
    background: transparent;
    cursor: pointer;
  }
}

// checkbox
.multi-checkbox {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;

  .custom-checkbox-container {
    display: flex;
    align-items: center;

    &:hover {
      .checkbox-remove-btn {
        visibility: visible;
      }
    }
    .checkbox-remove-btn {
      visibility: hidden;
      border: none;
      background: transparent;
      cursor: pointer;

      margin-bottom: 32px;
    }
  }

  .checkboxCustom {
    margin-bottom: 32px;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
  }

  &__button {
    @include Body2_bold;
    color: $blue;
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: left;
  }

  .checkmark__container {
    margin-bottom: 32px;
  }

  .ml30 {
    margin-left: 30px;
  }
}

.form-submit-button {
  margin-top: 70px;
  width: 100%;
  max-width: 198px;
  &:first-of-type {
    margin-right: 20px;
  }
}

.full-width {
  width: 100% !important;
}

.experience-icon {
  font-size: 32px;
  color: $light_grey;
}

// multi select
.multi-select {
  > div {
    @include grey_border;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;

    color: $light_grey;

    min-height: 60px;
  }
}

// team take care
.team-take-care {
  @include Body2;
  color: $grey;
  padding-top: 25px;
}

// calendar wrapper
.calendar-wrapper {
  &__text {
    @include Body2;
    color: $grey;
    padding-top: 18px;
  }
}

// ************ FILE UPLOADERS ************

.common-drop-zone__picture-gradient {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(64, 64, 64, 0) 0%,
    rgba(64, 64, 64, 1) 100%
  );
  transition: opacity .2s linear;
}

// ************ MEDIA UPLOADER ************
.media-uploader__inner {
  position: relative;
  color: $light_grey;
  @include grey_border;
  border-radius: $border-radius;
  height: 230px;

  &:hover {
    .media-uploader__remove-button {
      opacity: 1;
    }
  }

  > div {
    display: flex;
  }

  .loader {
    margin: auto;
  }
}

.portrait-photo {
  width: 270px;
  height: 360px;
}

.media-uploader__image-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @include Body2;;
  color: $light_grey;
  font-size: 48px;
  line-height: 52px;
  text-align: center;

  @include display-flex(column, center, flex-end);

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  span {
    padding: 10px;
  }
}

.media-uploader__file-name {
  @include Caption;
  color: $grey;
}

.media-uploader__file-duration {
  color: $light_grey;
}

.media-uploader__file-size {
  @include Small;
  color: $light_grey;
  opacity: 0.8;
}

.media-uploader__content {
  flex: 1;
  @include display-flex(column, center, center);
  padding: 0 32px 24px;

  span {
    @include Body2;;
    font-weight: 700;
    color: $blue;
    padding-top: 8px;
    padding-bottom: 39px;
  }

  h6 {
    margin-top: 0;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: $blue;
  }

  i {
    padding-top: 45px;
    font-size: 38px;
  }

  p {
    @include Body2;
    text-align: center;
    color: $light_grey;
  }
}

.video-wrapper {
  height: auto;
}

// ************ REMOVE BUTTON ************
// shared remove button
.common-remove-button {
  position: absolute;
  display: block;
  background: white !important;
  font-family: Montserrat;
  font-size: get-font-size('s-1');
  line-height: 32px;
  color: $blue;
  border: 2px solid $blue;
  border-radius: $border-radius;
  opacity: 0;
  transition: all .2s linear;
}

.register-upload-profile-pic__remove-button {
  @extend .common-remove-button;
  bottom: 20px;
  left: 75px;
  padding: 6px 18px;
  font-size: get-font-size('s-3');
}

.picture__remove-button {
  @extend .common-remove-button;
  bottom: 30px;
  left: 11px;
  padding: 5px 8px;
}

.media-uploader__remove-button {
  @extend .common-remove-button;
  padding: 11px 10px;
  right: 24px;
  top: 21px;

  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
  line-height: 32px;
  border-radius: 0;
  box-shadow: 3px 3px 7px rgba(209, 210, 225, 0.3), -3px -3px 7px rgba(250, 250, 255, 0.5);

  cursor: pointer;
  border: none;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 20px;
  }
}

// error border
.error-border {
  border: 2px solid red !important;
}

// loading border
.loading-border {
  border: 2px solid blue;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-12 {
  margin-bottom: 12px;
}

.charity-tip {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: $blue;
  padding-top: 10px;
  display: block;

  a {
    color: $blue;
    text-decoration: underline;
  }
}

.common-button-submitted {
  background: linear-gradient(360deg, #28a745 0%, #28a770 97.83%) !important;
}

.common-button-gradient {
  background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
  padding: 2px;
  border-radius: 4px;
}

.common-button {
  width: 100%;
  height: 52px; // 56px - 2px border
  font-family: Montserrat;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  border: none;
  text-transform: uppercase;

  display: block !important;
  color: #ffffff !important;
  text-align: center;
  background: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0 4px 25px rgba(0, 0, 0, 0.45) !important;
  border-radius: 4px;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed !important;
  }

  &:hover {
    cursor: pointer;
    background: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%);
  }
}

.small-button {
  width: max-content;

  .common-button {
    height: 46px; // 50px - 2px border
    padding: 10px 40px;
    color: #ffffff !important;
    text-transform: uppercase !important;
    background: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%) !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0 4px 25px rgba(0, 0, 0, 0.45) !important;
    border-radius: 4px  !important;
  }
}


.error {
  border-color: red;
}

.license-children {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  i {
    font-size: 24px;
    margin-bottom: 16px;
    margin-top: 62px;
  }

  h3 {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;

    color: #272835;
    margin-bottom: 32px;
  }

  p {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    color: #9495a7;
  }
}

.r-image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  video {
    background-color: #000000;
  }

  &:hover {
    .common-drop-zone__picture-gradient {
      opacity: 1;
    }
  }
}

.r-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.upload-hint {
  @include display-flex(column, center, center);
  width: 100%;
  height: 100%;

  text-align: center;

  i {
    font-size: 24px;
    margin-bottom: 16px;
    // margin-top: 62px;
  }

  h3 {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;

    color: #272835;
    // margin-bottom: 32px;
  }

  p {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    color: #9495a7;
  }
}

.tip-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media only screen and (max-width: 768px) {
    position: absolute;
    bottom: 0;
  }

  &__button {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;

    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: all .3s linear;

    &:hover {
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.45);
    }

    @media only screen and (max-width: 768px) {
      padding: 2px;
    }
  }
}

.forgot-password-button {
  font-family: $font-family-default;
  font-weight: get-font-weight('medium');
  font-size: 14px;
  line-height: $default-line-height;
  color: get-colour('blue');

  display: block;
  margin-left: auto;
  border: none;
  background-color: transparent;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    font-size: get-font-size('s-4');
  }
}
