.custom-video-player {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--theme-color--blue);

  video {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    outline: none;
    pointer-events: all;
  }

  &.fullscreen {
    video {
      object-fit: contain;
    }
  }

  .video-icon {
    position: absolute;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
    z-index: 3;
    pointer-events: all;
    opacity: 0.6;

    .path {
      fill: linear-gradient(360deg, #272835 0%, #636583 97.83%);
    }
  }

  &.play {
    .video-icon {
      opacity: 0;
    }
  }

  &:hover {
    .video-icon {
      opacity: 0.6;
    }
  }

  .volume-icon {
    width: 45px;
    height: 45px;
    left: 15%;
    bottom: 0;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .play-icon {
    width: 60px;
    height: 60px;
    left: 50%;
    bottom: -8px;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .fullscreen-icon {
    width: 45px;
    height: 45px;
    left: 85%;
    bottom: 0;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1366px) {
    .volume-icon {
      width: 30px;
      height: 30px;
      bottom: 0;
    }

    .play-icon {
      width: 45px;
      height: 45px;
    }

    .fullscreen-icon {
      width: 30px;
      height: 30px;
      bottom: 0;
    }
  }

  @media screen and (max-width: 668px) {
    .volume-icon {
      width: 20px;
      height: 20px;
      bottom: 0;
    }

    .play-icon {
      width: 32px;
      height: 32px;
    }

    .fullscreen-icon {
      width: 20px;
      height: 20px;
      bottom: 0;
    }
  }

  @media screen and (max-width: 320px) {
    .volume-icon {
      width: 18px;
      height: 18px;
      bottom: 0;
    }

    .play-icon {
      width: 35px;
      height: 35px;
    }

    .fullscreen-icon {
      width: 18px;
      height: 18px;
      bottom: 0;
    }
  }
}
.video-object-fit-contain {
  object-fit: contain !important;
  width: 100%;
  height: 100%;
}