.carousel-icon {
    position: absolute;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
    z-index: 3;
    pointer-events: all;
    opacity: 0.6;

    .path {
      fill: linear-gradient(360deg, #272835 0%, #636583 97.83%);
    }
  }
  
  .fullscreen-icon-carousal {
    width: 60px;
    height: 45px;
    margin-right: 50px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  .play-icon-carousal {
    width: 45px;
    height: 45px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  .volume-icon-carousal {
    width: 60px;
    height: 45px;
    margin-left: 50px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 668px) {
    .volume-icon-carousal {
      width: 50px;
      height: 35px;
      margin-left: 30px;
    }

    .play-icon-carousal {
      width: 50px;
      height: 35px;
    }

    .fullscreen-icon-carousal {
      width: 50px;
      height: 35px;
      margin-right: 30px;
    }
  }

  // @media screen and (max-width: 320px) {
  //   .volume-icon-carousal {
  //     width: 18px;
  //     height: 18px;
  //   }

  //   .play-icon-carousal {
  //     width: 35px;
  //     height: 35px;
  //   }

  //   .fullscreen-icon-carousal {
  //     width: 18px;
  //     height: 18px;
  //   }
  // }
  .hide-video-icon{
    visibility: hidden;
  }
