.not-found-container {
  @include display-flex(column, center, center);
  margin: 0 auto;
  width: 100%;
  position: relative;
  flex: 1;
}

.not-found-header {
  @include display-flex(row, center);

  text-align: center;
  font: {
    family: $font-family-primary;
    feature-settings: 'case' on;
    size: get-font-size('xl-1');
  }
  color: get-colour('blue');
  opacity: 0.08;
}

.not-found-content {
  position: absolute;

  text-align: center;
  color: get-colour('blue');
  font: {
    weight: get-font-weight('bold');
    size: get-font-size('m-1');
  }

  a {
    color: inherit;
  }
}
.talent-pending-for-approval-content{
  position: absolute;
  text-align: center;
  color: #272835;
  font-weight: 700;
  font-size: 1em;
  width: 70%;
  line-height: 21px;
}