// gradients (figma)
$degrade: linear-gradient(360deg, #272835 0%, #636583 97.83%);
$stroke: linear-gradient(360deg, #272835 0%, #636583 97.83%);
$grad_butt: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%);
$grey_gradient: linear-gradient(158.26deg, #bbbbbe 5.55%, #cacbd1 92.68%);

// colors (figma)
$blue: #272835;
$grey: #626473;
$light_grey: #9495a7;
$blue_text: #babbcd;
$ultra_light_blue: #e9eaf0;
$white: #ffffff;
$grey2: #f5f5f5;

// colors 
$text_main: #c4c4c4;
$border_color: #babbcd;

// font family
$font-family-primary: Playfair Display;
$font-family-default: Montserrat;
