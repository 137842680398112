.joinToTalent-entreevip {
  $modal-media-sm: 725;

  @mixin modal-media($width) {
    @media only screen and (max-width: #{$width - 1px}) {
      @content;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;
    .react-select-container {
      .error {
        margin-top: 0;
      }
      .react-select__menu {
        padding: 0;
        box-shadow: none;
      }
      .react-select__control {
        height: 56px;
      }
    }
    ._multi-select__list {
      height: 180px;
      overflow-y: auto;
      padding-right: 20px;
      margin-right: -20px;
      @include modal-media($modal-media-sm) {
        padding-right: 0;
        margin-right: 0;
        overflow-y: auto;
      }
    }
    .error-message-container {
      position: static;
    }
  }
  &__submit {
    align-self: flex-end;
  }
}
