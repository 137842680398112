$p-v-c: '.public-view-card';

#{$p-v-c} {
  &--bordered {
    position: relative;
    border-radius: 4px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to bottom, get-colour('white'), rgba(0, 0, 0, 0)) 1 100%;
    border-image-slice: 1;
    border-top: 0;

    @media only screen and (max-width: 1024px) {
      border: none;
      &.gradient {
        border: 0;
        border-image: unset;
      }
    }

    @media only screen and (max-width: 767px) {
      max-height: none;

      .content {
        flex-direction: column;
      }
      .top-border {
        display: none;
      }
    }

    .top-border {
      display: table;
      width: 100%;
      position: absolute;
      top: 0;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
      &::before,
      &::after {
        content: '';
        border-top: 2px solid get-colour('white');
        width: 10%;
        display: table-cell;
        white-space: nowrap;
      }
    }

    .content {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: row;
      padding: 0 32px;

      .slider,
      .description {
        flex: 1;
      }

      .slider {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @media only screen and (max-width: 1024px) {
        padding: 24px 0;
      }

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        position: relative;
        padding: 0;
      }
    }

    &.gradient {
      border-image: linear-gradient(to bottom, get-colour('blue') 0%, #63658300 90%) 1 100%;

      .top-border {
        &::before,
        &::after {
          border-top: 2px solid get-colour('blue');
        }
      }
    }
  }
}