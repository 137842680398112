$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;

.chat-input {
  position: relative;

  @media screen and (max-width: #{$media-md}) {
    margin: 0 16px 40px;
  }

  &__field {
    width: 100%;
    height: 100%;

    resize: none;
    border: none;

    border-radius: 0 0 4px 4px;

    font-size: 14px;
    line-height: 1.85;
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);

    color: var(--theme-color--blue);

    padding: 10px 50px 10px 16px;

    @media screen and (max-width: #{$media-md}) {
      border-radius: 4px;
    }

    &::placeholder {
      color: var(--theme-color--lightGrey);
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: var(--theme-color--blueText);
    }
  }

  &__button {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    border: none;
    background: transparent;
    cursor: pointer;
  }
}
