$media-dt: 1024px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;

.footer {
  background: #272835;
  width: 100%;
  &__desktop {
    padding-top: 24px;
    padding-bottom: 18px;
    @media screen and (max-width: $media-dt) {
      padding-bottom: 104px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__blocks {
    display: grid;
    grid-template-columns: .7fr .8fr 1fr 1fr 1.5fr;
    grid-gap: 10px;

    @media screen and (max-width: $media-lg) {
      grid-template-columns: .8fr 1.2fr 1.5fr 2fr;
      grid-gap: 15px;
    }

    @media screen and (max-width: $media-md) {
      grid-template: 1fr auto / .5fr .8fr 1.2fr .5fr;
      grid-gap: 30px 15px;
    }

    @media screen and (max-width: $media-sm) {
      grid-template: 1fr auto / 1.2fr 1fr;
      grid-gap: 32px 15px;
    }
  }

  &__block {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &--social {
      @media screen and (max-width: $media-lg) {
        grid-area: 2/4;
        flex-direction: row;
        align-items: center;
        .footer__title {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: $media-md) {
        grid-area: auto;
        flex-direction: column;
        align-items: flex-start;
        .footer__title {
          margin-bottom: 12px;
        }
      }
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: get-font-weight('black');
    font-size: get-font-size('m-4');
    color: get-colour('white');
    line-height: 1.625;
    margin-top: 0;
    margin-bottom: 12px;
    @media screen and (max-width: $media-lg) {
      font-size: get-font-size('s-1');
    }
    @media screen and (max-width: $media-sm) {
      font-size: get-font-size('m-4');
    }
  }

  &__nav {
    li {
      overflow-wrap: anywhere;

      &:not(:last-of-type) {
        margin-bottom: 2px;
        @media screen and (max-width: $media-sm) {
          margin-bottom: 6px;
        }
      }
    }

    a {
      display: block;
      font-size: get-font-size('s-3');
      font-weight: get-font-weight('bold');
      font-family: $font-family-default;
      color: get-colour('light-grey');
      line-height: 2;
      text-decoration: none;
      cursor: pointer;
      transition: color .3s ease-in-out;
      text-transform: uppercase;

      &:hover {
        color: get-colour('white');
      }
    }
  }

  .social-list {
    @media screen and (max-width: $media-lg) {
      margin-left: 18px;
    }
    @media screen and (max-width: $media-md) {
      display: grid;
      grid-template: 1fr 1fr / 1fr 1fr;
      grid-gap: 24px;
      margin-left: 0;
    }

    li {
      &:not(:last-of-type) {
        margin-right: 18px;
        @media screen and (max-width: $media-lg) {
          margin-right: 14px;
        }
        @media screen and (max-width: $media-md) {
          margin-right: 0;
        }
      }
    }
  }

  &__mailing-list {
    .footer__title {
      margin-bottom: 18px;
    }

    @media screen and (max-width: $media-md) {
      grid-area: 2/3/3/5;
    }

    @media screen and (max-width: $media-sm) {
      grid-area: 3/1/3/-1;
      .footer__title,
      span {
        text-align: center;
      }
    }

    form {
      display: flex;
      flex-direction: column;

      div {
        background-image: linear-gradient(180deg, #4e4e5e 0%, #343643 100%);
        padding: 2px;
        border-radius: 3px;
      }

      input {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), inset 0 4px 25px rgba(0, 0, 0, 0.2);
        background: linear-gradient(142.35deg, #282834 21.39%, #393947 78.22%);
        border-radius: 3px;
        height: 50px;
        width: 100%;
        box-sizing: border-box;
        font-size: get-font-size('s-1');
        font-family: $font-family-default;
        font-weight: get-font-weight('medium');
        color: get-colour('light-grey');
        line-height: 1.625;
        padding: 14px;
        outline: none;
        &::placeholder {
          color: get-colour('light-grey');
        }
      }

      span {
        font-size: get-font-size('s-3');
        font-family: $font-family-default;
        font-weight: get-font-weight('medium');
        line-height: 1.5;
        color: #ffffff;
        margin-top: 18px;
      }
    }
  }

  &__copyright {
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: get-font-size('s-3');
    line-height: 2;
    color: get-colour('dark-grey');
    grid-area: 2/1/2/3;
    @media screen and (max-width: $media-md) {
      grid-area: 2/1/2/3;
      align-self: flex-end;
    }
    @media screen and (max-width: $media-sm) {
      grid-area: 4/1/4/-1;
      margin-top: 4px;
      text-align: center;
    }
  }

  &__mobile {
    background: linear-gradient(0deg, #272835 -19.54%, #636583 187.36%);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 83px;
    padding: 0 20px;

    @media screen and (min-width: $media-dt + 1) {
      display: none;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      color: #9495a7;
      text-decoration: none;

      span {
        font-size: 11px;
        font-family: $font-family-default;
        font-weight: get-font-weight('medium');
      }

      i {
        font-size: 20px;
      }

      .icon-arrow {
        position: absolute;
        font-size: 10px;
        left: 3px;
        top: 5px;
      }
    }
  }
}
