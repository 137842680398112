.radio-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: -10px;

  &-item {
    margin: 10px;

    &__label {
      cursor: pointer;
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.625;

      background-color: #e9eaf0;
      color: #626473;
      border-radius: 4px;

      transition: all .3s linear;

      padding: 6px 18px;
    }

    &__sub-title {
      font-size: 12px;
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .radio-buttons-item__label {
        background-color: #272835;
        color: #ffffff;
      }

      &:disabled + .radio-buttons-item__label {
        opacity: .75;
        cursor: not-allowed;
      }
    }

    &--dark {
      .radio-buttons-item__label {
        background-color: var(--theme-color--blue);
        color: var(--theme-color--white);
        border: 1px solid var(--theme-color--ultraLightBlue);
      }

      .radio-buttons-item__input:checked + .radio-buttons-item__label {
        background-color: var(--theme-color--ultraLightBlue);
        color: var(--theme-color--grey);
      }
    }
  }
}
