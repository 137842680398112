// Main font
$font-family-default: 'Montserrat' !default;

// Secondary font
$font-family-primary: 'Playfair Display' !default;

// radius
$border-radius: 4px !default;
$modal-border-radius: 8px !default;

// shadow
$modal-box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);

$header-height: 75px;
$header-height-md: 66px;
$header-z-index: 99;

$spacer-map: (
  '0': 0,
  '1': 4px,
  '2': 8px,
  '3': 16px,
  '4': 20px,
  '5': 24px,
  '6': 32px,
  '7': 40px,
  '8': 48px,
  '9': 64px,
  '10': 80px,
  '12': 90px,
) !default;

$content-padding-desktop: #{get-spacer('4')};
$content-padding-desktop-m: #{get-spacer('6')};

$content-padding-tablet: #{get-spacer('5')};
$content-padding-mobile: #{get-spacer('3')};

//
// Main colours
//
// This colours are used for generate project palette of tints and for utility classes
//
$colours: (
  'white': #ffffff,
  'black': #000000,
  'dark-blue': #23232f,
  'grey': #f5f5f5,
  'ultra-light-blue': #e9eaf0,
  'blue-text': #babbcd,
  'light-grey': #9495a7,
  'dark-grey': #626473,
  'blue': #272835,
) !default;

// Typography margins
$default-margin-headings: 0.6em 0 0.3em 0 !default;
$default-margin-body: 0 0 1rem 0 !default;

// Line height
$default-line-height: 1.5em !default;

$font-weight-map: (
  'normal': 400,
  'medium': 500,
  'semi-bold': 600,
  'bold': 700,
  'black': 900,
) !default;

$font-size-map: (
  xl-2: 22em,
  xl-1: 18em,

  l-1: 3.75em,      /* 64px */
  l-2: 2.625em,     /* 42px */
  l-3: 2em,         /* 32px */
  m-1: 1.75em,      /* 28px */
  m-2: 1.5em,       /* 24px */
  m-3: 1.375em,     /* 22px */
  m-4: 1.25em,      /* 20px */
  m-5: 1.125em,     /* 18px */
  s-1: 1em,         /* 16px */
  s-2: 0.875em,     /* 14px */
  s-3: 0.75em,      /* 12px */
  s-4: 0.625em,     /* 10px */
  s-5: 0.5em,       /* 8px */
) !default;

//get-font-size('l-1')
