.chats {
  font-family: var(--theme-fontFamily--default);
  font-style: normal;

  min-height: 530px;

  &__loader {
    height: 20px;
    display: flex;
    justify-content: center;

    padding: 50px;
  }

  &__wrapper {
    position: relative;

    display: flex;

    height: 80vh;
    max-height: 800px;

    width: 100%;
    max-width: 1414px;

    margin: 50px auto;

    box-shadow: 10px 10px 20px rgba(209, 210, 225, 0.3),
      -10px -10px 20px rgba(250, 250, 255, 0.5);

    border-radius: var(--theme-borderRadius--default);

    overflow: hidden;

    @media screen and (max-width: 1024px) {
      margin: 10px auto 20px;
    }
  }

  &__left-panel {
    flex: 0 1 325px;

    background: var(--theme-color--white);

    box-shadow: 10px 10px 20px rgba(209, 210, 225, 0.3),
      -10px -10px 20px rgba(250, 250, 255, 0.5);

    border-radius: 4px 0 0 4px;

    border-right: 1px solid var(--theme-color--ultraLightBlue);

    &.mobile-view {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}
