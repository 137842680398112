.block-width-border {
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 50px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: calc(38px / 2);
    width: 1px;
    height: 100%;
    background-image: linear-gradient(180deg, #272835 0%, rgba(39, 40, 53, 0) 100%);
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
  .top {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
  }
  .line {
    flex: 1;
    height: 1px;
    background-color: #272835;
  }
  .title {
    font-family: 'Playfair Display', sans-serif;
    font-weight: 900;
    font-size: 28px;
    line-height: 1;
    color: #9495a7;
    margin: 0 45px;
    padding-bottom: 5px;
    @media screen and (max-width: 575px) {
      font-size: 24px;
      margin: 0 20px;
    }
    @media screen and (max-width: 425px) {
      font-size: 24px;
      margin: 0 10px;
    }
  }
  .content {
    padding: 48px 16px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 30px;
      padding: 48px 16px 16px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
      padding: 16px;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }
  }
}