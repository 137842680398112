$cr-width: 520px;
$cr-height: 738px;

.cr-modal {
  @include display-flex(column);
  width: $cr-width;
  height: $cr-height;
  padding: 16px 40px 40px;
  background-color: get-colour('white');
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  &__header {
    @extend .font-header-h2;
    color: get-colour('blue');
    text-align: center;
    margin-bottom: 24px;
  }

  &__subheader {
    @extend .font-caption;
    text-transform: uppercase;
    color: get-colour('light-grey');
    margin-bottom: 12px;
  }

  &__item {
    margin-bottom: 34px;
  }

  .type-group {
    &__wrapper {
      width: 100%;
      display: inline-grid;
      grid-template-columns: 150px 1fr;
      grid-column-gap: 20px;
    }
  }

  .ends-group {

    &__wrapper {
      @include display-flex(column);
    }

    &__item {
      @include display-flex(row, center);
      height: 60px;
      margin-bottom: 16px;

      .radio-wrapper {
        min-width: 120px;
      }
    }

    // &__after-occurrence-input {
    //   position: relative;

    //   input {
    //     width: 247px;
    //     padding-right: 175px;

    //     &::-webkit-outer-spin-button,
    //     &::-webkit-inner-spin-button {
    //       -webkit-appearance: slider-horizontal;
    //       margin: 0;
    //     }

    //     /* Firefox */
    //     &[type=number] {
    //       -moz-appearance: none;
    //     }
    //   }
    // }

    /* General styling for the input container */
    &__after-occurrence-input {
      position: relative;
    }

    /* Styling for the input element */
    &__after-occurrence-input input {
      width: 247px;
      padding-right: 175px;
    }

    /* Styling for number input arrows in WebKit browsers */
    &__after-occurrence-input input::-webkit-outer-spin-button,
    &__after-occurrence-input input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    /* Styling for number input arrows in Firefox */
    &__after-occurrence-input input[type=number] {
      -moz-appearance: textfield;
    }


    &__after-occurrence-label {
      @extend .font-body-2;
      @include position-absolute(0, 16px, 0, auto);
      @include display-flex(row, center);

      &.disabled {
        color: get-colour('light-grey');
      }
    }
  }

  .button-block {
    @include display-flex(row, flex-end, flex-end);
    flex: 1;

    &__item {
      border-radius: $border-radius;
      width: 140px;
      height: 56px;

      font-family: $font-family-default;
      font-weight: get-font-weight('bold');
      font-size: get-font-size('s-1');
      line-height: $default-line-height;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      border: none;
    }

    .light {
      background: transparent;
      color: get-colour('dark-grey');
    }

    .gradient {
      background: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
        inset 0 4px 25px rgba(0, 0, 0, 0.45);
      color: get-colour('white');
    }
  }

  @media all and (max-width: 575px) {
    width: auto;

    .type-group {
      &__wrapper {
        grid-template-columns: 1fr 1fr;
      }
    } 

    .ends-group {
      &__after-occurrence-input {
        input {
          width: 100%;
          max-width: 247px;
          padding-right: 125px;
        }
      }
    }
  }
}
