// TODO: check which styles are still needed now

.headshot-container {
  display: flex;
  justify-content: flex-start;

  @media  only screen and (max-width: 768px) {
    justify-content: space-between;
  }
}
%error-margin {
  // Error message
  > div + p {
    margin-top: 4px;
  }
}

.headshot-item {
  width: 150px;

  @media  only screen and (max-width: 768px) {
    width: 250px;
  }
  @media  only screen and (max-width: 575px) {
    width: 200px;
  }
  @media  only screen and (max-width: 500px) {
    width: 150px;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  .single-select__library-button,
  img {
    border-radius: 50%;
    overflow: hidden;
  }

  &__image-wrapper {
    height: 150px;
    margin-bottom: 8px;

    @media  only screen and (max-width: 768px) {
      height: 250px;
    }
    @media  only screen and (max-width: 575px) {
      height: 200px;
    }
    @media  only screen and (max-width: 500px) {
      height: 150px;
    }
  }

  @media  only screen and (max-width: 768px) {
    &--single {
      width: 250px;
      .headshot-item__image-wrapper {
        height: 250px;
      }
    }
  }

  &--ml {
    margin-left: 105px;
    @media  only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  .loader-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 50%;
  }
}

.upload-container {
  display: flex;
  justify-content: space-between;
}

.upload-item {
  width: 228px;
  position: relative;
  &__image {
    width: 100%;
    height: 100%;
  }
  .custom-image{
    object-fit: contain;
  }
  &__image-hint {
    position: absolute;
    top: 0;
    right: -28px;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
  }
  &--full-body {
    margin-right: 28px;
  }
  @media  only screen and (max-width: 768px) {
    width: 244px;
  }
  @media only screen and (max-width: 500px) {
    width: 174px;
  }
  @media only screen and (max-width: 425px) {
    width: 158px;
  }
  @media only screen and (max-width: 375px) {
    width: 153px;
  }

  &__full-body-wrapper {
    margin-bottom: 8px;
    @extend %error-margin;
  }
  &__full-body-wrapper,
  &__image-hint {
    height: 352px;
    @media only screen and (max-width: 768px) {
      height: 375px;
    }
    @media only screen and (max-width: 500px) {
      height: 268px;
    }
    @media only screen and (max-width: 425px) {
      height: 244px;
    }
    @media only screen and (max-width: 375px) {
      height: 236px;
    }
  }
  &__half-body-wrapper {
    height: 303px;
    margin-bottom: 8px;
    @extend %error-margin;

    @media only screen and (max-width: 768px) {
      height: 322px;
    }
    @media only screen and (max-width: 500px) {
      height: 230px;
    }
    @media only screen and (max-width: 425px) {
      height: 214px;
    }
    @media only screen and (max-width: 375px) {
      height: 203px;
    }
  }
}

.upload-label {
  font-family: $font-family-default;
  line-height: 1.625;
  font-size: 16px;
  text-align: center;
  margin-top: 24px;

  &--dark,
  strong {
    font-weight: 600;
    color: get-colour('blue');
  }

  &--light {
    font-weight: 500;
    color: get-colour('light-grey');
  }

  @media only screen and (max-width: 425px) {
    font-size: 12px;
  }
}
