.payment-settings {
  &--connected {
    display: flex;

    &-actions {
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-info {
      font-weight: var(--theme-fontWeight--medium);
      font-size: 18px;
      line-height: 1.625em;
      color: var(--theme-color--blue);
    }
  }

  &--links-wrapper button,
  &--links-wrapper a {
    font-weight: var(--theme-color--medium);
    font-size: 14px;
    line-height: 1.5em;
    color: var(--theme-color--grey);
    border: none;
    background: none;
    padding: 0;
    margin-right: 20px;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
