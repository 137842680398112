$tc: '.talent-calendar';
$fc-border: 1px solid #dddddd;

#{$tc} {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 80px;


  @media all and (max-width: 1024px) {
    padding: 0 20px;
    margin-bottom: 40px;
  }

  @media all and (max-width: 768px) {
    padding: 0;
    margin-bottom: 0;
  }

  &-heading {
    margin: 40px 0 12px;
    color: var(--theme-color--blue);
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 60px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

    @media all and (max-width: 575px) {
      margin: 20px 0;
      font-size: 32px;
    }

    &__time-zone {
      margin: 12px 0 24px;

      span {
        margin-left: 4px;
        font-weight: var(--theme-fontWeight--bold);
      }
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    background-color: #ffffff;
    box-shadow: 10px 10px 20px rgba(209, 210, 225, 0.3), -10px -10px 20px rgba(250, 250, 255, 0.5);
    border-radius: var(--theme-borderRadius--default);

    @media all and (max-width: 768px) {
      flex-direction: column-reverse;
      box-shadow: none;
      border-radius: 0;
    }
  }

  &__sidebar {
    flex: 1;
    min-width: 280px;
    position: relative;

    @media all and (max-width: 768px) {
      position: initial;
      box-shadow: 0 -1px 20px rgba(0, 0, 0, 0.25);
    }
  }

  &_inner {
    flex: 4;
    width: 100%;
    border-left: $fc-border;
    opacity: 1;
    transition: opacity 0.375s;

    &.drawer-opened {
      transition: opacity 0.375s;

      @media all and (max-width: 600px) {
        opacity: 0.1;
      }
    }
  }

  &__add-event {
    display: none;

    @media all and (pointer: coarse) {
      display: block;
      max-width: 300px;
      margin-bottom: 16px;
    }
  }
}

//
// 3rd party override 
//

// To prevent showing header above react date picker portal
.react-datepicker-popper {
  z-index: 10000;
}
