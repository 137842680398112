$modal-height-mobile: calc(100vh - 170px);
$modal-height-mobile-fullscreen: 100vh;

.modal-wrapper {
  z-index: 1000000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000001;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 500ms ease-in-out;

    &__submodal {
      z-index: 1000002;
    }
  }

  &-content {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%);
    outline: none;
    z-index: 1000003;

    @media screen and (max-width: 575px) {
      width: 100%;
      height: $modal-height-mobile;
      max-height: $modal-height-mobile;

      &__mobile-fullscreen {
        height: $modal-height-mobile-fullscreen;
        max-height: $modal-height-mobile-fullscreen;
      }
    }

    &-children {
      > * {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 90vh;

        // Firefox scrollbar support
        scrollbar-width: thin;
        scrollbar-color: var(--theme-color--black) var(--theme-color--white);

        &::-webkit-scrollbar {
          width: 3px;
          background: var(--theme-color--white);
        }

        &::-webkit-scrollbar-thumb {
          background: var(--theme-color--black);
        }

        @media screen and (max-width: 575px) {
          height: $modal-height-mobile;
          max-height: $modal-height-mobile;
        }
      }

      &__mobile-fullscreen {
        > * {
          @media screen and (max-width: 575px) {
            height: $modal-height-mobile-fullscreen;
            max-height: $modal-height-mobile-fullscreen;
          }
        }
      }
    }

    &-button {
      outline: none;
      cursor: pointer;
      border: none;
      background-image: linear-gradient(360deg, #272835 0%, #636583 97.83%);
      position: absolute;
      top: -13px;
      right: -13px;
      z-index: 100;
      height: 26px;
      width: 26px;
      padding: 0;
      border-radius: 50%;

      &:before,
      &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 16px;
        width: 3px;
        background: var(--theme-color--white);
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      @media screen and (max-width: 575px) {
        top: 0;
        right: 0;
        border-radius: 0 0 0 8px;
      }
    }
  }

  // Children
  .modal {
    &-header {
      font-family: var(--theme-fontFamily--primary);
      font-weight: var(--theme-fontWeight--black);
      color: var(--theme-color--blue);
      font-size: 32px;
      line-height: 54px;
      text-align: center;

      @media screen and (max-width: 1366px) {
        font-size: 42px;
        line-height: 54px;
      }

      @media screen and (max-width: 1280px) {
        font-size: 28px;
        line-height: 36px;
      }

      @media screen and (max-width: 375px) {
        font-size: 24px;
        line-height: 32px;
      }

      @media screen and (max-width: 360px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    &-text {
      font-family: var(--theme-fontFamily--default);
      font-weight: var(--theme-fontWeight--medium);
      font-size: 16px;
      line-height: 1.625;
      text-align: center;
      color: var(--theme-color--grey);

      a,
      b,
      strong {
        font-weight: var(--theme-fontWeight--bold);
      }

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}