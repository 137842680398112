.image-modal,
.video-modal {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .image-modal,
  .video-modal {
    &--horizontal {
      width: 800px;
      height: auto;
    }

    &--vertical {
      width: auto;
      height: 600px;

      // Safari adaptive width fix
      video {
        min-width: 100%;
        max-width: 100%;
        width: auto;
      }
    }

    &__primary-button {
      font-family: $font-family-default;
      font-weight: get-font-weight('bold');
      font-size: 12px;
      line-height: 30px;
      letter-spacing: 0.07em;
      text-transform: uppercase;

      color: get-colour('white');
      border: 2px solid get-colour('white');
      border-radius: 4px;

      background: get-colour('black');
      opacity: 0.85;
      backdrop-filter: blur(4px);
      cursor: pointer;

      @include position-absolute(auto, auto, -20%, 50%);
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 1280px), screen and (max-height: 1000px) {

      &--horizontal {
        width: 800px;
        height: auto;
      }

      &--vertical {
        width: auto;
        height: 600px;
      }
    }

    @media screen and (max-width: 1024px), screen and (max-height: 700px) {

      &--horizontal {
        width: 720px;
        height: auto;
      }

      &--vertical {
        width: auto;
        height: 520px;
      }
    }

    @media screen and (max-width: 800px), screen and (max-height: 620px) {

      &--horizontal {
        width: 600px;
        height: auto;
      }

      &--vertical {
        width: auto;
        height: 400px;
      }
    }

    @media screen and (max-width: 600px) {
      @include display-flex('column', center, center);
      background-color: rgba(0, 0, 0, .8);

      &--horizontal {
        width: 100%;
        height: auto;
      }

      &--vertical {
        width: 100%;
        height: 100%;
      }

      border-radius: 0;
      height: 100%;
    }
  }

  &.loading {
    overflow: hidden !important;
    background-color: transparent;
  }

  .video-with-autoplay {
    &--hidden {
      display: none;
    }

    video {
      max-height: 90vh;

      @media screen and (max-width: 600px) {
        max-height: unset;
      }
    }    
  }
}
