.base-icon {
  width: 24px;
  height: 24px;

  fill: black;
    
  &.arrow-icon {
    stroke: black;
  }

  &.heart-icon {
    fill: none;
        
    path {
      stroke: #272835;
      stroke-width: 2;
    }
  }
}