._price {
  font-family: var(--theme-fontFamily--default);
  color: var(--theme-color--blue);
  font-weight: var(--theme-fontWeight--bold);
  text-transform: uppercase;
  line-height: 1.5;

  &__value {
    font-size: 32px;
  }
  
  &__currency {
    font-size: 16px;
  }
}
