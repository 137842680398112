.react-select-container {
  position: relative;

  .react-select {
    &__single-value {
      color: get-colour('black');
      font-size: 18px;
      text-transform: none;

      &--is-disabled {
        color: get-colour('blue-text');
      }
    }

    &__input {
      width: 100% !important;
      input {
        width: 100% !important;
        height: 100% !important;
        font-weight: 500;
        font-size: 18px;
      }
    }

    &__group-heading {
      font-family: var(--theme-fontFamily--default);
      font-weight: var(--theme-fontWeight--bold);
      font-size: 13px;
      line-height: 1.4;
      color: var(--theme-color--lightGrey);
    }

    &__control {
      height: 60px;
      padding: 0 8px 0 18px;
      border-radius: $border-radius;
      border: 2px solid get-colour('blue-text');
      box-shadow: none;

      font-family: $font-family-default;
      font-weight: get-font-weight('medium');
      font-size: get-font-size('s-1');
      line-height: 32px;
      color: get-colour('light-grey');
      width: 100%;
      background-color: transparent;


      &:focus,
      &:hover {
        border-color: get-colour('black');
      }

      &--is-disabled {
        background-color: get-colour('ultra-light-blue') !important;
      }

      &--menu-is-open {
        border-color: get-colour('black');

        .react-select__indicator {
          transform: rotate(180deg);
        }
      }
    }

    &__placeholder {
      white-space: nowrap;
      color: get-colour('light-grey');
      font-size: 18px;
      text-transform: none;
    }

    &__option {
      padding: 12px 7px;
      cursor: pointer;
      transition: all .3s linear;

      font-family: $font-family-default;
      font-weight: get-font-weight('medium');
      font-size: get-font-size('s-1');
      line-height: 26px;
      text-align: start;
      color: get-colour('dark-grey');
      background-color: get-colour('white');

      &:hover {
        background: get-colour('ultra-light-blue');
      }

      &--is-selected {
        color: get-colour('blue');
        font-weight: get-font-weight('bold');
      }

      &--is-focused {
        background-color: get-colour('ultra-light-blue') !important;;
      }
    }

    &__menu {
      background: get-colour('white');
      box-shadow: -1px 2px 12px rgba(0, 0, 0, 0.18);
      border-radius: 4px;
      padding: 4px 0;
      top: 68px;
      z-index: var(--theme-zIndex--12);

      &-list {
        @extend .custom-scrollbar;
      }
    }

    &__value-container {
      z-index: 1;
      padding-left: 0;
      padding-right: 0;
      > div {
        width: 100% !important;
      }
    }

    &__indicator-container {
      padding-left: 0;
      padding-right: 0;
    }

    &__indicator {
      z-index: 11;
      transition: all .2s ease;
    }

    &__loading-indicator {
      display: none;
    }
  }

  &--black-theme {
    .react-select {

      &__control {
        color: get-colour('white');

        &:focus,
        &:hover {
          border-color: get-colour('white');
        }

        &--menu-is-open {
          border-color: get-colour('white');
        }
      }

      &__single-value {
        color: get-colour('white');
      }

      &__input {
        color: get-colour('white');
      }
    }
  }

  &--danger {
    .react-select {

      &__control {
        border-color: red;
      }
    }
  }
}

@keyframes blink {
  50% {
    color: transparent;
  }
}

.react-select-loader {
  &__dot {
    animation: 1s blink infinite;

    &:nth-child(2) {
      animation-delay: 250ms;
    }

    &:nth-child(3) {
      animation-delay: 500ms;
    }
  }
}
