:root {
  --slider-section-live-experience__font-size: 20px;
}

@import 'config';
@import 'styles/_legacy/abstracts/_mixins.scss';

@function getScale($coef) {
  @return $tbv-scale-slider * $coef;
}

@mixin tbv-desktop-slider {
  width: 300px;

  height: 542px;

  display: flex;
  flex-direction: column;

  position: relative;
  @media screen and (max-width: $tbv-media-width-sm) {
    height: 440px;
    margin-bottom: 30px !important;
  }
  @include _tbv-media-height($tbv-media-height-lg) {
    height: 480px;
    margin-bottom: 30px !important;
  }
  @include _tbv-media-height($tbv-media-height-md) {
    height: 370px;
    margin-bottom: 10px !important;
  }
  @include _tbv-media-width($tbv-media-width-lg) {
    height: 370px;
    margin-bottom: 10px !important;
  }
  @include _tbv-media-height($tbv-media-height-sm) {
    height: 350px;
    margin-bottom: 10px !important;
  }

  &[attr-size=small] {
    height: 416px;
  }

  &-title {
    display: flex;
    justify-content: center;

    font-family: $font-family-primary;
    font-weight: get-font-weight('black');
    font-size: get-font-size('m-1');
    line-height: 1.4;
    font-feature-settings: 'case' on;

    text-align: center;

    @include _tbv-media-height($tbv-media-height-md) {
      font-size: get-font-size('m-4') !important;
    }
    @include _tbv-media-width($tbv-media-width-lg) {
      font-size: get-font-size('m-4') !important;
    }
  }

  &-actions {
    margin-top: -21px;

    width: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    @include _tbv-media-height($tbv-media-height-md) {
      .button {
        &__item {
          padding: 8px 14px;
        }

        &__text {
          font-size: get-font-size('s-3');
        }
      }
    }
    @include _tbv-media-width($tbv-media-width-lg) {
      .button {
        &__item {
          padding: 8px 14px;
        }

        &__text {
          font-size: get-font-size('s-3');
        }
      }
    }
    @include _tbv-media-height($tbv-media-height-sm) {
      .button {
        &__item {
          padding: 6px 12px;
        }
      }
    }


    &-arrow {
      background: none;
      border: none;

      height: 16px;
      stroke-width: 2px;

      &-button {
        padding: 0;

        width: 32px;
        height: 32px;

        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        cursor: pointer;

        transition: background-color 100ms linear;


        &:first-child {
          margin-right: 27px;
          @include _tbv-media-height($tbv-media-height-md) {
            margin-right: 5px;
          }
          @include _tbv-media-width($tbv-media-width-lg) {
            margin-right: 5px;
          }
        }

        &:last-child {
          margin-left: 27px;
          @include _tbv-media-height($tbv-media-height-md) {
            margin-left: 5px;
          }
          @include _tbv-media-width($tbv-media-width-lg) {
            margin-left: 5px;
          }
        }

        &:hover {
          background-color: #0c171f22;
        }

        &:active {
          background-color: #666b6886;
        }
      }
    }

    &-calendar {

      display: flex;
      flex-direction: column;

      align-items: center;

      border: none;
      background: none;

      i {
        font-size: get-font-size('l-2');
      }

      &-label {
        font-family: $font-family-default;
        font-weight: get-font-weight('bold');
        font-size: 14px;
        line-height: 1.5;
        text-align: center;
        text-transform: uppercase;
        color: get-colour('dark-grey');
      }
    }

    &-video-messages {
      display: flex;
      flex-direction: column;

      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      &-response-time-label {
        position: absolute;
        top: 100%;
        margin-top: 8px;

        font-family: $font-family-default;
        font-weight: get-font-weight('bold');
        font-size: get-font-size('s-3');
        line-height: 1.4;

        text-align: center;
        text-transform: uppercase;

        color: get-colour('dark-grey');
      }
    }
  }

  &[attr-with-gradient-overhead=true] {
    & .tbv-slider-slide-content-gradient-overhead {
      background-image: linear-gradient(180deg, rgba(39, 40, 53, 0) 0%, rgba(39, 40, 53, 0) 31.56%, rgba(39, 40, 53, 0.15) 46.15%, rgba(39, 40, 53, 0.6) 69.06%, rgba(39, 40, 53, 0.6) 100%);
      //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.78) 100%);
    }
  }
}

@mixin tbv-mobile-slider() {
  width: 352px;

  align-self: flex-end;

  display: flex;
  flex-direction: column;
  @include _tbv-media-width($tbv-media-width-mb-lg) {
    width: 380px;
  }
  @include _tbv-media-width($tbv-media-width-mb-md) {
    margin-top: 30px;
    align-self: center;
    max-width: 400px;
    width: 100%;
  }

  @include _tbv-media-width($tbv-media-width-mb-md) {
    //width: 100%;
    align-self: flex-start;
  }

  &-header {
    margin-bottom: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      margin: 0;
      font-family: $font-family-primary;
      font-weight: get-font-weight('black');
      font-size: get-font-size('m-2');
      line-height: 1.4;
      font-feature-settings: 'case' on, 'liga' off;
      color: get-colour('blue');
    }
  }

  &-actions {
    &-calendar {
      display: flex;

      font-size: get-font-size('s-3');

      align-items: center;

      border: none;
      background: none;

      svg {
        margin-right: 10px;
      }
    }
  }

  &-machine {
    padding-top: 20px;

    width: 352px;
    height: 350px;

    overflow: hidden;
  }

  &-list {
    margin-left: 100px;
    display: flex;

    transition: .3s margin-left ease;
  }

  &-slide {
    @include tbv-mobile-slide;
  }

  @include _tbv-media-width($tbv-media-width-mb-xs) {
    .side-arrows .side-arrow-wrapper {
      margin: 0 !important;
      position: absolute;
      &:first-of-type {
        left: 20px;
      }
      &:last-of-type {
        right: 20px;
      }
    }
  }

  @include _tbv-media-width($tbv-media-width-mb-xs) {
    .side-arrows .side-arrow-wrapper {
      &:first-of-type {
        left: 10px;
      }
      &:last-of-type {
        right: 10px;
      }
    }
  }
}

@mixin tbv-desktop-slide() {
  min-width: 500px;
  width: 500px;
  height: 364px;

  position: relative;

  &[attr-size=small] {
    height: 253px;
    & .tbv-slider-slide-empty-icon {
      margin: 20px 0 12px;
    }
  }

  &[attr-mirrored-inside=true] {
    &:before {
      transform: rotate(4deg) scaleX(-1) translate(-132px, 7px);
      @media screen and (max-width: $tbv-media-width-sm) {
        transform: rotate(4deg) scaleX(-1) translate(-132px, 3px) scale(getScale(.8));
      }
      @include _tbv-media-height($tbv-media-height-lg) {
        transform: rotate(4deg) scaleX(-1) translate(-132px, 3px) scale(getScale(.85));
      }
      @include _tbv-media-height($tbv-media-height-md) {
        transform: rotate(4deg) scaleX(-1) translate(-105px, 5px) scale(getScale(.65));
      }
      @include _tbv-media-width($tbv-media-width-lg) {
        transform: rotate(4deg) scaleX(-1) translate(-105px, 5px) scale(getScale(.65));
      }
    }

    & .tbv-slider-slide-content {
      background: linear-gradient(90deg, #dbdde6 0%, #f9fafd 100%), linear-gradient(90deg, #dbdde6 40%, #f9fafd 100%);
    }
  }

  &-content {
    @include tbv-slide-content;
  }

  &-machine {
    margin-left: -100px;
    margin-top: 25px;

    width: 500px;
    height: 450px;

    overflow: hidden;

    transform: perspective(150px) rotateY(3deg);

    & + .tbv-slider-slide-overlay {
      transform: rotateY(3deg);
    }

    pointer-events: none;
    @media screen and (max-width: $tbv-media-width-sm) {
      margin-top: -20px;
    }
    @include _tbv-media-height($tbv-media-height-lg) {
      margin-top: -15px;
      height: 400px;
    }
    @include _tbv-media-height($tbv-media-height-md) {
      margin-top: -55px;
      height: 365px;
    }
    @include _tbv-media-width($tbv-media-width-lg) {
      margin-top: -55px;
      height: 365px;
    }

    &[attr-size=small] {
      height: 315px;
    }

    &[attr-mirrored-inside=true] {
      transform: perspective(150px) rotateY(-3deg);

      & + .tbv-slider-slide-overlay {
        transform: rotateY(-3deg);
      }
    }

    &.fullscreen + .tbv-slider-slide-overlay {
      transform: rotateY(0deg);
    }

    &-list {
      margin-left: 100px;
      width: 900px;
      display: flex;

      transition: .3s transform ease;
    }
  }

  &[attr-is-current=false] {
    & .tbv-slider-slide-overlay {
      opacity: 0;
    }
  }

  &-overlay {
    width: 100%;

    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 0;
    padding: 0 22px 113px 10px;
    animation: 600ms tbv-slider-slide-overlay-fade-in ease;

    pointer-events: none;

    .tooltip__wrapper {
      transform: translate3d(0, 0, 10px);
    }

    @include _tbv-media-height($tbv-media-height-md) {
      padding: 0 22px 65px 10px;
      .button {
        &__item {
          padding: 8px 14px;
        }

        &__text {
          font-size: get-font-size('s-3');
        }
      }
    }

    @include _tbv-media-width($tbv-media-width-lg) {
      padding: 0 22px 65px 10px;
      .button {
        &__item {
          padding: 8px 14px;
        }

        &__text {
          font-size: get-font-size('s-3');
        }
      }
    }


    &-live-experience {
      pointer-events: none;
      padding: 0 30px 25px;

      flex: 1;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;

      color: #272835;

      @media screen and (min-width: 1025px) {
        padding-bottom: 55px;
      }

      &--video {
        @media screen and (max-width: 1024px) {
          position: absolute;
          left: 0;
          bottom: 35px;
          z-index: 3;
          width: 100%;
          text-align: center;
        }

        @media screen and (min-width: 1025px) {
          padding-bottom: 95px;
        }
      }

      &-date {
        text-align: center;
        font-family: $font-family-default;
        font-weight: get-font-weight('bold');
        font-size: get-font-size('s-1');
        line-height: 1.62;
        color: #ffffff;
        @media screen and (max-width: 1024px){
          margin-bottom: 20px;
        }
        @include _tbv-media-height($tbv-media-height-md) {
          font-size: get-font-size('s-2');
        }
        @include _tbv-media-width($tbv-media-width-lg) {
          font-size: get-font-size('s-2');
        }
      }

      &-name {
        text-align: center;
        justify-content: center;
        width: 100%;
        font-family: $font-family-primary;
        font-weight: get-font-weight('black');
        font-size: var(--slider-section-live-experience__font-size);
        color: #ffffff;
        line-height: 24px;
        overflow: hidden;
        pointer-events: none;
        max-width: 328px;
        max-height: 80px;
        padding: 0 10px;
        height: 100%;
        @media screen and (max-width: 1024px){
          margin-bottom: 10px;
        }
        @include lineClamp(3);
        @include _tbv-media-height($tbv-media-width-mb-md) {
          font-size: 16px;
          height: auto;
        }
        @include _tbv-media-width($tbv-media-width-lg) {
          font-size: 20px;
        }
      }
    }

    &-live-video-chats {
      padding-bottom: 26px;
      margin-top: auto;

      pointer-events: auto;

      @media screen and (max-width: 1024px) {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }

  &-empty {
    @include tbv-desktop-empty;
  }

  &::before {
    content: '';
    width: 396px;
    height: 90%;
    background: linear-gradient(90deg, transparent 15%, rgba(0, 0, 0, 0.6) 100%);
    filter: blur(4px);
    position: absolute;
    bottom: 10px;
    left: 0;
    transform: rotate(-4deg) translate(-38px, 11px);
    z-index: -55;
    @media screen and (max-width: $tbv-media-width-sm) {
      transform: rotate(-4deg) translate(-38px, 11px) scale(getScale(.8));
    }
    @include _tbv-media-height($tbv-media-height-lg) {
      transform: rotate(-4deg) translate(-38px, 11px) scale(getScale(.85));
    }
    @include _tbv-media-height($tbv-media-height-md) {
      transform: rotate(-4deg) translate(0, 12px) scale(getScale(.65));
    }
    @include _tbv-media-width($tbv-media-width-lg) {
      transform: rotate(-4deg) translate(0, 12px) scale(getScale(.65));
    }
  }
}

@mixin tbv-mobile-slide() {
  margin: 0 74px;

  min-width: 204px;
  width: 204px;
  height: 272px;

  display: flex;

  background: linear-gradient(88.62deg, #dcdee7 1.07%, #f8f9fd 98.75%);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);

  position: relative;

  &-overlay {
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    background-image: linear-gradient(180deg, rgba(39, 40, 53, 0) 0%, rgba(39, 40, 53, 0) 31.56%, rgba(39, 40, 53, 0.15) 46.15%, rgba(39, 40, 53, 0.6) 69.06%, rgba(39, 40, 53, 0.6) 100%);
  }

  &-empty {
    @include tbv-mobile-empty;
  }

  &-content {
    @include tbv-slide-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-media-video {
      flex-direction: column;
      justify-content: center;
    }
  }
}

@mixin tbv-content() {
  padding: 0 50px;
  font-family: $font-family-default;
  font-weight: get-font-weight('medium');
  font-size: 16px;
  line-height: 1.62;
  text-align: center;
  color: get-colour('light-grey');
  @include _tbv-media-width($tbv-media-width-sm) {
    font-size: get-font-size('s-1');
  }
}

@mixin tbv-hint() {
  position: absolute;
  padding: 0 10px;
  bottom: 10px;
  font-family: $font-family-default;
  font-weight: get-font-weight('medium');
  font-size: get-font-size('s-2');
  line-height: 1.62;
  text-align: center;
  color: get-colour('light-grey');
  @include _tbv-media-width($tbv-media-width-mb-sm) {
    font-size: get-font-size('s-3');
  }
}

@mixin tbv-desktop-empty() {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;


  &-icon {
    margin: 50px 0 30px;

    svg path {
      fill: #9495a7;
    }
  }

  &-content {
    @include tbv-content;
  }

  &-hint {
    @include tbv-hint;
  }
}

@mixin tbv-mobile-empty() {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;


  &-icon {
    margin: 20px 0 15px;

    display: flex;
    justify-content: center;

    svg path {
      fill: #9495a7;
    }
  }

  &-content {
    @include tbv-content;

    padding: 0;
  }

  &-hint {
    @include tbv-hint;

    &-link {
      color: var(--theme-color--lightGrey);
      cursor: pointer;
    }
  }
}

@mixin tbv-slide-content() {
  max-width: 270px;
  max-height: 360px;
  margin: 0 114px;
  padding: 10px;

  width: 100%;
  height: 100%;

  background: linear-gradient(90deg, #f9fafd 40%, #dbdde6 100%), linear-gradient(90deg, #f9fafd 0%, #dbdde6 100%);

  @media screen and (max-width: $tbv-media-width-sm) {
    transform: scale(getScale(.8));
  }
  @include _tbv-media-height($tbv-media-height-lg) {
    transform: scale(getScale(.85));
  }
  @include _tbv-media-height($tbv-media-height-md) {
    transform: scale(getScale(.65));
  }
  @include _tbv-media-width($tbv-media-width-lg) {
    transform: scale(getScale(.65));
  }
  @include _tbv-media-width($tbv-media-width-sm) {
    transform: scale(getScale(1));
  }

  &-border {
    width: 100%;
    height: 100%;
    overflow: hidden;

    position: relative;
  }

  &-media {
    @include tbv-media;

    &-video {
      width: 100%;
      height: 100%;

      position: relative;

      display: flex;
    }
  }

  &-video-play-pause-button {
    @include tbv-play-pause-button;
  }

  &-gradient-overhead {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin tbv-media() {
  width: 100%;
  height: 100%;

  position: relative;
  object-fit: cover;
}

@mixin tbv-play-pause-button() {
  position: absolute;

  pointer-events: all;

  padding: 0;
  padding-left: 6px;

  width: 51px;
  height: 51px;

  left: calc(50% - 25px);

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  border-radius: 50%;
  background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
  opacity: 0.6;

  cursor: pointer;

  z-index: 1;

  &:hover {
    opacity: 1;
  }
}

.tbv {
  &-slider {
    @include tbv-desktop-slider;
    transform: translateZ(0);

    &-slide {
      @include tbv-desktop-slide;
    }
  }

  &-slider-mobile {
    @include tbv-mobile-slider;
  }

  //&-mobile {
  //  &-offers-navigation {
  //    width: 100%;
  //    height: 40px;
  //
  //    display: flex;
  //    flex-direction: column;
  //    justify-content: space-between;
  //
  //    &-items {
  //      margin: 0 6px;
  //
  //      display: flex;
  //
  //        &-item {
  //            margin-right: 12px;
  //            max-width: 30px;
  //            min-width: 30px;
  //            position: relative;
  //
  //        flex: 1;
  //
  //        display: flex;
  //
  //        overflow: hidden;
  //        transition: .3s ease;
  //        transition-property: margin-right, flex, max-width;
  //
  //        cursor: pointer;
  //
  //        &:last-child {
  //          margin-right: 14px;
  //        }
  //
  //        &[attr-active=true] {
  //          margin-right: 0;
  //        }
  //
  //        &-icons {
  //            margin-right: 8px;
  //
  //            min-width: 30px;
  //            min-height: 24px;
  //
  //            position: relative;
  //        }
  //
  //        &-icon {
  //          position: absolute;
  //          left: 50%;
  //          transform: translateX(-50%);
  //          transition: opacity .5s ease-in-out;
  //          &.active {
  //            opacity: 1;
  //          }
  //          &.hidden {
  //            opacity: 0;
  //          }
  //        }
  //
  //        &-label {
  //          font-family: Playfair Display;
  //          font-style: normal;
  //          font-weight: 900;
  //          font-size: 14px;
  //          line-height: 120%;
  //          /* identical to box height, or 17px */
  //
  //          display: flex;
  //          align-items: center;
  //          font-feature-settings: 'case' on;
  //
  //          /* blue */
  //
  //          color: #272835;
  //
  //          white-space: nowrap;
  //
  //          overflow: hidden;
  //        }
  //
  //        &[attr-active=true] {
  //          flex: 5;
  //
  //          max-width: 100%;
  //
  //          & svg {
  //            & path {
  //              fill: #272835;
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //    &-pointer {
  //      height: 8px;
  //
  //      display: flex;
  //      align-items: flex-end;
  //
  //      &-line {
  //        height: 1px;
  //
  //        &:first-child {
  //          width: 100%;
  //
  //          transition: max-width .3s ease;
  //        }
  //
  //        &:last-child {
  //          flex: 1;
  //        }
  //      }
  //
  //      &-arrow {
  //        fill: none;
  //        stroke: black;
  //        display: flex;
  //      }
  //    }
  //  }
  //}
}

.tbv-slider-actions {
  &-left-arrow {
    margin-right: 26px;
  }
  &-right-arrow {
    margin-left: 26px;
  }
}

@keyframes tbv-slider-slide-overlay-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
