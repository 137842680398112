$opa: '.order-pending-action';

#{$opa} {
  display: none;

  @media all and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding: 4px 16px;
    color: #ffffff;
    background: linear-gradient(360deg, #272835 0%, #636583 100%);
  
    &__name {
      text-align: left;
    }
  
    &__date {
      text-align: right;
    }
  
    &__title {
      font-size: 10px;
      line-height: 1.6;
      font-weight: var(--theme-fontWeight--bold);
      color: var(--theme-color--lightGrey);
    }
  
    &__value {
      font-size: 13px;
      line-height: 1.4;
      font-weight: var(--theme-fontWeight--bold);
    }
  }
}