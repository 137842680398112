.video-button {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: none;
  background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
  opacity: 0.8;
  cursor: pointer;
  font-size: 0;
  transition: all .3s;

  &--disconnect {
    background: #ff4d4f;
  }

  &--unread-messages {
    &:after {
      content: '';
      display: block;

      position: absolute;
      top: 0;
      right: 3px;

      width: 12px;
      height: 12px;

      border-radius: 50%;
      background: var(--theme-color--white);
      opacity: 1;
    }
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: .3;
    cursor: not-allowed;
  }
}