$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;


// typography
.entourage-public-header {
  font-family: var(--theme-fontFamily--primary);
  font-weight: var(--theme-fontWeight--black);
  font-size: 60px;
  line-height: 1.25;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  color: var(--theme-color--white);

  @media only screen and (max-width: #{$media-dt}) {
    font-size: 54px;
  }

  @media only screen and (max-width: #{$media-lg}) {
    font-size: 42px;
  }

  @media only screen and (max-width: #{$media-sm}) {
    font-size: 36px;
  }
}

.entourage-public-sub-header {
  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--bold);
  font-size: 20px;
  line-height: 1.5;
  color: var(--theme-color--white);

  margin-bottom: 16px;
}

.entourage-public-text {
  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--medium);
  font-size: 16px;
  line-height: 1.625;
  color: var(--theme-color--blueText);

  strong {
    color: var(--theme-color--white);
    font-weight: var(--theme-fontWeight--bold);
  }
}

.entourage-public-title {
  font-family: var(--theme-fontFamily--primary);
  font-weight: var(--theme-fontWeight--black);
  font-size: 28px;
  line-height: 1.36;
  font-feature-settings: 'case' on;
  color: var(--theme-color--white);

  margin-bottom: 22px;

  @media only screen and (max-width: #{$media-sm}) {
    font-size: 20px;

    margin-bottom: 12px;
  }
}


// components
.entourage-public-icon {
  display: block;
  margin: 0 auto 24px;

  @media only screen and (max-width: #{$media-sm}) {
    transform: scale(0.7);
    margin: 0 auto 12px;
  }
}

.entourage-info {
  @extend .entourage-public-text;

  margin-top: 14px;
  margin-bottom: 140px;

  border: 1px solid var(--theme-color--white);
  border-top-width: 0;

  @media only screen and (max-width: #{$media-dt}) {
    margin-bottom: 120px;
  }

  @media only screen and (max-width: #{$media-lg}) {
    margin-bottom: 100px;
  }

  @media only screen and (max-width: #{$media-md}) {
    margin-bottom: 80px;
  }

  @media only screen and (max-width: #{$media-sm}) {
    margin-bottom: 60px;
  }

  &__wrapper {
    padding: 0 118px 72px;

    @media only screen and (max-width: #{$media-dt}) {
      padding: 0 80px 50px;
    }

    @media only screen and (max-width: #{$media-lg}) {
      padding: 0 60px 36px;
    }

    @media only screen and (max-width: #{$media-md}) {
      padding: 0 40px 24px;
    }

    @media only screen and (max-width: #{$media-sm}) {
      padding: 0 16px 10px;
    }
  }

  &__header {
    @extend .entourage-public-sub-header;

    margin-top: 72px;

    @media only screen and (max-width: #{$media-lg}) {
      margin-top: 50px;
      text-align: center;
    }

    @media only screen and (max-width: #{$media-md}) {
      margin-top: 32px;
    }
  }

  &__description {
    display: table;
    width: 100%;

    margin-bottom: 32px;

    &:before,
    &:after {
      content: '';
      border-top: 1px solid var(--theme-color--white);
      width: 15%;
      display: table-cell;
      white-space: nowrap;
    }

    @media only screen and (max-width: #{$media-sm}) {
      margin-bottom: 20px;

      &:before,
      &:after {
        width: 5%;
      }
    }
  }

  &__description-text {
    padding: 0 15px;
    text-align: center;
  }

  &__controls {
    display: flex;
    align-items: center;

    margin-bottom: 32px;

    @media only screen and (max-width: #{$media-lg}) {
      margin: 20px auto;
      justify-content: center;
    }
  }

  &__note {
    margin-bottom: 40px;

    @media only screen and (max-width: #{$media-lg}) {
      margin-bottom: 30px;
    }

    @media only screen and (max-width: #{$media-sm}) {
      margin-bottom: 20px;
    }
  }

  &__details {
    display: flex;

    & > * {
      margin-bottom: 20px;

      &:not(:last-child) {
        margin-right: 34px;
      }
    }

    @media only screen and (max-width: #{$media-lg}) {
      flex-direction: column-reverse;
      align-items: center;

      & > * {
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }

  &__requirement-list {
    padding-left: 20px;
  }

  &__requirement-item {
    position: relative;
    margin: 6px 0;

    &:after {
      content: '';

      position: absolute;
      left: -20px;
      bottom: 50%;
      transform: translateY(50%);

      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--theme-color--white);
    }
  }

  &__partners-list {
    display: flex;

    @media only screen and (max-width: #{$media-lg}) {
      flex-direction: column;
    }
  }

  &__partner {
    flex: 0 1 50%;
    display: flex;

    &:not(:last-child) {
      margin-right: 30px;
    }

    @media screen and (max-width: #{$media-dt}) {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    @media only screen and (max-width: #{$media-lg}) {
      flex-direction: column;
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }

  &__partner-link {
    flex-shrink: 0;
    margin-right: 24px;

    @media only screen and (max-width: #{$media-lg}) {
      margin: 0 auto;
    }
  }
}

.entourage-card {
  width: 444px;

  padding: 24px 34px 34px;
  background: var(--theme-color--white);
  box-shadow: var(--theme-boxShadow--modal);

  @media only screen and (max-width: #{$media-sm}) {
    width: 100%;
    padding: 12px 16px 16px;
  }

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    font-feature-settings: 'case' on, 'liga' off;
    color: var(--theme-color--blue);
  }

  &__list {
    margin-bottom: 24px;
  }

  &__list-item {
    padding: 6px 0;

    span {
      font-size: 14px !important; // styled-components
    }

    a {
      color: #000000;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ._price {
    text-align: center;
    margin-bottom: 16px;

    &__value {
      font-size: 45px;

      @media only screen and (max-width: #{$media-sm}) {
        font-size: 32px;
      }
    }
  }
}

.entourage-benefits {

  &__item {
    margin-bottom: 100px;

    &:last-child {
      margin-bottom: 80px;
    }
  }

  &__header {
    @extend .entourage-public-header;

    margin-bottom: 88px;

    @media only screen and (max-width: #{$media-dt}) {
      margin-bottom: 60px;
    }

    @media only screen and (max-width: #{$media-sm}) {
      margin-bottom: 32px;
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap;

    margin: -30px -17px;
    margin-bottom: 32px;

    & > * {
      flex: 1 1 440px;
      padding: 30px 17px;

      @media only screen and (max-width: #{$media-sm}) {
        padding: 16px 8px;
      }
    }

    @media only screen and (max-width: #{$media-sm}) {
      margin: -16px -8px;
      margin-bottom: 40px;
    }
  }

  &__process-header {
    @extend .entourage-public-title;
  }

  &__text {
    @extend .entourage-public-text;
  }
}
