.cookies-details-item {
  padding: 16px;
  background-color: var(--theme-color--white);
  border: 1px solid var(--theme-color--white);
  border-radius: var(--theme-borderRadius--default);
  color: var(--theme-color--black);

  > * {
    display: flex;
    margin-bottom: 16px;
    font-weight: var(--theme-fontWeight--semiBold);

    &:nth-child(2) {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--theme-color--lightGrey);
      font-weight: initial;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--theme-color--black);
    text-decoration: underline;
  }
}