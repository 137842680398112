@import 'constants';

@mixin h1 {
  font: {
    family: $font-family-primary;
    style: normal;
    weight: 900;
    size: 60px;
  }

  line-height: 75px;

  color: $text_main;
}

@mixin h2 {
  font: {
    family: $font-family-primary;
    style: normal;
    weight: 900;
    size: 42px;
  }

  line-height: 54px;

  color: $text_main;
}

@mixin h3 {
  font: {
    family: $font-family-primary;
    style: normal;
    weight: 900;
    size: 28px;
  }

  line-height: 38px;

  color: $text_main;
}

@mixin h4 {
  font: {
    family: $font-family-primary;
    style: normal;
    weight: 900;
    size: 23px;
  }

  line-height: 29px;

  color: $text_main;
}

@mixin Subtitle {
  font: {
    family: $font-family-default;
    style: normal;
    weight: normal;
    size: 25px;
  }

  line-height: 38px;

  color: $text_main;
}

@mixin Body1 {
  font: {
    family: $font-family-default;
    style: normal;
    weight: 500;
    size: 21px;
  }

  line-height: 32px;

  color: $text_main;
}

@mixin Body1_bold {
  font: {
    family: $font-family-default;
    style: normal;
    weight: bold;
    size: 21px;
  }

  line-height: 32px;

  color: $text_main;
}

@mixin Body2 {
  font: {
    family: $font-family-default;
    style: normal;
    weight: 500;
    size: 16px;
  }

  line-height: 26px;

  color: $text_main;
}

@mixin Body2_bold {
  font: {
    family: $font-family-default;
    style: normal;
    weight: bold;
    size: 16px;
  }
  line-height: 26px;

  color: $text_main;
}

@mixin Caption {
  font: {
    family: $font-family-default;
    style: normal;
    weight: bold;
    size: 13px;
  }

  line-height: 18px;
  text-transform: uppercase;

  color: $text_main;
}

@mixin Small {
  font: {
    family: $font-family-default;
    style: normal;
    weight: 500;
    size: 13px;
  }

  line-height: 22px;

  color: $text_main;
}

@mixin Input_Style {
  font: {
    family: $font-family-default;
    style: normal;
    weight: 500;
    size: 18px;
  }

  line-height: 32px;
}

@mixin grey_border {
  border: 2px solid $border_color;
}
