.joinToTalent-modal {
  $with-triangle: 12px;

  $modal-media-lg: 992;
  $modal-media-md: 768;
  $modal-media-sm: 725;
  $modal-media-xs: 426;

  @mixin modal-media($width) {
    @media only screen and (max-width: #{$width - 1px}) {
      @content;
    }
  }

  background-color: get-colour('white');
  padding: 16px 0 40px;
  border-radius: 8px;
  font-family: $font-family-default;
  line-height: 1.5em;
  color: get-colour('blue');
  width: 820px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
  @include modal-media($modal-media-lg) {
    width: 700px;
  }
  @include modal-media($modal-media-sm) {
    width: 500px;
  }
  @include modal-media(576) {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 24px 16px 72px;
  }


  &__title {
    font-size: get-font-size('l-2');
    font-weight: get-font-weight('black');
    font-family: $font-family-primary;
    color: get-colour('blue');
    text-align: center;
    line-height: 1.3;
    margin: 0 0 24px;
    @include modal-media($modal-media-sm) {
      font-size: get-font-size('m-2');
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: get-font-size('s-1');
    font-weight: get-font-weight('medium');
    font-family: $font-family-default;
    color: get-colour('dark-grey');
    @include modal-media($modal-media-sm) {
      text-align: center;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 190px 1fr;
    position: relative;
    height: 465px;
    @include modal-media($modal-media-sm) {
      grid-template-columns: 1fr;
      height: auto;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #babbcd 0%, rgba(186, 187, 205, 0) 100%);
      @include modal-media($modal-media-sm) {
        display: none;
      }
    }
  }

  &__nav {
    position: relative;
    display: flex;
    flex-direction: column;
    @include modal-media($modal-media-sm) {
      flex-direction: row;
      border-top: 1px solid get-colour('blue-text');
      border-bottom: 1px solid get-colour('blue-text');
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, #babbcd 0%, rgba(186, 187, 205, 0) 100%);
      @include modal-media($modal-media-sm) {
        display: none;
      }
    }
  }

  &__user-link {
    margin-top: auto;
    padding-left: 40px;
    font-size: get-font-size('s-1');
    font-weight: get-font-weight('bold');
    font-family: $font-family-default;
    color: get-colour('dark-grey');
    text-decoration: underline;
    line-height: 1.62;
    cursor: pointer;
    @include modal-media($modal-media-sm) {
      display: none;
    }
    &--top {
      display: none;
      @include modal-media($modal-media-sm) {
        display: block;
        font-size: get-font-size('s-2');
        text-align: center;
        padding: 0;
        margin-bottom: 25px;
        br {
          display: none;
        }
      }
    }
  }

  &__tabs {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include modal-media($modal-media-sm) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
    }

    &_item {
      width: 100%;
    }

    &_btn {
      width: 100%;
      font-size: get-font-size('s-1');
      font-weight: get-font-weight('medium');
      font-family: $font-family-default;
      color: get-colour('dark-grey');
      text-align: left;
      background-color: rgba(get-colour('white'), 0);
      border: none;
      padding: 18px 0 18px 40px;
      cursor: pointer;
      outline: none;
      position: relative;
      transition: background-color .3s ease-in-out;
      @include modal-media($modal-media-sm) {
        padding: 18px 5px;
        text-align: center;
        transition: none;
        font-size: get-font-size('s-2');
        min-height: 72px;
        height: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -$with-triangle;
        width: 0;
        height: 0;
        border-top: $with-triangle solid transparent;
        border-left: $with-triangle solid get-colour('blue');
        border-bottom: $with-triangle solid transparent;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        @include modal-media($modal-media-sm) {
          top: 100%;
          right: calc(50% - #{$with-triangle});
          transform: translate(0);
          border-top: $with-triangle solid get-colour('blue');
          border-left: $with-triangle solid transparent;
          border-right: $with-triangle solid transparent;
          transition: none;
        }
      }

      &:hover {
        background-color: get-colour('ultra-light-blue');
      }

      &.active {
        font-weight: get-font-weight('bold');
        color: get-colour('white');
        background-color: get-colour('blue');

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__content {
    padding: 24px 40px 0 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include modal-media($modal-media-sm) {
      padding: 24px 16px;
    }
  }
  &__submit {
    @include modal-media($modal-media-sm) {
      width: 100%;
      margin-top: 32px !important;
    }
  }

  .react-select__menu-list {
    background-color: #ffffff;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    position: absolute;
    top: 120%;
    left: 0;
    z-index: 999;
    width: 100%;
    padding: 8px 0;
    max-height: 200px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  }
}
