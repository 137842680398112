$m-m: '.mobile-menu';

#{$m-m} {
  display: none;

  &.active {
    left: 0;
    transition: left 0.3s ease-out;
  }

  @media #{$md} {
    background: get-colour('white');
    min-width: 320px;
    height: calc(100% - 78px);
    width: 50%;
    position: fixed;
    //TODO create map function for z-index
    z-index: 99999;
    display: block;
    top: 0;
    left: -100%;
    transition: left 0.3s ease-out;
    overflow: scroll;

    &__heading {
      height: 100px;
      border-bottom: 1px solid #e9eaf0;
      @include display-flex(row, flex-end, space-between);
      padding: 24px;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &--title {
      font-family: $font-family-primary;
      font-weight: get-font-weight('black');
      font-size: get-font-size('m-2');
      line-height: 29px;
      font-feature-settings: 'case' on, 'liga' off;
      color: get-colour('blue');
    }

    &__list {
      height: 100%;
      display: block;
      margin-bottom: 85px;
    }

    &__profile {
      padding: 28px 16px;
      display: flex;

      &--image {
        @include size(64px);
        background: get-colour('blue');
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          background: none;
        }
      }

      &--info {
        padding-left: 12px;
        @include display-flex(column, null, center);

        p {
          font-weight: get-font-weight('bold');
          font-size: get-font-size('m-5');
          line-height: 26px;
          color: get-colour('blue');
          padding-bottom: 3px;
        }
      }
    }

    &__item {
      position: relative;

      &--heading {
        @include display-flex(null, baseline);
        width: 100%;
        height: 40px;
        padding: 10px 16px;

        p,
        a {
          background: transparent;
          border: none;
          cursor: pointer;
          font-weight: get-font-weight('semi-bold');
          font-size: get-font-size('s-1');
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: capitalize;
          outline: none;
          @include display-flex(null, baseline, space-between);
          flex: 1;
          padding-left: 16px;
          color: get-colour('dark-grey');
        }

        a,
        p,
        i {
          color: get-colour('dark-grey');
        }

        svg {
          stroke: get-colour('dark-grey');
        }
      }

      &.opened {
        background: get-colour('grey');
        color: get-colour('blue');

        #{$m-m}__item {
          &--heading {
            a,
            p,
            i {
              color: get-colour('blue');
            }
            svg {
              transform: rotate(180deg);
            }
          }

          &--body {
            height: 100%;
          }
        }
      }

      &--link a,
      button {
        font-weight: get-font-weight('medium');
        font-size: get-font-size('s-2');
        line-height: 26px;
        text-transform: capitalize;
        color: get-colour('dark-grey');
        height: 40px;
        width: 100%;
        padding-left: 54px;
        border: none;
        background: none;
        cursor: pointer;
        @include display-flex(null, center);
      }

      &--body {
        height: 0;
        @include display-flex(column);
        display: flex;
        color: get-colour('dark-grey');
        overflow: hidden;
      }

      &.unread-messages-indicator {
        a > * {
          position: relative;

          &:after {
            content: '';
            display: block;

            position: absolute;
            top: -6px;
            right: -14px;

            width: 8px;
            height: 8px;

            border-radius: 50%;
            background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
          }
        }
      }
    }

    &__body {
      margin-top: 100px;
    }
  }
  @media #{$sm} {
    width: 100%;
  }
}
