.chat {
  &__wrapper {
    display: flex;
    align-items: center;

    height: 64px;
    padding: 8px 16px;

    &:hover {
      cursor: pointer;
      background-color: var(--theme-color--ultraLightBlue);
    }

    &.active {
      background-color: var(--theme-color--ultraLightBlue);
    }
  }

  &__icon {
    width: 40px !important;
    height: 40px !important;
    flex-shrink: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding-left: 8px;

    &-header {
      display: flex;
      justify-content: space-between;

      &-chat-title {
        font-size: 13px;
        font-weight: var(--theme-fontWeight--bold);

        line-height: 1.7;

        color: var(--theme-color--blue);

        text-overflow: ellipsis;

        white-space: nowrap;
        overflow: hidden;
        width: 150px;

        &-wrapper {
          display: flex;

          svg {
            margin-right: 10px;
          }
        }
      }

      &-last-message-date {
        font-size: 11px;
        font-weight: var(--theme-fontWeight--medium);

        line-height: 2;

        color: var(--theme-color--lightGrey);
      }
    }

    &-message-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-last-message {
      font-size: 12px;
      font-weight: var(--theme-fontWeight--semiBold);

      line-height: 1.33;

      color: var(--theme-color--lightGrey);

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 226px;

      &-sender {
        font-weight: var(--theme-fontWeight--semiBold);
        color: var(--theme-color--black);

        padding-right: 10px;
      }
    }
  }
}

.unread-messages-counter {
  display: flex;
  justify-content: center;

  border-radius: 50%;

  font-size: 11px;
  font-weight: var(--theme-fontWeight--medium);
  color: var(--theme-color--white);

  height: 18px;
  min-width: 18px;
  line-height: 1.64;

  background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
}
