$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;

.main-participant {
  position: relative;

  height: 100vh;
  background-color: var(--theme-color--black);

  &__info {
    position: absolute;
    height: 100px;
    width: 100%;

    background: linear-gradient(180deg, rgba(39, 40, 53, 0.67) 0%, rgba(39, 40, 53, 0) 100%);
    opacity: 0.9;

    z-index: 1;

    @media screen and (max-width: #{$media-sm}) {
      height: 140px;
    }
  }

  &__title {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 14px;
    line-height: 1.3;
    text-transform: uppercase;
    color: var(--theme-color--white);
    opacity: 0.6;
    text-align: center;

    margin-top: 15px;
  }

  &__identity {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 24px;
    line-height: 1.2;
    font-feature-settings: 'case' on, 'liga' off;
    color: var(--theme-color--white);
    text-align: center;
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
