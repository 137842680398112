$unc: '.user-notifications-counter';

.user {
  &-account {
    @include display-flex(row, center);
  }

  &-profile {
    @include display-flex(row, center);
    cursor: pointer;
    @extend %main-theme-colors;

    &-photo {
      @include display-flex(row, center);

      img {
        @include size(47px);
        border-radius: 50%;
        background: get-colour('white');
        margin: 0 18px;
      }

      svg {
        @extend %main-theme-colors-svg;
      }

      svg.opened {
        transform: rotate(180deg);
        transition: transform .1s ease-in;
      }
    }

    &-name {
      font-weight: get-font-weight('bold');
      font-size: get-font-size('s-3');
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-right: 4px;
      text-align: right;
    }

    &-switcher {
      width: 24px;
      margin-right: 12px;
      margin-left: 12px;
      cursor: pointer;

      @at-root .dark-theme #{&} {
        path {
          fill: get-colour('white');
        }
      }

      @at-root .light-theme #{&} {
        path {
          fill: get-colour('blue');
        }
      }
    }
  }

  &-notifications {
    position: relative;
    @include size(40px);
    @include display-flex(row, center);
    cursor: pointer;
    @extend %main-theme-colors;

    .icon-bell {
      font-size: get-font-size('m-1');
      margin-top: 4px;
    }
  }
}

.switcher-icon {
  @at-root .dark-theme #{&} {
    fill: get-colour('white');
  }

  @at-root .light-theme #{&} {
    fill: get-colour('blue');
  }
}
