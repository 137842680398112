$gradient-btn: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%);
$gradient-btn-hover: linear-gradient(309.69deg, #707394 17.05%, #0f0f15 69.2%);
$gradient-btn-active: linear-gradient(309.69deg, #5a5d85 17.05%, #090911 69.2%);

$gradient-border-btn: linear-gradient(0, #272835 0, #636583 97.83%);
$gradient-border-btn-white: linear-gradient(0, #272835 0, #272835 0);

$border-width: 2px;
$border-radius: 4px;

.button {
  display: inline-block;
  padding: $border-width;
  border-radius: $border-radius;

  background-image: $gradient-border-btn;
  position: relative;
  z-index: 0;

  &:hover {
    .button__item {
      background-image: $gradient-btn-hover;
    }
  }

  &:focus,
  &:active {
    outline: none;

    .button__item {
      background-image: $gradient-btn-active;
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(#{var(--padding-top-bottom-btn)} - #{$border-width}) calc(#{var(--padding-left-right-btn)} - #{$border-width});

    outline: none;
    border-radius: $border-radius;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 16px;
    background-color: rgba(get-colour('white'), 0);
    background-image: $gradient-btn;
  }

  &__text {
    font-size: var(--font-size-btn);
    font-weight: var(--font-weight-btn);
    font-family: $font-family-default;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    line-height: 1.5;
    color: get-colour('white');
    text-decoration: none;
    text-transform: uppercase;
    z-index: 1;
  }

  &--loader {
    pointer-events: none;

    &:hover {
      background-image: $gradient-border-btn !important;

      .button {
        &__item {
          background-image: $gradient-btn !important;
        }
      }
    }

    .button {
      &__item {
        cursor: no-drop;
      }

      &__text {
        color: rgba(get-colour('white'), 0.1);
      }

      &__spinner {
        display: block;
      }
    }

    &.button--white {
      &:hover {
        background-color: get-colour('black');
        background-image: none !important;

        .button {
          &__item {
            background-color: get-colour('white');
            background-image: none !important;
          }

          &__text {
            -webkit-text-fill-color: get-colour('black');
          }
        }
      }

      .button {
        &__text {
          background-image: none !important;
          -webkit-text-fill-color: inherit !important;
          color: rgba(get-colour('blue'), 0.1);
        }
      }
    }
  }

  &__spinner {
    display: none;
    position: absolute;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  &--stretch {
    width: 100%;
  }

  &--gradient {
    background-image: $gradient-btn;
  }

  &--shadow {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0 4px 25px rgba(0, 0, 0, 0.45);

    &:before {
      content: '';
      @include position-absolute(0, 0, 0, 0);
      border-radius: $border-radius;
      box-shadow: 0 4px 40px rgba(0, 0, 0, 0.45);
    }
  }

  &--primary {
    background-image: none;
    border: 2px solid get-colour('blue');
    padding: 0;

    &:hover {
      background-image: none;

      .button {
        &__item {
          background-color: rgba(233, 234, 240, 0.99);
        }

        &__text {
          background-image: $gradient-btn;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &:focus,
    &:active {
      outline: none;

      .button__item {
        background-color: #ffffff;
      }
    }

    .button {
      &__text {
        color: get-colour('blue');
      }

      &__item {
        background-color: rgba(get-colour('white'), 0);
        background-image: none !important;
      }
    }
  }

  &--secondary {
    background-image: none;
    border: $border-width solid get-colour('white');
    padding: 0;

    &:hover {
      .button {
        &__item {
          background-image: none;
          background-color: rgba(get-colour('white'), .15);
        }

        &__text {
          background-image: none;
          -webkit-background-clip: text;
          -webkit-text-fill-color: get-colour('white');
          color: get-colour('white');
        }
      }
    }

    &:focus,
    &:active {
      .button__item {
        background-image: none;
        background-color: rgba(get-colour('white'), .3);
      }

      &__text {
        color: get-colour('white');
      }
    }

    .button {
      &__item {
        background-image: none;
        background-color: rgba(get-colour('white'), 0);
        padding: var(--padding-top-bottom-btn) var(--padding-left-right-btn);
      }

      &__text {
        color: get-colour('white');
      }
    }

    @at-root .light-theme .main-menu #{&} {
      border: 2px solid get-colour('blue');

      &:hover {
        background-image: none;

        .button {
          &__item {
            background-color: rgba(233, 234, 240, 0.99);
          }

          &__text {
            background-image: $gradient-btn;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      &:focus,
      &:active {
        outline: none;
        .button__item {
          background-color: #ffffff;
        }
      }

      .button__text {
        color: get-colour('blue');
      }
    }
  }

  &--disabled {
    background-image: none;
    background-color: get-colour('ultra-light-blue');
    pointer-events: none;

    .button {
      &__text {
        color: get-colour('blue-text');
      }

      &__item {
        background-image: none;
        background-color: get-colour('white');
        cursor: no-drop;
      }

      &--shadow {
        box-shadow: none;

        &:before {
          display: none;
        }
      }
    }

    &:hover {
      background-image: none;

      .button {
        &--gradient {
          background-image: none;
        }

        &__text {
          background-image: none;
          -webkit-text-fill-color: get-colour('blue-text');
        }

        &__item {
          background-image: none;
          background-color: #ffffff;
        }
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.close-button {
  outline: none;
  cursor: pointer;
  border: none;
  background: linear-gradient(360deg, #272835 0%, #636583 97.83%);

  @include position-absolute(-13px, -13px, null, null);
  z-index: 100;

  height: 26px;
  width: 26px;
  padding: 0;
  border-radius: 50%;

  i {
    font-size: 26px;
  }

  &::after,
  &::before {
    content: ' ';
    @include position-absolute(50%, null, null, 50%);

    height: 16px;
    width: 3px;
    background: get-colour('white');
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
