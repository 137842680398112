.bold-button {
  display: flex;
  align-items: center;

  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--bold);
  font-size: 16px;
  line-height: 1.625;
  color: var(--theme-color--blue);

  border: none;
  background: none;
  cursor: pointer;
}
