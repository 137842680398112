.id-verification {
  .row-right {
    @media only screen and (max-width: 768px) {
      margin: 0 auto;
    }
  }

  &--button {
    button {
      display: flex;
      flex-direction: row-reverse;
      .button__spinner {
        position: initial;
        margin-right: 16px;
        path {
          fill: var(--theme-color--blue);
        }
      }
    }
  }

  &--description {
    margin-top: 18px;
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625em;
    color: var(--theme-color--grey);
  }

  &--error {
    margin-top: 18px;
    color: red;
    line-height: 1.3;
  }
}

.onfido-sdk-ui-Theme-modalOverlay--after-open {
  z-index: 999999;
}