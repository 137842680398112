$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;

.pre-join-screen {
  display: flex;
  align-items: center;

  @media screen and (max-width: #{$media-md}) {
    flex-direction: column;
  }

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-feature-settings: 'case' on, 'liga' off;
    font-size: 42px;
    line-height: 1.28;
    color: var(--theme-color--blue);
    text-align: center;

    margin-bottom: 32px;

    @media screen and (max-width: #{$media-lg}) {
      font-size: 36px;
    }

    @media screen and (max-width: #{$media-md}) {
      font-size: 30px;
    }

    @media screen and (max-width: #{$media-sm}) {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }

  &__video-controls {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 575px) {
      bottom: 16px;
    }
  }

  &__description {
    flex: 1 1;

    margin-left: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: #{$media-md}) {
      margin-top: 32px;
      margin-left: 0;
    }
  }

  &__video {
    position: relative;
    overflow: hidden;
    width: 799px;
    height: 495px;

    @media screen and (max-width: #{$media-dt}) {
      width: 700px;
      height: 430px;
    }

    @media screen and (max-width: #{$media-lg}) {
      width: 560px;
      height: 360px;
    }

    @media screen and (max-width: #{$media-md}) {
      width: 494px;
      height: 300px;
    }

    @media screen and (max-width: #{$media-sm}) {
      width: 220px;
      height: 294px;
    }

    @media screen and (max-width: #{$media-xs}) {
      width: 220px;
      height: 294px;
    }
  }

  &__join-button {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  .video-button {
    margin: 0 9px;

    @media screen and (max-width: #{$media-sm}) {
      width: 48px;
      height: 48px;
    }
  }
}