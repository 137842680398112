.resource-center__items {
  .resource-center__item {
    p {
      text-align: left;
    }

    h2,
    p {
      margin-bottom: 24px;
    }

    & + * {
      margin-top: 100px;
    }

    &--file {
      + * {
        margin-top: 16px;
      }
    }

    @media screen and (max-width: 1024px) {
      h2 {
        font-size: 28px;
      }
    }

    @media screen and (max-width: 767px) {
      h2 {
        font-size: 24px;
      }

      h2,
      p {
        margin-bottom: 16px;
      }

      & + * {
        margin-top: 32px;
      }
    }
  }
}
.resource-center_preview{
  height: 105.19px;
  width: 187px;
  object-fit: cover;
}