.empty-chats-list {
  width: 100%;

  font-size: 14px;
  font-weight: var(--theme-fontWeight--medium);
  line-height: 1.86;

  text-align: center;

  padding: 20px;

  color: var(--theme-color--grey);
}
