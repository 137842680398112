.aficionado {
  &__wrapper {
    display: flex;
    align-items: center;

    height: 64px;
    padding: 8px 16px;

    &:hover {
      cursor: pointer;
      background-color: var(--theme-color--ultraLightBlue);
    }
  }

  &__icon {
    width: 40px !important;
    height: 40px !important;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-left: 10px;
  }

  &__full-name {
    width: 230px;

    font-size: 13px;
    font-weight: var(--theme-fontWeight--bold);

    line-height: 1.7;

    color: var(--theme-color--blue);

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__start-chat {
    font-size: 12px;
    font-weight: var(--theme-fontWeight--semiBold);

    line-height: 1.33;

    color: var(--theme-color--lightGrey);
  }
}
