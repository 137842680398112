$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;

.chat-window {
  display: flex;
  flex-direction: column;

  width: 375px;
  height: 530px;

  background: #f5f5f5;
  border-radius: 4px;

  position: absolute;
  right: 40px;
  bottom: 130px;
  z-index: 2;

  @media screen and (max-width: #{$media-md}) {
    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border-radius: 0;
  }
}