.search-card {
  position: relative;
  width: 204px;
  height: 272px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
  display: block;
  @media screen and (max-width: 575px) {
    width: 270px;
    height: 360px;
  }
  &:hover {
    &:after {
      height: 100%;
    }
    &:before {
      transform: translate(-50%, -8px);
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
  }
  &:before {
    width: 140px;
    height: 184px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.35);
    transition: transform .5s ease-in-out;
  }
  &:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80%;
    background-image: linear-gradient(
      180deg,
      rgba(39, 40, 53, 0) 0%,
      rgba(39, 40, 53, 0) 38%,
      rgba(39, 40, 53, 0.15) 56%,
      rgba(39, 40, 53, 0.6) 82%,
      rgba(39, 40, 53, 0.6) 100%);
    transition: height .3s ease-in-out;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e9eaf0;
    img {
      object-fit: cover;
    }
  }
  &__top {
    position: absolute;
    top: 10px;
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__offering {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.4));
  }
  &__save {
    margin-left: auto;
    padding: 0 !important;
    z-index: 3;
  }
  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px 12px;
  }
  text-align: center;
  &__fullName {
    width: 100%;
    margin-top: auto;
    &_name,
    &_lastname {
      margin: 0;
      font-family: 'Playfair Display', sans-serif;
      font-feature-settings: 'case' on, 'liga' off, 'lnum' on;
      color: #ffffff;
      line-height: 1.4;
      word-break: break-all;
      text-align: center;
    }
    &_name {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.01em;
    }
    &_lastname {
      font-size: 20px;
      font-weight: 900;
      font-feature-settings: 'case' on, 'liga' off, 'lnum' on;
    }
  }
  &__offers {
    overflow-wrap: break-word;
    font-family: 'Playfair Display', sans-serif;
    font-feature-settings: 'case' on, 'liga' off, 'lnum' on;
    font-weight: 900;
    font-size: 16px;
    line-height: 1.4;
    color: #ffffff;
    margin: 8px 0;
    max-height: 70px;
    height: 100%;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }
  &__date {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.4;
    color: #ffffff;
  }
}
