@font-face {
  font-family: 'fontello';
  src: url('../../assets/font/fontello.eot?94500913');
  src: url('../../assets/font/fontello.eot?94500913#iefix') format('embedded-opentype'),
       url('../../assets/font/fontello.woff2?94500913') format('woff2'),
       url('../../assets/font/fontello.woff?94500913') format('woff'),
       url('../../assets/font/fontello.ttf?94500913') format('truetype'),
       url('../../assets/font/fontello.svg?94500913#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?94500913#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-close-icon:before { content: '\e800'; } /* '' */
.icon-filter:before { content: '\e801'; } /* '' */
.icon-mail:before { content: '\e802'; } /* '' */
.icon-lightning-fill:before { content: '\e803'; } /* '' */
.icon-lightning:before { content: '\e804'; } /* '' */
.icon-log-in:before { content: '\e805'; } /* '' */
.icon-plus:before { content: '\e806'; } /* '' */
.icon-close-icon-1:before { content: '\e807'; } /* '' */
.icon-report:before { content: '\e808'; } /* '' */
.icon-calendar-alt-3:before { content: '\e809'; } /* '' */
.icon-instagram:before { content: '\e80a'; } /* '' */
.icon-play-1:before { content: '\e80b'; } /* '' */
.icon-tiktok:before { content: '\e80c'; } /* '' */
.icon-twitter:before { content: '\e80d'; } /* '' */
.icon-facebook:before { content: '\e80e'; } /* '' */
.icon-talent:before { content: '\e80f'; } /* '' */
.icon-sign_in:before { content: '\e810'; } /* '' */
.icon-location:before { content: '\e811'; } /* '' */
.icon-charities:before { content: '\e812'; } /* '' */
.icon-mortar-board:before { content: '\e813'; } /* '' */
.icon-video-alt:before { content: '\e814'; } /* '' */
.icon-image-fill:before { content: '\e815'; } /* '' */
.icon-frame:before { content: '\e816'; } /* '' */
.icon-speakerphone:before { content: '\e817'; } /* '' */
.icon-hometown:before { content: '\e818'; } /* '' */
.icon-add-circle:before { content: '\e819'; } /* '' */
.icon-arrow-back:before { content: '\e81a'; } /* '' */
.icon-arrow-drop-up:before { content: '\e81b'; } /* '' */
.icon-location-point-plus:before { content: '\e81c'; } /* '' */
.icon-sort:before { content: '\e81d'; } /* '' */
.icon-nationality:before { content: '\e81e'; } /* '' */
.icon-pause:before { content: '\e81f'; } /* '' */
.icon-experiences:before { content: '\e820'; } /* '' */
.icon-arrow:before { content: '\e821'; } /* '' */
.icon-logo-white:before { content: '\e822'; } /* '' */
.icon-human:before { content: '\e823'; } /* '' */
.icon-upload-files:before { content: '\e824'; } /* '' */
.icon-eye-invisible:before { content: '\e825'; } /* '' */
.icon-plus-in-circle:before { content: '\e826'; } /* '' */
.icon-menu:before { content: '\e827'; } /* '' */
.icon-clip:before { content: '\e829'; } /* '' */
.icon-chats:before { content: '\e82a'; } /* '' */
.icon-giving:before { content: '\e82c'; } /* '' */
.icon-one-on-one:before { content: '\e82d'; } /* '' */
.icon-passion:before { content: '\e82e'; } /* '' */
.icon-included:before { content: '\e82f'; } /* '' */
.icon-format:before { content: '\e830'; } /* '' */
.icon-access:before { content: '\e831'; } /* '' */
.icon-magnifier:before { content: '\e832'; } /* '' */
.icon-2:before { content: '\e833'; } /* '' */
.icon-calendar:before { content: '\e834'; } /* '' */
.icon-check_in_circle:before { content: '\e835'; } /* '' */
.icon-review:before { content: '\e836'; } /* '' */
.icon-chat:before { content: '\e837'; } /* '' */
.icon-my-account:before { content: '\e838'; } /* '' */
.icon-user-circle:before { content: '\e839'; } /* '' */
.icon-bookmark:before { content: '\e83a'; } /* '' */
.icon-attach-file:before { content: '\e83b'; } /* '' */
.icon-drag-indicator:before { content: '\e83c'; } /* '' */
.icon-ic_close:before { content: '\e83e'; } /* '' */
.icon-my-location:before { content: '\e83f'; } /* '' */
.icon-outline-timer:before { content: '\e840'; } /* '' */
.icon-send:before { content: '\e841'; } /* '' */
.icon-update:before { content: '\e842'; } /* '' */
.icon-languages:before { content: '\e843'; } /* '' */
.icon-location-point:before { content: '\e844'; } /* '' */
.icon-play-circle-o:before { content: '\e846'; } /* '' */
.icon-calendar-alt:before { content: '\e848'; } /* '' */
.icon-bell:before { content: '\e849'; } /* '' */
.icon-pricetag:before { content: '\e84a'; } /* '' */
.icon-profile-text:before { content: '\e84b'; } /* '' */
.icon-star-7:before { content: '\e84c'; } /* '' */
.icon-trash:before { content: '\e84d'; } /* '' */
.icon-ticket:before { content: '\e84e'; } /* '' */
.icon-users:before { content: '\e84f'; } /* '' */
.icon-check-alt:before { content: '\e850'; } /* '' */
.icon-passion-alt:before { content: '\e851'; } /* '' */
.icon-ticket-star:before { content: '\e859'; } /* '' */
.icon-horn:before { content: '\e85c'; } /* '' */
.icon-tv:before { content: '\e85d'; } /* '' */
.icon-play:before { content: '\e880'; } /* '' */