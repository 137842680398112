.about-us {
  $mission-border-height: 1px;

  $about-media-4xl: 1801;
  $about-media-3xl: 1601;
  $about-media-xxl: 1441;
  $about-media-xl: 1367;
  $about-media-lg: 992;
  $about-media-md: 768;
  $about-media-sm: 576;
  $about-media-xs: 426;

  @mixin about-media($width) {
    @media only screen and (max-width: #{$width - 1px}) {
      @content;
    }
  }

  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 90px;

  @include about-media($about-media-lg) {
    padding-bottom: 60px;
  }

  &__main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    @include about-media($about-media-md) {
      margin-bottom: 40px;
    }
    &--text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 9.5em;
      font-weight: get-font-weight('black');
      font-family: $font-family-primary;
      color: rgba(get-colour('light-grey'), .08);
      text-transform: uppercase;
      pointer-events: none;
      @include about-media($about-media-4xl) {
        font-size: 7em;
      }
      @include about-media($about-media-xxl) {
        font-size: 6em;
      }
      @include about-media($about-media-xl) {
        font-size: 4.5em;
      }
      @include about-media($about-media-lg) {
        font-size: get-font-size('l-1');
      }
      @include about-media($about-media-md) {
        display: none;
      }
      &-left {
        left: 105%;
        text-align: left;
      }
      &-right {
        right: 105%;
        text-align: right;
      }
    }
    &--circle {
      position: relative;
      width: 700px;
      height: 700px;
      background-color: get-colour('white');
      border-radius: 50%;
      box-shadow: 0 0 32px rgba(get-colour('white'), .5);
      @include about-media($about-media-3xl) {
        width: 550px;
        height: 550px;
      }
      @include about-media($about-media-xl) {
        width: 450px;
        height: 450px;
      }
      @include about-media($about-media-lg) {
        width: 350px;
        height: 350px;
      }
      @include about-media($about-media-xs) {
        width: 250px;
        height: 250px;
      }
    }
    &--silhouette {
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      pointer-events: none;
      @include about-media($about-media-3xl) {
        font-size: 9px;
      }
      @include about-media($about-media-xl) {
        font-size: 7px;
      }
      @include about-media($about-media-lg) {
        font-size: 6px;
      }
      @include about-media($about-media-xs) {
        font-size: 4px;
      }
      img {
        width: 65.4em;
        height: 59em;
      }
    }
    &--title {
      position: absolute;
      left: 50%;
      bottom: 10px;
      transform: translateX(-50%);
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include about-media($about-media-md) {
        bottom: -10px;
      }
      h2,
      h4 {
        font-family: $font-family-primary;
        font-weight: get-font-weight('black');
        color: get-colour('white');
      }
      h2 {
        font-size: 6.5em;
        margin: 0;
        @include about-media($about-media-xl) {
          font-size: get-font-size('l-1');
        }
        @include about-media($about-media-lg) {
          font-size: get-font-size('l-2');
        }
        @include about-media($about-media-xs) {
          font-size: get-font-size('m-1');
        }
      }
      h4 {
        font-size: 3.125em;
        margin-top: 0;
        margin-bottom: 16px;
        @include about-media($about-media-3xl) {
          font-size: get-font-size('l-2');
        }
        @include about-media($about-media-xl) {
          font-size: get-font-size('m-1');
        }
        @include about-media($about-media-xs) {
          font-size: get-font-size('m-3');
          margin-bottom: 8px;
        }
      }
    }
  }

  &__title {
    padding: 0 120px;
    @include about-media($about-media-xl) {
      padding: 0 60px;
    }
    @include about-media($about-media-md) {
      padding: 0 30px;
    }
    @include about-media($about-media-xs) {
      padding: 0;
    }

    h2 {
      font-size: get-font-size('l-2');
      font-weight: get-font-weight('black');
      font-family: $font-family-primary;
      color: get-colour('white');
      line-height: 1.3;
      margin-top: 0;
      margin-bottom: 70px;
      text-align: center;
      @include about-media($about-media-xl) {
        font-size: get-font-size('m-1');
      }
      @include about-media($about-media-lg) {
        margin-bottom: 40px;
      }
    }
    &--wr {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 40px;
      @include about-media($about-media-lg) {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
        text-align: center;
      }
    }
    p {
      font-size: get-font-size('s-1');
      font-weight: get-font-weight('medium');
      font-family: $font-family-default;
      color: get-colour('blue-text');
      line-height: 1.65;
    }
    strong {
      font-weight: get-font-weight('bold');
      color: get-colour('white');
    }
  }

  &__mission {
    position: relative;
    max-width: 680px;
    width: 100%;
    margin: 135px auto 150px;
    border-left: $mission-border-height solid get-colour('white');
    border-right: $mission-border-height solid get-colour('white');
    border-bottom: $mission-border-height solid get-colour('white');
    @include about-media($about-media-lg) {
      margin: 100px auto;
    }
    &_header {
      display: flex;
      justify-content: center;
      transform: translateY(-27px);
      position: relative;
      overflow: hidden;
      @include about-media($about-media-md) {
        transform: translateY(-24px);
      }
      @include about-media($about-media-sm) {
        transform: translateY(-17px);
      }
    }
    &_title {
      font-family: $font-family-primary;
      font-size: get-font-size('l-2');
      font-weight: get-font-weight('black');
      color: get-colour('white');
      position: relative;
      padding: 0 50px 10px;
      @include about-media($about-media-md) {
        padding: 0 27px 5px;
      }
      @include about-media($about-media-sm) {
        font-size: get-font-size('m-1');
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: $mission-border-height;
        background-color: get-colour('white');
        @include about-media($about-media-md) {
          width: 120%;
        }
      }
      &:before {
        left: 100%;
      }
      &:after {
        right: 100%;
      }
    }
    &_content {
      text-align: center;
      font-size: get-font-size('m-2');
      font-weight: get-font-weight('normal');
      color: get-colour('white');
      line-height: 1.5;
      padding: 15px 10px 45px;
      @include about-media($about-media-md) {
        font-size: get-font-size('m-4');
      }
      @include about-media($about-media-sm) {
        padding: 5px 5px 25px;
        font-size: get-font-size('s-2');
      }
      strong {
        font-weight: get-font-weight('bold');
      }
    }
  }

  &__purposes {
    margin-top: 10px;
    &_title {
      font-size: get-font-size('l-1');
      font-weight: get-font-weight('black');
      font-family: $font-family-primary;
      color: get-colour('white');
      text-align: center;
      margin-top: 0;
      margin-bottom: 105px;
      @include about-media($about-media-md) {
        font-size: get-font-size('l-2');
        margin-bottom: 60px;
      }
      @include about-media($about-media-sm) {
        font-size: get-font-size('m-1');
      }
    }
    &_wr {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 80px 30px;
      @include about-media($about-media-lg) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include about-media($about-media-md) {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
      }
    }
    &_item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      h4 {
        font-size: get-font-size('m-1');
        font-weight: get-font-weight('black');
        font-family: $font-family-primary;
        color: get-colour('white');
        margin-top: 0;
        margin-bottom: 20px;
        @include about-media($about-media-md) {
          margin-bottom: 14px;
          font-size: get-font-size('m-3');
        }
      }
      p {
        font-size: get-font-size('s-1');
        font-weight: get-font-weight('medium');
        font-family: $font-family-default;
        color: get-colour('blue-text');
        line-height: 1.65;
        margin: 0;
      }
    }
  }
}
