.aficionado-calendar {
  height: 100%;
  max-width: 920px;
  width: 80vw;
  background: var(--theme-color--white);

  display: flex;
  flex-direction: column;

  .fc-timegrid-now-indicator-container {
    background-color: #f1f2f5;
  }

  &__main {
    height: 550px;
    position: relative;
  }

  &__footer {
    padding: 12px;
    background: #ffffff;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.24);

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 575px) {
      flex-direction: column;
      padding-bottom: 30px;
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;

    .button__text {
      font-size: 12px;
      font-weight: 700;
    }

    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  &__event-info {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 14px;
    line-height: 1.5;
    color: var(--theme-color--blue);

    display: flex;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-bottom: 12px;
    }
  }

  &__event-info-icon {
    margin-right: 12px;
    flex-shrink: 0;
  }

  @media screen and (max-width: 575px) {
    height: 100vh;
    width: 100vw;

    &__main {
      height: 100%;
    }
  }
}
