$pl: '.public-location';

#{$pl} {
  h4 {
    margin-bottom: 12px;
    color: var(--theme-color--white);
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 22px;
    line-height: 1.3;
  }
  h3 {
    color: var(--theme-color--white);
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 16px;
    line-height: 1.3;

  }

  p {
    // color: var(--theme-color--white);
    color: var(--theme-color--blueText);
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.6;
  }

  &__single {
    margin-top: 36px;
  }

  &__multiple {
    .pv-card-location-flexible {
      overflow: hidden;
      margin-bottom: 8px;

      p {
        &:last-child {
          height: 0;
          color: var(--theme-color--blueText);

          &.visible {
            height: auto;
          }
        }
      }

      &.active {
        p:last-child {
          height: auto;
        }

        svg {
          transform: rotate(180deg);
          transition: transform 0.2s;
        }
        a svg {
          transform: rotate(0deg);
        }
        
      }

      @media all and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      cursor: pointer;

      * {
        pointer-events: none;
      }

      svg {
        margin-left: 8px;
        stroke: var(--theme-color--white);
        transition: transform 0.2s;
      }
    }
  }
}
.margin-top-bottom{
  margin-top: 13px;
  margin-bottom: 13px;
}
.margin-top-left-publicLocation{
  margin-top: 10px;
  margin-left: 15px;
}
.margin-left-publicLocation{
  margin-left: 15px;
}
