$sc: '.star-rating';

#{$sc} {
  display: flex;
  justify-content: space-between;

  &__label {
    padding: 8px;
    border: none;
    background: transparent;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;

    transition: all .2s cubic-bezier(0.39, 0.58, 0.57, 1);

    &:hover {
      transform: scale(1.2, 1.2);
    }

    &--active {
      #{$sc}__icon {
        fill: var(--theme-color--blue);
      }

      #{$sc}__star-label {
        color: var(--theme-color--blue);
      }
    }

    @media only screen and (max-width: 450px) {
      padding: 4px;
    }
  }


  &--disabled {
    pointer-events: none;
    &:hover {
      transform: scale(1);
    }
  }

  &__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }

  &__star-label {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 13px;
    line-height: 1.5;
    text-align: center;

    color: var(--theme-color--lightGrey);

    @media only screen and (max-width: 450px) {
      font-size: 10px;
    }
  }

  &__icon,
  &__star-label {
    transition: all .2s ease-in;
  }
}
