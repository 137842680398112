$loader: '.page-loader';

$loader-padding: 40px;

#{$loader} {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  &.with-padding {
    padding: $loader-padding 0;
  }
}