.calendar-working-hours {
  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--medium);
  font-size: 14px;
  line-height: 1.5;
  color: var(--theme-color--blue);

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 1px solid #dddddd;
  height: 40px;

  strong {
    font-weight: var(--theme-fontWeight--bold);
  }
}
