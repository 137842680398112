$od: '.od';

#{$od} {
  margin-bottom: 72px;

  // Text modifiers only
  &--uppercase-title {
    font-weight: var(--theme-fontWeight--bold);
    font-size: 13px;
    line-height: 1.3;
    color: var(--theme-color--lightGrey);
    text-transform: uppercase;

    &--mobile {
      @media only screen and (max-width: 767px) {
        margin-top: 16px;
      }
    }
  }

  &--status-title {
    font-weight: var(--theme-fontWeight--bold);
    font-size: 36px;
    line-height: 1.2em;
    color: var(--theme-color--blue);
  }

  &--status-small-title {
    font-weight: var(--theme-fontWeight--bold);
    font-size: 21px;
    line-height: 2em;
    color: var(--theme-color--blue);
  }

  &--main-text {
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625em;
    color: var(--theme-color--grey);
    overflow-wrap: anywhere;
  }

  &--small-text {
    font-weight: var(--theme-fontWeight--medium);
    font-size: 13px;
    line-height: 1.375em;
    color: var(--theme-color--grey);
    overflow-wrap: break-word;
  }

  &--with-link {
    a {
      text-decoration: none;
      color: var(--theme-color--blue);
      font-weight: var(--theme-fontWeight--bold);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--price-text {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 28px;
    line-height: 1.14;
    text-align: center;

    color: var(--theme-color--blue);
  }

  &--accented {
    color: var(--theme-color--blue);
    font-weight: var(--theme-fontWeight--bold);
  }

  &--blue-text {
    color: var(--theme-color--blue);
  }

  &--event-name {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 24px;
    line-height: 1.25;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off, 'case' on;
    color: var(--theme-color--blue);
  }

  &--capitalized {
    text-transform: capitalize;
  }


  // other modifiers
  &--shadow-box {
    background: var(--theme-color--white);
    box-shadow: var(--theme-boxShadow--order);
    border-radius: 4px;

    height: min-content;
    overflow: hidden;
  }

  &--centered {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  &--padding {
    &-info {
      padding: 28px;
    }
  }

  &--with-padding {
    padding: 18px 34px 24px;
  }

  &--stick-to-bottom {
    margin-bottom: auto;
  }


  // elements
  &__row {
    display: flex;
    flex-direction: row;
  }

  &__column {
    display: flex;
    flex-direction: column;
    flex: 1;

    // to switch row to column on mobile
    &-m {
      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__answer-media {
    max-width: 157px;
    height: 210px !important;
    object-fit: cover;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(39, 40, 53, 0) 0%, rgba(39, 40, 53, 0.94) 67.71%);
      border-radius: 4px;
    }

    .item-preview__icon {
      font-size: 40px;
    }

    @media only screen and (max-width: 450px) {
      max-width: 100%;
    }
  }

  &__answer-calendar-btn {
    display: flex;
    align-items: center;

    border: none;
    background: transparent;
    cursor: pointer;

    margin: 32px auto;

    &__icon {
      margin-right: 12px;
    }
  }

  &__media-aside {
    background: var(--theme-color--blue);
    overflow: hidden;
    flex: 0;
    min-width: 30%;
    max-height: 450px;
    position: relative;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &--overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        rgba(39, 40, 53, 0),
        rgba(39, 40, 53, 0),
        rgba(39, 40, 53, 0.15),
        rgba(39, 40, 53, 0.6),
        rgba(39, 40, 53, 0.6)
      );

      .custom-image.avatar {
        height: 64px;
        width: 64px;
        border: 2px solid rgba(42, 44, 57, 1);
        background: rgba(30, 31, 40, 1);
        box-shadow: 15px 15px 35px 0 rgba(22, 3, 31, 1);
      }
    }

    &--talent-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      padding-bottom: 24px;
      font-family: var(--theme-fontFamily--primary);
      color: var(--theme-color--white);
      font-feature-settings: 'case' on, 'liga' off;
      font-weight: var(--theme-fontWeight--black);

      p {
        font-size: 14px;
        line-height: 1.375em;
      }

      h6 {
        font-size: 20px;
        line-height: 1.625em;
      }
    }
  }

  &__public-link {
    justify-content: flex-end;
    align-items: center;

    &--text {
      margin-right: 4px;
    }

    &--arrow {
      width: 8px;
      height: 8px;
      border-right: solid 2px var(--theme-color--blue);
      border-bottom: solid 2px var(--theme-color--blue);
      transform: rotate(-45deg);
    }
  }

  &__heading {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 440px);
    grid-gap: 34px;
    margin-bottom: 60px;
    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  &--bordered {
    border-top: 1px solid var(--theme-color--ultraLightBlue);
  }

  &__inner-wrapper {
    border-top: 1px solid var(--theme-color--ultraLightBlue);
    padding: 24px 34px;
  }

  &-hold {
    padding: 0 0 32px;
  }

  &__review {
    &--stars {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid var(--theme-color--ultraLightBlue);
      padding: 16px 34px 0;
    }
  }

  &__details-list {
    margin: 32px 0 24px;
  }

  &__complaint-btn {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight);
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #e86161;

    border: none;
    background: transparent;
    cursor: pointer;

    margin: 12px auto;
    display: block;
  }

  &__answer {
    //TODO rewrite it
    &__add-more-btn {
      font-family: var(--theme-fontFamily--default);
      font-weight: var(--theme-fontWeight--bold);
      font-size: 16px;
      line-height: 1.625;
      color: var(--theme-color--black);
      text-align: start;

      background: none;
      border: none;
      cursor: pointer;

      padding: 0;
    }

    &-grid {
      display: grid;
      grid-template-columns: 157px 1fr;
      gap: 16px;

      @media only screen and (max-width: 450px) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__modal {
    border-radius: var(--theme-borderRadius--modal);
    box-shadow: var(--theme-boxShadow--modal);

    padding: 16px 40px 40px;
    background: var(--theme-color--white);
    width: 695px;

    @media screen and (max-width: 1440px) {
      padding: 16px 24px 24px;
      width: 663px;
    }

    @media screen and (max-width: 575px) {
      max-width: 100%;
      height: 100%;
      border-radius: 0;
    }

    &--heading {

      @media screen and (max-width: 575px) {
        flex: 1;
        justify-content: center;
      }

      h3 {
        font-size: 32px;
        font-weight: var(--theme-fontWeight--bold);
        font-family: var(--theme-fontFamily--primary);
        line-height: 1.25;
        color: var(--theme-color--blue);
        margin: 0 auto 24px;
        text-align: center;

        @media screen and (max-width: 1440px) {
          font-size: 28px;
        }

        @media screen and (max-width: 575px) {
          font-size: 23px;
          margin-bottom: 24px;
        }
      }

      p {
        margin: 16px 0;

        @media screen and (max-width: 575px) {
          text-align: center;
        }
      }
    }

    &--buttons-wrapper {
      .button:not(:last-of-type) {
        margin-right: 16px;
        @media screen and (max-width: 768px) {
          margin-bottom: 12px;
        }
      }
    }
  }
}
