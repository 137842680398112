$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;

.menu-bar {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;

  &__main-controls {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
  }

  .video-button {
    margin: 0 10px;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &--chat {
      position: absolute;
      right: 0;
    }

    @media screen and (max-width: #{$media-md}) {
      width: 48px;
      height: 48px;

      margin: 8px;

      svg {
        width: 24px;
        height: 24px;
      }

      &--audio {
        order: 1;
      }

      &--video {
        order: 2;
      }

      &--settings {
        order: 4;
      }

      &--disconnect {
        order: 3;

        width: 80px;
        height: 80px;

        svg {
          width: 38px;
          height: 38px;
        }
      }

      &--chat {
        position: relative;
        order: 5;
      }
    }
  }
}