.check-email-modal {
  padding: 69px 40px 40px;
  width: 520px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }

  &__text {
    margin-bottom: 40px;
  }
}

.password-changed-modal {
  padding: 49px 40px 40px;
  width: 490px;

  &__text {
    margin-bottom: 60px;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }
}

.check-email-modal,
.password-changed-modal {
  background: get-colour('white');
  @include display-flex(column, center);
  box-shadow: $modal-box-shadow;
  border-radius: $modal-border-radius;

  &__check-icon {
    margin-bottom: 33px;

    width: 90px;
    min-width: 90px;
    height: 90px;
    min-height: 90px;
    background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
    border-radius: 50%;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 50px;
      border: 10px solid get-colour('white');
      border-top: none;
      border-left: none;
      transform: rotate(45deg);

      @include position-absolute(10px, 30px, auto, auto);
    }
  }

  &__header {
    font-family: $font-family-primary;
    font-weight: get-font-weight('black');
    font-size: get-font-size('l-2');
    font-feature-settings: 'case' on, 'liga' off;
    line-height: 54px;
    text-align: center;
    color: get-colour('blue');

    margin-bottom: 24px;
  }

  &__text {
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: get-font-size('s-1');
    line-height: 26px;
    text-align: center;
    color: get-colour('dark-grey');
  }
}
