.contact-form {
  margin-top: 32px;
  padding: 0 12px;

  .contact-header {
    @extend .font-header-h1;
    color: get-colour('blue');
    margin-bottom: 40px;
    text-align: center;
  }

  .contact-subheader {
    @include display-flex(column, center, center);
    margin-bottom: 60px;

    h3 {
      @extend .font-header-h3;
      color: get-colour('blue');
      text-align: center;
    }

    p {
      @extend .font-body-1;
      color: get-colour('blue');
      text-align: center;
    }

    @media #{$md-lg-sizes} {
      margin-bottom: 60px;
    }
  }

  .form-row:last-child {
    margin-top: 75px;

    @media #{$md-lg-sizes} {
      margin-top: 0;
    }
  }

  .small-button .common-button {
    padding: 10px 56px;
  }

  @media #{$md-lg-sizes} {
    .small-button {
      margin: 0 auto;
    }
  }
}
