.entourage-logo {
  width: auto;

  &--lg {
    height: 45px;
  }

  &--md {
    height: 36px;
  }

  &--sm {
    height: 25px;
  }
}
