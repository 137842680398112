.payments-selector {
  position: relative;
  &__field {
    display: flex;
    align-items: center;
    border: 2px solid var(--theme-color--blueText);
    border-radius: var(--theme-borderRadius--default);
    padding: 14px 18px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.625;
    color: var(--theme-color--blue);
    cursor: pointer;
    transition: all .3s ease-in-out;
    height: 60px;
    &:hover {
      border-color: var(--theme-color--blue);
    }
    &--placeholder {
      font-weight: 500;
      color: var(--theme-color--lightGrey);
    }
  }
  &__arrow-select {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0);
    margin-left: auto;
    border: none;
    padding: 0;
    transition: all .3s ease-in-out;
    svg {
      fill: var(--theme-color--lightGrey);
    }
    &--open {
      transform: rotate(180deg);
      svg {
        fill: hsl(0, 0, 20%,);
      }
    }
  }
  &__list {
    position: absolute;
    top: 120%;
    left: 0;
    z-index: 3;
    width: 100%;
    max-height: 200px;
    padding: 6px 0;
    overflow-y: auto;
    background-color: var(--theme-color--white);
    box-shadow: var(--theme-boxShadow--modal);
    border-radius: var(--theme-borderRadius--default);
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color .3s ease-in-out;
    &:hover {
      background-color: var(--theme-color--ultraLightBlue);
    }
  }
  &__view {
    display: flex;
    align-items: center;
    width: calc(100% - 36px);
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.625;
    color: var(--theme-color--blue);
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border: none;
    &--default {
      font-weight: 700;
    }
  }
  &__logo-card {
    &__provider {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
    &__name {
      flex: 1;
      margin-right: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    &__number {
      margin-right: 12px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__delete {
    background: rgba(0, 0, 0, 0);
    border: none;
    padding: 0;
    transition: all .3s ease-in-out;
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 12px;
      height: 1px;
      transform: translateY(-50%);
      background-color: var(--theme-color--lightGrey);
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    &:hover {
      &::before,
      &::after {
        background-color: var(--theme-color--blue);
      }
    }
  }
}
