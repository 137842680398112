.rejoin-screen {

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 42px;
    line-height: 1.28;
    text-align: center;
    font-feature-settings: 'case' on, 'liga' off;
    color: var(--theme-color--blue);

    margin-bottom: 32px;
  }

  &__description {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 22px;
    line-height: 1.5;
    text-align: center;
    color: var(--theme-color--blue);

    max-width: 900px;
    margin: 0 auto 48px;

    a {
      text-decoration: none;
      font-weight: var(--theme-fontWeight--bold);
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}