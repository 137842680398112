.crop-modal {
  width: 1250px;
  height: 820px;
  background-color: get-colour('white');

  @include display-flex(column);
  flex: 1;

  @media screen and (max-width: 1440px) {
    max-width: 1280px;
  }

  @media screen and (max-width: 1280px) {
    max-width: 992px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 768px;
  }

  @media screen and (max-width: 992px) {
    max-width: 678px;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }

  @media screen and (max-height: 1200px) {
    max-height: 1025px;
  }

  @media screen and (max-height: 1024px) {
    max-height: 993px;
  }

  @media screen and (max-height: 992px) {
    max-height: 768px;
  }


  @media screen and (max-height: 768px) {
    max-height: 600px;
  }


  @media screen and (max-width: 414px) {
    max-height: none;
    max-width: none;

    height: 100%;
    width: 100%;
    border-radius: 0;
    min-height: 90vh;
  }

  &__header {
    background: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%);
    height: 64px;
    @include display-flex(column, center, center);
    z-index: 1;
  }

  &__body {
    flex: 1;
    position: relative;
  }

  &__controls {
    background: get-colour('white');
    height: 114px;
    z-index: 1;
    padding: 0 32px;

    @include display-flex(column, center, center);

    @media screen and (max-width: 414px) {
      height: 80px;
      padding: 0 16px;
    }
  }
}

.crop-modal-header {
  font-family: $font-family-primary;
  font-weight: get-font-weight('bold');
  font-size: get-font-size('m-1');
  line-height: $default-line-height;
  text-align: center;
  font-feature-settings: 'case' on;
  color: get-colour('white');
  position: relative;

  @media screen and (max-width: 414px) {
    font-size: get-font-size('m-2');
  }
}

.crop-modal-back {
  cursor: pointer;
  font-family: $font-family-default;
  font-weight: get-font-weight('bold');
  font-size: get-font-size('s-2');
  line-height: 18px;
  text-transform: uppercase;
  color: get-colour('blue-text');
  border: none;
  background-color: transparent;

  @include position-absolute(20px, null, null, 38px);
  @include display-flex(row, center, flex-start);

  &__btn {
    padding-left: 12px;
  }
}

.crop-controls {
  width: 100%;
  @include display-flex(row, center, space-between);

  &__title {
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: get-font-size('m-3');
    line-height: $default-line-height;
    align-items: center;
    color: get-colour('blue');

    @media screen and (max-width: 414px) {
      display: none;
    }
  }

  &__range {
    flex: 1;
    margin: 0 24px;


    @media screen and (max-width: 414px) {
      margin-left: 0;
    }
  }

  &__button {

    @media screen and (max-width: 414px) {
      .button__item {
        --padding-top-bottom-btn: 8px !important;
        --padding-left-right-btn: 20px !important;
      }
    }
  }
}

.__crop-modal-overlay {
  top: 0;
  height: 100%;
}

.headshot-image__inner {
  @include display-flex(row, center, space-between);
}


.profile-body,
.profile-photo {
  @include display-flex(row, center, center);
  position: relative;

  img {
    @include position-absolute(0, 0, 0, 0);
    height: 100%;
    width: 100%;
  }
}

.profile-photo {
  width: 228px;
  height: 228px;

  img {
    border-radius: 50%;
  }
}

.profile-body {
  width: 258px;
  height: 344px;
}
