$media-sm: 575px;

.account-entourage-status {
  padding: 10px 20px 0;
}

.account-entourage {
  &__status-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 16px;

    @media screen and (max-width: #{$media-sm}) {
      flex-direction: column;
    }
  }

  &__status {
    display: flex;
    align-items: center;

    @media screen and (max-width: #{$media-sm}) {
      margin-bottom: 20px;
    }
  }

  &__status-icon {
    margin-right: 16px;
  }

  &__status-info {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 14px;
    line-height: 1.5;
    color: var(--theme-color--blue);

    display: flex;
    flex-direction: column;

    strong {
      font-weight: var(--theme-fontWeight--bold);
      font-size: 16px;
      line-height: 1.625;
    }
  }

  &__text {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--grey);

    margin-bottom: 16px;

    a {
      color: var(--theme-color--blue);
      font-weight: var(--theme-fontWeight--bold);

      a:hover {
        text-decoration: underline;
      }
    }
  }

  &__benefits-list {
    display: flex;

    @media screen and (max-width: #{$media-sm}) {
      flex-direction: column;
    }
  }

  &__benefits-item {
    display: flex;
    flex: 1 1 50%;

    padding: 8px 0;

    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blue);

    svg {
      margin: 4px;
    }
  }

  &__button {
    margin-top: 50px;
  }
}