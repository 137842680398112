$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;


.entourage-verify {
  margin-bottom: 100px;

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 60px;
    line-height: 1.25;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: var(--theme-color--blue);

    margin-bottom: 40px;

    @media only screen and (max-width: #{$media-dt}) {
      font-size: 54px;
    }

    @media only screen and (max-width: #{$media-lg}) {
      font-size: 42px;

      margin-bottom: 32px;
    }

    @media only screen and (max-width: #{$media-sm}) {
      font-size: 36px;

      margin-bottom: 24px;
    }
  }

  &__text {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    color: var(--theme-color--grey);

    margin-bottom: 56px;

    @media only screen and (max-width: #{$media-sm}) {
      font-size: 15px;

      margin-bottom: 24px;
    }
  }

  &__cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin: -15px;

    & > * {
      margin: 15px;
    }
  }
}

.entourage-verify-card {
  background: #f5f5f5;
  box-shadow: -15px -15px 40px #ffffff, 15px 15px 40px #e5e4e7;

  padding: 32px 24px 40px;
  max-width: 440px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: #{$media-md}) {
    max-width: 360px;
    padding: 24px 16px 32px;
  }

  &__icon {
    margin: 0 auto 24px;
    display: block;

    @media screen and (max-width: #{$media-md}) {
      margin-bottom: 12px;
    }
  }

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    font-feature-settings: 'case' on, 'liga' off;
    color: var(--theme-color--blue);

    margin-bottom: 16px;

    @media screen and (max-width: #{$media-md}) {
      font-size: 20px;
    }
  }

  &__label {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 14px;
    text-align: center;
    color: var(--theme-color--grey);
    text-transform: uppercase;
  }

  &__text {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    text-align: center;
    color: var(--theme-color--grey);

    margin-bottom: 24px;

    a,
    button {
      padding: 0;
      border: none;
      background: none;
      font-size: inherit;
      color: var(--theme-color--blue);
      font-weight: var(--theme-fontWeight--bold);
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: #{$media-md}) {
      font-size: 14px;
    }
  }

  &__button {
    margin-bottom: 0;
    margin-top: auto;
  }

  &--disabled {
    opacity: .7;
    cursor: not-allowed;
    pointer-events: none;
  }
}
