.reset-password {
  padding-bottom: 40px;

  &__header {
    @extend .font-header-h1;
    text-align: center;
    margin-bottom: 64px;
    margin-top: 40px;
  }

  .input-block {
    position: relative;

    &__label {
      font-family: $font-family-default;
      font-weight: get-font-weight('bold');
      font-size: 13px;
      line-height: 18px;
      text-transform: uppercase;
      color: get-colour('light-grey');
    }
  }

  form > .submit-button {
    @media screen and (max-width: 575px) {
      margin-top: 48px;
    }
  }
}
