.joinToTalent-link {
  &__form {
    display: flex;
    align-items: flex-end;
  }
  &__link {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  &__label {
    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: get-font-size('s-2');
    line-height: 1.5;
    text-transform: uppercase;
    color: get-colour('light-grey');
  }
  &__area {
    background: get-colour('grey');
    border: 2px solid get-colour('ultra-light-blue');
    border-radius: $border-radius;
    width: 100%;
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: get-font-size('s-1');
    line-height: 1.625;
    color: get-colour('blue');
    padding: 12px 15px 12px 40px;
    height: 56px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__icon {
    position: absolute;
    left: 16px;
    bottom: 20px;
  }
  &__loader {
    position: absolute;
    left: 16px;
    bottom: 20px;
  }
  &__button {
    height: 56px;
    border-radius: 0 $border-radius $border-radius 0 !important;
    margin-left: -2px;
    .button__item {
      border-radius: 0 $border-radius $border-radius 0 !important;
    }
  }

  &__desc {
    margin-top: 16px;
    margin-bottom: 0;
    font-size: get-font-size('s-1');
    font-weight: get-font-weight('medium');
    font-family: $font-family-default;
    color: get-colour('dark-grey');
  }
}
