$rme-orders: '.ready-made-event-lessons-details-orders';

#{$rme-orders} {
  &-table {
    margin-top: 0;
    margin-bottom: 40px;

    .col > * {
      padding: 16px 0;
    }

    #{$rme-orders}-row.row-content {
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }

    // Hack to align select width with search width
    @media screen and (min-width: 769px) {
      .filter {
        max-width: 300px;
  
        .react-dropdown-select-dropdown {
          width: 300px;
        }
      }
    }
  }
}