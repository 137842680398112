:root {
  // variables for button
  --padding-top-bottom-btn: 6px;
  --padding-left-right-btn: 16px;
  --font-size-btn: 1em;
  --font-weight-btn: '700';
}

body {
  font-family: $font-family-default;
  margin: 0;
  * {
    box-sizing: border-box;
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
}

main {
  flex: 1;

  display: flex;
  flex-direction: column;

  &--dark {
    background-color: get-colour('blue');
  }

  &--light {
    background-color: get-colour('white');
  }


  @media (max-width: 1024px) {
    //padding-bottom: 83px;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  //&:hover {
  //  text-decoration: underline;
  //}
  &[class^='icon-'] {
    &:hover {
      text-decoration: none;
    }
  }
}

i:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  margin: auto;
  width: 100%;

  @media #{$dt} {
    max-width: 1440px;
  }
}

.hide {
  display: none;
}

.force-hide {
  display: none !important;
}

.noscroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.g-p-relative {
  position: relative;
}

.blured-on-scroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.stop-scrolling {
  overflow: hidden;

  body {
    overflow: hidden;
    height: 100%;
    max-height: 100%;
  }
}
