.controlled-collapse {
  max-height: 0;
  transition: all 0.3s ease-out;
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: 0;
  scrollbar-color: transparent;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &__opened {
    max-height: 500px;
    transition: all 0.3s ease-in;


    // Firefox scrollbar support
    scrollbar-width: thin;
    scrollbar-color: var(--theme-color--white) var(--theme-color--grey);

    &::-webkit-scrollbar {
      width: 3px;
      background: var(--theme-color--grey);
      transition: all 0.3s ease-in;
    }

    .controlled-collapse {
      padding: 0 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--theme-color--white);
    }

    @media only screen and (max-width: 768px) {
      max-height: 250px;
    }
  }

  &__transition-going {
    // Firefox scrollbar support
    scrollbar-width: 0;
    scrollbar-color: transparent;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
}