.button__download {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 16px;
  font-size: 0.75em;
  text-transform: uppercase;
  color: var(--theme-color--blue);
  border: 2px solid var(--theme-color--blue);
  border-radius: var(--theme-borderRadius--default);
  font-weight: var(--theme-fontWeight--bold);

  &:hover {
    background-color: var(--theme-color--ultraLightBlue);
  }

  > svg {
    margin-right: 10px;
  }
}