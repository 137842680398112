.video-message-request {
  padding-top: 30px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 1024px) {
    padding-bottom: 40px;
  }
  ._title {
    font-size: 42px;
    margin-bottom: 62px;
    @media screen and (max-width: 767px) {
      font-size: 38px;
    }
    @media screen and (max-width: 575px) {
      font-size: 26px;
      margin-bottom: 32px;
    }
    @media screen and (max-width: 425px) {
      font-size: 24px;
    }
  }
  .fullName {
    font-size: 60px;
    @media screen and (max-width: 767px) {
      font-size: 54px;
    }
    @media screen and (max-width: 575px) {
      font-size: 42px;
    }
    @media screen and (max-width: 425px) {
      font-size: 30px;
    }
  }

  &__form {
    &_field {
      &-checkbox {
        .error {
          top: 100% !important;
          margin-top: 0;
        }
        .checkbox {
          margin-top: 24px;
        }
      }
      &-radio {
        margin-bottom: 32px !important;
        .row-left {
          padding-top: 0;
        }
      }
    }
    .radio-buttons {
      margin-bottom: -20px;
      &-item {
        padding-bottom: 20px;
      }
    }
  }
}
