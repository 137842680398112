$legal-terms-width: 520px;
$legal-terms-height: 320px;


.legal-terms-modal {
  @include display-flex(column);
  width: $legal-terms-width;
  height: $legal-terms-height;
  padding: 16px 40px 40px;
  background-color: get-colour('white');
  box-shadow: $modal-box-shadow;
  border-radius: $modal-border-radius;
}
