$triangle-dark:#23232f;
$triangle-light:#f5f5f5;

#{$ac} {
  @include display-flex(row, center, space-between);
  width: 100%;
  bottom: 0;

  @media #{$md} {
    display: none;
  }

  &.active {
    opacity: 0.2;
    transition: opacity 0.25s ease-out;
    #{$ac}__item {
      a:visited {
        @extend %theme-colors;
      }

      svg {
        @extend %theme-colors-svg;
      }
    }
  }

  opacity: 1;
  transition: opacity 0.25s ease-out;

  &__item {
    @extend %theme-colors;
    position: relative;
    @include display-flex(null, center, center);
    width: 100%;
    cursor: pointer;

    &:hover {
      #{$ac}__item--heading {
        i,
        p,
        a {
          @extend %main-theme-colors;
        }

        svg {
          @extend %main-theme-colors-svg;
        }
      }
    }

    //// Colors ///
    &--heading {
      @include display-flex(null, center, center);
      @include size(100%, 48px);

      i,
      p,
      a {
        @extend %theme-colors;
      }

      svg {
        @extend %theme-colors-svg;
      }

      p,
      a {
        background: transparent;
        border: none;
        cursor: pointer;
        font-family: $font-family-default;
        font-weight: get-font-weight('semi-bold');
        font-size: get-font-size('s-2');
        line-height: 16px;
        text-align: center;
        letter-spacing: 0;
        text-transform: capitalize;
        outline: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-left: 4px;
        }
      }

      i {
        font-size: get-font-size('s-1');
        margin-right: 4px;
      }
    }

    &.opened {
      #{$ac}__item {
        &--heading {
          background: linear-gradient(309.69deg, #5a5d85 17.05%, #090911 69.2%);
          @extend %main-theme-colors;
        
          i,
          p {
            @extend %opened-menu;
          }

          svg {
            @extend %opened-menu-svg;            
            transform: rotate(180deg);
          }
        }

        &--body {
          li {
            a,
            button {
              color: $dark-theme-color;
            }

            &:hover {
              background: linear-gradient(90deg, #e9eaf0 0%, #e9eaf0 100%);
              a {
                color: $main-dark;
              }
            }
          }
        }

        &--list {
          display: flex;
        }
      }
    }

    #{$ac}__line-indicator {
      display: none;
    }

    &:hover,
    &.opened {
      #{$ac}__line-indicator {
        position: absolute;
        width: 100%;
        display: block;
        top: -4px;

        svg {
          width: 100%;
        }

        @media screen and #{$size-xl-only} {
          top: -3px;
        }
      }
    }

    @at-root .dark-theme #{&} {
      &:hover,
      &.opened {
        #{$ac}__line-indicator {
          svg {
            #triangle {
              fill: $triangle-dark;
            }
          }
        }
      }
    }
    @at-root .light-theme #{&} {
      &:hover,
      &.opened {
        #{$ac}__line-indicator {
          svg {
            #triangle {
              fill: $triangle-light;
            }
          }
        }
      }
    }

    &--list {
      position: absolute;
      top: 48px;
      width: 100%;
      box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
      display: none;
      overflow: hidden;
    }

    &--triangle {
      @include position-absolute(0, null, null, calc(50% - 7px));
      z-index: 3;
    }

    &--body {
      @include display-flex(column);
      background: $main-light;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
      border-radius: 0 0 $border-radius $border-radius;
      width: 100%;

      li {
        position: relative;
        @include display-flex(null, center);
        text-transform: none;
        padding: 0;

        button {
          background: transparent;
          border: none;
          padding: 0;
        }

        a,
        button {
          @include display-flex(null, center);
          width: 100%;
          padding-left: 16px;
          font-family: $font-family-default;
          font-weight: get-font-weight('semi-bold');
          font-size: get-font-size('s-2');
          text-decoration: none;
          text-align: left;
          height: 40px;
          cursor: pointer;
        }

        &:after {
          content: '';
          background: linear-gradient(to right, #272835 0%, #27283500 100%);
          height: 1px;

          @include position-absolute(null, null, 0, 0);

          width: calc(100% + 16px);
        }

        &:last-child:after {
          display: none;
        }

        &:first-of-type {
          height: 46px;
        }
      }
    }

    &--icon {
      @media screen and (max-width: 1250px) {
        display: none;
      }
    }

    &.unread-messages-indicator {
      &:after {
        content: '';
        display: block;

        position: absolute;
        top: 10px;
        right: 16px;

        width: 8px;
        height: 8px;

        border-radius: 50%;
        background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
      }
    }
  }

  &__nav {
    position: relative;
    @include display-flex(null, center, center);
    flex: 1 1;

    &:before {
      content: ' ';
      width: calc(100% - #{$content-padding-desktop} * 2);
      height: 1px;
      border: none;
      top: -1px;
      position: absolute;
      background: linear-gradient(
        90deg,
        rgba(148, 149, 167, 0.25) 0%,
        rgba(107, 109, 134, 1) 50%,
        rgba(148, 149, 167, 0.25) 86.3%
      );
      z-index: -1;
      opacity: 0.3;

      @media #{$dt-m} {
        width: calc(100% - #{$content-padding-desktop-m} * 2);
        top: 0;
      }

      @media #{$md} {
        width: calc(100% - #{$content-padding-tablet} * 2);
      }

      @media #{$sm} {
        width: calc(100% - #{$content-padding-mobile} * 2);
      }
    }
  }
}
