.options-modal {
  padding: 40px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, .3);
  background: var(--theme-color--white);
  max-width: 500px;

  border-radius: var(--theme-borderRadius--modal);

  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 32px;

    @media all and (max-width: 767px) {
      font-size: 22px;
    }
  }

  &__list {
    text-align: left;
    width: 100%;
    margin: 12px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
  }
}