$cb: '.calendar-button';

#{$cb} {
  margin-bottom: 20px;

  &__text {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 16px;
    line-height: 1.625;

    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 12px;
  }

  &__dash-btn {
    margin-top: 8px;
    margin-left: 30px;
  }

  &__bold-btn {
    margin-right: 30px;
  }

  &--dark {
    #{$cb} {
      &__icon {
        fill: #000000;
      }

      &__text {
        color: var(--theme-color--blue);
      }
    }
  }

  &--light {
    #{$cb} {
      &__icon {
        fill: #ffffff;
      }

      &__text,
      &__dash-btn,
      &__bold-btn {
        color: var(--theme-color--white);
      }
    }
  }
}
