$sb: '.status-bar';
$sb-v: '.status-bar--vertical';

$line-height: 2px;

$passive-color: var(--theme-color--ultraLightBlue);
$active-color: var(--theme-color--blue);


#{$sb} {
  position: relative;
  width: 100%;
  height: 100px;
  padding: 0 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__container {
    position: relative;

    &__steps {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__line {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;

    &--main {
      flex: 1;

      &__wrapper {
        position: relative;
      }

      &--bg {
        height: 2px;
        width: 100%;
        transition: all 0.3s ease;
        background: $passive-color;
      }

      &--progress {
        position: absolute;
        height: $line-height;
        top: 0;
        left: 0;
        background: $active-color;
        width: var(--status-line-width);
      }
    }

    &__expand {
      display: none;
    }
  }
}

#{$sb}#{$sb-v} {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 0 16px;

  #{$sb}__container__steps {
    flex-direction: column;
  }

  #{$sb}__line {
    height: 100%;
    left: calc((16px / 2) - (#{$line-height} / 2));
    top: 0;
    right: unset;
    bottom: unset;
    transform: none;

    &--main {
      height: 100%;

      &__wrapper {
        height: 100%;
      }

      &--bg {
        width: 2px;
        height: 100%;
      }

      &--progress {
        width: 2px;
        height: var(--status-line-width);
      }
    }
  }

  #{$sb}__expand {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    right: 16px;
    transition: all 0.2s;
    z-index: 1;

    svg {
      stroke: var(--theme-color--blue);
    }

    &.expanded {
      transform: rotate(180deg);
    }
  }
}