.upload-file {
  position: relative;
  width: 107px;
  height: 143px;

  margin: 8px;

  &__btn {
    position: absolute;
    right: 8px;
    bottom: 10px;
    z-index: 10;

    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: 0;
    transition: all .2s ease-in;
    background: linear-gradient(180deg, rgba(39, 40, 53, 0) 0%, rgba(39, 40, 53, 0.94) 67.71%);
    border-radius: 0 0 4px 4px;
  }

  &:hover:after {
    opacity: 1;
  }
}

.upload-file-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  margin: -8px;
}