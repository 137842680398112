.dropdown {
  position: relative;

  & > button {
    img {
      transform: rotate(180deg);

      &.opened {
        transform: rotate(0deg);
      }
    }
  }

  .dropdown-content {
    position: absolute;
    left: -47px;
    top: 40px;
  }
}


.ReactModal__Overlay {
  z-index: 3;
}

/* just line with upper/lower arrow */
.pointer-line {

  display: flex;

  .line {
    height: 1px;
    min-width: 11px;


    background-color: #2e2f3c;

    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .after {
    flex: 1;
    background: linear-gradient(90deg, rgb(39, 40, 53) 0%, rgb(160, 161, 168) 100%);
  }

  .pointer-line-arrow {
    width: 6px;

    position: relative;

    &::before,
    &::after {
      content: '';

      height: 1px;
      width: 5px;

      position: absolute;
      left: -1.1px;
      top: -2px;

      display: block;

      transform: rotate(-45deg);

      background-color: #2e2f3c;
    }

    &::after {

      left: 1.9px;

      transform: rotate(45deg);
    }

    &[reverse=true] {
      transform: rotate(180deg);
    }
  }
}

/* arrows in two sides on any container */
.side-arrows {
  display: flex;
  align-items: center;
  justify-content: center;

  .side-arrow-wrapper {
    min-width: 50px;
    height: 50px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0;
    background: none;
    border: none;

    cursor: pointer;
    transition: all 0.1s ease-in-out;

    border-radius: 50%;

    &:hover {
      background-color: #55555518;
    }

    &:active {

      background-color: #55555528;
    }

    &:disabled {
      background: none;
      opacity: 0;
      visibility: hidden;
    }

    .side-arrow {
      height: 26px;
      width: 40px;

      stroke-width: 1px;

      stroke: #ffffff;

      z-index: 50;

      &.black {
        stroke: #272835;
      }
    }
  }
}

/* used for pagination on mobile */
.points-pagination {
  display: flex;
  justify-content: center;

  margin: 0 -30px;

  .points {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .point {
      margin: 0 6px;
      padding: 0;

      flex: 0 0 11px;
      height: 11px;

      border-radius: 50%;
      border: 2px solid #272835;
      box-sizing: border-box;

      background: none;

      cursor: pointer;

      &:hover {
        background-color: #22222215;
      }

      &[active=true] {
        background: #272835;
        border: none;
      }
    }
  }
}

/* Customize the label (the container) */
.checkboxCustom {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ i {
    background-color: #cccccc;
  }



  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ i {
      background-color: #272835;
      border: none;
      &:before {
        display: block;
      }
    }
    &:disabled ~ i {
      background-color: #cccccc;
    }
  }

  i {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    background-color: get-colour('white');
    border: 2px solid #babacd;
    border-radius: 4px;
    &:before {
      display: none;
      content: '';
      position: absolute;
      top: 4px;
      left: 10px;
      width: 4px;
      height: 11px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(40deg);
    }
  }
  span {
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: get-font-size('s-1');
    color: get-colour('blue');
    padding-left: 6px;
  }

  &--secondary {
    input:checked ~ i {
      background-color: get-colour('white');
    }
    i {
      border-color: get-colour('white');
      background-color: rgba(get-colour('white'), 0);
      &:before {
        border-color: get-colour('blue');
      }
    }
    span {
      color: get-colour('white');
    }
  }
}

.loader {
  animation: loader 2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
