$default: '.default';
$darken: '.darken';

.input--white {
  border-color: get-colour('blue-text');
  color: get-colour('blue');

  &::placeholder {

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: get-colour('light-grey');

    /* Firefox */
    opacity: 1;
  }

  &:disabled {
    background-color: get-colour('ultra-light-blue');
    color: get-colour('light-grey');
  }

  &:focus,
  &:hover {
    border-color: black;
  }

  // error
  &.error {
    border: 2px solid red;
  }
}

.input--dark {
  border-color: get-colour('blue-text');
  color: get-colour('white');

  &::placeholder {

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: get-colour('light-grey');

    /* Firefox */
    opacity: 1;
  }

  &:disabled {
    background-color: get-colour('ultra-light-blue');
    color: get-colour('light-grey');
  }

  &:focus,
  &:hover {
    border-color: get-colour('white');
  }

  // error
  &.error {
    border: 2px solid red;
  }
}

.input {
  display: block;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  resize: none;

  // border
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;

  // font
  font-family: $font-family-default;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;

  // remove arrow
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

  // error
  &.error {
    border: 2px solid red;
  }

  // modal input
  &-modal {
    padding: 0 16px;
    height: 56px;
  }

  // default form input
  &-default {
    height: 50px;
    padding: 0 18px;
  }

  // lesson page input
  &-lesson {
    height: 50px;
    padding: 0 18px;
  }

  &-color-picker {
    width: 60px;
    padding: 0;
  }
  &-mask {
    display: flex !important;
    align-items: center;
    position: relative;
    padding: 0 12px;
    > * {
      &:first-child {
        margin-right: 4px ;
      }
      //  &:last-child {
      //    margin-left: 4px;
      //  }
    }
    input {
      background-color: rgba(get-colour('white'), 0) !important;
      border: none !important;
      outline: none !important;
      font-size: get-font-size('s-1') !important;
      font-weight: get-font-weight('medium') !important;
      font-family: $font-family-default !important;
      padding: 0 !important;
    }
    .error-message-container {
      top: 115%;
      left: 0;
      font-size: get-font-size('s-2');
      font-weight: get-font-weight('normal');
      margin-left: 0;
    }
    .error-message {
      line-height: 1;
      position: static !important;
      padding-top: 0;
    }
  }
}



#{$default} {
  .input {
    &-modal,
    &-default,
    &-lesson {
      @extend .input--white;
    }
  }
}

#{$darken} {
  .input {
    &-modal,
    &-default,
    &-lesson {
      @extend .input--dark;
    }
  }
}

.input-label {
  font-family: $font-family-default;
  font-weight: get-font-weight('bold');
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: get-colour('light-grey');
}

.input-container {
  position: relative;
}

.input-tip {
  font-family: $font-family-default;
  font-weight: get-font-weight('normal');
  font-size: 13px;
  line-height: 22px;
  text-align: right;
  text-transform: lowercase;
  color: get-colour('blue');
}
