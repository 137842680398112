$offer: #635dab;
$myEvent: #545772;
$timeUnavailable: #9495a7;
$syncedCalendar: #226e78;
$inPersonExperience: #2e9cce;
$virtualEvent: #cd618e;
$videoChat: #c09664;
$lessons: #d1603c;
$lock: #c3c4cf;
$aficionado: #257e4a;


$event-type-to-colour-map: (
  'OFFER': $offer,
  'IN_PERSON_EXPERIENCE': $inPersonExperience,
  'VIRTUAL_EVENT': $virtualEvent,
  'VIDEO_CHAT': $videoChat,
  'LESSONS': $lessons,
  'TIME_UNAVAILABLE': $timeUnavailable,
  'MY_EVENT': $myEvent,
  'SYNCED_CALENDAR': $syncedCalendar,
  'LOCK': $lock,
  'AFICIONADO': $aficionado, 
);

:export {
  offer: #{$offer};
  myEvent: #{$myEvent};
  timeUnavailable: #{$timeUnavailable};
  syncedCalendar: #{$syncedCalendar};
  inPersonExperience: #{$inPersonExperience};
  virtualEvent: #{$virtualEvent};
  videoChat: #{$videoChat};
  lessons: #{$lessons};
  lock: #{$lock};
  aficionado: #{$aficionado};
}