//
// HEADER BASE STYLING
// MORE DETAILED STYLES YOU CAN FINE IN PROPER FOLDER
//

header {
  position: absolute;
  top: 0;

  /* move z-index to mixin */
  z-index: 9999;
  width: 100%;

  @media screen and (max-width: 1024px) {
    padding-bottom: 0;
  }

  #{$m-m} {
    @include display-flex(null, center, space-between);
    @include size(100%, var(--theme-header-height));
    position: relative;

    @media screen and (max-width: 1400px) {
      height: var(--theme-header-height-small);
    }

    &__logo {
      &--icon {
        @media screen and (max-width: 1400px) {
          height: 34px;
        }

        @media screen and (max-width: 1150px) {
          width: 150px;
        }

        @media screen and (max-width: 1050px) {
          width: 123px;
        }

        @media screen and (max-width: 1020px) {
          width: 210px;
        }
      }
    }

    &__mini-logo {
      &--icon {
        height: 34px;
      }
    }

    //only layout styles, content styles placed in _main-menu & _user-account folders
    &__nav,
    &__personal,
    &__personal_new_landing {
      @include display-flex(null, center, center);
    }

    &__nav {
      flex: 1;

      @media #{$md} {
        display: none;
        justify-content: flex-end;
      }
    }

    @media #{$sm} {
      &__personal {
        display: none;
      }

      &__personal_new_landing {
        .language_selector {
          display: none;
        }
      }
    }

    //
    // Categories menu
    //
    $mls-pointer-color: #22242d;
    $mls-counter-color: #595b79;

    $mls-gradient-bg: linear-gradient(
      rgba(46, 47, 56, 0.95),
      rgba(50, 55, 73, 0.95)
    );

    $mls-gradient-primary: linear-gradient(
      175deg,
      var(--theme-color--blue) 0%,
      #1c2a39 15%,
      #44455a 25%,
      #363748 50%,
      #1f273e 85%,
      var(--theme-color--blue) 100%
    );

    $mls-gradient-active: linear-gradient(
      90deg,
      #424d65 0%,
      #3a4357 40%,
      rgba(32, 40, 60, 0.5) 100%
    );

    $mls-shadow-1: 0 0 32px 0 rgba(0, 0, 0, 0.5) inset;
    $mls-shadow-2: 0 0 4px 0 rgba(255, 255, 255, 0.3);
    $mls-shadow-3: 0 2px 2px 0 rgba(255, 255, 255, 0.25);
    $mls-shadow-4: 0 4px 16px 0 rgba(0, 0, 0, 0.4);
    $mls-shadow-5: 0 0 12px 0 rgba(0, 0, 0, 0.25) inset;
    $mls-shadow-6: 0 0 8px 0 rgba(0, 0, 0, 0.2);

    .multi-level-select {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 40px);
      height: 560px;
      background: $mls-gradient-bg;
      box-shadow: #{$mls-shadow-1}, #{$mls-shadow-2};
      border-radius: var(--theme-borderRadius--default);
      border: none;
      backdrop-filter: blur(10px);

      &__talent .cascader-list__list {
        width: calc(100% / 3);
      }

      &__offerings .cascader-list__list {
        width: calc(100% / 4);
      }

      &__pointer {
        display: block;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          background: $mls-pointer-color;
          transform: rotate(45deg);
          top: 10px;
          left: 10px;
          box-shadow: $mls-shadow-2;
        }

        &__wrapper {
          position: absolute;
          overflow: hidden;
          margin-left: 15px;
          top: -20px;
          height: 20px;
          width: 40px;
        }
      }

      .cascader-list__list {
        height: 100%;
        background: $mls-gradient-primary;
        box-shadow: #{$mls-shadow-3}, #{$mls-shadow-4};
        border: none;

        &:first-of-type {
          border-radius: var(--theme-borderRadius--default) 0 0 var(--theme-borderRadius--default);
        }

        @for $i from 0 to 10 {
          &:nth-child(#{$i}) {
            z-index: 10 - $i;
          }
        }
      }

      .cascader-list__list-item {
        height: 44px;
        color: var(--theme-color--ultraLightBlue);
        transition: all 0.1s;

        &--nested:after {
          height: 5px;
          width: 5px;
        }

        &--active:after {
          border-color: var(--theme-color--white);
        }
      }

      .cascader-list__list-item:hover,
      .cascader-list__list-item--active {
        color: var(--theme-color--white);
        box-shadow: #{$mls-shadow-2}, #{$mls-shadow-5};
        background: $mls-gradient-active;
      }

      .cascader-list__list-item--loading {
        background-color: $mls-counter-color;
      }

      .cascader-list__counter {
        background-color: $mls-counter-color;
        box-shadow: #{$mls-shadow-6};
      }
    }
  }
}
