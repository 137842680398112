// TODO: Find a way to import these styles into the project without duplicating imports or move this to the general styles
.public-view {
  display: flex;
  flex-direction: column;

  &__note {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blue);

    padding-bottom: 24px;

    strong {
      font-weight: var(--theme-fontWeight--bold);
    }
  }

  &__subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
  }

  &__info-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    margin: 56px 0;
    @media only screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, minmax(250px, 1fr));
      grid-gap: 48px;
    }

    @media only screen and (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(250px, 1fr));
      grid-gap: 48px;
    }
    &.alternate {
      grid-template-columns: repeat(2, 1fr);
      max-width: 800px;
      margin: 56px auto;
      @media only screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 48px;
      }
    }
  }

  &__card-wrapper {
    padding: 0 20px;
    @media only screen and (max-width: 1024px) {
      padding: 0 24px;
    }
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }

  &__timezone-reminder {
    color: var(--theme-color--white);
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    text-align: center;

    span {
      margin-left: 4px;
      font-weight: var(--theme-fontWeight--bold);
    }

    div {
      margin-top: 4px;
      font-weight: var(--theme-fontWeight--bold);
    }
  }

  &__card-inner-wrapper {
    position: absolute;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    flex-direction: row;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__fixed-item {
    position: relative;
    cursor: pointer;

    svg {
      stroke: #ffffff;
      position: absolute;
      top: 50%;
      right: 20px;
    }

    @media only screen and (max-width: 767px) {
      grid-column: auto;
    }
  }

  .video-messages {
    &__wrapper {
      min-height: 650px;
      position: relative;

      .arrow-down {
        position: absolute;
        bottom: 8px;
        left: calc(50% - 30px);
      }

      .content {
        padding: 40px 32px 130px;
      }
    }
  }

  .rotate {
    transform: rotate(180deg);
  }
}