.public-select-list {
  padding: 16px;
  background-color: var(--theme-color--white);
  box-shadow: var(--theme-boxShadow--modal);

  position: absolute;
  z-index: 2;
  top: calc(100% + 16px);
  width: calc(100% + 20px);
  transform: translateX(-10px);

  &__item {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 16px;
    line-height: 1.625;
    text-align: center;
    color: var(--theme-color--blue);
    text-overflow: ellipsis;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 12px 20px;
    margin-bottom: 16px;

    background-color: var(--theme-color--white);
    border: 2px solid var(--theme-color--blueText);
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s ease-in;

    &:hover {
      border-color: var(--theme-color--blue);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: .65;

      &:hover {
        border-color: var(--theme-color--blueText);
      }
    }
  }
}