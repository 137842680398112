$default: '.default';
$darken: '.darken';

._radio {
  @include display-flex(row, center);

  &__input {
    @extend .visually-hidden;

    &:checked + ._radio__button {
      &::after {
        content: ' ';
        background: black;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 6px);
      }
    }
  }

  &__button {
    position: relative;
    height: 26px;
    width: 26px;
    margin-right: 14px;
    background: transparent;
    border: 2px solid black;
    cursor: pointer;
    appearance: none;
    outline: none;
    border-radius: 50%;
  }
}

#{$default} {
  ._radio {
    color: black;

    &__input:checked + ._radio__button::after {
      background-color: black;
    }

    &__button {
      border-color: black;
    }
  }
}

#{$darken} {
  ._radio {
    color: white;

    &__input:checked + ._radio__button::after {
      background-color: white;
    }

    &__button {
      border-color: white;
    }
  }
}

._radio-group {
  display: flex;

  &--vertical {
    flex-direction: row;
  }

  &--horizontal {
    flex-direction: column;
  }
}
