.loaders {
  display: block;
  padding: 12px 0;
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 4px;
  }
  .loader {
    height: 19px;
    margin: 4px;
  }
}

.table-component-offering {
  display: block;
  padding: 12px 0;
  cursor: default;
  &:link:hover .header {
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 4px;
  }
  .header {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    color: var(--theme-color--blue);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .category {
    display: flex;
    align-items: center;
    .icon {
      height: 24px;
      path {
        fill: var(--theme-color--lightGrey);
      }
    }
    .text {
      margin-left: 8px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: var(--theme-color--lightGrey);
      text-transform: uppercase;
    }
  }
}