.table-filter-default {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
}

.table-filter-default svg {
  fill: #babbcd;
}

.table-filter-default svg:first-child {
  transform: rotate(180deg);
  margin-bottom: 2px;
}

.table-filter-default svg.active {
  fill: #272835;
}
