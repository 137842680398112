.why-join {
  &-heading {
    margin: 0 auto 72px; // -67px reason__list-item
    padding-top: 50px;

    &__header {
      @extend .font-header-h1;
      text-align: center;
      color: get-colour('white');
      text-shadow: 15px 4px 15px rgba(39, 53, 47, 0.65);

      margin: 0 0 37px;
    }

    &__subheader {
      @extend .font-body-1;
      text-align: center;
      color: get-colour('white');
      max-width: 621px;
      margin: auto;
    }
  }

  .phone-input {
    height: 50px !important;
  }

  &-reason {

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 36px;

      @media #{$size-md-lg-only} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media #{$size-md-only} {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__list-item {
      margin-top: 67px;

      @media #{$sm} {
        margin-top: 16px;
      }

      h6 {
        @extend .font-header-h3;
        color: get-colour('white');
      }

      p {
        @extend .font-body-2;
        color: get-colour('white');
        margin-top: 22px;
      }
    }
  }

  &-apply {
    margin: 90px auto 64px;
    @media #{$sm} {
      padding-top: 32px;
      margin: 64px 0 32px;
    }
  }
  .required-information,
  .experience-input,
  .label {
    color: get-colour('white');
  }
  .required-information {
    font-size: get-font-size('s-2');
  }
  .react-tel-input .form-control {
    color: get-colour('white');
    border-color: get-colour('light-grey');
  }
}

.social-channel {

  &__list {
    display: flex;
    flex-direction: column;
  }

  @media #{$md-lg-sizes} {
    .channel-handle .social-item__inner {
      width: calc(100% - 40px);
    }
  }
}

.social-item {
  width: 100%;
  position: relative;
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  &__inner {
    display: inline-grid;
    column-gap: 36px;
    width: 100%;
    grid-template-columns: 6fr 6fr 5fr;
  }

  @media #{$md-lg-sizes} {
    &__inner {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__select {
      margin-right: 0;
    }

    &__username-input {
      margin-right: 0;
    }

    .button-item-remove {
      right: 0;
      top: 16px;
    }
  }
}
