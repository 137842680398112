$my-bookings: '.my-bookings';

#{$my-bookings} {
  margin: 0 auto 100px;

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 60px;
    line-height: 1.25;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: var(--theme-color--blue);
    margin: 50px 0 24px;
  }
}