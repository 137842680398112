.content-wrapper {
  padding-left: $content-padding-desktop;
  padding-right: $content-padding-desktop;

  @media #{$dt-m} {
    max-width: 100%;
    padding-left: $content-padding-desktop-m;
    padding-right: $content-padding-desktop-m;
  }

  @media #{$md} {
    padding-left: $content-padding-tablet;
    padding-right: $content-padding-tablet;
  }

  @media #{$sm} {
    padding-left: $content-padding-mobile;
    padding-right: $content-padding-mobile;
  }
}