.biography-list {
  .biography-item {
    background: #ffffff;
    padding: 16px 16px 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius;

    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    .char-tip {
      @extend .font-small;
      text-align: end;
      color: get-colour('blue');
      padding-top: 9px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .biography-header {
      @include display-flex(row, center, space-between);
      margin-bottom: 16px;

      &__logo {
        @extend .font-caption;
        @include display-flex(row, center);
        text-align: start;
        color: get-colour('dark-grey');

        span {
          margin-left: 12px;
        }
      }

      &__controls {
        @include display-flex(row, center, flex-end);

        button {
          border: none;
          cursor: pointer;
          background-color: rgba(get-colour('white'), 0);
        }

        button:disabled {
          cursor: not-allowed;
          opacity: .5;
        }
      }

      &__remove-button {
        margin-left: 12px;
      }

      &__up-button {
        margin-left: 6px;
      }

      button:disabled {
        i {
          cursor: not-allowed;
          color: grey;
        }
      }
    }

    .biography-inputs {
      @include display-flex(row, center);
      margin-bottom: 16px;
      box-sizing: border-box;

      @media only screen and (max-width: 500px) {
        flex-direction: column;
      }

      &__item:first-child {
        width: 160px;
        margin-right: 18px;
        overflow: hidden;
        @media only screen and (max-width: 500px) {
          margin-right: 0;
          width: 100%;
          margin-bottom: 24px;
        }
      }
      &__item:last-child {
        width: 100%;
      }
    }

    .biography-textarea-wrapper {
      .biography-textarea {
        @extend .experience-input;
        resize: none;
        height: 92px !important;
        padding: 12px !important;

        .error {
          border-color: red;
        }
      }
    }
  }

  &__add-button {
    @extend .font-link;
    color: black;
    cursor: pointer;
    padding-top: 20px;
  }
}

.biographies-tip-container {
  @include display-flex(column, flex-end);

  small {
    @extend .font-small;
    color: get-colour('blue');
  }

  button {
    @extend .font-small;
    color: get-colour('blue');
    border: none;
    background: transparent;
    border-bottom: 1px dashed #272835;
    &:hover {
      cursor: pointer;
    }
  }
}
