.recurring-list__table {
  margin-top: 0;
  margin-bottom: 40px;
  .title {
    margin: 50px 0 40px;
    font-family: 'Playfair Display', sans-serif;
    font-size: 60px;
    font-weight: 900;
    line-height: 75px;
    color: var(--theme-color--blue);
    text-align: center;
  }
  .row-content {
    .centered {
      text-align: center;
      justify-content: center;
    }
  }
}