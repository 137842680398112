.sliders-wrap {
  &:first-child .slider-wrap .slider .slick-list {
    transform: rotateY(15deg);
  }


  &:not(:nth-child(2n)) {
    .slider-wrap:not(:nth-child(2n)) {
      .slider {
        .slider-content-wrap {
          &::before {
            right: 37px;
          }
        }
      }
    }
  }

  &:nth-child(2n) {
    .slider-wrap:not(:nth-child(2n)) {
      .slider {
        .slider-content-wrap {
          &::before {
            width: 436px;
          }
        }
      }
    }
  }
}

.slider-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 507px;
  perspective: 500px;

  &:nth-child(2n) {
    margin-top: 23px;
    .slider {

      .slick-prev,
      .slick-next {
        bottom: -18px;
      }

      .slider-content-wrap {
        &::before {
          height: 141%;
          right: 0;
        }
      }
    }
  }
  &__response-time {
    font-family: $font-family-default;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;

    /* identical to box height, or 138% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    /* grey */

    color: #626473;
    position: absolute;
    bottom: -38px;
    right: 16px;

    @media (min-width: 1680px) {
      right: 27px;
    }
  }
  .slider {
    width: 396px;

    .slick-slider {
      margin-left: -100px;
      .base-icon {
        height: 12px;
      }
    }

    &[bigger=true] .slick-slider {
      margin-bottom: -27px;
    }
    .base-icon.arrow-icon {
      color: #272835;
    }
    .slick-list {
      transform: rotateY(-15deg);
      pointer-events: none;
    }
    .slick-arrow.slick-prev {
      margin-left: 82px;
    }
        
    .slick-arrow.slick-next {
      margin-right: 82px;
    }

    .slick-track {
      .slick-slide {
        height: 296px;
        .slider-content-wrap {
          height: 296px;
          width: 296px !important;
          margin-left: 100px;
        }

        &__play_button {
          left: 41%;
          bottom: 6%;
          position: absolute;
          height: 51px;
          width: 51px;
          border-radius: 50%;
          border: none;
          background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          cursor: pointer;
          opacity: .8;
          i {
            color: white;
            font-size: 18px;
          }
        }
      }
    }
    .slick-prev {
      top: unset;
      bottom: -45px;
      left: 50px;
    }
    .slick-next {
      top: unset;
      bottom: -45px;
      right: 55px;
    }
    .slick-prev:before,
    .slick-next:before {
      display: none;
      color: darkgrey;
      font-size: 30px;
    }

    .slider-content-wrap {
      width: 100%;
      height: 100%;

      position: relative;

      background: linear-gradient(90deg, #f9fafd 40%, #dbdde6 100%), linear-gradient(90deg, #f9fafd 0%, #dbdde6 100%);

      h5 {
        position: absolute;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;

        color: #272835;
        left: 0;
        right: 0;
        bottom: 160px;
        text-align: center;
      }
      h3 {
        position: absolute;
        font-family: Playfair Display;
        font-style: normal;
        font-weight: 900;
        font-size: 23px;
        line-height: 29px;

        /* or 126% */

        text-align: center;
        font-feature-settings: 'case' on, 'liga' off;

        /* blue */

        color: #272835;
        left: 0;
        right: 0;
        bottom: 70px;

        margin: 0 auto;
        max-width: 75%;
      }
      &::before {
        content: '';
        width: 396px;
        height: 94%;
        background: linear-gradient(90deg, transparent 15%, rgba(0, 0, 0, 0.25) 100%);
        filter: blur(4px);
        // box-shadow: 4px 121px 24px 0px linear-gradient(white, black);
        position: absolute;
        bottom: 0;
        right: -37px;
        transform: rotate(-4deg) translate(-14px, 11px);
        z-index: -55;
      }

      &[reverse=true] {
        &::before {
          transform: rotate(4deg) scaleX(-1) translate(-120px, 10px);
        }
      }
    }
        
    &[bigger=true] {
      .slick-track {
        height: 376px;
        .slick-slide {
          height: 346px;
          .slider-content-wrap {
            height: 346px;
          }
        }
      }
    }
        
    img,
    iframe {
      width: 100%;
      padding: 7px;
      background-size: 100% 7px;
      background-position: 0 0, 0 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &[reverse=true] .slider .slick-slider {
    margin-left: 0;
    margin-right: -100px;
  }

  .slider-header {
    margin: 35px 0 22px;
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;

    /* identical to box height, or 136% */

    margin-right: 100px;

    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'case' on;

    /* blue */

    color: #272835;

    &[reverse=true] {
      margin-right: 0;
      margin-left: 100px;
    }
  }

  .calendar-button {
    margin-top: 27px;
    margin-left: calc(50% - 50px);
    margin-right: calc(50% + 50px);

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;

    text-transform: uppercase;

    font-size: 13px;
    line-height: 18px;
    color: #626473;
    cursor: pointer;

    border: none;
    background: transparent;
    outline: none;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 134px;
    i {
      cursor: pointer;
      font-size: 36px;
      color: #272835;
      margin-bottom: 11px;
    }
  }
  .request-button {
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 25px);
        
    &.absolute {
      position: absolute;
      bottom: 37%;
      left: 20%;

      &.live-experience {
        bottom: 20%;
      }
    }
    &.request {
      height: 40px;
      width: 133px;
      left: 0;
      border-radius: 4px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 12px;
      color: #ffffff;
      top: unset;
      bottom: 0;
    }
    .button-gradient-wrapper {
      padding: 3px;
    }
  }
  &[reverse=true] .request-button {
    margin-left: 25%;
    margin-top: 25px;
  }

  @media #{$dt-m} {
    .request-button {

      &.absolute {
        left: 20%;
      }
    }
    // &[reverse=true] .request-button{
    //     margin-left: 20%;
    // }    
  }

  //TODO rewrite to match media queries
  @media (max-width: 1200px) {
    .request-button {

      &.absolute {
        left: 30%;
      }
    }
    &[reverse=true] .request-button {
      margin-left: 20%;
    }
  }
}

.slider-preview {
  width: 296px;
  height: 380px;
  margin-left: -100px;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  i {
    font-size: 90px;
    color: #9495a7;
  }

  & > div {
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: center;
    justify-content: center;
  }

  h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    /* or 162% */

    text-align: center;

    /* light grey */

    color: #9495a7;
    max-width: 60%;
    margin-top: 20px;
  }

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;

    /* identical to box height, or 169% */

    text-align: center;

    /* light grey */

    color: #9495a7;
    margin-bottom: 20px;
  }position: relative;

            
  &::before {
    content: '';
    width: 396px;
    height: 97%;
    background: linear-gradient(90deg, transparent 15%, rgba(0, 0, 0, 0.6) 100%);
    filter: blur(4px);
    // box-shadow: 4px 121px 24px 0px linear-gradient(white, black);
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(-4deg) translate(-14px, 11px);
    z-index: -55;
  }

            
  &::after {
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(88.62deg, rgb(248, 249, 253) 1.07%, rgb(220, 222, 231) 98.75%);
  }

  &[reverse=true] {
    margin-left: 100px;
    &::before {
      transform: rotate(4deg) scaleX(-1) translate(-120px, 10px);
    }
  }
}
