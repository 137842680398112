.talent-reviews {
  max-width: 300px;
  margin-bottom: 64px;

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 24px;
  }

  &__nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all .3s;

    &:disabled {
      cursor: not-allowed;
      opacity: .3;
    }

    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-top: 2px solid var(--theme-color--grey);
      border-left: 2px solid var(--theme-color--grey);
    }

    &--prev {
      transform: rotate(-45deg);
    }

    &--next {
      transform: rotate(130deg);
    }
  }

  &__title {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 28px;
    line-height: 1.36;
    color: var(--theme-color--blue);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 991px) {
      font-size: 26px;
    }

    @media screen and (max-width: 414px) {
      font-size: 22px;
    }
  }

  &__controls {
    flex-shrink: 0;
  }

  &__quote {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    color: var(--theme-color--grey);
    word-break: break-word;
    font-size: 16px;
    line-height: 1.625;
    margin-bottom: 12px;

    &:after {
      content: '\201D';
    }

    &:before {
      content: '\201C';
    }
  }

  &__sender {
    display: flex;
    align-items: flex-start;

    font-size: 14px;
    line-height: 1.5;
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    color: var(--theme-color--blue);
    text-transform: uppercase;

    padding-top: 8px;

    @media screen and (max-width: 414px) {
      flex-direction: column;
    }

    a,
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      color: var(--theme-color--blue);
      text-decoration: none;
      flex-shrink: 0;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      color: var(--theme-color--grey);
    }
  }
}
