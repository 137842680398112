$a2hs: '.ios-a2hs-modal';

#{$a2hs} {
  position: relative;
  padding: 16px;
  padding-top: 32px;
  background-color: var(--theme-color--white);
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.3;


  &__wrapper {
    position: fixed;
    bottom: 16px;
    width: calc(100% - 32px);
    margin: 0 16px;
    z-index: 99999999;
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &__title {
    color: var(--theme-color--blue);
    font-weight: var(--theme-fontWeight--bold);
  }

  &__body {
    display: flex;
    color: var(--theme-color--grey);

    &__logo {
      width: 48px;
      height: 48px;
      border-radius: 12px;
    }

    &__save-button-img {
      margin: 0 8px -4px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;

    & > * {
      flex: 1;
      min-height: 32px;
      color: var(--theme-color--white);
      font-size: 14px;
      font-weight: var(--theme-fontWeight--semiBold);
    }

    & > * + * {
      margin-top: 8px;
    }
  }

  &__cta {
    color: var(--theme-color--grey);
    text-align: center;
  }

  &__pointer:after {
    content: '';
    position: absolute;
    left: calc(50% - 18px);
    bottom: -17px;
    width: 20px;
    height: 20px;
    background: var(--theme-color--white);
    transform: rotate(45deg) translate(0, -50%);
    z-index: -1;
  }
}