$bs: '.biography-slider';

#{$bs} {
  margin-top: 65px;
  margin-bottom: 50px;

  @media screen and (max-width: 1366px) {
    padding: 0 70px;
  }

  @media screen and (max-width: 991px) {
    padding: 0 35px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 65px;
  }

  &__header {
    margin-bottom: 20px;

    @media screen and (max-width: 1366px) {
      padding-left: 0;
      margin-left: -35px;
    }
    @media (max-width: 991px) {
      margin-left: -35px;
    }

    .title {
      font-family: var(--theme-fontFamily--primary), sans-serif;
      font-weight: var(--theme-fontWeight--black);
      font-size: 24px;
      line-height: 1.6;
    }
  }

  &__wrapper {
    position: relative;

    &[data-disabled='true'] {
      .swiper-pagination {
        display: none;
      }
    }

    .swiper {
      margin: 0 -5px;
      &-container {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 45px;
      }

      &-pagination-bullet {
        width: 11px;
        height: 11px;
        opacity: 1;
        background: transparent;

        // TODO: add support for the rgb css vars
        border: 2px solid rgba(#272835, 0.4); // --theme-color--blue

        &-active {
          background: var(--theme-color--blue);
        }
      }
    }
  }

  &__navigation {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: calc(50% - 35px);
    width: 100%;

    &_btn {
      position: absolute;
      cursor: pointer;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: background-color .3s ease-in-out;
      background: none;
      border: none;
      outline: none;

      &:active,
      &:hover {
        background-color: rgba(#555555, 0.1);
      }

      svg {
        width: 13px;
        height: 26px;
      }

      &.swiper-button-disabled {
        display: none;
      }

      &--prev {
        left: -45px;
      }

      &--next {
        right: -45px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;

    &_header {
      display: flex;
      justify-content: space-between;

      &--line {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-self: flex-end;
        height: 27px;
        border-top: 1px solid  var(--theme-color--blue);

        &:first-child {
          border-left: 1px solid  var(--theme-color--blue);
        }

        &:last-child {
          border-right: 1px solid  var(--theme-color--blue);
        }
      }

      &--text {
        margin: 0 10px;
        font-family: var(--theme-fontFamily--primary), sans-serif;
        font-weight: var(--theme-fontWeight--black);
        font-size: 56px;
        font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off, 'case' on;
        line-height: 1.3;
        color:  var(--theme-color--blue);
        -webkit-line-clamp: 2;

        @media screen and (max-width: 1450px) {
          font-size: 48px;
        }

        @media screen and (max-width: 1366px) {
          font-size: 40px;
        }

        @media screen and (max-width: 767px) {
          font-size: 38px;
          line-height: 1.5;
        }

        @media screen and (max-width: 375px) {
          font-size: 42px;
        }

        &[data-star-size='true'] {
          font-size: 40px;

          @media screen and (max-width: 1025px) {
            font-size: 32px;
          }

          @media screen and (max-width: 700px) {
            font-size: 28px;
            line-height: 2.1;
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 250px;
    color:  var(--theme-color--blue);
    padding: 0 20px 20px;
    border: 1px solid  var(--theme-color--blue);
    border-top: none;

    @media screen and (max-width: 1500px) {
      height: 200px;
    }

    @media screen and (max-width: 1036px) {
      height: 220px;
    }

    @media screen and (max-width: 940px) {
      height: 250px;
    }

    @media screen and (max-width: 700px) {
      padding: 0 10px;
      height: 270px;
    }

    @media screen and (max-width: 625px) {
      height: 200px;
    }

    @media screen and (max-width: 500px) {
      height: 250px;
    }

    @media screen and (max-width: 400px) {
      height: 275px;
    }

    @media screen and (max-width: 350px) {
      height: 300px;
    }

    &_title {
      display: flex;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 6px;
      margin-bottom: 16px;
      font-family: var(--theme-fontFamily--primary), sans-serif;
      font-weight: var(--theme-fontWeight--bold);
      font-size: 20px;
      line-height: 1.4;
      font-feature-settings: 'case';
      text-align: center;
      -webkit-line-clamp: 2;
      overflow-wrap: break-word;
    }

    &_description {
      display: flex;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 16px;
      line-height: 1.625;
      text-align: center;
      -webkit-line-clamp: 4;
      overflow-wrap: break-word;
    }
  }
}
