.favorites-wrapper {
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  .search-list,
  .search-feature__slider {
    margin-bottom: 50px;
  }
}