.upload-info-modal {
  background-color: get-colour('white');
  padding: 16px;
  max-width: 500px;
  border-radius: $modal-border-radius;

  font-family: $font-family-default;
  font-weight: get-font-weight('medium');
  font-size: get-font-size('s-2');
  line-height: $default-line-height;
  color: get-colour('blue');
  text-align: justify ;

  &__title {
    text-transform: uppercase;
    font-weight: get-font-weight('bold');
  }

  strong {
    font-weight: get-font-weight('bold');
  }

  @media screen and (max-width: 414px) {
    max-height: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 36px 16px 16px;
    font-size: get-font-size('s-1');
    
    &__title {
      font-size: get-font-size('m-3');
    }
  }
}
