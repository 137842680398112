.chat-header-wrapper {
  display: flex;
  justify-content: space-between;

  max-height: 56px;
  width: 100%;

  padding: 8px 16px;

  background: var(--theme-color--white);
  box-shadow: 10px 10px 20px rgba(209, 210, 225, 0.3),
    -10px -10px 20px rgba(250, 250, 255, 0.5);
}

.chat-header-info-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  cursor: pointer;
}

.chat-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;

  padding-left: 15px;

  &__chat-title {
    font-size: 13px;
    font-weight: var(--theme-fontWeight--bold);

    line-height: 1.7;

    color: var(--theme-color--blue);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__chat-type {
    display: flex;
    align-items: center;

    font-size: 12px;
    font-weight: var(--theme-fontWeight--bold);

    line-height: 1.33;

    text-transform: uppercase;

    svg {
      fill: var(--theme-color--lightGrey);
      margin-right: 10px;
    }

    color: var(--theme-color--lightGrey);
  }
}

.chat-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin: 0 15px;

    &:hover {
      cursor: pointer;
    }
  }
}

.mobile-view {
  .chat-header-wrapper {
    align-items: center;

    border-bottom: 1px solid var(--theme-color--ultraLightBlue);
  }

  .chat-header-info-wrapper {
    width: 100%;
  }
  .chat-info {
    &__chat-title {
      font-family: var(--theme-fontFamily--primary);
      font-weight: var(--theme-fontWeight--black);
      font-size: 23px;
      line-height: 1.26;
    }
  }

  .chat__icon {
    margin-left: auto;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  .back-btn {
    cursor: pointer;
  }
}
