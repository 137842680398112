$unc: '.user-notifications-counter';

.dark-theme #{$unc} {
  position: absolute;
  top: -3px;
  right: 0;
  width: 22px;
  height: 22px;
  display: flex;
  flex-direction: row;
  color: var(--theme-color--blue);
  background: var(--theme-color--grey);
  border-radius: 50px;
  font-weight: var(--theme-fontWeight--bold);
  font-size: 12px;

  .user-notifications-counter__number {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 18px;
    min-width: 18px;
    padding: 0 4px;
    color: var(--theme-color--blue);
    background: var(--theme-color--white);
    border-radius: 50px;
    line-height: 18px;
  }
}

#{$unc} {
  position: absolute;
  top: 3px;
  right: -4px;
  width: 18px;
  height: 18px;
  color: var(--theme-color--white);
}

.user-notifications-counter__number {
  position: absolute;
  top: 0;
  right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 18px;
  min-width: 18px;
  padding: 0 4px;
  color: var(--theme-color--white);
  background: linear-gradient(360deg, var(--theme-color--blue) 0%, #636583 100%);
  border-radius: 50px;
  font-size: 11px;
  line-height: 18px;
  font-weight: var(--theme-fontWeight--bold);
}

@media only screen and (max-width: 1024px) {
  .dark#{$unc} {
    top: -2px;
    right: 10px;
    width: 18px;
    height: 18px;

    .user-notifications-counter__number {
      top: 2px;
      right: 2px;
      width: 14px;
      height: 14px;
    }
  }

  #{$unc} {
    top: 3px;
    right: 2px;
    width: 16px;
    height: 16px;
  }

  .user-notifications-counter__number {
    top: 2px;
    right: 2px;
    height: 16px;
    min-width: 16px;
    padding: 0 4px;
  }
}
