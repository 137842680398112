$tib-ib: '.talent-info-block__icons-bar';

#{$tib-ib} {
  display: flex;

  &__item {
    width: 62px;
    margin: 7px;
    cursor: pointer;
        
    svg {
      width: 24px;
      height: 24px;
      fill: #ffffff;
      transition: all .3s ease-in-out;
    }

    &[active] {
      svg {
        fill: var(--theme-color--blue);
      }
    }
  }
}