.messages-list {
  flex: 1;

  padding: 25px 0;

  overflow: auto;

  &__loader {
    height: 20px;
    display: flex;
    justify-content: center;

    margin: 10px 0;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--theme-color--blueText);
  }
}

.new-messages {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 32px;
  background: var(--theme-color--ultraLightBlue);

  p {
    font-size: 13px;
    font-weight: var(--theme-fontWeight--bold);
    line-height: 1.7;

    color: var(--theme-color--lightGrey);
  }
}

.mobile-view {
  &.messages-list {
    height: 494px;
  }
}
