.device-selection-modal {
  background: var(--theme-color--white);
  padding: 16px 40px 40px;
  box-shadow: var(--theme-boxShadow--modal);
  border-radius: var(--theme-borderRadius--modal);

  width: 548px;
  overflow-y: auto;

  @media screen and (max-width: 575px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-feature-settings: 'case' on;
    font-size: 32px;
    line-height: 1.375;
    text-align: center;
    color: var(--theme-color--blue);

    margin-bottom: 24px;
  }

  &__group-header {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 22px;
    line-height: 1.5;
    color: var(--theme-color--blue);
  }

  &__group {
    margin-bottom: 32px;
    position: relative;

    &:not(:last-child):after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(to left, #e3e3e8, #a0a2b1, #e3e3e8);
      border-radius: 8px;

      position: absolute;
      bottom: -16px;
    }
  }
}

.device-selection-modal-wrapper {
  @media screen and (max-width: 575px) {
    height: 100% !important;
  }
}

.media-list {
  &__header {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 14px;
    line-height: 1.5;
    text-transform: uppercase;
    color: var(--theme-color--lightGrey);

    margin-bottom: 4px;
    margin-top: 16px;
  }

  &__default {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 18px;
    line-height: 1.78;
    color: var(--theme-color--blue);
  }

  &__video-wrapper {
    width: 256px;
    height: 144px;

    margin: 12px auto;
  }
}