.table-media {
  display: flex;
  align-items: center;
  padding: 16px 0;

  &__file {
    flex: 0 0 60px;
    max-width: 60px;
    height: 80px;

    border-radius: 0 0 4px 4px;
    overflow: hidden;

    .item-preview {
      box-shadow: none;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        //background: linear-gradient(
        //  180deg,
        //  rgba(39, 40, 53, 0) 0%,
        //  rgba(39, 40, 53, 0.94) 67.71%
        //);
      }

      &__icon {
        z-index: 3;
      }
      .loader {
        position: absolute;
        z-index: 3;
      }
    }
  }

  &__file-loader {
    height: 100% !important;
    width: 50px !important;
  }

  &__name {
    padding-left: 16px;
    width: 100%;

    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blue);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
