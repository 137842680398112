$cs: '.calendar-slot';
$fc: '.fc';

#{$cs} {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__locked {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__info {
    overflow: hidden;
    flex: 1;
    padding: 4px;

    &__title {
      font-weight: var(--theme-fontWeight--bold);
      font-size: 10px;
    }

    &__desc {
      letter-spacing: -0.1px;
    }

    &--center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  &__before,
  &__after {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000000, 0.3);
  }

  &__before {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &__after {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}



//
// .fc override
//
// To hide driving time for events that are splitted between dates
#{$fc}-event {
  &-start:not(&-end) {
    #{$cs}__after {
      display: none;
    }
  }

  &-end:not(&-start) {
    #{$cs}__before {
      display: none;
    }
  }

  #{$cs} {
    &__before,
    &__after,
    &__info__desc {
      display: none;
    }

    &__info {
      padding: 0;
    }
  }
}
