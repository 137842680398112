.search-feature {
  @media screen and (max-width: 575px) {
    margin-bottom: 70px;
  }

  &__header {
    font-family: 'Playfair Display', sans-serif;
    font-weight: 900;
    font-size: 28px;
    line-height: 1.4;
    color: #272835;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &__slider {
    position: relative;
    margin: -12px;
    @media screen and (max-width: 1460px) {
      padding: 0 35px;
    }
    @media screen and (max-width: 525px) {
      padding: 0;
    }
    .swiper-container {
      padding: 12px 12px 70px;
    }
    &_slide {
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 525px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__nav {
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;

    @media screen and (max-width: 575px) {
      top: auto;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_pagination {
      width: 40% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      .swiper-pagination {
        color: #9495a7;
        font-size: 16px;
        &-current {
          font-size: 22px;
          font-weight: 700;
          color: #272835;
          margin-right: 5px;
        }
        &-total {
          margin-left: 5px;
        }
      }
      @media screen and (min-width: 575px) {
        display: none;
      }
    }

    &_btn {
      position: absolute;
      cursor: pointer;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: background-color .3s ease-in-out;
      @media screen and (max-width: 575px) {
        position: static;
      }
      &:active,
      &:hover {
        background-color: rgba(85, 85, 85, .09);
      }
      svg {
        width: 12px;
        height: 24px;
      }

      &[data-disabled='true'] {
        pointer-events: none;
        opacity: .3;
      }

      &--prev {
        left: -34px;
        @media screen and (max-width: 1460px) {
          left: 0;
        }
      }

      &--next {
        right: -34px;
        @media screen and (max-width: 1460px) {
          right: 0;
        }
      }
    }
  }
}
