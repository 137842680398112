@import '~react-popper-tooltip/dist/styles.css';

$color-tooltip: #7c7e8a;

.tooltip-wrapper {

  &--stretch {
    width: 100%;
  }
}

.tooltip-children {
  display: inline;
}

.tooltip-container {
  max-width: 320px;
  padding: 8px 16px;

  background-color: $color-tooltip;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);

  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: get-colour('white');

  overflow-wrap: break-word  !important;

  @media screen and (max-width: 525px) {
    max-width: 260px;
  }

  @media screen and (max-width: 425px) {
    max-width: 210px;
  }
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::after {
  border-color: transparent transparent transparent $color-tooltip;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::after {
  border-color: transparent $color-tooltip transparent transparent;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::after {
  border-color: transparent transparent $color-tooltip transparent;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::after {
  border-color: $color-tooltip transparent transparent transparent;
}
