$fb: '.follow-button';

#{$fb} {
  appearance: none;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  & > * {
    pointer-events: none;
  }

  &__icon {
    width: 24px;
    height: auto;

    path {
      fill-opacity: 1;
      transition: 0.2s fill, 0.2s stroke;
    }
  }

  &.simple {
    #{$fb}__text {
      display: none;
    }

    #{$fb}__icon {
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.4));
      -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.4));

      path {
        transition: 0.2s fill;
      }
    }

    &.dark {
      #{$fb}__icon path {
        fill: rgba(#7a808c, 0.6);
        stroke: var(--theme-color--white);
      }

      &.active {
        #{$fb}__icon path {
          fill: var(--theme-color--blue);
        }
      }
    }
  
    &.light {
      #{$fb}__icon path {
        fill: rgba(#ffffff, 0.6);
        stroke: var(--theme-color--blue);
      }

      &.active {
        #{$fb}__icon path {
          fill: rgba(#ffffff, 1);
        }
      }
    }
  }

  &.button-like {
    padding: 4px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    transition: 0.2s color, 0.2s background-color;

    & > * + * {
      margin-left: 8px;
    }

    #{$fb}__icon path {
      fill: transparent;
    }

    #{$fb}__text {
      font-size: 16px;
      line-height: 1.625;
      font-weight: var(--theme-fontWeight--bold);
    }

    &.light {
      color: var(--theme-color--blue);
      background-color: var(--theme-color--white);

      #{$fb}__icon path {
        stroke: var(--theme-color--blue);
      }

      &:hover {
        background-color: var(--theme-color--ultraLightBlue);
      }

      &.active {
        #{$fb}__icon path {
          fill: var(--theme-color--blue);
        }
      }
    }
  
    &.dark {
      color: var(--theme-color--white);
      background-color: rgba(#272835, 0.6);

      #{$fb}__icon path {
        stroke: var(--theme-color--white);
      }

      &:hover {
        background-color: var(--theme-color--blue);
      }

      &.active {
        #{$fb}__icon path {
          fill: var(--theme-color--white);
        }
      }
    }
  }
}