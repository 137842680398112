//Sizes based on designs from figma

// wrappers size for instance with shadow (if we set smaller size swiper will crop shadow)
$swiper-slider-wrapper-w: 500px;
$swiper-slider-wrapper-h: 475px;

// wrappers size for instance with shadow (if we set smaller size swiper will crop shadow) for smaller screens
$swiper-slider-wrapper-w-sm: 320px;
$swiper-slider-wrapper-h-sm: 420px;

// size for slide only
$swiper-slide-w: 323px;
$swiper-slide-h: 431px;

// size for slide only for smaller screens
$swiper-slide-w-sm: 270px;
$swiper-slide-h-sm: 360px;

.public-slider {
  width: #{$swiper-slider-wrapper-w};
  height: #{$swiper-slider-wrapper-h};
  position: relative;
  &-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  &-slide {
    width: #{$swiper-slide-w};
    height: #{$swiper-slide-h};
    transform: perspective(150px) rotateY(3deg);
    margin: 40px auto;

    &.reversed {
      transform: perspective(150px) rotateY(-3deg);
    }

    img,
    video {
      margin: 0 auto;
      padding: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: linear-gradient(90deg, #f9fafd 40%, #dbdde6 100%), linear-gradient(90deg, #f9fafd 0%, #dbdde6 100%);
      overflow: hidden;
    }

    .public-slider-player {
      background: linear-gradient(90deg, #f9fafd 40%, #dbdde6 100%), linear-gradient(90deg, #f9fafd 0%, #dbdde6 100%);

      img,
      video {
        background: none;
      }

      @media screen and (max-width: 1366px) {
        .volume-icon,
        .fullscreen-icon {
          width: 50px;
          height: 50px;
        }
        .play-icon {
          width: 80px;
          height: 80px;
        }
      }
      @media screen and (max-width: 1024px) {
        .volume-icon,
        .fullscreen-icon {
          width: 40px;
          height: 40px;
        }
        .play-icon {
          width: 64px;
          height: 64px;
        }
      }
    }

    &:before {
      content: '';
      width: 396px;
      height: 90%;
      filter: blur(4px);
      background: linear-gradient(90deg, transparent 10%, rgba(0, 0, 0, 0.6) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(-4deg) translate(-90px, 0);
      z-index: -1;
      display: block;
    }
  }

  &__nav {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    z-index: 5;

    &_btn {
      cursor: pointer;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: background-color .3s ease-in-out;
      margin: 0 24px;

      &:active,
      &:hover {
        background-color: rgba(85, 85, 85, .09);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: #{$swiper-slider-wrapper-w-sm};
    height: #{$swiper-slider-wrapper-h-sm};
    &-slide {
      width: #{$swiper-slide-w-sm};
      height: #{$swiper-slide-h-sm};
      transform: none;
      margin: 24px auto;
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
      &:before {
        display: none;
      }

      &.reversed {
        transform: none;
      }
    }

    &__nav {
      margin-top: -20px;
    }

    &-wrapper {
      margin-top: -24px;
      align-self: center;
    }
  }

  @media only screen and (max-width: 768px) {
    &__nav {
      margin-bottom: 40px;
    }
  }
}
