$od-t: '.od__title';

#{$od-t} {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 40px;
  font-family: var(--theme-fontFamily--primary);
  font-weight: var(--theme-fontWeight--black);
  overflow-wrap: anywhere;
  h3 {
    font-size: 1.375em;
    line-height: 1.75em;
    color: var(--theme-color--lightGrey);
  }
  h1 {
    font-size: 3.75em;
    line-height: 75px;
    color: var(--theme-color--blue);
    max-width: 800px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  }

  @media only screen and (max-width: 1024px) {
    h3 {
      font-weight: var(--theme-fontWeight--bold);
      font-size: 16px;
    }
    h1 {
      font-size: 32px;
      line-height: 54px;
      font-feature-settings: 'case' on;
    }
  }
  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 28px;
      line-height: 38px;
    }
  }
}