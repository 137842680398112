// preview-modal
.preview-modal {
  &__navigation {
    position: fixed;
    top: 20px;
    right: 13px;
    z-index: 1000;
    display: flex;
    flex-direction: column;

    &-button {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      margin-bottom: 6px;
      color: white;
      border: none;

      i {
        color: white;
      }

      &.publish-button {
        background-color: rgb(116, 221, 116) !important;
      }

      &.draft-button {
        background-color: rgb(106, 106, 194) !important;
      }
    }
  }

  &__content {
    border-radius: 8px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
    // max-height: 75vh;
    // width: 80vw;
    height: 100vh;
    width: 100vw;

    // todo fix
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
    }

    .tbv-page {
      margin-top: 0;
      padding-top: 16px;
      background-color: get-colour('white');
      background-image: url('../../../../assets/images/talent-public-view-background.png');
    }

    // todo fix
    .talent-public-view-wrapper .talent-public-view {
      margin-top: 0;
    }
  }
}
