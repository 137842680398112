$pay-card: '.payment-card';

#{$pay-card} {
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 970px;
    width: 100%;
    margin: 62px auto 72px;
    @media screen and (max-width: 991px) {
      align-items: center;
      flex-direction: column ;
    }
    @media screen and (max-width: 575px) {
      margin: 30px auto;
    }
  }

  &__form-wrapper {
    max-width: 480px;
    width: 100%;
  }

  &__form {
    width: 100%;
    margin-bottom: 40px;
  }
  &__field {
    display: block;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    >._error-message {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
  &__label {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    text-transform: uppercase;
    color: var(--theme-color--lightGrey);
    display: block;
    margin-bottom: 4px;
  }

  &__input {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 4px;
    border: 2px solid var(--theme-color--blueText);
    padding: 17px;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;

    &.error {
      border: 2px solid red;
    }

    &::placeholder {
      color: var(--theme-color--lightGrey);
    }

    &:disabled {
      background-color: #e9eaf0;
      color: var(--theme-color--lightGrey);
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        border-color: var(--theme-color--blueText);
      }

      &:focus {
        pointer-events: none;
      }
    }

    &:focus,
    &:hover {
      border-color: var(--theme-color--black);
    }
  }
  &__promo-code {
    margin-top: 24px;
    margin-bottom: 40px;
    &_btn {
      display: flex;
      align-items: center;
      padding: 0;
      border: none;
      background-color: transparent;
      outline: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.625;
      color: var(--theme-color--blue);
      cursor: pointer;
      svg {
        transition: transform .3s ease-in-out;
        &[data-open='true'] {
          transform: rotate(-180deg);
        }
      }
    }
    &_block {
      display: none;
      margin-top: 16px;
      &[data-open='true'] {
        display: block;
      }
    }
    &_enter {
      display: grid;
      grid-template-columns: 2fr .3fr;
      grid-column-gap: 16px;
      .payment-card__input {
        text-transform: uppercase;
      }

      >._error-message {
        position: absolute;
        top: 100%;
        left: 0;
      }
    }
    &-view {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_wr {
        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 1.625;
          color: var(--theme-color--blue);
          text-transform: uppercase;
        }
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 1.625;
          color: var(--theme-color--grey);
        }
      }
      &_btn {
        position: relative;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 5px;
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          height: 2px;
          width: 100%;
          transform: translateY(-50%);
          background-color: var(--theme-color--lightGrey);
          transition: background-color .3s ease-in-out;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
        &:hover {
          &:before,
          &:after {
            background-color: var(--theme-color--blue);
          }
        }
      }
    }
  }
  &__check {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover input ~ i {
      background-color: #cccccc;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ i {
        background-color: var(--theme-color--blue);
        border: none;
        &:before {
          display: block;
        }
      }
      &:disabled ~ i {
        background-color: #cccccc;
      }
    }

    i {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 25px;
      width: 25px;
      border: 2px solid var(--theme-color--blueText);
      border-radius: 4px;
      &:before {
        display: none;
        content: '';
        position: absolute;
        top: 20%;
        left: 50%;
        width: 4px;
        height: 12px;
        border: solid var(--theme-color--white);
        border-width: 0 2px 2px 0;
        transform: rotate(40deg) translateX(-50%);
      }
    }
    &--text {
      font-weight: var(--theme-fontWeight--medium);
      font-size: 16px;
      line-height: 1.625;
      color: var(--theme-color--blue);
      padding-left: 6px;
    }
  }

  &__title {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 23px;
    line-height: 1.8em;
    font-feature-settings: 'case' on, 'liga' off;
    color: var(--theme-color--blue);
    margin-bottom: 12px;
  }

  &__subtitle {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625em;
    color: var(--theme-color--grey);
    margin-bottom: 24px;
  }

  // TODO Find better solution when we rewrite forms?
  &__shipment {
    margin-bottom: 56px;
    .row {
      flex-direction: column;
      margin-bottom: 24px;

      &-left {
        .row-name {
          font-family: var(--theme-fontFamily--default);
          font-weight: var(--theme-fontWeight--bold);
          font-size: 13px;
          line-height: 1.125em;
          text-transform: uppercase;
          color: var(--theme-color--lightGrey);
          padding-bottom: 4px;
        }
      }

      &-right {
        width: 100%;
        flex: 1;
        max-width: 100%;

        .zip-code {
          max-width: 100%;
        }
      }
    }
  }
}

.payment-page {
  .Toastify__toast {
    width: 100% !important;
    &-container {
      width: 100% !important;
      max-width: 920px;
    }
  }
}

.mobile-payment-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    text-align: center;
    color: var(--theme-color--blue);

    padding: 0 16px;
    margin-bottom: 6px;

    position: relative;

    &:after,
    &:before {
      content: '';
      display: block;
      height: 2px;
      width: 122px;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @media screen and (max-width: 510px) {
        width: 72px;
      }

      @media screen and (max-width: 414px) {
        width: 50px;
      }
    }

    &:after {
      background: linear-gradient(to left, #ffffff, #282936);
      left: 100%;
    }

    &:before {
      background: linear-gradient(to left, #282936, #ffffff);
      right: 100%;
    }
  }

  &__info {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: var(--theme-color--grey);

    margin-bottom: 40px;
  }

  &__buttons {
    width: 100%;
  }
}
