.icon {
    svg {
      width: 40px;
      height: 54px;
      margin: 0 auto;
      display: block;
      justify-content: center;
      opacity: 0.6;
    }
}
.icon-buildings-with-ban {
    svg {
      width: 30px;
      height: 54px;
      margin: 0 auto;
      display: block;
      justify-content:get-font-weight('bold') center;
      opacity: 0.6;
    }
}
.relative{
    position: relative;
}
.icon-ban {
    svg {
      width: 58px;
      height: 60px;
      position: absolute;
      top: 0;
      left: 16px;
      opacity: 0.6;
    }
}
.move-up-right-corner{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 5px;
    width: 90px;
}
.text-fan{
  color: #626473;
  font-family: "Playfair Display", sans-serif;
  font-weight: 500;
  font-size: get-font-size('s-1');
  line-height: 1.62;
  text-align: center;
  justify-content: center;
  width: 100%;
  opacity: 0.6;
}
.text-commercial{
  color: #626473;
  font-family: "Playfair Display", sans-serif;
  font-weight: 500;
  font-size: get-font-size('s-1');
  line-height: 1.62;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-top: -5px;
  opacity: 0.6;
}
.overlay-button{
    position:absolute;
    z-Index: 100;
    bottom: 5px;
    left: 34.5%;
    margin-top: 10px;
    @media screen and (max-width: 950px) {
      left: 27.5%;
    }
}
.video-overlay{
  z-Index: 100;
}