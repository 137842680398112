$ac: '.action-cell';

#{$ac} {
  display: flex;
  justify-content: center;

  & > * + * {
    margin-left: 8px;
  }
}

.col {
  #{$ac} {
    justify-content: center;
  }
}

.cell-wrapper {
  #{$ac} {
    justify-content: flex-start;
  }
}