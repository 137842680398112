.payment-modal {
  width: 560px;
  background-color: var(--theme-color--white);
  padding: 16px 40px 40px;
  border-radius: var(--theme-borderRadius--modal);
  @media screen and (max-width: 575px) {
    width: 100%;
    padding: 8px 16px 16px;
    margin-top: 32px;
  }
  &__title {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 32px;
    line-height: 1.4;
    color: var(--theme-color--blue);
    margin-bottom: 24px;
    text-align: center;
  }
 
  &__btn-add {
    margin-top: 12px;
    @media screen and (max-width: 575px) {
      margin-top: 0;
    }
  }

  &--close {
    @media screen and (max-width: 575px) {
      //TODO remove after rewite modals from styled components
      top: 32px !important;
    }
  }
}