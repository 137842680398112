$thumb-size: 18px;
$track-size: 8px;
$thumb-bg: #636583;
$track-bg: #f2f2f2;
$progress-bg: #636583;

.range__input {
  --webkitProgressPercent: 0%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: $thumb-size;
  width: 100%;
  margin: 0;
  padding: 0;


  &:focus {
    outline: none;
  }

  /* Thumb */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: $thumb-size;
    height: $thumb-size;
    background-color: $thumb-bg;
    border-radius: calc(#{$thumb-size} / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((#{$thumb-size} - #{$track-size}) / 2) * -1);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: $thumb-size;
    height: $thumb-size;
    background-color: $thumb-bg;
    border-radius: calc(#{$thumb-size} / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((#{$thumb-size} - #{$track-size}) / 2) * -1);
    cursor: pointer;
  }

  &::-ms-thumb {
    -ms-appearance: none;
    appearance: none;
    width: $thumb-size;
    height: $thumb-size;
    background-color: $thumb-bg;
    border-radius: calc(#{$thumb-size} / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((#{$thumb-size} - #{$track-size}) / 2) * -1);
    cursor: pointer;
  }

  &::-moz-range-track {
    height: $track-size;
    background-color: $track-bg;
    border-radius: calc(#{$track-size} / 2);
  }

  &::-ms-track {
    height: $track-size;
    background-color: $track-bg;
    border-radius: calc(#{$track-size} / 2);
  }

  &::-webkit-slider-runnable-track {
    height: $track-size;
    background-image: linear-gradient(
      90deg,
      $progress-bg var(--webkitProgressPercent),
      $track-bg var(--webkitProgressPercent)
    );

    border-radius: calc(#{$track-size} / 2);
  }

  /* Progress */
  &::-moz-range-progress {
    height: $track-size;
    background-color: $progress-bg;
    border-radius: calc(#{$track-size} / 2) 0 0 calc(#{$track-size} / 2);
  }

  &::-ms-fill-lower {
    height: $track-size;
    background-color: $progress-bg;
    border-radius: calc(#{$track-size} / 2) 0 0 calc(#{$track-size} / 2);
  }
}
