.participant-info {
  background-color: var(--theme-color--black);
  border-radius: 4px;
  border: 4px solid var(--theme-color--ultraLightBlue);
  position: relative;

  height: 186px;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--theme-color--black);
  }

  &__avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__avatar-icon {
    height: 100px;
    width: 100px;
  }

  &--hide {
    display: none;
  }
}