.reviews-table {
  padding-bottom: 100px;

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 60px;
    line-height: 1.25;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: var(--theme-color--blue);
  }

  .col {
    padding: 12px 0;
  }

  .row-content {
    grid-template-columns: 36fr 46fr 30fr 14fr !important;
  }
}
