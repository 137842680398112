.empty-chat {
  font-family: var(--theme-fontFamily--default);
  font-style: normal;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  font-size: 16px;
  font-weight: var(--theme-fontWeight--medium);
  line-height: 1.63;
  text-align: center;

  color: var(--theme-color--lightGrey);

  p {
    width: 270px;
    margin-top: 30px;
  }
}