.video-message-offering-form {
  .enable-in-you-services {
    .row-left {
      padding-top: 0;
    }
    &-radio {
      display: flex;
      align-items: center;
      label:not(:last-of-type) {
        margin-right: 45px;
        @media screen and (max-width: 767px) {
          margin-right: 0;
        }
      }
    }
  }

  &__submit {
    margin-top: 50px;
    @media screen and (max-width: 767px) {
      margin-top: 0;
      width: 100%;
    }
  }
}


