$tibt: '.talent-info-block-tab';

#{$tibt} {
  margin-top: 20px;
  font-weight: var(--theme-fontWeight--medium);

  &__header {
    margin-bottom: 10px;
    color: var(--theme-color--grey);
    font-size: 14px;
    font-weight: var(--theme-fontWeight--bold);
    text-transform: uppercase;
  }

  &__info {
    margin-bottom: 8px;
    color: var(--theme-color--blue);
    font-size: 18px;
    font-weight: var(--theme-fontWeight--semiBold);
  }
}