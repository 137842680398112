@import 'styles/_legacy/abstracts/_mixins.scss';


$c: '.calendar';
$fc: '.fc';
$fc-border: 1px solid #dddddd;


#{$c} {
  width: 100%;
  height: 100%;

  &--pr32 .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    padding-right: 32px;
  }
  .fc-timegrid-slot {
    height: 40px !important
}

  &--pr32 {
    .dots-wrapper {
      position: absolute;
      right: 0;
      top: 14px;
    }
  }

  //
  // Full calendar inner styles override
  //
  #{$fc} {

    // Scroll area
    #{$fc}-view-harness {
      max-height: 550px;

      @include customScrollbar();

      @media all and (max-width: 768px) {
        max-height: unset;
      }

      @media all and (max-width: 575px) {
        min-height: 450px;
      }
    }

    // Toolbar
    #{$fc}-header-toolbar {
      padding: 8px 12px;
      border-bottom: $fc-border;

      &#{$fc}-toolbar {
        margin-bottom: 0;
      }
    }

    #{$fc}-toolbar {
      &-title {
        font-size: 16px;
        line-height: 1.5;
        font-weight: var(--theme-fontWeight--medium);
      }

      &-chunk {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      #{$fc}-toolbar-chunk + #{$fc}-toolbar-chunk {
        margin-left: 6px;
      }

      @media all and (max-width: 575px) {
        flex-direction: column-reverse;

        &-chunk {
          width: 100%;

          #{$fc}-timeGridWeek-button,
          #{$fc}-timeGridDay-button,
          #{$fc}-listWeek-button {
            flex: 1;
          }
        }

        #{$fc}-toolbar-chunk + #{$fc}-toolbar-chunk {
          margin-left: 0;
          margin-bottom: 12px;
        }

        #{$fc}-toolbar-chunk:first-of-type {
          justify-content: space-between;
        }
      }

      @media all and (max-width: 1023px) and (orientation: landscape) {
        &-chunk {
          flex-direction: row;
          display: flex;
        }
      }
    }

    // Buttons
    #{$fc}-button {
      border: none;
      transition: background-color 0.2s, color 0.2s;
      text-transform: capitalize;
      box-shadow: none !important;

      &-primary {
        &:not(:disabled) {
          &:active,
          &.fc-button-active,
          &:hover,
          &:focus {
            color: var(--theme-color--white);
            background-color: var(--theme-color--blue);
          }
        }

        &:disabled {
          cursor: not-allowed;
          color: var(--theme-color--blueText);
        }

        &.fc-prev-button,
        &.fc-next-button {
          transition: opacity 0.2s;

          &,
          &:active,
          &:hover,
          &:focus {
            background: none;
            color: var(--theme-color--blue);
          }
        }
      }
    }

    // Table
    #{$fc}-col-header {
      &-cell {
        padding: 8px;
        border-left: none;
        border-right: none;

        &-cushion {
          color: var(--theme-color--grey);
          font-size: 14px;
          line-height: 1.6;
          font-weight: var(--theme-fontWeight--bold);

          @media screen and (max-width: 767px) {
            font-size: 12px;
            letter-spacing: -0.5px;
          }
        }
      }
    }

    #{$fc}-timegrid {
      &-body {
        overflow: hidden;
      }

      &-slot {
        &-label {
          &-cushion {
            font-size: 12px;
            line-height: 1.6;
            text-transform: uppercase;
          }
        }
      }
    }

    // Events
    #{$fc}-event {
      border: none;
      overflow: hidden;

      &-main {
        font-size: 12px;
        line-height: 1.3;
        padding: 0;
      }
    }

    //
    // Week view
    //
    #{$fc}-scrollgrid {
      border: none;
    }

    //
    // List view
    //
    #{$fc}-list {
      margin: 0;
      margin-left: 12px;
      border: none;

      &-table {
        th {
          padding: 8px 0;
          font-weight: var(--theme-fontWeight--bold);
        }

        td {
          padding: 16px;
        }

        #{$fc}-list-day {
          & > th {
            border: none;
          }

          &-cushion {
            background: #ffffff;
          }
        }

        #{$fc}-list-event {
          & > * {
            transition: 0.2s background-color;
            border: none;
          }

          &-graphic {
            padding-right: 4px;
          }

          &-title {
            padding-left: 4px;
          }

          &-dot {
            border-radius: 2px;
          }
        }

        #{$fc}-list-event + #{$fc}-list-day {
          border-top: $fc-border;
        }
      }
    }
  }
}

// Full calendar variables override
:root {
  --fc-button-bg-color: var(--theme-color--ultraLightBlue);
  --fc-button-active-bg-color: var(--theme-color--blue);
  --fc-button-text-color: var(--theme-color--grey);
  --fc-bg-event-opacity: .7;
  --fc-bg-event-color: #ffffff;
}
