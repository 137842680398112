.offering-table-item {
  display: flex;
  align-items: center;

  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--bold);

  &__picture {
    width: 60px !important;
    height: 80px !important;
    border-radius: var(--theme-borderRadius--default);
    overflow: hidden;
  }

  &__info {
    margin-left: 16px;
  }

  &__title {
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blue);
  }

  &__offer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__offer-title {
    font-size: 12px;
    line-height: 1.33;
    text-transform: uppercase;
    color: var(--theme-color--lightGrey);

    padding-left: 8px;
  }
}
