.time-selector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;

  &_item {
    display: grid;
    grid-template-columns: 1fr 220px;
    grid-column-gap: 8px;
    align-items: center;

    @media screen and (max-width: 575px) {
      grid-template: 1fr / 1fr;
      grid-row-gap: 8px;
    }
  }
  &_label {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.625;
    color: #272835;
  }

  .error-message {
    position: absolute;
    margin-top: 4px;
  }
}