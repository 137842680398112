$dw: '.dots-wrapper';
$md: '.menu-dot';
$ml: '.menu-list';


#{$dw} {
  position: relative;
  width: 24px;
  padding: 2px 4px;
  cursor: pointer;

  &:hover {
    #{$md} {
      background: var(--theme-color--blue);
    }
  }
}


#{$md} {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--theme-color--lightGrey);
  margin: 2px 0;
  transition: background 0.2s;
}


#{$ml} {
  position: absolute;
  top: 24px;
  right: 12px;
  z-index: 5;

  min-width: 140px;
  background: var(--theme-color--white);
  border-radius: 4px;
  white-space: nowrap;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);

  &__item {
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--grey);

    padding: 7px 16px;
    margin: 4px 0;

    &:hover {
      background: var(--theme-color--ultraLightBlue);
      cursor: pointer;
    }
  }
}
