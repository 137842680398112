.video-message-download {
  &__wrapper {
    .public-view-card--bordered .content {
      margin: 40px;

      @media screen and (max-width: 768px) {
        margin: auto;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 768px) {
      align-items: center;
      text-align: center;
    }
  }

  &--button-wrapper {
    display: flex;
    margin-top: 40px;

    .button {
      margin-right: 16px;
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
}
