

#{$m-m} {
  &__logo, &__mini-logo {
    @extend %main-theme-colors;
  }

  &__preferences,
  &__nav--item {
    @extend %theme-colors;

    svg {
      @extend %theme-colors-svg;
    }
  }
}


.personal-wrap {
  & > .dropdown {
    margin-left: 43px;
  }
}

.select-container {
  @include display-flex(null, center, center);
  flex: 1;

  svg {
    cursor: pointer;
    @extend %theme-colors-svg;
  }

  .dropdown {
    margin-left: 24px;
  }
}

.header-dropdown {
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: $font-family-default;
  font-weight: get-font-weight('bold');
  font-size: get-font-size('s-3');
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  outline: none;

  &-content {
    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: get-font-size('s-3');
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background: #ffffff;

    li {
      margin: 5px 0;
      cursor: pointer;
    }
  }
  svg { margin-left: 4px;
  }
}

.auth-buttons-wrap {
  @include display-flex(null, center);
  margin-right: 12px;

  .login {
    margin-right: 10px;
    @include size(110px, 36px);
  }
}

.sign-up {
  position: relative;
  &__button {
    .button {
      &__item {
        flex-direction: row-reverse;
      }
      &__text {
        margin-right: 5px;
      }
      &__icon {
        margin: 0;
        svg path {
          fill: white;
        }
        &.on {
          transition: all .3s ease;
          transform: rotate(180deg);
        }
      }
    }
  }

  &__content {
    position: absolute;
    top: 120%;
    left: -30%;
    padding: 2px 10px;
    @include size(202px, 104px);
    background: #ffffff;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    @include display-flex(column, center, space-around);
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none;
    transition: all .3s ease-in-out;
    &.on {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }
  }
}

header {
  @media (max-width: 480px) {
    .sign-up-dropdown {
      display: none;
    }

    .main_menu__personal .auth-buttons-wrap {
      .login {
        display: none;
      }
    }
  }

  @media #{$dt-m} {
    .personal-wrap {
      & > .dropdown {
        margin-left: 12px;
      }
    }

    .auth-buttons-wrap {
      .login.button-stroke {
        padding: 7px 18px;
      }
    }

    .select-container {
      .dropdown {
        margin-left: 12px;
      }
    }
  }
  @media #{$md} {
    .select-container {

      .dropdown {
        display: none;
      }
    }
  }
}
