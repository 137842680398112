:root {
  --slider-section-card-text__font-size: 16px;
}

@import 'styles/_legacy/abstracts/_mixins.scss';

.slider-section__card-text {
  --bottom-offset: 0;

  &.video {
    --bottom-offset: 75px;
  }

  // TODO: fix video controls overlaping
  z-index: 4;

  position: absolute;
  padding: 16px;
  bottom: var(--bottom-offset);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: #ffffff;
  text-decoration: none;
  font-family: Playfair Display;
  font-size: var(--slider-section-card-text__font-size);

  .first-name {
    font-weight: normal;
    font-size: 1.6em;
    line-height: 1.2em;

    letter-spacing: -0.01em;
    text-transform: capitalize;
    font-feature-settings: 'case';
    @include lineClamp();
  }

  .last-name {
    margin-bottom: 12px;

    font-weight: 900;
    font-size: 1.8em;
    line-height: 1.4em;
    font-feature-settings: 'case' on, 'liga' off;
    @include lineClamp();
  }

  .name {
    margin-bottom: 12px;
    max-width: 100%;

    font-weight: 900;
    font-size: 1.2em;
    line-height: 1.3em;
    font-feature-settings: 'case' on, 'liga' off;
    text-align: center;
  }

  .date {
    margin-bottom: 12px;
    font-size: 0.8em;
    line-height: 1.2em;
    font-weight: 700;
  }

  @media screen and (max-width: 1366px) {
    padding: 8px;

    &.video {
      --bottom-offset: 45px;
    }

    .name {
      font-size: 1em;
    }

    .date {
      font-size: 0.8em;
    }
  }

  @media screen and (max-width: 668px) {
    &.video {
      --bottom-offset: 24px;
    }
  }

  @media screen and (max-width: 320px) {
    &.video {
      --bottom-offset: 30px;
    }
  }
}

// TODO: move to the common styles
.clamp-long-word {
  word-break: break-all;
  text-align: center;
}
