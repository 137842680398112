.public-view__taboos {
  position: relative;
  margin-top: 24px;
  border: 1px solid #272835;
  border-top: transparent;
  width: 100%;

  .top {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
  }

  .line {
    height: 1px;
    background-color: #272835;

    &:first-of-type {
      flex-grow: 1;
      flex-shrink: 1;
      @media screen and (max-width: 575px) {
        flex-grow: 1;
      }
    }

    &:last-of-type {
      flex-grow: 14;
      flex-shrink: 1;
      @media screen and (max-width: 575px) {
        flex-grow: 1;
      }
    }
  }

  .title {
    font-family: 'Playfair Display', sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 1;
    color: #272835;
    font-feature-settings: 'case' on, 'liga' off;
    margin: 0 15px;
    padding-bottom: 12px;
    @media screen and (max-width: 575px) {
      font-size: 24px;
      margin: 0 20px;
    }
    @media screen and (max-width: 425px) {
      font-size: 24px;
      margin: 0 10px;
    }
  }

  &_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px 16px;
    padding: 0 16px 16px;
    margin-top: -12px;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }

    li {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.625;
      color: #626473;
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        //transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background-color: #272835;
        border-radius: 50%;
      }
    }
  }
}
