$lbw: '.lesson-backdrop-wrapper';

#{$lbw} {

  width: 100%;
  height: 100%;
  border-radius: var(--theme-borderRadius--default);
  border: var(--theme-border--default);
  background: transparent;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  i {
    margin-bottom: 14px;
    font-size: 36px;
    color: var(--theme-color--lightGrey);
  }

  h3 {
    font-family: var(--theme-fontFamily--default), sans-serif;
    font-weight: var(--theme-fontWeight--bold);
    color: var(--theme-color--white);
    font-size: 16px;
    line-height: 1.625;
  }

  p {
    font-family: var(--theme-fontFamily--default), sans-serif;
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;

    color: var(--theme-color--lightGrey);
  }
  
  &:hover {
    color: var(--theme-color--white);
  }
}