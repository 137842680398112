$careers-info-label-fs-dt: 220px;
$careers-info-label-fs-dt-m: 180px;
$careers-title-mb: 40px;
$careers-description-mt: 20px;

$careers-form-wrap: 680px;
$careers-grid-gap: 34px;

$careers-title-mb: 35px;

$careers-list-title-pb: 24px;
$careers-list-top-mb: 20px;

$careers-from-mt: 128px;
$careers-from-mb: 95px;
$careers-from-pr: 100px;

.careers {
  &__heading {
    font-family: $font-family-primary;
    font-weight: get-font-weight('black');
    font-size: get-font-size('l-1');
    line-height: 75px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: get-colour('blue');
    margin-bottom: $careers-title-mb;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $careers-grid-gap;

    &__top {
      position: relative;
      margin-bottom: $careers-list-top-mb;
      span {
        font-family: $font-family-primary;
        font-weight: get-font-weight('bold');
        font-size: $careers-info-label-fs-dt;
        line-height: $careers-info-label-fs-dt;
        text-align: center;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off, 'case' on;
        color: get-colour('dark-grey');
        opacity: 0.08;
        display: block;
      }

      h4 {
        font-family: $font-family-primary;
        font-weight: get-font-weight('black');
        font-size: get-font-size('l-2');
        line-height: 54px;
        text-align: center;
        font-feature-settings: 'case' on, 'liga' off;
        color: get-colour('blue');

        @include position-absolute(28%);
        @include position-absolute(auto, auto, $careers-list-title-pb, auto);
        width: 100%;
      }
    }
    &__item {
      p {
        font-family: $font-family-default;
        font-weight: get-font-weight('medium');
        font-size: get-font-size('m-4');
        line-height: get-spacer('6');
        text-align: center;
        color: get-colour('dark-grey');
      }
    }

    @media #{$dt-m},
      #{$md} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);

      &__item {
        span {
          font-size: $careers-info-label-fs-dt-m;
          line-height: $careers-info-label-fs-dt-m;
        }

        h4 {
          font-size: get-font-size('l-2');
          line-height: 48px;
        }

        p {
          font-size: get-font-size('s-1');
          line-height: get-spacer('6');
        }
      }
    }

    @media #{$sm} {
      //grid-template-columns: repeat(1, 1fr);
      display: flex;
      align-items: center;
      flex-direction: column;

      &__top {
        margin-bottom: 24px;
      }

      &__item {
        span {
          font-size: $careers-info-label-fs-dt-m;
          line-height: $careers-info-label-fs-dt-m;
        }

        h4 {
          font-size: get-font-size('l-2');
        }

        p {
          font-size: get-font-size('m-5');
          line-height: get-spacer('6');
        }
      }
    }
  }

  &-form {
    margin-top: $careers-from-mt;
    margin-bottom: $careers-from-mb;

    @media #{$sm} {
      margin-top: 64px;
      margin-bottom: 32px;
    }

    &__wr {
      margin-top: get-spacer('9');
      padding-right: $careers-from-pr;
      .form-row {
        justify-content: flex-end;
      }
      @media #{$md} {
        padding-right: 0;
        margin-top: 64px;
      }
    }

    &__title {
      font-family: $font-family-default;
      font-weight: get-font-weight('medium');
      font-size: get-font-size('m-4');
      line-height: get-spacer('6');
      text-align: center;
      color: get-colour('blue');
      margin: auto;
      max-width: $careers-form-wrap;
    }

    &__file {
      height: 60px !important;
      .loader {
        display: none;
      }
    }

    &__submit {
      button {
        height: 50px;
        width: 160px;
      }
    }
  }
}

.upload-hint-file {
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 16px;
  padding-right: 16px;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
  }

  &__file-name {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
    margin-left: 12px;
  }
}
