// TODO: cleanup useless styles after calendaer components structure refactor
$cs: '.calendar-slot';
$fc: '.fc';

#{$cs} {
  display: flex;
  flex-direction: column;
  height: 100%;

  #{$cs}__locked {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#{$cs}__info {
  overflow: hidden;
  flex: 1;
  padding: 4px;

  &__title {
    font-weight: var(--theme-fontWeight--bold);
  }

  &__desc {
    letter-spacing: -0.1px;
  }
}


//
// .fc override
//
#{$fc}-list {
  #{$cs}__info__desc {
    display: none;
  }

  #{$cs}__info {
    padding: 0;
  }
}