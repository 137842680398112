.color-section {
  border-radius: 2px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  display: block;

  margin-right: 12px;
}

.select-option {
  display: flex !important;
  flex-direction: row;
  align-items: center !important;
  justify-content: flex-start;
  position: relative;

  opacity: 1;
  cursor: pointer;

  &--disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &:hover {
    background: var(--theme-color--ultraLightBlue);
  }

  &.react-select__option {
    padding: 12px 7px;
    cursor: pointer;
    transition: all .3s linear;

    background-color: var(--theme-color--white);
    color: var(--theme-color--grey);
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    line-height: 1.5;

    &--is-selected {
      color: var(--theme-color--blue);
      font-weight: var(--theme-fontWeight--bold);
      background-color: var(--theme-color--ultraLightBlue);
    }

    &--is-focused {
      background-color: var(--theme-color--ultraLightBlue) !important;
    }
  }
}

.select-single-value {
  display: flex !important;
  align-items: center !important;
}
