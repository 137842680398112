.offering-card {
  position: relative;
  display: block;
  width: 300px;
  height: 400px;
  margin-top: 22px;
  @media screen and (max-width: 991px) {
    margin-top: 0;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 575px) {
    width: 270px;
    height: 360px;
  }
  &:hover {
    &:after { height: 100%;
    }
    &:before { transform: translate(-50%, 0);
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
  }
  &:before {
    width: 140px;
    height: 184px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.35);
    transition: transform .5s ease-in-out;
  }
  &:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(39, 40, 53, 0) 0%,
    rgba(39, 40, 53, 0) 31.56%, rgba(39, 40, 53, 0.15) 46.15%,
    rgba(39, 40, 53, 0.6) 69.06%, rgba(39, 40, 53, 0.6) 100%);
    transition: height .3s ease-in-out;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e9eaf0;
    img {
      object-fit: cover;
    }
  }
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
  }
  &__logo {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%) scale(1.45);
  }
  &__top {
    position: absolute;
    top: 10px;
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__offering {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.4));
  }
  &__save {
    margin-left: auto;
    width: 24px;
    z-index: 3;
    padding: 0 !important;
    box-shadow: none !important;
  }
  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px 12px;
  }
  &__fullName {
    width: 100%;
    margin-top: auto;
    &_name,
    &_lastname {
      margin: 0;
      font-family: 'Playfair Display', sans-serif;
      font-feature-settings: 'case' on, 'liga' off, 'lnum' on;
      color: #ffffff;
      line-height: 1.4;
      word-break: break-all;
      text-align: center;
    }
    &_name {
      font-size: calc(75% + 1vw);
      letter-spacing: -0.01em;
    }
    &_lastname {
      font-size: calc(75% + 1vw);
      font-weight: 900;
      font-feature-settings: 'case' on, 'liga' off, 'lnum' on;
    }
  }
  &__offers {
    font-family: 'Playfair Display', sans-serif;
    font-feature-settings: 'case' on, 'liga' off, 'lnum' on;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.4;
    color: #ffffff;
    margin: 8px 0;
    max-height: 70px;
    height: 100%;
    overflow: hidden;
    text-align: center;
    -webkit-line-clamp: 3;
  }
  &__date {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    color: #ffffff;
  }
  &__price {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
  }
  &__newprice {
    color: #ffffff;
    ._price__currency {
      font-size: 12px;
    }
  }
  &__oldprice {
    position: relative;
    color: var(--theme-color--blueText);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background-color: var(--theme-color--blueText);
    }
    ._price__value {
      font-size: 16px;
    }
    ._price__currency {
      font-size: 12px;
    }
  }
  &__friends {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.625;
    color: #ffffff;
  }
  &__name-offering {
    font-family: 'Playfair Display', sans-serif;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.4;
    font-feature-settings: 'case' on, 'liga' off;
    color: #ffffff;
    margin-top: 12px;

    &--center {
      text-align: center;
    }
  }
}
