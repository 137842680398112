$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;


.join-modal {
  background-color: var(--theme-color--white);
  border-radius: var(--theme-borderRadius--modal);
  box-shadow: var(--theme-boxShadow--modal);

  width: 944px;
  height: 500px;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: #{$media-dt}) {
    width: 800px;
  }

  @media screen and (max-width: #{$media-lg}) {
    width: 600px;
  }

  @media screen and (max-width: #{$media-sm}) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  &__header {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 32px;
    line-height: 1.375;
    text-align: center;
    font-feature-settings: 'case' on, 'liga' off;
    color: var(--theme-color--blue);

    padding-top: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--theme-color--ultraLightBlue);
  }
}

.join-table {
  overflow: hidden;
  height: 100%;

  &__row {
    display: grid;
    grid-template-columns: 7fr 10fr 3fr 2fr;
    column-gap: 16px;
    align-items: center;

    padding: 12px 40px;
    border-bottom: 1px solid var(--theme-color--ultraLightBlue);

    &--header {
      @media screen and (max-width: #{$media-sm}) {
        display: none;
      }
    }

    &--body {
      @media screen and (max-width: #{$media-sm}) {
        width: 100%;
        grid-template-columns: 1fr;
        padding: 12px 18px;
        margin-top: 10px;

        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
      }
    }
  }

  &__label {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--theme-color--lightGrey);

    &--center {
      text-align: center;

      @media screen and (max-width: #{$media-sm}) {
        text-align: start;
      }
    }
  }

  &__body {
    height: 100%;
    overflow-y: auto;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 3px;
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
    }
    &:after {
      content: '';
      display: block;
      flex-shrink: 0;
      height: 40px;
    }
  }

  &__row-cell {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--grey);

    &--center {
      text-align: center;

      @media screen and (max-width: #{$media-sm}) {
        text-align: start;
      }
    }

    span,
    strong {
      display: block;
    }

    strong {
      font-weight: var(--theme-fontWeight--bold);
      color: var(--theme-color--blue);
    }

    @media screen and (max-width: #{$media-sm}) {
      margin-top: 16px;
    }

    .join-table__label {
      display: none;

      @media screen and (max-width: #{$media-sm}) {
        display: block;
      }
    }
  }
}
