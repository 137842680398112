.keyword-search {
  background-image: url('../../../assets/images/search-bg.png');
  background-size: cover;
  height: 100%;
  padding-top: 0;
  min-height: 100vh;

  &__wrapper {
    margin: 0 auto;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 24px auto;

    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }

  &__input {
    height: 80px;
    background: transparent;
    font-weight: var(--theme-fontWeight--medium);
    font-size: 21px;
    line-height: 2em;
    color: var(--theme-color--ultraLightBlue);
    margin-left: 40px;
    width: 100%;

    &-wrapper {
      height: 100px;
      border-bottom: 2px solid rgba(233, 234, 240, 0.2);
      display: flex;
      align-items: center;
      padding: 0 24px;
    }
  }

  &__svg-button {
    cursor: pointer;

    path {
      transition: fill 0.2s;
      fill: var(--theme-color--lightGrey);
    }

    &:hover {
      path {
        fill: var(--theme-color--ultraLightBlue);
      }
    }
  }

  &__categories {
    font-weight: var(--theme-fontWeight--medium);
    font-size: 14px;
    line-height: 1.375em;
    color: var(--theme-color--lightGrey);
    padding-left: 24px;

    &--link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: white;
      }
    }

    @media only screen and (max-width: 450px) {
      padding-left: 0;
    }
  }

  &__list-item {
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625em;
    color: var(--theme-color--blueText);
    min-width: 220px;

    em {
      font-weight: var(--theme-fontWeight--bold);
      color: var(--theme-color--white);
    }

    small {
      color: var(--theme-color--grey);
    }
  }

  &__list {
    &--item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 24px;
      max-width: 600px;
      cursor: pointer;
      overflow-wrap: anywhere;

      > svg {
        min-width: 48px;
      }

      &:hover {
        background: #1d1e2b;
        border-radius: 24px;
      }

      p {
        font-weight: var(--theme-fontWeight--medium);
        font-size: 16px;
        line-height: 1.625em;
        color: var(--theme-color--blueText);
        margin-left: 40px;
        width: 100%;

        span {
          color: #babbcd4a;
        }
      }

      @media only screen and (max-width: 450px) {
        padding: 10px 0;

        p { margin-left: 16px;
        }
      }
    }
    
    &--list {
      margin-top: 24px;
    }
  }

  .text-highlighted {
    font-weight: var(--theme-fontWeight--bold);
    color: var(--theme-color--white);
  }

  &__aggregation-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, 220px);
    grid-gap: 48px;
    flex-grow: 1;
    justify-content: end;

    @media screen and (max-width: 575px) {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
    }
  }

  &__no-result {
    display: flex;
    margin-top: 24px;
    
    @media screen and (max-width: 414px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__no-result-icon {
    flex-shrink: 0;
    margin: 10px 38px;

    @media screen and (max-width: 414px) {
      width: 60px;
      height: 60px;
      margin: 24px;
    }
  }

  &__no-result-text {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--lightGrey);

    strong {
      color: var(--theme-color--blueText);
      font-weight: var(--theme-fontWeight--semiBold);
    }

    a {
      color: var(--theme-color--white);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}