@import '~react-datepicker/dist/react-datepicker.css';

.error {
  .react-datepicker__input-container {
    input {
      border-color: red !important;
    }
  }
}

.react-datepicker {
  border: none;
  box-shadow: 0 2px 23px #ededed;
  border-radius: 6px;

  padding-top: 18px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  @media only screen and (max-width: 425px) {
    padding: 10px 15px;
  }

  &-popper {
    z-index: var(--theme-zIndex--12);
  }

  &-wrapper {
    width: 100%;
  }

  &__header {
    background: white;
    border: none;
  }

  &__current-month {
    text-align: start;
    padding-left: 88px;

    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 21px;

    color: #626473;

    @media screen and (max-width: 575px) {
      padding-left: 12px;
      text-align: center;
    }
  }

  &__month {
    margin: 0;
  }

  &__day {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #9495a7;

    margin-bottom: 10px;
    margin-right: 0;

    padding: 6px;
    border-radius: 0;

    width: 48px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 425px) {
      width: 38px;
      height: 26px;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    &--keyboard-selected {
      background-color: white;
      color: #9495a7;
    }

    &--disabled {
      opacity: .5;
    }

    &-name {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: #9495a7;

      margin-right: 0;
      margin-bottom: 6px;

      width: 48px;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 425px) {
        width: 38px;
        height: 26px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-names {
      padding-top: 16px;
    }

    &--outside-month {
      color: #babbcd;
    }

    &--in-range {
      background: #9495a7;
      color: white;
      border-radius: 0;

      &:hover {
        background: #e9eaf0;
        color: white;
      }
    }

    &--in-selecting-range {
      background: #9495a7;
      color: white;

      &:hover {
        background-color: #e9eaf0;
        color: white;
      }
    }

    &--range-start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &--range-end {
      // border-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    &--selected {
      background: #9495a7;
      color: white;
      // border-radius: 50%;

      &:hover {
        background: #e9eaf0;
        color: white;
      }
    }
  }

  &__triangle {
    // remove triangle
    display: none;
  }

  &__navigation {
    width: 30px;
    height: 30px;

    background: linear-gradient(
      309.69deg,
      #636583 17.05%,
      #272835 69.2%
    ) !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
      inset 0 4px 25px rgba(0, 0, 0, 0.45);
    border-radius: 4px;
    border: none;
    top: 19px;

    &::after {
      // add navigation arrow
      content: '';

      display: block;
      border: 2px solid white;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);

      position: absolute;
      top: 10px;
    }

    &--previous {
      left: 310px;
      @media only screen and (max-width: 425px) {
        left: auto;
        right: 60px;
      }

      &::after {
        border-right: none;
        border-top: none;

        left: 12px;
        transform: rotate(45deg);
      }
    }

    &--next {
      right: 35px;
      @media only screen and (max-width: 425px) {
        left: auto;
        right: 20px;
      }

      &::after {
        border-left: none;
        border-bottom: none;

        left: 6px;
      }
    }

    @media only screen and (max-width: 425px) {
      top: 13px;
    }
  }

  &__input-container {
    width: 100%;
    input {
      @extend .input;
      @extend .input--white;
      padding: 0 18px;
      height: 60px;

      &:focus {
        border-color: black;
      }
    }
    
    .form-group {
      position: relative;
      input {
        cursor: default;
      }

      &:after {
        content: '';
        display: block;
        width: 6px;
        height: 6px;

        position: absolute;
        top: calc(50% - 4px);
        right: 18px;

        border: 2px solid get-colour('blue-text');
        border-right: 0;
        border-bottom: 0;
        pointer-events: none;
        transform: rotate(-135deg);
        transition: all .1s linear;
      }

      &--rotate {
        &:after {
          transform: rotate(45deg);
          border-color: get-colour('blue');
        }
      }
    }
  }

  &__year {
    &-dropdown {
      background: white;
      padding: 6px 40px;
      left: -20%;
      top: 4px;

      &-container {
        position: absolute;
        top: 2px;
        left: 0;

        text-align: start;
        padding-left: 8px;
      }
    }

    &-option {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 13px;
      line-height: 22px;
      color: #626473;
    }

    &-read-view {

      &--selected-year {
        font-family: Montserrat;
        font-weight: 700;
        font-size: 21px;
        line-height: 32px;

        color: #626473;
      }

      &--down-arrow {
        top: 10px;
        margin-left: 4px;
      }
    }
  }

  &__navigation--years {
    top: auto;
    width: 30px;
    height: 16px;

    &-upcoming {
      &::after {
        border-bottom: none;
        border-right: none;
        left: 10px;
        top: 7px;
      }
    }

    &-previous {
      &::after {
        border-top: none;
        border-left: none;
        left: 10px;
        top: 2px;
      }
    }
  }

  &--time-only {
    padding: 0;
  }

  &__time-list {
    &-item {

      &--selected {
        background-color: get-colour('light-grey') !important;
      }
    }

    &::-webkit-scrollbar {
      width: 3px !important;
      background: #f1f1f1 !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #c4c4c4 !important;
    }
  }
}

.range-datepicker {
  .react-datepicker__input-container {
    input {
      caret-color: transparent;
      cursor: pointer;
    }
  }
}

.datepicker-wrapper {
  position: relative;


  .react-datepicker__header--time {
    display: none;
  }

  &__icon {
    position: absolute;
    top: 20px;
    right: 10px;
    path {
      fill: get-colour('light-grey');
    }
  }

  .react-datepicker--time-only {
    width: 100%;
    box-shadow: 0 2px 6px #ededed;

    .react-datepicker__time-container {
      width: 100%;

      .react-datepicker__time-list-item {
        width: 100%;
        text-align: start;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #626473;
        height: 40px !important;
        display: flex;
        align-items: center;

        &--selected {
          color: #626473 !important;
          background-color: #ffffff !important;
        }
      }
    }
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
  }

  .react-datepicker--time {
    .react-datepicker__time-box {
      width: 100%;
    }
  }
}

.form-group {
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;

    position: absolute;
    top: 26px;
    right: 18px;

    border: 2px solid get-colour('blue-text');
    border-right: 0;
    border-bottom: 0;
    pointer-events: none;
    transform: rotate(-135deg);
    transition: all .1s linear;
  }

  &--rotate {
    &:after {
      transform: rotate(45deg);
      border-color: get-colour('blue');
    }
  }
}
