.register {
  // display: flex;
  flex-direction: column;
  padding: 0 0 72px;

  .mobile-header,
  .mobile-sign-in-header {
    display: none;
  }
  h6 {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 900;
    font-size: 23px;
    line-height: 29px;

    /* identical to box height, or 126% */

    text-align: center;
    font-feature-settings: 'case' on, 'liga' off;

    /* light grey */

    color: #9495a7;
    margin: 90px 0 10px;
  }

  h1 {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 75px;

    /* identical to box height, or 125% */

    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

    /* blue */

    color: #272835;
    margin-bottom: 10px;
  }

  .data-field {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: flex-start;

    &:nth-child(5) {
      align-items: center;
    }

    &:nth-child(6) {
      align-items: center;
    }

    &.terms {

      .label {
        width: 290px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        a {
          display: block;
          border-bottom: 1px dashed #272835;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 22px;

          color: #272835;
          cursor: pointer;
          width: 72px;
        }
      }
    }
    .new-user {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;

      /* identical to box height, or 114% */

      display: flex;
      justify-content: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* blue */

      color: #272835;
      text-decoration: none;

      margin-top: 32px;
    }
    .profile-picture-block,
    .category-block {
      display: flex;
      flex-direction: column;
    }

    .field-content {
      max-width: 560px;

      &.textarea-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .max-characters {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 22px;

          /* identical to box height, or 169% */

          display: flex;
          align-items: center;
          text-align: right;

          /* blue */

          color: #272835;
          margin-top: 9px;
        }
      }
    }

    .label-mobile {
      display: none;
    }

    .error-message {
      color: red;
      font-family: Montserrat;
    }

    label {
      position: relative;
      display: flex;
      flex-direction: column;
      .error-message {
        position: absolute;
        bottom: -21px;
      }
    }
    .label {
      font-family: Playfair Display;
      font-style: normal;
      font-weight: 900;
      font-size: 23px;
      line-height: 29px;

      /* identical to box height, or 126% */

      text-align: right;
      font-feature-settings: 'case' on, 'liga' off;

      /* blue */

      color: #272835;
      margin-right: 73px;
      width: 295px;
    }

    .label.profile-type {
      width: 350px;
      margin-right: 73px;
    }
    .label.profile-manager {
      width: 365px;
      margin-right: 76px;
    }
    .field-content.radio-wrap {
      display: flex;
      min-width: 623px;
      max-width: unset;

      .company {
        width: 128px;
      }
      .self {
        width: 74px;
      }

      .agent {
        width: 97px;
      }
      .representative {
        width: 272px;
      }
      & > div {
        height: 45px;
        box-sizing: border-box;
        margin-right: 20px;
        padding: 7px 18px;
        cursor: pointer;
        background: #e9eaf0;
        border-radius: 4px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;

        /* identical to box height, or 178% */

        display: flex;
        align-items: center;
        text-align: center;

        /* grey */

        color: #626473;

        &.active {
          background: #272835;
          color: #ffffff;
        }
        span {
          font-size: 12px;
        }
      }
    }

    &.required-wrap {
      .label {
        min-width: 285px;
      }

      .field-content {
        width: 561px;
      }

      .required {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;

        /* identical to box height, or 169% */

        display: flex;
        align-items: center;
        text-align: center;

        /* blue */

        color: #272835;

        @media (max-width: 480px) {
          padding-left: 12px;
        }
      }
    }

    .phone-verification-box {
      width: 561px;
      display: flex;
      justify-content: space-between;

      input {
        width: 350px;
      }

      @media (max-width: 480px) {
        width: 100%;
        flex-direction: column;
        align-items: center;

        input {
          width: 100%;
        }

        .verify-button {
          width: 100%;
        }
      }


      button {
        line-height: 38px;
      }

      .button-gradient-wrapper {
        margin: 0;
      }

      &:nth-last-child(2),
      &:last-child {
        margin-top: 50px;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }



  .profile-picture-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 561px;

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: center;
    }

    & > img {
      height: 100%;
      width: 100%;
    }
    p {
      width: 100%;
      margin-top: 30px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;

      /* or 162% */


      /* grey */

      color: #626473;
    }

    .profile-photo-example {
      display: flex;
      flex-direction: column;
      margin-left: 65px;

      img {
        width: 212px;
        height: 311px;
        object-fit: cover;
        border: 2px solid #babbcd;
      }

      span {
        margin-top: 20px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;


        /* blue */

        color: #272835;
        text-align: center;
      }
    }
  }

  .profile-picture-preview {
    width: 258px;
    height: 350px;

    border: 2px solid #babbcd;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #9495a7;

    p {
      text-align: center;
      max-width: 150px;
      margin-top: 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #9495a7;
    }

    i {
      font-size: 32px;
      color: #9495a7;
      margin-top: 45px;
    }
    img,
    video {
      height: 100%;
      width: 100%;
    }

    h6 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;

      /* or 138% */

      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      /* light grey */

      color: #9495a7;

      margin: 10px 0 30px;
      max-width: 23%;
    }
  }


  .register-input {
    padding: 0 18px;
    box-sizing: border-box;
    height: 60px;
    width: 561px;
    border-radius: 4px;
    border: 2px solid #babbcd;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    // background: #E5E5E5;

    /* identical to box height, or 178% */

    display: flex;
    align-items: center;

    /* light grey */

    color: #9495a7;
    &.error {
      border: 2px solid red;
    }
  }

  .category-dropdown {
    .select__menu {
      border: 0;
      height: unset;
    }
  }
  .category-dropdown > div {
    padding: 0 18px;
    box-sizing: border-box;
    height: 60px;
    width: 561px;
    border-radius: 4px;
    border: 2px solid #babbcd;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;

    /* identical to box height, or 178% */

    display: flex;
    align-items: center;

    /* light grey */

    color: #9495a7;
    &.error {
      border: 2px solid red;
    }
    // background: #E5E5E5;
  }
  .multiple-profile-pictures {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 561px;

    @media (max-width: 480px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 338px;

      .picture-wrap {
        justify-self: center;
      }

      // flex-direction: column;
      // align-items: center;
    }
  }
  .picture-wrap {
    outline: none;
    margin: 0 auto;
    width: 128px;
    height: 128px;

    &:first-child {
      padding-left: 0;
      margin-left: 0;
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;
    }


    & > div {
      margin: 0 auto;
      width: 116px;
      height: 116px;

      border: 2px solid #babbcd;
      box-sizing: border-box;
      border-radius: 50%;
      display: flex;

      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 18px;

      /* identical to box height, or 164% */

      text-transform: uppercase;

      /* light grey */

      color: #9495a7;

      cursor: pointer;
      outline: none;
      overflow: hidden;

      i {
        font-size: 27px;
        margin-bottom: 7px;
      }
      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }


    & > span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 22px;

      /* identical to box height, or 200% */

      text-align: center;

      /* grey */

      color: #626473;
      margin-top: 4px;
      display: block;
    }

    &:nth-child(2) {
      margin-left: 12px;
    }
  }

  .licence-wrap {
    width: 561px;
    height: 230px;

    /* blue text */

    border: 2px solid #babbcd;
    box-sizing: border-box;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .documents-wrap {
      display: flex;
      flex-direction: column;
    }
    &.borderHidden {
      // border: none;
      align-items: flex-start;
      justify-content: flex-start;
    }
    i {
      font-size: 25px;
      color: #333333;
    }

    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;

      /* identical to box height, or 162% */

      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;

      /* blue */

      color: #272835;

      margin-top: 16px;
    }

    p {
      margin-top: 32px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;

      /* identical to box height, or 162% */

      display: flex;
      align-items: center;
      text-align: center;

      /* light grey */

      color: #9495a7;
    }
  }

  .terms-wrap {
    margin-top: 34px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;

    /* or 169% */


    /* blue */

    color: #272835;

    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      /* or 169% */


      /* blue */

      color: #272835;

      a {
        font-weight: 700;
        text-decoration: underline;
        font-size: 14px;
      }
    }

    @media(max-width: 480px) {
      .checkmark {
        top: 5px;
        left: 400px;
      }
    }
  }

  button[type=submit],
  .verify-button {
    font-size: 16px;
    line-height: 30px;
    outline: none;
    &:disabled {
      cursor: not-allowed;
    }
  }

  .button-gradient-wrapper {
    margin-top: 40px;
    margin-right: auto;

    @supports (-webkit-appearance:none) {
      padding: 2px;
    }

    .button-gradient {
      min-width: 150px;
    }
  }

  .multi-select {
    width: 100%;

    .select__control {
      border: 2px solid #babbcd;
      height: 60px;

      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #9495a7;
    }
  }

  .calendar-wrap {
    font-family: Montserrat;
    max-width: 561px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    /* or 162% */


    /* grey */

    color: #626473;

    p {
      margin-top: 18px;
    }
  }

  .processing-consent {
    width: 561px;
    display: flex;
    flex-direction: column;

    p,
    a {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;

      /* or 162% */


      /* grey */

      color: #626473;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .signature-wrap {
    display: flex;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    /* identical to box height, or 162% */


    /* grey */

    position: relative;

    color: #626473;

    input {
      width: 262px;
      margin-left: 10px;
    }

    .error-message {
      right: 60px;
      bottom: -24px;
    }
  }

  .charities-wrap {
    .charity-wrap {
      display: flex;
      justify-content: space-between;
      width: 561px;
      margin-bottom: 20px;
    }
    & > button {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;

      /* identical to box height, or 162% */

      display: flex;
      align-items: center;
      text-transform: capitalize;

      color: #000000;
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;
    }

    .chips {
      display: flex;
      flex-wrap: wrap;
    }

    .chip {
      margin: 8px;
    }
  }
  .languages-wrap {
    .language-wrap {
      display: flex;
      justify-content: space-between;
      width: 561px;
      margin-bottom: 20px;

      @media (max-width: 480px) {
        width: 100%;
      }

      // & > div {
      //     width: 262px;
      // }

      .register-input {
        width: 262px;
      }

      & > div {
        width: 262px;
      }
      & > div > div {
        width: 100%;


        & > div {
          border: 2px solid #babbcd;
        }
      }

      .select__control {
        margin: 0;
      }
    }

    & > button {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;

      /* identical to box height, or 162% */

      display: flex;
      align-items: center;
      text-transform: capitalize;

      color: #000000;
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;
    }
  }

  @media (max-width: 480px) {
    overflow: hidden;
    padding: 0 0 64px;

    .mobile-header {
      display: block;

      h1 {
        display: block;
      }
    }
    label {
      height: unset;
      padding: 12px;
      padding-top: 0;
      width: 100%;

      .field-content {

        > div {
          width: 100% !important;
        }
      }
    }

    textarea {
      width: 100%;
    }
    .data-field {
      margin-top: 18px;

      label {
        input {
          margin: auto;
        }
      }


      .calendar-wrap {
        max-width: 100%;

        p {
          margin-top: 30px;
        }
      }

      .languages-wrap {


        &>span {
          width: 100%;
          margin: 0 auto 4px;
        }

        .language-wrap {
          .register-input {
            width: 338px;
          }
        }

        button {
          width: 338px;
          margin: 0 auto;
        }
      }
      .label {
        display: none;
        height: unset;
      }

      .label-mobile {
        margin-bottom: 4px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;

        /* blue */

        color: #272835;
      }
      .field-content {



        &.radio-wrap {
          flex-direction: column;
          min-width: unset;

          & > div {
            margin: 10px 0 0;
            width: 338px;
            justify-content: center;
          }
        }
      }

      .register-input {
        width: 100%;
      }


      .profile-picture-wrap {
        justify-content: center;
        .profile-photo-example {
          margin: 20px 0 0;
          align-items: center;
        }
        p {
          width: 450px;
        }
      }

      .category-dropdown {
        & > div {
          width: 338px;
        }
      }

      .charities-wrap {
        & > div {
          width: 100%;
          padding-left: 0;
        }
      }

      .language-wrap {
        flex-direction: column;
        align-items: center;
      }

      .signature-wrap {
        flex-direction: column;
      }

      button[type=submit] {
        margin: 35px auto 0;
      }
    }

    .licence-wrap {
      width: 100%;
    }

    .upload-file-license {
      width: 100%;
      padding: 12px;
    }

    .processing-consent {
      width: 100%;
    }

    .terms {
      .label {
        display: none !important;
      }

      button {
        width: 100%;
      }
    }
  }
}

.register__signature-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 262px;
  }
}

.file-shot {
  width: 172px !important;
  height: 234px !important;
}

.upload-profile-pic {
  display: flex;
  flex-direction: column;

  &__photo {
    width: 258px;
    height: 350px;
    // border: 2px solid #BABBCD;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #9495a7;
  }

  &__wrapper {
    display: flex;
  }

  &__prev-pictures {
    @extend .font-caption;
    color: get-colour('dark-grey');
    margin-bottom: 12px;
  }

  .profile-photo-example {
    display: flex;
    flex-direction: column;
    margin-left: 65px;

    img {
      width: 212px;
      height: 311px;
      object-fit: cover;
      border: 2px solid #babbcd;
    }

    span {
      margin-top: 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;


      /* blue */

      color: #272835;
      text-align: center;
    }
  }

  .profile-photo-prev-photos-list {
    width: 212px;
    height: 311px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    // gap: 18px;

    .prev-photos-list-item {
      margin-right: 18px;
      margin-bottom: 18px;
    }

    .prev-photos-list-photo {
      width: 68px;
      height: 92px;
      background-size: cover;
      border: none;
      cursor: pointer;
    }
  }
}

.phone-verification-container {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 180px;
}

.talent-invitation {
  &-subheader {
    margin-top: 50px;
    margin-bottom: 10px;
    @extend .font-header-h4;
    text-align: center;
    color: #9495a7;
  }

  &-header {
    @extend .font-header-h1;
    color: #272835;
    margin-bottom: 61px !important;
  }
}

.charities-list {

  &__inner {
    @include display-flex(column);
  }

  &__item {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    &-wrapper {
      position: relative;
    }
  }
}


.head-shot-wrapper {
  position: relative;
  color: #9495a7;
  border: 2px solid #babbcd;
  height: 150px;
  width: 150px;
  border-radius: 50%;

  img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
  }
}

