.login-modal {
  width: 520px;
  height: 482px;
  @include display-flex(column);
  background-color: get-colour('white');
  border-radius: $modal-border-radius;
  @media screen and (max-width: 725px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  &__password-label {
    @include display-flex(row, center, space-between);
  }

  &__form {
    padding: 16px 40px 40px;
    @media screen and (max-width: 725px) {
      margin: auto 0 0;
    }

    &-header {
      font-family: $font-family-primary;
      font-weight: get-font-weight('black');
      font-size: 42px;
      line-height: 50px;
      text-align: center;
      color: get-colour('blue');
      margin-bottom: 24px;
    }
  }

  &__footer {
    flex: 1;
    @include display-flex(column, center, center);
    background: #e9eaf0;
    border-radius: 0 0 8px 8px;

    @media screen and (max-width: 725px) {
      margin-bottom: auto;
      flex: 0;
      background: transparent;
    }

    h6 {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;

      color: get-colour('light-grey');

      a {
        color: black;
      }
    }
  }
}

.modal-form-link {
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  text-align: right;
  border: none;
  background-color: transparent;
  padding: 0;

  color: get-colour('light-grey');

  &:disabled {
    cursor: not-allowed;
  }
}

.sign-in-link {
  @include display-flex(row, center, center);
  text-decoration: none !important;
  color: black;
  border: 2px solid black ;

  &:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }
}

.restore-modal {
  width: 520px;
  @include display-flex(column);
  background-color: get-colour('white');
  border-radius: $modal-border-radius;
  padding: 16px 40px 40px;

  &__form-header {
    font-family: $font-family-primary;
    font-weight: get-font-weight('black');
    font-size: 42px;
    line-height: 50px;
    text-align: center;
    color: get-colour('blue');
    margin-bottom: 24px;
  }

  @media screen and (max-width: 725px) {
    width: 100%;
  }
}
