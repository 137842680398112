$sb-step: '.status-bar-step';
$sb-v: '.status-bar--vertical';

$sb-step-size: 16px;
$sb-step-main-color: var(--theme-color--blue);
$sb-step-main-text-color: var(--theme-color--blue);

$sb-step-secondary-color: var(--theme-color--ultraLightBlue);
$sb-step-secondary-text-color: var(--theme-color--blueText);

#{$sb-step} {
  position: relative;
  margin: 40px 0;
  text-align: center;
  font-family: var(--theme-fontFamily--default);
  font-size: 14px;
  line-height: 1.3;

  &__marker {
    display: block;
    box-sizing: border-box;
    width: $sb-step-size;
    height: $sb-step-size;
    border-radius: 50%;
  }

  &#{&}--done {
    color: $sb-step-main-text-color;

    #{$sb-step}__marker {
      background-color: $sb-step-main-color;
    }
  }

  &#{&}--active {
    color: $sb-step-main-text-color;

    #{$sb-step}__marker {
      border: 4px solid;
      border-color: $sb-step-main-color;
      background-color: var(--theme-color--white);
    }
  }

  &#{&}--inactive {
    color: $sb-step-secondary-text-color;

    #{$sb-step}__marker {
      background-color: $sb-step-secondary-color;
    }
  }

  &__title,
  &__subtitle {
    position: absolute;
    left: $sb-step-size / 2;
    transform: translateX(-50%);
    margin: 4px 0;
    white-space: nowrap;
  }

  &__title {
    bottom: 100%;
    font-weight: var(--theme-fontWeight--bold);
  }

  &__subtitle {
    top: 100%;
    font-weight: var(--theme-fontWeight--normal);
  }

  &:first-child {
    #{$sb-step}__title,
    #{$sb-step}__subtitle {
      transform: translateX(calc(-#{$sb-step-size} / 2));
    }
  }

  &:last-child {
    #{$sb-step}__title,
    #{$sb-step}__subtitle {
      transform: translateX(calc(-100% + #{$sb-step-size} / 2));
    }
  }
}

#{$sb-v} {
  margin: 10px 0;
  
  #{$sb-step} {
    display: flex;
    text-align: left;
    margin: 10px 0;
    line-height: 16px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &__marker {
      order: 1;
    }

    &__title {
      order: 2;
    }

    &__subtitle {
      order: 3;
      border-left: 2px solid #dddddd;
      padding-left: 8px;
    }

    &,
    &:first-child,
    &:last-child {
      #{$sb-step}__title,
      #{$sb-step}__subtitle {
        position: relative;
        left: 0;
        transform: none;
        margin: 0 4px;
      }
    }
  }
}

