$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;


.tpvc {
  height: 100%;
  max-width: 920px;
  width: 80vw;
  background: #ffffff;

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__info {
    flex: 1;
    position: relative;
  }

  &__calendar {
    flex: 3;
    width: 100%;
  }

  @media screen and (max-width: #{$media-md}) {
    width: 100vw;

    &__wrapper {
      flex-direction: column-reverse;
    }

    .tpvc__info {
      position: unset;
    }
  }
}
