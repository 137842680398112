$dc: '.day-checkbox';
$dl: '.days-list';

#{$dc} {
  display: inline-block;
  height: 56px;
  width: 56px;

  &:last-child {
    margin-right: 0;
  }

  &__input {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;

    + #{$dc}__label {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;

      font-size: 16px;
      font-family: var(--theme-fontFamily--default);
      font-weight: var(--theme-fontWeight--medium);
      color: var(--theme-color--lightGrey);
      text-align: center;

      background: var(--theme-color--white);
      border: 2px solid var(--theme-color--blueText);
      border-radius: var(--theme-borderRadius--default);
      cursor: pointer;
    }

    &:checked {
      + label {
        border-color: var(--theme-color--blue);
        color: var(--theme-color--blue);
      }
    }
  }
}

#{$dl} {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin: -4px;

  & > #{$dc} {
    margin: 4px;
  }
}
