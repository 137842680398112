.library-modal {
  background-color: white;
  padding: 16px 40px 40px;

  @include display-flex(column);
  flex: 1;

  box-shadow: $modal-box-shadow;
  border-radius: $modal-border-radius;

  min-height: 484px;
  max-height: 940px;
  width: 510px;

  &__header {
    font-size: 32px;
    line-height: 1.375;
    margin-bottom: 24px;
  }

  &__media-wrapper {
    margin-bottom: 12px;
  }

  &__library {
    flex: 1;
    margin-bottom: 20px;
    margin-left: -40px; // for shadow
  }

  .media-uploader__inner {
    height: 103px;
  }

  // queries
  @media screen and (max-width: 1366px) {
    padding: 16px 24px 24px;
    width: 478px;
    max-height: 575px;

    &__header {
      margin-bottom: 16px;
      font-size: 28px;
      line-height: 1.36;
    }

    &__library {
      margin-left: -24px; // for shadow
    }
  }

  @media screen and (max-width: 768px) and (max-height: 1024px) {
    max-height: 703px;
  }


  @media screen and (max-width: 414px) {
    max-height: 90vh;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 32px 16px 16px;

    &__header {
      font-size: 23px;
      line-height: 1.26;
    }

    &__library {
      margin-bottom: 8px;
      margin-left: -16px; // for shadow
      flex: 1;
      overflow: scroll;
    }

    &__media-wrapper {
      margin-bottom: 16px;
    }
  }

  .license-children.upload-hint {

    i {
      padding-top: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      &:before {
        font-size: 38px;
      }
    }

    h3,
    p {
      font-size: 13px;
      line-height: $default-line-height;
    }

    h3 {
      margin-bottom: 0;
    }

    @media screen and (max-width: 414px) {
      h3 {
        font-size: get-font-size('s-3');
      }
    }
  }
}

// *************** item separator ***************
.item-separator {
  margin: 12px 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(39, 40, 53, 1),
    rgba(39, 40, 53, 0)
  );
}


// *************** library ***************
.library {
  @include display-flex(column);
  flex: 1;
  height: 100%;

  &__list,
  &__header,
  &__selected-file {
    padding-left: 40px; // for shadow
  }

  &__header {
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: 13px;
    line-height: 1.69;
    color: get-colour('blue');

    margin-bottom: 12px;
  }

  &__list {
    @extend .custom-scrollbar;

    max-height: 244px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  //queries
  @media screen and (max-width: 1366px) {

    &__list,
    &__header,
    &__selected-file {
      padding-left: 24px; // for shadow
    }

    &__list {
      max-height: 187px;
    }
  }

  @media screen and (max-width: 1280px) {
    &__list {
      max-height: 180px;
    }
  }

  @media screen and (max-width: 768px) and (max-height: 1024px) {
    &__list {
      max-height: 308px;
    }
  }


  @media screen and (max-width: 414px) {

    &__list,
    &__header,
    &__selected-file {
      padding-left: 16px; // for shadow
    }

    &__header {
      font-size: 12px;
      line-height: 1.33;
    }

    &__list {
      max-height: none;
      overflow-y: scroll;
      height: 100%;
    }
  }
}


// *************** library item ***************
.library-item {
  display: grid;
  grid-gap: 12px;
  grid-template: 'file content control' 52px / 52px 1fr 50px;

  cursor: default;
  margin-bottom: 12px;

  &__file {
    grid-area: file;
  }

  &__content {
    grid-area: content;
    white-space: nowrap;
    overflow: hidden;
  }

  &__controls {
    grid-area: control;
  }

  &--disabled {
    z-index: 1;
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none !important;
  }

  &[disabled] {
    pointer-events: none !important;
    opacity: .5;
  }
}

.library-content {

  &__label {
    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: 14px;
    line-height: 1.5;
    color: get-colour('blue');
    margin-bottom: 5px;

    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__size {
    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: 13px;
    line-height: 1;
    color: get-colour('light-grey');
  }

  &__delete-btn {
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: transparent;

    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: get-font-size('s-3');
    color: get-colour('light-grey');

    i {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 414px) {
    font-size: 12px;
    line-height: 1.33;
  }
}

.library-controls {
  @include display-flex(column, center, center);
  height: 100%;

  &__delete-btn {
    color: get-colour('light-grey');
    font-weight: get-font-weight('medium');
    font-size: get-font-size('s-3');
    font-family: $font-family-default;

    padding: 0;
    cursor: pointer;
    border: none;
    background-color: transparent;

    i {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 360px) {
    ._radio__button {
      margin-right: 0;
    }
  }
}

// utility
.black-icon {
  color: black;
  font-size: 26px !important;
}

// *************** item preview ***************
.item-preview {
  width: 100%;
  height: 100%;

  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.35);

  &__icon {
    @include position-absolute(0, 0, 0, 0);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .library-item__video {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1366px), screen and (max-height: 700px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1280px), screen and (max-height: 1000px) {
    font-size: 26px;
  }

  @media screen and (max-width: 1024px), screen and (max-height: 700px) {
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
}

// *************** selected media ***************
.selected-media {
  width: 96px;
  height: 96px;
  position: relative;

  &__primary-label {
    @include position-absolute(0, auto, auto, 50%);
    margin-right: -50%;
    transform: translate(-50%, -50%);

    padding: 4px;

    transition: all .3s linear;

    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    color: get-colour('white');

    background: get-colour('blue');
    border: 2px solid get-colour('white');
    border-radius: $border-radius;

    &--active {
      z-index: 1;
      transform: translate(-50%, -50%);
      opacity: 1;

      + .selected-media__make-primary-btn {
        bottom: 0 !important;
        z-index: 0 !important;
        opacity: 0 !important;
      }
    }

    &--disable {
      z-index: 0;
      transform: translate(-50%, 0%);
      opacity: 0;
    }
  }

  &__remove-btn {
    top: -13px !important;
    right: -13px !important;
    position: absolute;
    display: none;
  }

  &__make-primary-btn {
    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: get-colour('light-grey');

    padding: 4px 6px;

    background: get-colour('white');
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius;

    border: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
    opacity: 0;

    position: absolute;
    bottom: 0;
  }

  &:hover {
    .selected-media {

      &__remove-btn {
        display: block;
      }

      &__make-primary-btn {
        display: block;
        bottom: -30px;
        z-index: 1;
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 600px) {

    &__primary-label {
      font-size: 8px;
    }

    &__primary-label + &__make-primary-btn {
      display: block;
      bottom: -30px;
      z-index: 1;
      opacity: 1;
    }

    .close-button {
      display: block;
      top: -9px;
      right: -9px;
      height: 18px;
      width: 18px;

      &:after,
      &:before {
        height: 10px;
        width: 2px;
      }
    }
  }
}

.darken {
  .multi-select-library__open-modal-btn {
    &:hover {
      border-color: get-colour('white');
    }
  }
}

.multi-select-library {
  position: relative;
  &__open-modal-btn {
    @include display-flex(column, center, center);

    width: 100%;
    height: 220px;
    padding: 38px 30px 24px;

    border: 2px solid get-colour('blue-text');
    border-radius: 4px;
    background-color: get-colour('white');

    cursor: pointer;

    i {
      font-size: 36px;
      color: get-colour('light-grey');
      margin-bottom: 14px;
    }

    strong {
      font-family: $font-family-default;
      font-weight: get-font-weight('bold');
      font-size: 16px;
      line-height: 1.625;
      color: get-colour('blue');
      margin-bottom: 32px;
    }

    p {
      font-family: $font-family-default;
      font-weight: get-font-weight('medium');
      font-size: 16px;
      line-height: 1.625;
      color: get-colour('light-grey');
    }

    &--red {
      border-color: red;
    }

    @media screen and (max-width: 992px) {
      strong {
        margin-bottom: 24px;
      }
    }

    @media screen and (max-width: 768px) {
      i {
        margin-bottom: 6px;
      }

      strong {
        margin-bottom: 16px;
      }
    }
  }

  &__selected-items-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(96px, 1fr));
    grid-gap: 44px 20px;
    margin-top: 28px;


    @media screen and (max-width: 520px) {
      grid-column-gap: 16px;
      margin-bottom: 32px;
    }
  }
}


