.cookies {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000000;
  padding: 12px 0;
  min-height: 240px;
  background-color: rgba(39, 40, 53, 0.85);

  @supports ((-webkit-backdrop-filter: blur(16px)) or (backdrop-filter: blur(16px))) {
    background-color: rgba(39, 40, 53, 0.5);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);

    &.light-theme {
      background-color: rgba(39, 40, 53, 0.85);
    }
  }

  &__wrapper {
    max-width: 790px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 16px;

    .title {
      font-size: 20px;
      margin: 0;
      text-align: center;
      font-weight: var(--theme-fontWeight--black);
      font-family: var(--theme-fontFamily--primary), sans-serif;
      color: var(--theme-color--white);
    }

    .description {
      font-size: 12px;
      line-height: 1.5;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
      font-weight: var(--theme-fontWeight--medium);
      color: var(--theme-color--white);
    }

    .buttons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;

      @media only screen and (max-width: 767px) {
        .button__item {
          padding: 4px 8px;
        }
      }
      @media only screen and (max-width: 575px) {
        display: flex;
        align-items: center;
        flex-direction: column;

        .button {
          max-width: 320px;

          &:not(:last-of-type) {
            margin-bottom: 5px;
          }
        }
      }
    }

    .line {
      margin: 20px 0;
      height: 1px;
      width: 100%;
      border: none;
      background-color: rgba(107, 109, 134, .7);
    }

    .bottom {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      + .controlled-collapse__opened {
        margin-top: 16px;
      }

      @media only screen and (max-width: 767px) {
        align-items: center;
      }
      @media only screen and (max-width: 425px) {
        flex-direction: column;
      }
    }

    .options {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 16px;

      @media only screen and (max-width: 575px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
      }

      @media only screen and (max-width: 425px) {
        margin-bottom: 10px;
      }

      .checkboxCustom {
        padding-left: 25px;

        i {
          width: 20px;
          height: 20px;
        }

        span {
          font-size: 14px;
          color: var(--theme-color--white);
        }
      }
    }

    .details {
      display: flex;
      align-items: center;
      font-size: 12px;
      text-transform: uppercase;
      background-color: transparent;
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
      color: var(--theme-color--white);
      font-weight: var(--theme-fontWeight--bold);

      svg {
        margin-left: 5px;
        transition: transform .3s ease-in-out;
        fill: var(--theme-color--white);
      }

      &.on {
        svg {
          transform: rotate(-180deg);
        }
      }

      &.collapse {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
}