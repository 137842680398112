/// EDIT PROFILE MENU REFACTOR REQUIRED

.account-submenu {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 30px auto 46px;

  min-height: 78px;

  &-list {
    padding: 15px 0;
    max-width: 1200px;

    flex: 1;

    display: flex;
    justify-content: space-between;

    &-wrapper {
      flex: 1;
      align-self: stretch;

      display: flex;
      justify-content: center;
    }

    &-item {
      padding: 0 5px;

      a {
        font-family: $font-family-default;
        font-weight: get-font-weight('medium');
        font-size: 21px;
        line-height: 32px;
        color: #626473;
        text-decoration: none;
      }

      &[attr-active='true'] {
        a {
          color: $blue;
          font-weight: get-font-weight('bold');
        }
      }
    }
  }

  &-pointer {
    flex: 1;
    align-self: stretch;
    display: flex;

    &-line {
      height: 2px;

      background-color: #2e2f3c;

      &[attr-is-animate='true'] {
        transition: all 0.3s ease;
      }

      &[attr-before] {
        mask-image: -webkit-gradient(
          linear,
          left center,
          right center,
          from(rgba(0, 0, 0, 0)),
          to(rgba(0, 0, 0, 1))
        );
      }

      &[attr-after] {
        flex: 1;
        mask-image: -webkit-gradient(
          linear,
          left center,
          right center,
          from(rgba(0, 0, 0, 1)),
          to(rgba(0, 0, 0, 0))
        );
      }
    }

    &-arrow {
      width: 20px;
      height: 2px;

      position: relative;

      &::before,
      &::after {
        content: '';

        height: 1.8px;
        width: 13px;

        position: absolute;
        left: -1.6px;
        top: -4px;

        display: block;

        transform: rotate(-35deg);

        background-color: #2e2f3c;
      }

      &::after {
        left: 8.4px;

        transform: rotate(35deg);
      }

      &[attr-reverse='true'] {
        transform: rotate(180deg);

        &::after,
        &::before {
          top: -4px;
        }
      }
    }
  }
}
