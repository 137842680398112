// TODO: split up styles between lesson wrapper, forms and book views

$desktop-lg: 1560px;
$desktop-md: 1024px;
$desktop-sm: 820px;
$desktop-xs: 700px;

.lessons-container {
  padding: 50px 0 168px;
  margin: 0 auto;

  @media only screen and (max-width: $desktop-xs) {
    padding: 0;
  }

  &--subtitle {
    font-weight: get-font-weight('medium');
    font-size: get-font-size('m-3');
    line-height: 32px;
    text-align: center;
    color: #babbcd;
    padding-top: 24px;

    @media #{$sm} {
      font-size: get-font-size('m-5');
    }
  }

  h1 {
    font-family: $font-family-primary;
    font-weight: get-font-weight('black');
    text-align: center;
    color: get-colour('white');

    margin: 0 auto;
    font-size: get-font-size('l-1');
    line-height: 1.5;
    @media only screen and (max-width: $desktop-md) {
      font-size: get-font-size('l-2');
    }
    @media only screen and (max-width: $desktop-xs) {
      font-size: get-font-size('m-1');
    }

    &.large {
      font-size: get-font-size('l-1');
      line-height: 1.5;
      max-width: 840px;
      @media only screen and (max-width: $desktop-md) {
        font-size: get-font-size('l-2');
      }
      @media only screen and (max-width: $desktop-xs) {
        font-size: get-font-size('m-1');
      }
    }

    span {
      font-size: get-font-size('m-3');
      @media only screen and (max-width: $desktop-md) {
        font-size: get-font-size('m-5');
      }
      @media only screen and (max-width: $desktop-xs) {
        font-size: get-font-size('s-1');
      }
    }
  }

  .lessons-title-hint {
    display: block;
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: get-font-size('s-1');
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    line-height: 1.625;
    color: get-colour('light-grey');
    margin: 24px auto 0;
    a {
      font-weight: get-font-weight('bold');
      color: get-colour('white');
      &:hover {
        text-decoration: underline;
      }
    }

    @media only screen and (max-width: $desktop-xs) {
      margin: 12px auto 0;
    }
  }
}

.lessons__button {
  width: 100%;
  margin: 54px auto 0;
}

// ***************** lesson list ****************
.lessons-list {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 100%;

  @media only screen and (max-width: $desktop-md) {
    width: 50%;
  }

  @media only screen and (max-width: $desktop-xs) {
    flex-wrap: wrap;
    width: 100%;
  }

  .lesson-wrap {
    flex: 0 0 32%;
    max-width: 32%;
    height: fit-content;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.46) 100%);

    @media only screen and (max-width: $desktop-md) {
      flex: 0 0 100%;
      max-width: 100%;

      &.on {
        margin-right: 0;
      }
    }

    @media only screen and (max-width: $desktop-xs) {
      &:first-child {
        .header .title {
          max-width: 200px;
          width: 100%;
          transform: translateY(14px);
        }

        .lesson__more {
          padding: 30px 0 20px;
        }
      }
    }


    @media only screen and (max-width: $desktop-md) {
      @include position-absolute(0, 0, null, 0);
      margin-top: 0;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      height: 0;
      &.on {
        height: auto;
        position: relative;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }

    @media only screen and (max-width: $desktop-xs) {
      height: auto;
      position: relative;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      margin-bottom: 30px;
      background: none;
    }

    &.fill {
      height: 100%;
    }

    .content {
      border: 2px solid get-colour('light-grey');
      border-top: unset;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      position: relative;

      &__wrapper {
        padding: 34px 24px;
        height: 100%;

        @media only screen and (max-width: $desktop-md) {
          padding: 0 24px 34px;
          height: auto;
        }

        @media only screen and (max-width: $desktop-xs) {
          margin-top: 0;
          @include position-absolute(0, 0, null, 0);
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          height: 0;
          overflow: hidden;
          padding: 0;
          &.on {
            padding: 34px 24px;
            opacity: 1;
            height: auto;
            position: relative;
            visibility: visible;
            pointer-events: auto;
          }
        }
      }

      .rules-list {
        margin-bottom: 16px;

        li {
          display: flex;
          align-items: flex-start;
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }

          span {
            font-family: $font-family-primary;
            font-weight: get-font-weight('black');
            font-size: get-font-size('l-2');
            line-height: 1;
            text-transform: uppercase;
            font-feature-settings: 'case' on;
            color: get-colour('white');
            margin-right: 24px;
          }

          div {
            font-family: $font-family-default;
            font-weight: get-font-weight('medium');
            font-size: get-font-size('s-1');
            line-height: 1.65;
            color: get-colour('blue-text');

            b {
              color: get-colour('white');
              font-weight: get-font-weight('bold');;
            }
          }
        }
      }

      h3.label,
      .input-label,
      .text-area-group__label,
      .price-with-label__label {
        font-family: $font-family-primary;
        font-weight: get-font-weight('black');
        font-size: get-font-size('m-2');
        line-height: 1.4;
        font-feature-settings: 'case' on, 'liga' off;
        color: get-colour('white');
        margin: 0 0 14px;
        text-align: left;

        @media only screen and (max-width: 1280px) {
          font-size: get-font-size('m-4');
        }
      }

      .special-instructions,
      .lesson-preview__note {
        background: get-colour('blue');
        box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
        padding: 20px 22px;
        overflow-wrap: anywhere;

        h6 {
          font-family: $font-family-primary;
          font-weight: get-font-weight('black');
          font-size: 23px;
          line-height: 29px;
          text-align: center;
          font-feature-settings: 'case' on, 'liga' off;
          color: get-colour('white');
          margin-bottom: 14px;
        }

        p {
          font-family: $font-family-default;
          font-weight: get-font-weight('medium');
          font-size: 16px;
          line-height: 26px;
          text-align: center;
          color: get-colour('blue-text');
        }

        b {
          color: get-colour('white');
          font-weight: get-font-weight('bold');
        }
      }

      .include-service {
        margin-top: 48px;
      }

      .instruction {
        margin-top: 31px;

        .chars-length {
          @extend .font-small;
          text-align: right;
          color: get-colour('white');

          &-error {
            color: red;
          }
        }
      }
    }

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        margin: 0 25px;

        font-family: $font-family-primary;
        font-weight: get-font-weight('black');
        font-size: get-font-size('l-3');
        line-height: 1.4;
        width: 240px;
        color: get-colour('white');
        padding-bottom: 15px;
        @media only screen and (max-width: $desktop-md) {
          font-size: get-font-size('m-2');
          line-height: 2;
          width: auto;
          transform: translateY(-50%);
          padding-bottom: 0;
        }
        @media only screen and (max-width: $desktop-xs) {
          width: auto;
          transform: translateY(0);
          line-height: 1.35;
          padding: 0;
        }
      }

      &-line-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: flex-end;

        flex: 1;

        height: 52px;

        border-top: 2px solid #94939a;

        @media only screen and (max-width: $desktop-xs) {
          height: 14px;
        }

        &:first-child {
          border-left: 2px solid #94939a;
        }

        &:last-child {
          border-right: 2px solid #94939a;
        }
      }
    }

    .title {
      font-family: $font-family-primary;
      font-weight: get-font-weight('black');
      font-size: get-font-size('l-1');
      line-height: 1.3;
      text-align: center;
      font-feature-settings: 'case' on, 'liga' off;

      color: get-colour('white');
    }
  }
}


.lessons {
  &__wrapper {
    margin-top: 64px;
    @include display-flex(row, flex-start, space-between);

    @media only screen and (max-width: $desktop-xs) {
      margin-top: 32px;
    }
  }

  &__tabs {
    padding-left: 50px;
    @media only screen and (min-width: $desktop-md + 1) {
      display: none;
    }
    @media only screen and (max-width: $desktop-xs) {
      display: none;
    }
    @media only screen and (max-width: $desktop-sm) {
      padding-top: 25px;
    }

    li {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }

    button {
      font-size: get-font-size('m-1');
      font-weight: get-font-weight('black');
      font-family: $font-family-primary;
      color: get-colour('light-grey');
      border: none;
      background-color: rgba(get-colour('white'), 0);
      position: relative;
      cursor: pointer;
      transition: all .3s ease-in-out;

      @media only screen and (max-width: $desktop-sm) {
        font-size: get-font-size('m-3');
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -84px;
        transform: translateY(-50%);
        max-width: 74px;
        height: 2px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0, rgba(#ffffff, 1) 100%);
        opacity: 0;
        width: 0;
        transition: all .5s ease-in-out;
      }

      &.on {
        color: get-colour('white');

        &:before {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }
  &__files {
    .multi-select-library__open-modal-btn {
      height: 130px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(get-colour('white'), 0);
      strong {
        margin-bottom: 0;
        color: get-colour('white');
      }
      p {
        display: none;
      }
    }
  }
}

.lesson {
  &__more {
    @media only screen and (min-width: $desktop-xs + 1) {
      display: none;
    }
    background-color: rgba(get-colour('white'), 0);
    font-size: get-font-size('s-2');
    font-weight: get-font-weight('bold');
    font-family: $font-family-default;
    color: get-colour('light-grey');
    text-transform: uppercase;
    padding: 20px 0;
    cursor: pointer;
    border: none;
    outline: none;
    transition: all .3s ease-in-out;

    &:hover {
      color: get-colour('white');
    }
  }
}


// ***************** lessons preview ****************
.lesson-preview {
  @include display-flex(column, null, space-between);
  height: 100%;
  width: 100%;

  & > * + * {
    margin-top: 24px;
  }

  &__inner {
    & > * + * {
      margin-top: 16px;
    }

    .charities-tip {
      font-family: var(--theme-fontFamily--default);
      font-weight: var(--theme-fontWeight--medium);
      font-size: 14px;
      line-height: 1.3;
      text-align: center;
      color: var(--theme-color--lightGrey);
    }

    .time-length,
    .charity-wrap,
    .location,
    .response-time {
      width: 100%;
      font-family: $font-family-default;
      font-weight: get-font-weight('bold');
      font-size: 16px;
      line-height: 26px;
      text-align: center;

      .label {
        color: get-colour('blue-text');
        font-family: $font-family-default;
        font-weight: get-font-weight('bold');
        font-size: 16px;
        margin-left: unset;
      }

      .value {
        color: get-colour('white');
        font-family: $font-family-default;
      }
    }
  }

  ._price {
    text-align: center;

    &__value {
      color: #ffffff;
      font-size: 35px;
    }

    &__currency {
      color: #ffffff;
      font-size: 16px;
      line-height: 1;
    }
  }

  .calendar-button {
    margin: 24px auto 0;
  }

  .entourage-required {
    flex-direction: column;
    margin-top: 16px;

    &__icon {
      margin-bottom: 16px;
    }

    &__text {
      color: var(--theme-color--white);
      text-align: center;
      margin-left: 0;
    }
  }
}


// ***************** labels ****************
.include-service {
  margin-top: 48px;

  &__inner {
    display: flex;
    align-items: center;
    label:not(:last-of-type) {
      margin-right: 45px;
    }
  }
}

.lesson-input {
  margin-bottom: 25px;
}

.lessons-field {
  margin-bottom: 25px;
  width: 100%;

  .tip-container {
    position: static;
    display: inline;

    &__button {
      display: inline;
    }

    &__icon {
      width: 20px;
      height: 20px;
      fill: #ffffff;
    }
  }

  .label {
    width: auto;
    max-width: 100%;
  }

  .text-area-group,
  .input {

    &__tip {
      color: white;
    }

    // todo: refactor with same classes
    &__label,
    &-label {
      font-family: $font-family-primary;
      font-weight: get-font-weight('black');
      font-size: get-font-size('m-2');
      line-height: 29px;
      font-feature-settings: 'case' on, 'liga' off;
      color: get-colour('white');
      text-transform: unset;
    }

    // todo: refactor with same classes
    &__label-container,
    &-label-container {
      margin-bottom: 14px;
    }
  }

  button {
    right: 0;
    top: 114px;
  }
}

.lesson-charities {

  .multiple-location__button {
    color: get-colour('white');
  }

  .remove-button {
    top: auto;
    bottom: 24px;
    @media screen and (max-width: 767px) {
      bottom: 35px !important;
    }
  }
}

.lesson-charity {

  &__charity {
    position: relative;
    margin-bottom: 16px;
  }

  &__percent {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 26px;
    grid-gap: 20px;

    .form-row__remove--black {
      top: 37px;
      right: 0;
    }

    .lessons-field {
      margin-bottom: 0;
    }
  }
}

.lesson-separator {
  height: 1px;
  background-image: linear-gradient(to right, rgba(39, 40, 53, 0), white, rgba(39, 40, 53, 0));
  margin: 24px 0;
}
