.chat-message {
  &__wrapper {
    display: flex;

    padding: 8px 16px;

    &.from-me {
      justify-content: flex-end;
    }

    &.from-system {
      justify-content: center;
    }
  }

  &__sender-icon {
    min-width: 32px;
    width: 32px !important;
    height: 32px !important;

    margin-right: 12px;
  }

  &__body {
    display: flex;
    flex-direction: column;

    font-size: 14px;
    font-weight: var(--theme-fontWeight--medium);

    line-height: 1.86;

    white-space: pre-line;

    &.from-me {
      align-items: flex-end;
    }

    &.from-others {
      align-items: flex-start;
    }
  }

  &__message {
    padding: 8px 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    overflow-wrap: break-word;

    display: flex;
    gap: 10px;
    align-items: center;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      input {
        max-width: 50vw;
        font-family: inherit;
        font-size: inherit;
        padding: 5px;
        border-radius: 7.5px;
      }
    }

    &__edited {
      font-weight: inherit;
      text-transform: capitalize;
      font-style: italic;
      float: right;
      color: #acacac;
      font-size: 12px;
    }
    

    a {
      &:hover {
        text-decoration: underline;
      }
    }

    &.from-system {
      font-size: 13px;
      font-weight: var(--theme-fontWeight--medium);

      line-height: 1.7;

      color: var(--theme-color--grey);

      box-shadow: unset;

      a {
        color: var(--theme-color--grey);
      }

      span {
        font-weight: var(--theme-fontWeight--bold);
      }
    }

    &.from-me {
      border-radius: 16px;

      color: var(--theme-color--white);
      background: var(--theme-color--blue);

      a {
        color: var(--theme-color--white);
      }
    }

    &.from-others {
      border-radius: 0 16px 16px;

      color: var(--theme-color--blue);
      background: var(--theme-color--white);

      a {
        color: var(--theme-color--blue);
      }
    }

    &-time {
      font-size: 13px;
      line-height: 1.7;

      margin-top: 5px;
      width: fit-content;

      color: var(--theme-color--blueText);
    }
  }
}
