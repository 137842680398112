body {
  font-size: 100%;
  font-weight: var(--theme-fontWeight--normal);
}


// CSS class that represents Title from the common StyledComponents 
.title--default {
  font-size: 60px;
  font-weight: var(--theme-fontWeight--black);
  font-family: var(--theme-fontFamily--primary), sans-serif;
  line-height: 1.25;
  color: var(--theme-color--blue);
  margin: 0 auto;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 48px;
  }
  @media only screen and (max-width: 575px) {
    font-size: 42px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 34px;
  }
}
