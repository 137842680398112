@import '~react-phone-input-2/lib/style.css';

$r-tel-ip: '.react-tel-input';

#{$r-tel-ip} {

  // TODO: refactor reset and form share style cascading
  input {
    color: var(--theme-color--white) !important;
    transition: 0.2s border-color;
  }

  input:focus,
  input:hover {
    & + .flag-dropdown {
      background-color: var(--theme-color--white);
    }
  }

  .form-control {
    padding-left: 54px;
    @extend .experience-input;
  }

  .flag-dropdown {
    background-color: var(--theme-color--blueText);
    top: 0;
    left: 0;
    bottom: 0;
    margin: 2px;
    border: none;
    border-radius: 0!important;
    transition: 0.2s background-color;

    &.open {
      background-color: var(--theme-color--blueText);
    }
  }

  .flag-dropdown.open .selected-flag {
    background-color: var(--theme-color--blueText);
  }
}

.white-background {
  #{$r-tel-ip} {
    input {
      color: var(--theme-color--blue) !important;
    }

    input:focus,
    input:hover {
      & + .flag-dropdown {
        background-color: var(--theme-color--blueText);
      }
    }

    .flag-dropdown {
      background-color: var(--theme-color--blueText);

      &.open {
        background-color: var(--theme-color--blueText);
      }
    }

    .selected-flag:hover {
      background-color: var(--theme-color--blueText);
    }

    .flag-dropdown.open .selected-flag {
      background-color: var(--theme-color--blueText);
    }
  }
}
