body {
  min-width: 320px;
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Montserrat', sans-serif ;
}

.global-wrapper {
  display: flex;
  flex-direction: column;

  background-position: 0 0;
  background-repeat: no-repeat;
  padding-top: 140px;
  @media only screen and (max-width: 1024px) {
    padding-top: var(--theme-header-height-small);
  }

  &[background-type=default] {
    background-image: url('../../assets/images/body-default-background.png');
    background-size: cover;
    height: 100%;
  }
  &[background-type=account] {
    background-image: url('../../assets/images/body-default-background.png');
    background-size: cover;
    height: 100%;
    @media only screen and (max-width: 1024px) {
      background-image: none;
    }
    @media only screen and (max-width: 768px) {
      padding-bottom: 24px;
    }
  }
  &[background-type=default-dark] {
    background-image: url('../../assets/images/body-default-dark-background.jpg');
    background-size: cover;
  }
  &[background-type=home] {
    padding-top: 0;
  }
  &[background-type=talent] {
    background-image: url('../../assets/images/talent-public-view-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media only screen and (max-width: 1024px) {
      background-size: cover;
    }
  }
  &[background-type=commercial-engagements] {
    background-image: url('../../assets/images/commercialEngagements.png');
    background-position: 50% 0%;
    @media only screen and (max-width: 1024px) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  &[background-type=how-it-works] {
    background: url('../../assets/images/howItWorks/background.png');
  }
  &[background-type=why-join] {
    background: url('../../assets/images/bg-join-us.png');
  }
  &[background-type=lessons] {
    background-image: linear-gradient(0deg, #272835 1%, rgba(0, 0, 0, 0) 100%), url('../../assets/images/lessons-bg.png');
    background-position: 50% 0;
    background-size: cover;
    background-color: #272835;
    @media only screen and (max-width: 1024px) {
      background-size: 100%;
    }
  }
  &[background-type=experience] {
    background: url('../../assets/images/bg-live-1.png');
    background-size: cover;
    @media only screen and (max-width: 768px) {
      background-position: 50% 0%;
    }
  }
  &[background-type=video-mess-commercial] {
    background: #ffffff url('../../assets/images/body-default-background.png') no-repeat top center;
    background-size: 100%;
    position: relative;
    @media only screen and (max-width: 1024px) {
      background-size: auto;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-height: 1200px;
      height: 100%;
      background: url('../../assets/images/bg_bottom-gradient.jpg') no-repeat bottom center;
      background-size: 100%;
      @media only screen and (max-width: 1024px) {
        background-size: auto;
      }
    }
  }
  &[background-type=edit-profile] {
    background-image: url('../../assets/images/body-default-background.png');
    background-size: 100%;
    background-position: top center;
    @media only screen and (max-width: 1024px) {
      background-image: none;
    }
    @media only screen and (max-width: 768px) {
      padding-bottom: 24px;
    }
  }
  &[background-type=commercial-engagement] {
    background-image: linear-gradient(180deg, #e9eaf0 0%, rgba(233, 234, 240, 0) 100%),
      url('../../assets/images/body-default-background.png');

    padding-top: 0;
    background-size: 100%;
    background-position: top center;
  }
  &[background-type=video-message] {
    background-color: #e9eaf0;
  }
  &[background-type=aficionado] {
    background-image: url('../../assets/images/aficionado-profile-bg.jpg');
    background-position: 50% 0;
    background-size: cover;
  }
}

/* Abstracts */
@import 'abstracts/index';

/* Theme */
@import 'theme';

/* Base */
@import 'base/index';

@import './fontello.css';


// @font-face {
//   font-family: 'Montserrat';
//   src: url('../font/Montserrat-Regular.otf') format('opentype'),
//     url('../font/Montserrat-Regular.ttf') format('truetype'),
// }

@import 'constants.scss';
@import 'mixins.scss';
//@import 'header.scss';
//@import 'footer.scss';
@import 'common.scss';
@import 'register.scss';
@import 'contact.scss';
@import 'login.scss';
@import 'icon.scss';
@import 'slider.scss';
@import 'howItWorks.scss';
@import 'whyJoin.scss';
@import 'notification.scss';
@import 'settings.scss';
@import 'videoChatAndVideoMessage.scss';
@import 'not-found.scss';
@import 'resetPassword';
@import 'offerings/manage.scss';

@import 'experience.scss';

/* Components */
@import 'components/index';

@font-face {
  font-family: 'Playfair Display Black';
  src: url('../../assets/font/PlayfairDisplay-Black.ttf') format('tff'),
    url('../../assets/font/PlayfairDisplay-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../../assets/font/PlayfairDisplay-Bold.ttf') format('truetype')
    url('../../assets/font/PlayfairDisplay-Bold.woff2') format('truetype');
}

@font-face {
  font-family: 'Playfair Display Bold';
  src: url('../../assets/font/PlayfairDisplay-Bold.ttf') format('tff'),
    url('../../assets/font/PlayfairDisplay-Bold.woff2') format('woff2');
}

@import 'form-shared-components';

/* Layout */
@import 'layout/index';

@import 'reset/index';
