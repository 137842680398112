.user {
  &-profile-name {
    & > p {
      margin-right: 4px;
      font-family: var(--theme-fontFamily--default);
      font-size: 12px;
      font-weight: var(--theme-fontWeight--bold);
      line-height: 1.25em;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: right;
    }

    &.with-talent {
      & > p {
        text-transform: none;
        font-weight: var(--theme-fontWeight--medium);

        & > span {
          font-weight: var(--theme-fontWeight--bold);
        }
      }
    }
  }
  &-account {
    &__exit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 40px;
      margin: 0 6px;
      cursor: pointer;

      @at-root .dark-theme #{&} {
        svg {
          path {
            fill: var(--theme-color--white);
          }
        }
      }

      @at-root .light-theme #{&} {
        svg {
          path {
            fill: var(--theme-color--blue);
          }
        }
      }

      svg {
        height: 28px;
        width: 28px;

        path {
          fill: var(--theme-color--white);
        }
      }
    }

    &__icons {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      max-width: 148px;
      width: auto;
      margin: 0 6px;
    }

    &__switcher {
      position: relative;
      margin: 0 6px;
      display: flex;

      &-wrapper {
        position: absolute;
        width: 256px;
        right: 0;
        top: 42px;
        background: var(--theme-color--white);
        box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        height: 0;
        transition: height 0.15s ease;
        overflow: hidden;
        display: none;

        &.active {
          display: block;
          z-index: 99;
          transition: height 0.15s ease;
          max-height: 310px;
          height: 100%;
          min-height: 138px;
          overflow: visible;
        }
      }

      &-button {
        font-weight: var(--theme-fontWeight--medium);
        font-size: 16px;
        line-height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--theme-color--white);
        box-shadow: 0 -2px 5px rgba(0, 0, 0, .15);
        border-radius: 0 0 4px 4px;
        cursor: pointer;
        border: none;
        width: 100%;
        padding: 0;
      }
    }
  }
}
