.new-event-form {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__inner {
    height: 100%;
    overflow: auto;
    padding: 12px;

    &.select-opened {
      overflow: visible;
    }
  }

  &__form-group {
    margin-bottom: 24px;
  }

  &__form-control {
    input + .error {
      margin: 0;
    }
  }

  &__controls {
    margin-top: auto;

    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    padding: 12px;

    background: #ffffff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 0 4px;
    transition: all .5s ease-in;
    margin-bottom: 0;

    &--2-cols {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__error-message {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 14px;
    line-height: 1.5;
    color: red;
  }

  &__repeat-every {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 3fr;
  }

  &__radio-group {
    display: grid;
    grid-template-columns: 110px 1fr;
    align-items: center;

    height: 80px;
  }

  &__change-btn {
    background: none;
    border: none;
    border-bottom: 1px dashed var(--theme-color--grey);
    cursor: pointer;

    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 14px;
    line-height: 1.5;
    color: var(--theme-color--grey);
    text-transform: capitalize;

    padding: 0;
    margin-left: 32px; // pl-12px + icon-width: 20px
  }

  &__after-occurrence-label {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;

    display: flex;
    align-items: center;

    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 14px;
    line-height: 1.5;
    color: var(--theme-color--grey);
    text-transform: lowercase;

    &.disabled {
      color: var(--theme-color--lightGrey);
    }
  }

  .offering-details-header-item__text {
    padding-bottom: 4px !important;
  }

  .ends-group__after-occurrence-input {
    position: relative;
  }

  // Input styles override
  // TODO: Implement the common input styling system later
  $ip-border-width: 2px;
  $ip-height: 40px;
  $ip-select-inner-height: $ip-height - $ip-border-width * 2;
  $ip-select-menu-gap: 10px;

  & input,
  & select,
  & .react-select-container .react-select__control,
  & .react-select__single-value {
    height: $ip-height;
    line-height: $ip-height;
    font-size: 16px;
  }

  & .react-select-container {
    .react-select__control .react-select__value-container {
      height: $ip-select-inner-height;
    }

    .react-select__menu {
      top: $ip-height + $ip-select-menu-gap;
    }
  }

  // Strange hack to avoid inner content positioning bug (jumping) inside the "overflow: hidden" block when radio input or checkbox is pressed :)
  // https://stackoverflow.com/questions/24299567/radio-button-causes-browser-to-jump-to-the-top
  input[type='radio'],
  input[type='checkbox'] {
    top: 0 !important;
  }
}



// TODO: investigate how to add class for a react-select portal itself
[class^='css-'] {
  z-index: 1000000 !important;
}

#root-portal {
  .react-datepicker__time-container,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    min-width: 120px;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    font-family: var(--theme-fontFamily--default);
    font-size: 16px;
  }
}
