$pwl: '.price-with-label';

#{$pwl} {
  margin-bottom: 25px;

  .inputPrice {
    height: 50px;
  }

  &__label {
    font-family: var(--theme-fontFamily--primary), sans-serif;
    font-weight: var(--theme-fontWeight--black);
    font-size: 20px;
    line-height: 1.4;
    font-feature-settings: 'case' on, 'liga' off;
    color: #ffffff;
    margin: 0 0 14px;
    text-align: left;
  }
}
