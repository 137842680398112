$rme-product: '.ready-made-event-lessons-details-product';

#{$rme-product} {
  &-table {
    margin-top: 0;
    margin-bottom: 40px;

    #{$rme-product}-row.row-content {
      grid-template-columns: 1fr 180px;
    }

    // Hack to align select width with search width
    @media screen and (min-width: 769px) {
      .filter {
        max-width: 300px;
  
        .react-dropdown-select-dropdown {
          width: 300px;
        }
      }
    }
  }
}