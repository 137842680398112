.table-tabs {
  .account-submenu-list-wrapper {
    align-self: center;

    .account-submenu-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 44px;
      text-align: center;
    }
  }
}