$ni: '.notification-item';

#{$ni} {
  display: grid;
  position: relative;

  &__icon {
    margin-right: 16px;

    grid-area: icon;

    opacity: 1;
  }

  &__text {
    grid-area: text;
  }

  &__date {
    grid-area: date;
    align-self: center;
  }

  &__controls {
    grid-area: controls;

    display: flex;
    align-items: center;
  }

  &__action-button:not(:last-child) {
    margin-right: 12px;
  }

  &--read {
    transition: all .3s ease-in;
    #{$ni}__icon {
      opacity: 0.4;
    }
  }

  &--overdue {
    fill: var(--theme-color--rejected);
  }

  &--menu {
    padding: 16px;

    background: var(--theme-color--white);
    border-top: 1px solid var(--theme-color--ultraLightBlue);

    grid-template-areas: 'icon text     text'
      'icon date     mark'
      'icon controls controls';
    grid-template-columns: min-content 1fr 1fr;

    #{$ni} {

      &__text {
        margin-bottom: 4px;
      }

      &__controls {
        justify-content: flex-start;
        margin-top: 14px;
      }

      &__mark-btn {
        grid-area: mark;
        justify-self: end;
        align-self: center;
      }
    }
  }

  &--table {
    grid-template-areas: 'icon text controls'
      'icon date controls';
    grid-template-columns: min-content 3fr 1fr;

    padding: 25px 10px 25px 0;
    position: relative;

    #{$ni} {

      &__controls {
        justify-content: flex-end;
      }

      &__mark-btn {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

    @media screen and (max-width: 767px) {
      grid-template-areas: 'icon text'
        'icon date'
        'controls controls';
      grid-template-columns: min-content 1fr;

      padding: 0;
      margin-top: 12px;

      #{$ni} {

        &__mark-btn {
          display: none;
        }

        &__controls {
          width: 100%;
          margin-top: 24px;

          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 12px;

          &--status {
            grid-template-columns: 1fr;
            justify-content: center;

            .notification-status {
              text-align: center;
            }
          }
        }

        &__action-button {
          width: 100%;

          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
          }

          &:nth-child(2) {
            grid-area: 1 / 2 / 2 / 3;
          }

          &:nth-child(3) {
            grid-area: 2 / 1 / 3 / 3;
          }
        }
      }
    }
  }
}


.mark-as-read-button {
  position: relative;

  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--medium);
  font-size: 14px;
  line-height: 1.5;
  color: var(--theme-color--grey);

  border: none;
  background: none;
  cursor: pointer;

  padding-right: 0;
  padding-left: 24px;

  &--unread {
    &:after {
      content: '';
      display: block;
      width: 4px;
      height: 10px;

      position: absolute;
      top: 3px;
      left: 6px;
      transform: rotate(40deg);

      border: 2px solid var(--theme-color--grey);
      border-left: none;
      border-top: none;
    }
  }
}


.notification-date {
  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--bold);
  font-size: 14px;
  line-height: 1.28;
  text-transform: uppercase;
  color: var(--theme-color--lightGrey);
}


.notification-status {
  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--bold);
  font-size: 12px;
  text-transform: uppercase;

  color: var(--theme-color--lightGrey);
}


.notification-text {
  font-family: var(--theme-fontFamily--default);
  font-weight: var(--theme-fontWeight--medium);
  font-size: 16px;
  line-height: 1.625;
  color: var(--theme-color--grey);

  overflow: hidden;
  text-overflow: ellipsis;

  ul {
    list-style: inside;
  }

  ol {
    list-style: decimal inside;
  }

  pre {
    font-family: monospace;
  }

  em,
  i {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
    font-style: italic;
  }

  blockquote {
    border-left: 2px solid var(--theme-color--grey);
    padding-left: 12px;
  }

  h1,
  strong,
  b,
  a {
    font-weight: var(--theme-fontWeight--bold);
    color: inherit;
    font-family: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}


.notification-table-loader {
  display: flex;
  align-items: center;

  padding: 16px 0;
  height: 100%;


  &__icon {
    flex-shrink: 0;

    width: 48px !important;
    height: 48px !important;

    border-radius: 50%;

    margin-right: 16px;
  }
}
