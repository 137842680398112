$bn-o-h: '.bottom-navigation__overlay__header';
$bn-o-h-height: 130px;

#{$bn-o-h} {
  position: fixed;
  height: $bn-o-h-height;
  width: 100%;

  &__logo {
    padding: 16px;

    svg {
      width: auto;
      height: 25px;
    }
  }

  &__navigation {
    height: $bn-o-h-height / 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid var(--theme-color--ultraLightBlue);

    &__title {
      flex: 1;
      padding: 0 16px;
      margin-bottom: 5px;
      font-family: var(--theme-fontFamily--primary);
      font-size: 23px;
      line-height: 1.3;
      font-weight: var(--theme-fontWeight--black);
      overflow-wrap: anywhere;
    }

    svg {
      height: 14px;
      width: auto;

      path {
        fill: var(--theme-color--blue);
        transition: fill 0.2s;

        &:hover {
          fill: var(--theme-color--grey);
        }
      }
    }
  }
}