.avatar-with-entourage-type {
  position: relative;

  .entourage-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-10%, -10%);
  }
}
