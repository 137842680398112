@import 'components/Calendar/categoriesColourTypes.module.scss';

$cc: '.calendar-checkbox';

.categories-sidebar {
  padding: 16px 12px;
  background: var(--theme-color--white);
  height: 100%;

  @media all and (max-width: 768px) {
    padding-bottom: 0;
  }
}

.calendar-filter-group {
  margin-bottom: 25px;

  &__header {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--theme-color--lightGrey);

    margin-bottom: 12px;

    @media all and (max-width: 768px) {
      margin-bottom: 4px;
    }
  }

  #{$cc} {
    display: flex;

    & > #{$cc}__input {
      padding-left: 24px;

      &  > i {
        width: 20px;
        height: 20px;
      }
    }
  }

  // Reusable components override
  // TODO: replace with an adequate inputs
  input[type='checkbox'] ~ i {
    transition: 0.2s background-color;

    &:before {
      top: 25%;
      left: 50%;

      height: 8px;
      width: 4px;
    }
  }

  @each $key, $val in $event-type-to-colour-map {
    input[type='checkbox'][value='#{$key}'] {
      &:checked {
        & ~ i {
          background-color: $val;
        }

        & ~ i:hover {
          background-color: darken($val, 20);
        }
      }
    }
  }

  @media all and (max-width: 768px) {
    margin-bottom: 12px;

    &__option-list {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media all and (max-width: 600px) {
    &__option-list {
      flex-direction: column;
    }
  }
}

.calendar-checkbox {
  display: block;
  margin: 6px;
  cursor: pointer;

  &__label {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontFamily--normal);
    font-size: 14px;
    line-height: 1.5;
    color: var(--theme-color--grey);
  }
}

