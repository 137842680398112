.font-header-h1 {
  font: {
    family: $font-family-primary;
    weight: get-font-weight('black'); // 900
    size: 60px;
  }

  @media #{$sm} {
    font-size: get-font-size('l-2');
  }
  line-height: 75px;
}

.font-header-h2 {
  font: {
    family: $font-family-primary;
    weight: get-font-weight('black'); // 900
    size: get-font-size('l-2'); // 42px
  }
  line-height: $default-line-height;
}

.font-header-h3 {
  font: {
    family: $font-family-primary;
    weight: get-font-weight('black'); // 900
    size: get-font-size('m-1'); // 28px
  }
  line-height: 38px;
}

.font-header-h4 {
  font: {
    family: $font-family-primary;
    weight: get-font-weight('black'); // 900
    size: get-font-size('m-2'); // 24px
  }
  line-height: 29px;
}

.font-subtitle {
  font: {
    family: $font-family-default;
    weight: get-font-weight('normal'); // 400
    size: get-font-size('m-2'); // 24px
  }
  line-height: $default-line-height;
}

.font-body-1 {
  font: {
    family: $font-family-default;
    weight: get-font-weight('normal'); // 400
    size: get-font-size('m-3'); // 22px
  }
  line-height: $default-line-height;
}

.font-body-1-bold {
  font: {
    family: $font-family-default;
    weight: get-font-weight('bold'); // 700
    size: get-font-size('m-3'); // 22px
  }
  line-height: $default-line-height;
}

.font-body-2 {
  font: {
    family: $font-family-default;
    weight: get-font-weight('normal'); // 400
    size: get-font-size('s-1'); // 16px
  }
  line-height: 26px;
}

.font-body-2-bold {
  font: {
    family: $font-family-default;
    weight: get-font-weight('bold'); // 700
    size: get-font-size('s-1'); // 16px
  }
  line-height: 26px;
}

.font-caption {
  font: {
    family: $font-family-default;
    weight: get-font-weight('bold'); // 700
    size: get-font-size('s-2'); // 14px
  }
  line-height: 18px;
  text-transform: uppercase;
}

.font-small {
  font: {
    family: $font-family-default;
    weight: get-font-weight('medium'); // 500
    size: get-font-size('s-2'); // 14px
  }

  line-height: $default-line-height;
}

.font-input {
  font: {
    family: $font-family-default;
    weight: get-font-weight('normal'); // 400
    size: get-font-size('m-5'); // 18px
  }
  line-height: 32px;
}

.font-link {
  font: {
    family: $font-family-default;
    weight: get-font-weight('bold'); // 400
    size: get-font-size('s-1'); // 16px
  }
  line-height: $default-line-height;
}
