$ce: '.commercial-engagement';
$cef: '.commercial-engagements-form';

$cef-bg-url: '../../../../assets/images/commercialEngagements.png';

$cef-padding-dt: 157px 80px 0 80px;
$cef-padding-md: 109px 48px 0 48px;
$cef-padding-sm: 116px 16px 0 16px;

$cef-line-block-border: 1px solid var(--theme-color--white);

#{$cef} {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $cef-padding-dt;

  background: grey url(#{$cef-bg-url}) no-repeat 0 0;
  background-size: cover;

  @media screen and (max-width: 1024px) {
    padding: $cef-padding-md;
  }

  @media screen and (max-width: 767px) {
    padding: $cef-padding-sm;

    .button {
      margin-top: 30px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  #{$ce}-header {
    color: var(--theme-color--white);
    text-align: center;
    margin-bottom: 37px;

    @media screen and (max-width: 1024px) {
      font-size: 32px;
      line-height: 1.625;
      margin-bottom: 56px;
    }

    @media screen and (max-width: 768px) {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 32px;
    }
  }

  #{$ce}-subheader {
    max-width: 680px;
    margin: 37px auto 80px;
    color: var(--theme-color--white);
  
    font-family: var(--theme-fontFamily--default);
    font-style: normal;
    font-weight: var(--theme-fontWeight--medium);
    font-size: 21px;
    line-height: 1.5;
    text-align: center;

    @media screen and (max-width: 1024px) {
      margin-bottom: 56px;

      font-size: 16px;
      line-height: 1.625;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 32px;
    }
  }

  .partnership-wrap {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 138px;
    max-width: 800px;
  
    @media screen and (max-width: 1024px) {
      margin-bottom: 100px;
    }
  
    @media screen and (max-width: 768px) {
      margin-bottom: 80px;
    }
  
    .content {
      border: 1px solid var(--theme-color--white);
      border-top: unset;
    }

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        margin: 0 10px;
        width: 450px;
        max-width: 412px;
        color: var(--theme-color--white);
        font-family: var(--theme-fontFamily--primary);
        font-size: 42px;
        line-height: 1.3;
        font-weight: var(--theme-fontWeight--black);
        text-align: center;

        @media screen and (max-width: 1024px) {
          width: 240px;
          font-size: 23px;
        }
      }

      &-line-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: flex-end;
        flex: 1;
        height: 52px;
        border-top: $cef-line-block-border;

        &:first-child {
          border-left: $cef-line-block-border;
        }

        &:last-child {
          border-right: $cef-line-block-border;
        }

        @media screen and (max-width: 1024px) {
          height: 32px;
        }
      }
    }

    p {
      margin-top: 52px;
      padding: 0 60px;
      color: var(--theme-color--blueText);
      text-align: center;

      &:nth-child(2n) {
        margin: 26px 0 60px;
      }

      @media screen and (max-width: 1024px) {
        padding: 0 32px;
        font-size: 16px;
        line-height: 1.625;

        &:nth-child(2n) {
          margin: 26px 0 32px;
        }
      }
    }
  }

  .fill-form {
    max-width: 680px;
    margin: 0 auto 50px;
    color: var(--theme-color--white);
    text-align: center;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 1.625;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 32px;
    }
  }

  & > form {

    // TODO: Add transitions for all form elements globally
    input,
 
    textarea {
      transition: 0.2s border-color;
    }

    // Common components override
    .react-select__single-value,
    .row-name,
    input {
      color: var(--theme-color--white);

      &:focus,
      &:active,
      &:hover {
        border-color: var(--theme-color--white);
      }
    }

    .row-component {
      color: var(--theme-color--white);
    }

    .react-select__control {
      &:focus,
      &:active,
      &:hover {
        border-color: var(--theme-color--white);
      }
    }

    textarea {
      color: var(--theme-color--white);

      &:focus,
      &:active,
      &:hover {
        border-color: var(--theme-color--white);
      }
    }

    .textarea-darken span {
      color: var(--theme-color--white);
    }
  }

  &--budget {
    .row-left {
      padding-top: 0;
    }
  }

  .phone-input__dropdown {
    color: var(--theme-color--black);
  }
}