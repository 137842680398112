$mlsb-color: #525361;

.multi-level-select-backgorund {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  left: 50%;
  svg {
    stroke: none !important;
  }
  &__arrow {
    position: absolute;
    top: -120px;
    left: -225px;
  }
  &__desc {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__icon {
    position: relative;
    margin-bottom: 20px;
    fill: $mlsb-color;
  }
  &__text {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.625;
    color: $mlsb-color;
  }
}