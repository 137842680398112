.data-processing-consent {
  .check {
    margin-top: 24px;
  }

  .check-inner {
    margin-bottom: 12px;
    display: flex;

    a {
      font-weight: var(--theme-fontWeight--bold);
      text-decoration: none;
      color: var(--theme-color--blue);
      margin-left: 4px;
    }

    span {
      color: var(--theme-color--blue);
    }

    span,
    a {
      line-height: 1.625;
    }
  }
}