.categories-select-outside-handler {
  &--opened {
    z-index: 9999;
  }
}

.cascader-container {
  position: relative;

  width: 100%;
  max-width: 300px;

  margin-right: 8px;
}

.cascader-field {
  position: relative;

  width: 100%;
  max-width: 300px;

  margin-right: 8px;
    
  &__input {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--theme-color--blue);
    outline: none;
    padding: 10px 18px;
    background: var(--theme-color--white);
    border: solid 2px var(--theme-color--ultraLightBlue);
    border-radius: var(--theme-borderRadius--default);
    transition: all 0.2s;
    padding-right: 36px;
    position: relative;
    cursor: default;
    caret-color: rgba(0, 0, 0, 0);
    &::placeholder {
      color: var(--theme-color--lightGrey);
    }
  }

  &__icon {
    position: absolute;
    right: 14px;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
    fill: hsl(0, 0%, 80%);
    cursor: pointer;
    &--open {
      transform: translateY(-50%) rotate(180deg);
      transition: transform .3s ease-in-out;
      fill: hsl(0, 0%, 40%);
    }
  }

  &__error {
    color: red;
  }

  &--active {
    transform: rotate(180deg);
    transition: transform .3s ease-in-out;
    color: hsl(0, 0%, 40%);
  }
}

.cascader-list {
  position: absolute;
  z-index: 3;

  background-color: var(--theme-color--white);
  border: 2px solid var(--theme-color--blueText);
  border-radius: var(--theme-borderRadius--default);

  &__list {
    width: 250px;
    height: 400px;
    padding: 0;

    list-style: none;
    
    background-color: var(--theme-color--white);
    overflow-y: auto;

    
    &:not(:last-of-type) {
      border-right: 1px solid var(--theme-color--blueText);
    }

    // Firefox scrollbar support
    scrollbar-width: thin;
    scrollbar-color: #c4c4c4 #f1f1f1;

    &::-webkit-scrollbar {
      width: 3px;
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
    }
  }

  &__list-item {
    position: relative;
    width: 100%;
    padding: 6px 16px;
    margin: 0;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--grey);
    
    cursor: pointer;
    background-color: transparent;

    &:hover,
    &--active {
      background-color: var(--theme-color--ultraLightBlue);
    }

    &--nested {
      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        
        position: absolute;
        top: 50%;
        right: 15px;
        
        border: 2px solid var(--theme-color--lightGrey);
        border-top: none;
        border-left: none;
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    &--loading {
      display: block;
      padding: 16px 0;
      margin: 6px;
      width: 95%;
      background-color: var(--theme-color--ultraLightBlue);
      animation: opacityAnimation 1.5s infinite;
    }
  }

  &__icon {
    stroke: none;
    fill: var(--theme-color--grey);
  }

  &__counter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    margin-right: 16px;

    background: var(--theme-color--blueText);
    border-radius: var(--theme-borderRadius--default);

    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: var(--theme-color--white);
  }
}

.multi-level-select {
  position: absolute;
  z-index: 3;

  display: flex;

  background-color: var(--theme-color--white);
  border: 2px solid var(--theme-color--blueText);
  border-radius: var(--theme-borderRadius--default);


  @media only screen and (max-width: 1024px) {
    position: fixed;
    top: 68px;
    left: 0;
    z-index: 9999999;
    width: 100%;
    height: 100%;
    border: none;

    .category-header__button {
      fill: var(--theme-color--blue);
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
      outline: none;
      transition: all .3s ease-in-out;
      
      &:hover {
        fill: var(--theme-color--black);
      }
    }

    .cascader-list__list {
      width: 100%;
      height: 100%;
      overflow: scroll;

      li {
        padding: 0 16px;
        min-height: 40px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        font-weight: 500;
        font-size: 16px;
        line-height: 1.625;
        color: var(--theme-color--grey);

        &[data-arrow='true']:after {
          content: '';
          border: 2px solid var(--theme-color--lightGrey);
          width: 8px;
          display: block;
          height: 8px;
          border-top: none;
          border-left: none;
          transform: rotate(-45deg);
        }
        
        &.category__header {
          border: 1px solid var(--theme-color--ultraLightBlue);
          min-height: 48px;

          &--title {
            font-family: 'Playfair Display', sans-serif;
            font-feature-settings: 'case' on, 'liga' off;
            font-weight: 900;
            font-size: 24px;
            line-height: 1.2;
            color: var(--theme-color--blue);
          }
        }
      }
    }
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
