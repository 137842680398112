$eem: '.edit-event-modal';

#{$eem} {
  padding: 16px 40px 40px;
  width: 476px;
  border-radius: var(--theme-borderRadius--modal);
  background-color: var(--theme-color--white);
  box-shadow: var(--theme-boxShadow--modal);

  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  .modal-header {
    margin-bottom: 24px;
  }

  .modal-text {
    margin-bottom: 32px;
  }

  .button:first-child {
    margin-right: 12px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    border-radius: 0;
    height: 100% !important;
    padding: 12px 20px 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.mobileModalScreen{
  @media screen and (max-width: 600px) {
    display: block;
  }

}
.mms{
  @media screen and (max-width: 600px) {
    margin-top: 5px;
    width: 100%
  }

}
