$lef: '.lesson-edit-form';

#{$lef} {
  &__media-wrapper {
    .single-select__library-button {
      width: 100%;
      height: 130px;
      padding: 0;
    }
    
    .remove-button {
      right: 8px;
      top: 8px;
    }
  }

  // Error messages margin - diff fields components have diff spacer for errors
  * + .error {
    margin: 0;
  }
}