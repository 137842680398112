.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
  }
}

.mt {
  &-0 {
    margin-top: 0;
  }

  &-4 {
    margin-top: 4px;
  }

  &-8 {
    margin-top: 8px;
  }

  &-16 {
    margin-top: 16px;
  }

  &-20 {
    margin-top: 20px;
  }

  &-32 {
    margin-top: 32px;
  }

  &-100 {
    margin-top: 100px !important;
  }
}

.mb {
  &-0 {
    margin-bottom: 4px;
  }

  &-4 {
    margin-bottom: 4px;
  }

  &-8 {
    margin-bottom: 8px;
  }

  &-16 {
    margin-bottom: 16px;
  }

  &-24 {
    margin-bottom: 24px;
  }

  &-32 {
    margin-bottom: 32px;
  }

  &-40 {
    margin-bottom: 40px;
  }

  &-62 {
    margin-bottom: 62px !important;
  }
}

.mr {
  &-4 {
    margin-right: 4px;
  }

  &-8 {
    margin-right: 8px;
  }

  &-16 {
    margin-right: 16px;
  }

  &-24 {
    margin-right: 16px;
  }

  &-32 {
    margin-right: 32px;
  }

  &-40 {
    margin-right: 40px;
  }
}

.ml {
  &-4 {
    margin-right: 4px;
  }

  &-8 {
    margin-left: 8px;
  }

  &-16 {
    margin-left: 16px;
  }

  &-24 {
    margin-left: 16px;
  }

  &-32 {
    margin-left: 32px;
  }

  &-40 {
    margin-left: 40px;
  }
}

.pl {
  &-32 {
    padding-left: 32px;
  }
}

.last-child-mb-0 {
  &:last-child {
    margin-bottom: 0;
  }
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
