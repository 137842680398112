.cascader-catagory {
  position: relative;
  &__item {
    position: relative;
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
  &__more {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    cursor: pointer;
    padding: 20px 0 0;
    background: none;
    border: none;
    outline: none;
  }
  .categories-select-wrapper {
    max-width: 100%;
    .multi-level-select {
      display: flex;
      align-items: flex-start;
      ._list {
        min-width: fit-content;
      }
      @media screen and (max-width: 840px) {
        width: 100%;
        z-index: 999999;
      }
    }
  }
  .cascader-field {
    max-width: 100%;
    &__icon { 
      display: none;
    }
    &__input {
      position: relative;
      display: inline-block;
      width: 100%;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 4px;
      border: 2px solid #babbcd;
      padding: 17px;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      cursor: default;
      &--error {
        border-color: red;
      }
    }
  }
}

.category-description {
  font-size: 16px;
  font-weight: var(--theme-fontWeight--medium);
  font-family: var(--theme-fontFamily--default);
  color: var(--theme-color--grey);
  line-height: 1.625;
  margin-top: 16px;
  margin-bottom: 0;
  a {
    text-decoration: none;
    color: var(--theme-color--grey);
    &:hover {
      text-decoration: underline;
    }
  }
}
