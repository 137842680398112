$tbv-scale-slider: 1;

$tbv-media-height-lg: 920px;
$tbv-media-height-md: 850px;
$tbv-media-height-sm: 700px;

$tbv-media-width-lg: 1367px;
$tbv-media-width-md: 1280px;
$tbv-media-width-sm: 1025px;

$tbv-media-width-mb-lg: 992px;
$tbv-media-width-mb-md: 768px;
$tbv-media-width-mb-sm: 576px;
$tbv-media-width-mb-xs: 426px;

@mixin _tbv-media-width($width) {
  @media only screen and (max-width: #{$width - 1px}) {
    @content;
  }
}

@mixin _tbv-media-height($height) {
  @media only screen and (max-height: #{$height - 1px}) {
    @content;
  }
}
