.local-video-default {
  width: 100%;
  height: 100%;

  background: #000000;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}