.calendar-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  background: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%);
  position: relative;

  &__title {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 20px;
    line-height: 1.5;
    font-feature-settings: 'case' on, 'liga' off;
    color: var(--theme-color--white);
  }

  &__info-btn {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    left: 8px;
  }

  &__info-icon {
    fill: white;
    width: 20px;
    height: 20px;
  }
}