.location-list {
  &__item {
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;

    strong {
      display: block;
      font-size: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
