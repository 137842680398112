.selectJoinToTalentModal {
  background-color: get-colour('white');
  padding: 16px 32px 40px;
  border-radius: 8px;
  width: 580px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
  color: get-colour('blue');
  @media screen and (max-width: 725px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &__subtitle {
    font-family: $font-family-default;
    font-weight: get-font-weight('medium');
    font-size: get-font-size('s-1');
    line-height: 1.625;
    color: get-colour('dark-grey');
    text-align: center;
    margin-bottom: 24px;
  }
  &__buttons {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 -15px;
    @media screen and (max-width: 725px) {
      flex-direction: column;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 240px;
    width: 100%;
    background-color: get-colour('ultra-light-blue');
    border-radius: $border-radius;
    color: get-colour('dark-grey');
    font-weight: get-font-weight('medium');
    font-size: get-font-size('m-5');
    border: none;
    outline: none;
    padding: 14px;
    cursor: pointer;
    margin: 0 15px;
    @media screen and (max-width: 725px) {
      max-width: 100%;
      margin: 0;
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
    &:hover {
      color: get-colour('white');
      background-color: get-colour('blue');
    }
  }
}
