.od {
  .row-content {
    .centered {
      text-align: center;
      justify-content: center;
    }
  }
  &-requests {
    &-row {
      grid-template-columns: 5fr 2fr 2fr 3fr !important;
    }
  }

  &-participants {
    &-row {
      grid-template-columns: 5fr 2fr 2fr 2fr 3fr !important;
    }
  }
  
  .user-info {
    padding: 16px 0;
  }

  .bold {
    font-weight: var(--theme-fontWeight--bold);
  }

  .react-select-container {
    margin-right: 8px;
  }
}

.live-virtual-event-order {
  margin-bottom: 72px;
}
