:root {
  --theme-color--blue: #272835;
  --theme-color--grey: #626473;
  --theme-color--blueText: #babbcd;
  --theme-color--lightGrey: #9495a7;
  --theme-color--ultraLightBlue: #e9eaf0;
  --theme-color--accepted: #007b2a;
  --theme-color--rejected: #d93025;
  --theme-color--white: #ffffff;
  --theme-color--black: #000000;

  --theme-fontFamily--default: Montserrat;
  --theme-fontFamily--primary: Playfair Display;

  --theme-fontWeight--normal: 400;
  --theme-fontWeight--medium: 500;
  --theme-fontWeight--semiBold: 600;
  --theme-fontWeight--bold: 700;
  --theme-fontWeight--black: 900;

  --theme-boxShadow--modal: 0 20px 60px rgba(0, 0, 0, 0.3);
  --theme-boxShadow--order: 10px 10px 20px rgba(209, 210, 225, 0.3), -10px -10px 20px rgba(250, 250, 255, 0.5);

  --theme-borderRadius--default: 4px;
  --theme-borderRadius--modal: 8px;

  --theme-border--default: 2px solid #babbcd;

  // todo: add normal z-index map
  --theme-zIndex--12: 12; // select

  --theme-header-height: 85px;
  --theme-header-height-small: 74px;
}
