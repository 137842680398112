$tpv-page-m: '.tpv-page-mobile';

#{$tpv-page-m} {
  &__wrapper {
    display: flex;
    position: relative;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    .main-image {
      position: absolute;
      max-width: 65%;

      @media screen and (max-width: 991px) {
        max-width: 55%;
      }

      @media screen and (max-width: 767px) {
        position: relative;
        height: 600px;
        max-width: 100%;
        margin-bottom: 20px;

        img {
          height: 100%;
        }
      }
    }
  }

  &__content {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex: 0 0 344px;
    max-width: 344px;
    margin-left: auto;

    @media screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      align-items: center;
    }

    .tpv-page__description {
      margin-bottom: 64px;
    }

    .commercial-engagement {
      width: 100%;
      flex-direction: row;
      position: relative;
      margin-top: 50px;
      margin-bottom: 65px;
      padding: 16px 0;

      @media screen and (max-width: 767px) {
        justify-content: center;
      }

      @media screen and (max-width: 425px) {
        justify-content: stretch;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(90deg, rgba(148, 149, 167, 0.25) -0.77%, #6b6d86 43.22%, rgba(148, 149, 167, 0.25) 86.3%);;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }

      .icon {
        margin-right: 12px;

        svg {
          height: 37px;
        }
      }

      .text {
        margin: 0;
        text-align: left;
      }

      .button {
        width: auto;

        @media screen and (max-width: 1024px) {
          margin-left: auto;
        }

        @media screen and (max-width: 767px) {
          margin-left: 40px;
        }

        @media screen and (max-width: 425px) {
          margin-left: auto;
        }

        &__item {
          padding: 7px 14px;
        }

        &__text {
          font-size: 12px;
        }
      }
    }
  }

  &__block-info {
    display: flex;
    justify-content: space-between;

    .tpv-page__description {
      flex: 0 0 344px;
      max-width: 344px;
    }

    .block-info {
      padding-top: 280px;
      padding-bottom: 64px;

      @media screen and (max-width: 991px) {
        padding-top: 150px;
      }

      @media screen and (max-width: 830px) {
        padding-top: 50px;
      }

      @media screen and (max-width: 575px) {
        padding-top: 0;
      }
    }
  }
}
