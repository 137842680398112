.reject-modal {
  padding: 40px;
  width: 544px;
  display: flex;
  flex-direction: column;

  border-radius: var(--theme-borderRadius--modal);
  background-color: var(--theme-color--white);

  .modal-header {
    margin-bottom: 12px;
  }

  .input-label {
    display: block;
    margin-bottom: 24px;
  }

  .textarea {
    height: 154px;
  }


  @media screen and (max-width: 575px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 24px;
    overflow: scroll;

    .modal-header {
      margin-top: 54px;
    }

    .input-label {
      margin-top: 12px;
    }

    .textarea {
      height: 328px;
    }
  }
}
