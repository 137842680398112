$bn-p-height: 78px;

.currency-and-language {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px 16px $bn-p-height + 24px;
  }

  &__btn {
    margin-top: auto;
  }

  .react-select-container {
    margin-bottom: 32px;

    .select-label {
      font-family: var(--theme-fontFamily--primary);
      font-weight: var(--theme-fontWeight--black);
      font-size: 20px;
      line-height: 1.3;
      color: var(--theme-color--blue);
      text-transform: none;
    }

    .react-select__control {
      height: 56px;
    }
  }
}
