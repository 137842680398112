$p-v-f-i: '.public-view__fixed-item';

#{$p-v-f-i} {
  border: 1px solid get-colour('white');
  border-top-width: 0;
  text-align: center;
  flex: 1 1;
  height: 80px;

  h2 {
    display: table;
    width: 100%;
    margin-bottom: -10px;
    &:before,
    &:after {
      content: '';
      border-top: 1px solid get-colour('white');
      width: 25%;
    }
    &:before,
    &:after,
    span {
      display: table-cell;
      white-space: nowrap;
    }
    span {
      font-size: 24px;
      color: get-colour('white');
      text-align: center;
      padding: 0 10px;
      position: relative;
      top: -16px;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  p {
    font-family: $font-family-default;
    font-size: 24px;
    font-weight: get-font-weight('medium');
    line-height: 1;
    margin-top: 8px;
    color: get-colour('white');
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 42px;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
    span {
      color: get-colour('blue-text');
    }
  }

  small {
    font-family: $font-family-default;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.625;
    text-align: center;

    color: get-colour('white');
  }

  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    height: 60px;
  }
}