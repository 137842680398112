$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;

.chat-window-header {
  background: var(--theme-color--blue);
  border-radius: 4px 4px 0 0;
  padding: 10px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: #{$media-md}) {
    border-radius: 0;
  }

  &__text {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 24px;
    line-height: 1.25;

    font-feature-settings: 'case' on, 'liga' off;

    color: var(--theme-color--white);
  }

  &__btn {
    background: none;
    border: none;
    cursor: pointer;
  }
}