.categories-select {
  &-wrapper {
    width: 100%;
    max-width: 300px;
    margin-right: 8px;
    @media screen and (max-width: 1024px) {
      max-width: 100%;
      .cascader-field {
        max-width: 100%;
      }
    }

    .multi-level-select {
      top: 68px;
      left: 0;
      z-index: var(--theme-zIndex--12);
      margin-bottom: 8px;
      margin-top: 8px;
      background: #ffffff;
      box-shadow: -1px 2px 12px rgba(0, 0, 0, 0.18);
      border-radius: 4px;
      width: 100%;
      border: none;

      ._list {
        width: 100%;
        box-shadow: none;
        max-height: 300px;
        overflow-y: auto;
        padding-bottom: 4px;
        padding-top: 4px;
        position: relative;
        min-width: 100%;
      }
      .cascader-list__list {
        width: calc(100% / 3);
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }
  }

  &-input {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #272835;
    outline: none;
    padding: 10px 18px;
    background: #ffffff;
    border: solid 2px #e9eaf0;
    border-radius: 4px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    padding-right: 36px;
    position: relative;
  }

  position: relative;

  svg {
    position: absolute;
    right: 14px;
    top: 0;
    bottom: 0;
    margin: 12px 0;
    color: hsl(0, 0%, 80%);
  }

  &.active {
    svg {
      transform: rotate(180deg);
      transition: transform .3s ease-in-out;
      color: hsl(0, 0%, 40%);
    }
  }

  @media only screen and (max-width: 767px) {
    &-wrapper {
      max-width: 100%;

      .multi-level-select {
        top: 0;
        padding: 0;
        margin: 0;
      }
    }
  }
}