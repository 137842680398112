.user-calendar {

  &__header {
    margin: 40px 0 12px;
    color: var(--theme-color--blue);
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 60px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

    @media all and (max-width: 575px) {
      margin: 20px 0;
      font-size: 32px;
    }
  }

  &__time-zone {
    margin: 12px 0 24px;
    text-align: center;

    span {
      margin-left: 4px;
      font-weight: var(--theme-fontWeight--bold);
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    background-color: #ffffff;
    box-shadow: 10px 10px 20px rgba(209, 210, 225, 0.3), -10px -10px 20px rgba(250, 250, 255, 0.5);
    border-radius: var(--theme-borderRadius--default);

    margin: 0 auto 80px;

    @media all and (max-width: 768px) {
      flex-direction: column-reverse;
      box-shadow: none;
      border-radius: 0;
    }
  }

  &__info {
    flex: 1;
    min-width: 280px;
    position: relative;
    border-right: 1px solid #dddddd;

    @media all and (max-width: 767px) {
      position: unset;
    }
  }
}