//
// Classes
//

$m-m: '.main-menu';

$ac: '.account-menu';

//
// Colors
//

$main-light: get-colour('white');
$main-dark: get-colour('blue');

$light-theme-color: get-colour('blue-text');
$dark-theme-color: get-colour('dark-grey');


$light-theme-triangle-color: get-colour('grey');
$dark-theme-triangle-color: get-colour('dark-blue');

//
// RoomHeader abstract classes
//
// Feel free to extend this list of modifiers based on you needs for custom header theme styling
//
// *Important note*
// Remember that abstract classes actually are rendered in place of declaration, not in the place of usage
//

// Dark theme colors
%dark-theme-main-color {
  @at-root .dark-theme #{&} {
    color: $main-light;
    fill: $main-light;
  }
}

%dark-theme-color {
  @at-root .dark-theme #{&} {
    color: $light-theme-color;
  }
}

%dark-theme-svg {
  @at-root .dark-theme #{&} {
    stroke: $light-theme-color;
  }
}

%dark-main-theme-svg {
  @at-root .dark-theme #{&} {
    stroke: $main-light;
  }
}

// Light theme colors
%light-theme-main-color {
  @at-root .light-theme #{&} {
    color: $main-dark;
    fill: $main-dark;
  }
}

%light-theme-color {
  @at-root .light-theme #{&} {
    color: $dark-theme-color;
  }
}

%light-theme-svg {
  @at-root .light-theme #{&} {
    stroke: $dark-theme-color;
  }
}

%light-main-theme-svg {
  @at-root .light-theme #{&} {
    stroke: $main-dark;
  }
}

// Main theme colors
%main-theme-colors {
  @extend %dark-theme-main-color;
  @extend %light-theme-main-color;
}

// Theme colors brighter/darken than main
%theme-colors {
  @extend %dark-theme-color;
  @extend %light-theme-color;
}

// Same as theme color but for svg
%theme-colors-svg {
  @extend %dark-theme-svg;
  @extend %light-theme-svg;
}

// Same as main theme color but for svg
%main-theme-colors-svg {
  @extend %dark-main-theme-svg;
  @extend %light-main-theme-svg;
}


// Triangle colors for sub menu hover indicator

%dark-theme-triangle {
  @at-root .dark-theme #{&} {
    background: $dark-theme-triangle-color;
  }
}

%light-theme-triangle {
  @at-root .light-theme #{&} {
    background: $light-theme-triangle-color;
  }
}

%indicator-theme-triangle {
  @extend %dark-theme-triangle;
  @extend %light-theme-triangle;
}

%opened-menu {
  @at-root .dark-theme #{&} {
    color: $main-light;
  }
  @at-root .light-theme #{&} {
    color: $main-light;
  }
}

%opened-menu-svg {
  @at-root .dark-theme #{&} {
    stroke: $main-light;
  }
  @at-root .light-theme #{&} {
    stroke: $main-light;
  }
}
