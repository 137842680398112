// need to remove form-row, because it links to video chat
.data-field,
.form-row {
  .experience-time-controller-container {
    display: flex;
    justify-content: space-between;
  }

  .experience-time-controller {
    width: 45%;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;

    &__input {
      width: 140px;
    }

    &__label {
      @include Body2;
      color: $blue;
      padding-right: 10px;
      margin: auto;
    }
  }

  .basic-multi-select {
    > div {
      border: 2px solid #babbcd;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;

      color: #9495a7;

      height: 60px;
    }
  }

  .profile-picture-block.experience-picture-block {
    .profile-picture-preview {
      border: 2px solid #babbcd;
      box-sizing: border-box;
      border-radius: 4px;
      width: 561px;
      height: 230px;

      p {
        max-width: unset;
      }
    }
  }

  .field-content.participant {
    display: flex;
    justify-content: space-between;
    width: 561px;

    .participant-wrap {
      display: flex;

      & > div {
        display: flex;
        flex-direction: column;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;

        h4 {
          font-size: 16px;
          line-height: 26px;
          color: #272835;
        }

        h6 {
          margin: 0;
          font-size: 13px;
          line-height: 22px;
          color: #626473;
          font-weight: 500;
        }
      }

      input {
        border: 2px solid #babbcd;
        box-sizing: border-box;
        border-radius: 4px;
        width: 80px;
        height: 60px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;

        /* identical to box height, or 178% */
        display: flex;
        align-items: center;
        margin-left: 16px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* light grey */
        &::placeholder {
          color: #9495a7;
        }
      }
    }
  }
}

.submit-container {
  position: relative;
  margin: 0 auto;
}

.experience-button-container {
  position: relative;

  > button {
    &:first-child {
      margin-right: 20px;
    }
  }
}

.experience-error-message {
  color: red;
  text-align: center;
  display: block;
}

.type-header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #9495a7;
}

.close-modal-button {
  outline: none;
  cursor: pointer;
  border: none;

  position: absolute;
  right: -18px;
  top: -15px;

  i {
    font-size: 26px;
  }

  padding: 0;

  height: 26px;
  width: 26px;

  background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
  border-radius: 50%;

  &::after,
  &::before {
    content: ' ';

    height: 17px;
    width: 3px;

    position: absolute;

    left: 12px;
    top: 5px;

    background: white;

    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
}

.create-experience-form {
  padding-left: 15px;
  padding-right: 15px;

  // my styles

  .experience__header {
    margin-bottom: 62px;
  }

  .experience__sub-header {
    margin-top: 50px;
  }

  .required-information {
    @include Small;
    color: $blue;
  }

  .add-dollar {
    color: $blue;

    input::before {
      position: absolute;
      top: 0;
      right: 0;
      content: '$';
    }

    &::placeholder {
      color: $blue;
    }
  }

  .experience-button-container {
    button {
      margin-top: 0;
    }
  }

  .radio-group {
    // height: calc(151px - (62px + 62px));

    display: flex;
    flex-direction: row;
    align-items: center;

    .radio {
      margin-right: 45px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &__input {
        height: 26px;
        width: 26px;
        margin-right: 14px;

        position: relative;

        background: white;
        border: 2px solid black;

        cursor: pointer;

        &:checked {
          border: 2px solid black;

          &:after {
            content: ' ';
            background: black;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            position: absolute;
            top: calc(50% - 6px);
            left: calc(50% - 6px);
          }
        }
      }

      &__label {
        @include Body2;

        color: $blue;
      }
    }
  }

  .checkmark__container {
    margin-bottom: 0;
  }

  .mb-0 {
    margin-bottom: 0;
  }
  &:checked::after {
    content: ' ';
    background: black;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
  }
}

.pl-32 {
  padding-left: 32px;
}
