.how-to-videos {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
    font-size: 60px;
    line-height: 1.75em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: var(--theme-color--blue);
    margin: 48px 0;

    @media only screen and (max-width: 1024px) {
      font-size: 32px;
    }

    @media only screen and (max-width: 450px) {
      font-size: 28px;
    }
  }

  &__item {

    h6 {
      font-family: var(--theme-fontFamily--primary);
      font-weight: var(--theme-fontWeight--black);
      font-size: 23px;
      line-height: 1.8em;
      font-feature-settings: 'case' on, 'liga' off;
      color: var(--theme-color--blue);
    }

    p {
      font-weight: var(--theme-fontWeight--medium);
      font-size: 16px;
      line-height: 1.625em;
      color: var(--theme-color--grey);
      overflow-wrap: break-word;
    }

    span {
      font-weight: var(--theme-fontWeight--bold);
      font-size: 16px;
      line-height: 1.625em;
      color: var(--theme-color--blue);
    }

    &--border-line {
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(39, 40, 53, 1) 0%, rgba(39, 40, 53, 0) 95%);
      margin: 48px 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 680px;
  }

  &__search {
    background: var(--theme-color--white);
    border: 2px solid var(--theme-color--ultraLightBlue);
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    padding: 0 18px;
    margin-bottom: 48px;
    align-items: center;
    width: 440px;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    &--input {
      font-weight: var(--theme-fontWeight--medium);
      font-size: 16px;
      line-height: 1.625em;
      color: var(--theme-color--lightGrey);
      min-height: 48px;
      width: 100%;
    }
  }

  &__svg-button {
    cursor: pointer;
    margin: 15px;
  }
}