.video-message,
.video-chat,
.video-message-commercial-use {
  width: 100%;
  margin: -30px auto 0;
  padding: 0 16px;

  &__row-radio {
    .form-row__item:first-of-type {
      margin-top: 0;
    }
  }

  .header {
    margin-top: 64px;
    margin-bottom: 40px;
    @include h1;
    color: #272835;
    text-align: center;
    @media screen and (max-width: 991px) {
      font-size: 45px;
      line-height: 1.62;
    }
    @media screen and (max-width: 767px) {
      font-size: 38px;
      line-height: 1.62;
    }
    @media screen and (max-width: 575px) {
      font-size: 30px;
      line-height: 1.62;
    }
  }

  &__subheader {
    @extend .font-body-2;
    margin-bottom: 62px;
    text-align: center;

    a {
      color: get-colour('blue');
    }
  }

  .form-tip {
    @include Small;
    color: $blue;
    margin-bottom: 16px;
  }

  .input {
    height: 60px;
  }

  //video message commercial use
  .vmcu {

    &-information {
      font-family: $font-family-default;
      font-weight: get-font-weight('medium');
      font-size: 16px;
      line-height: 26px;
      color: get-colour('dark-grey');

      &__header {
        margin-bottom: 12px;
      }

      &__list {
        list-style: disc;
        padding-left: 20px;
      }

      &__list-item {
        margin-bottom: 12px;
      }

      &__legal-btn {
        font-family: $font-family-default;
        font-size: 16px;
        line-height: 26px;
        font-weight: get-font-weight('bold');
        color: get-colour('blue');

        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
}

