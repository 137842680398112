@mixin info-header {
  font-weight: var(--theme-fontWeight--bold);
  font-size: 12px;

  line-height: 1.33;
  text-transform: uppercase;

  color: var(--theme-color--lightGrey);
}

.active-chat-details {
  &__wrapper {
    height: 100%;
    width: 272px;

    background: var(--theme-color--white);
    box-shadow: 10px 10px 20px rgba(209, 210, 225, 0.3),
      -10px -10px 20px rgba(250, 250, 255, 0.5);
    border-radius: 0 4px 0 0;

    border-left: 1px solid var(--theme-color--ultraLightBlue);

    @media only screen and (max-width: 968px) {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - 325px);
    }

    @media only screen and (max-width: 768px) {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
    }
  }

  &__header {
    @include info-header;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 32px;
    padding: 11px;

    border-bottom: 1px solid var(--theme-color--ultraLightBlue);

    &-title {
      width: 250px;

      display: flex;
      align-items: center;

      font-size: 12px;
      font-weight: var(--theme-fontWeight--bold);

      line-height: 1.33;

      text-transform: uppercase;

      p {
        width: 200px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      svg {
        fill: var(--theme-color--lightGrey);
        margin-right: 10px;
      }

      color: var(--theme-color--lightGrey);
    }
  }

  &__close-icon {
    width: 10px !important;
    height: 10px !important;

    cursor: pointer;
  }

  &__event-info {
    display: flex;
    flex-direction: column;

    font-size: 12px;

    padding: 12px;

    border-bottom: 1px solid var(--theme-color--ultraLightBlue);

    .event-title {
      font-style: normal;
      font-weight: var(--theme-fontWeight--bold);
      font-size: 16px;
      line-height: 1.6;

      width: 250px;
      padding-bottom: inherit;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      color: var(--theme-color--blue);
    }

    .event-date {
      display: flex;
      align-items: center;

      font-style: normal;
      font-weight: var(--theme-fontWeight--bold);
      font-size: 14px;
      line-height: 1.6;

      color: var(--theme-color--blue);

      svg {
        margin-right: 10px;
      }
    }

    .event-location {
      display: flex;
      align-items: center;

      font-style: normal;
      font-weight: var(--theme-fontWeight--bold);
      font-size: 14px;
      line-height: 1.6;

      color: var(--theme-color--blue);

      svg {
        margin-right: 10px;
      }
    }

    .offering-details-header-item {
      &__text {
        .item {
          font-size: 14px;
        }
        .subitem {
          font-size: 13px;
        }
      }
    }

    .event-link {
      &__link {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &--text {
          font-family: var(--theme-fontFamily--default);
          font-size: 14px;
          line-height: 1.6;
          font-weight: var(--theme-fontWeight--bold);
          color: var(--theme-color--blue);
          margin-right: 4px;
        }

        &--arrow {
          width: 8px;
          height: 8px;
          transform: rotate(-45deg);
          border-right: solid 2px var(--theme-color--blue);
          border-bottom: solid 2px var(--theme-color--blue);
        }
      }
    }
  }

  &__participants-list {
    &-header {
      @include info-header;
      padding: 8px 15px;
    }
  }
}

.chat-participant__wrapper {
  padding: 8px 15px;

  .user-info {
    &__avatar {
      width: 40px;
      height: 40px;
      min-width: 40px;

      margin-right: 10px;

      .avatar {
        width: 40px;
        height: 40px;
      }
    }

    &__fullname {
      font-size: 14px;
      font-weight: var(--theme-fontWeight--medium);

      line-height: 1.14;

      padding-bottom: 4px;
    }

    .user-info__stats {
      margin-bottom: unset;
    }

    .user-info__reviews-link {
      font-size: 12px;
      line-height: 1.5;
    }

    .entourage-logo {
      transform: scale(.8) translate(-50%, -50%);
    }
  }
}
