$p-v: '.public-view';

#{$p-v} {
  &__heading {
    &.inverted {
      h1 {
        color: get-colour('blue');
        overflow-wrap: break-word;
      }
    }
    &.short {
      padding: 20px 0 14px;
      @media only screen and (max-width: 1024px) {
        padding: 20px 0 24px;
      }
    }
  }

  .arrow-down {
    display: block;
    min-height: 60px;
    position: relative;

    div {
      bottom: 20px;
      opacity: 1;

      span {
        border-right: 2px solid get-colour('blue');
        border-bottom: 2px solid get-colour('blue');
        width: 14px;
        height: 14px;
        &.down-arrow-2 {
          margin-top: 0;
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  #{$p-v-c} {
    &--bordered {
      .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media only screen and (max-width: 1024px) {
          justify-content: inherit;
        }
        @media only screen and (max-width: 768px) {
          text-align: center;
          padding-bottom: 40px;
          .tip {
            text-align: center;
          }
        }
      }

      .slider {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1024px) {
          align-items: flex-start;
        }
      }
    }
  }

  &__price {
    padding: 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.inverted {
      flex-direction: column;
      align-items: normal;

      .price-wrapper {
        margin-bottom: 32px;
      }
    }

    .price-wrapper {
      p {
        font-family: $font-family-primary;
        font-weight: get-font-weight('black');
        font-size: 20px;
        line-height: 1.45;
        font-feature-settings: 'case' on, 'liga' off;
        color: get-colour('blue');;
      }

      ._price__value {
        font-size: 45px;
        line-height: 55px;

        @media screen and (max-width: 991px) {
          margin-bottom: 24px;
        }
      }

      div {
        font-family: $font-family-default;
        font-weight: get-font-weight('bold');
        font-size: 45px;
        line-height: 1.25;
        text-transform: uppercase;
        color: get-colour('blue');

        span {
          font-family: $font-family-default;
          font-weight: get-font-weight('bold');
          font-size: 16px;
          line-height: 1.625;
          color: get-colour('blue');
        }
      }
    }
  }

  &-description {
    &--one-line {
      font-family: $font-family-default;
      font-weight: get-font-weight('medium');
      font-size: 16px;
      line-height: 1.625;
      color: get-colour('blue');
      overflow-wrap: anywhere;

      a,
      button {
        font-weight: get-font-weight('bold');
        color: get-colour('blue');
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;
      }

      .tip {
        font-weight: get-font-weight('medium');
        font-size: 14px;
        line-height: 1.3;
        color: get-colour('light-grey');
        display: block;
        width: 100%;
        margin: 0;
        max-width: initial;
        text-align: left;
      }

      .inline {
        display: inline;
      }

      .grayed {
        color: get-colour('dark-grey');
      }

      span {
        font-weight: get-font-weight('bold');
      }
    }
  }

  &__how-to {
    background: get-colour('blue');
    background-size: 100%;
    color: get-colour('white');
    &--video-message {
      background-image: url('../../../../assets/images/videoMessage.png');
      background-repeat: no-repeat;
    }
    &--video-chat {
      background-image: url('../../../../assets/images/body-default-dark-background.jpg');
      background-repeat: repeat-y;
    }

    @media only screen and (max-width: 1024px) {
      & &--list {
        h1 {
          padding-bottom: 120px;
        }
      }
    }

    .wrapper {
      padding-top: 150px;
      @media only screen and (max-width: 1024px) {
        padding-top: 60px;
      }
    }

    h1 {
      font-family: $font-family-primary;
      font-weight: get-font-weight('black');
      font-size: 60px;
      line-height: 75px;
      text-align: center;
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      color: get-colour('white');
      margin-bottom: 130px;
      @media only screen and (max-width: 1024px) {
        padding-bottom: 70px;
        margin: 0;
        font-size: 32px;
        line-height: 54px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 28px;
        line-height: 38px;
      }
    }

    &--grid {
      display: grid;
      max-width: 920px;
      grid-template-columns: repeat(2, minmax(100px, 442px));
      grid-gap: 34px;
      margin: auto;

      #{$p-v-f-i} {
        height: auto;
        h2 {
          margin-bottom: -35px;
          span {
            font-family: $font-family-primary;
            font-weight: get-font-weight('black');
            font-size: 42px;
            line-height: 54px;
            font-feature-settings: 'case' on, 'liga' off;
            top: -35px;
          }
        }
        p {
          padding: 40px 56px;
          font-weight: get-font-weight('medium');
          font-size: 21px;
          line-height: 32px;
          text-overflow: none;
          white-space: unset;
          overflow: auto;
          @media only screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(1, minmax(100px, 100%));
        grid-gap: 64px;

        #{$p-v-f-i} {
          h2 {
            span {
              font-size: 24px;
              line-height: 1.45;
              top: -20px;
            }
          }
        }
      }
    }

    .sponsorship-tip {
      font-family: $font-family-default;
      font-weight: get-font-weight('medium');
      font-size: 16px;
      line-height: 1.625;
      color: get-colour('blue-text');
      max-width: 400px;
      margin: 48px auto 0;

      @media only screen and (max-width: 768px) {
        text-align: center;
      }

      a {
        color: get-colour('white');
      }
    }

    &-item {
      display: flex;
      padding-bottom: 130px;

      &:nth-child(odd) {
        flex-direction: row-reverse;

        #{$p-v}__how-to-item {
          &--description {
            text-align: right;
          }

          &--number {
            transform: translateX(-50%);
            left: 100%;
          }
        }
      }

      &--icon,
      &--description {
        flex: 1;
      }

      &--icon {
        display: flex;
        justify-content: center;

        svg {
          width: 100%;
          min-height: 160px;

          path {
            fill: get-colour('white');
          }
        }
      }

      &--description {
        position: relative;
      }

      &--number {
        position: absolute;
        left: 0%;
        top: -25%;
        transform: translateX(-50%);
        color: get-colour('blue-text');
        opacity: .15;
        font-family: $font-family-primary;
        font-weight: get-font-weight('bold');
        font-size: 170px;
        font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off, 'case' on;
        line-height: 1.13;
      }

      &--content {
        h2 {
          font-family: $font-family-primary;
          font-weight: get-font-weight('black');
          font-size: 42px;
          line-height: 54px;
          font-feature-settings: 'case' on, 'liga' off;
          color: get-colour('white');
          margin-bottom: 32px;
        }

        p {
          font-family: $font-family-default;
          font-weight: get-font-weight('medium');
          font-size: 22px;
          line-height: 1.6;
          color: get-colour('blue-text');;
        }
      }

      @media only screen and (max-width: 1024px) {
        display: block;
        position: relative;

        &:nth-child(odd) {
          #{$p-v}__how-to-item {
            &--description {
              text-align: left;
            }

            &--number {
              transform: translateX(0%);
              left: 0;
            }
          }
        }

        &--number {
          top: -50%;
          transform: translateX(0%);
          left: 0;
          font-size: 93px;
          opacity: .2;
        }

        &--icon {
          position: relative;

          svg {
            min-height: 60px;
            position: absolute;
            right: -40%;
            top: 20%;
            margin-bottom: 24px;
            transform: translateY(-22px);

            path {
              fill: url(#Member);
            }
          }
        }

        &--content {
          h2 {
            font-size: 20px;
            line-height: 28px;
          }
          p {
            font-size: 16px;
            line-height: 1.625;
          }
        }
      }

      @media only screen and (max-width: 540px) {
        &--number {
          top: -40%;
        }
      }
    }
  }
}
