.search-container {
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  input,
  input.custom-input,
  .react-select__placeholder {
    font-size: 16px;
    
    &::placeholder {
      font-size: 16px;
    }
  }

  .categories-select-wrapper {
    max-width: unset;

    .cascader-field {
      max-width: unset;
    }
  }
}

.search-list {
  display: grid;
  grid-template-columns: repeat(6, minmax(204px, 1fr));
  grid-gap: 26px;
  @media screen and (max-width: 1460px) {
    padding: 0 57px;
    grid-template-columns: repeat(5, minmax(204px, 1fr));
    grid-gap: 34px 26px;
  }
  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(4, minmax(204px, 1fr));
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, minmax(204px, 1fr));
  }
  @media screen and (max-width: 820px) {
    grid-template-columns: repeat(2, minmax(204px, 1fr));
  }
  &__item {
    display: flex;
    justify-content: center;
  }
}

.search-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;

  &__info {
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 575px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  &__count {
    font-family: 'Playfair Display', sans-serif;
    font-weight: 900;
    font-size: 28px;
    line-height: 1.35;
    font-feature-settings: 'case' on, 'liga' off, 'lnum' on;
    color: #272835;
    @media screen and (max-width: 575px) {
      font-size: 24px;
    }
  }

  &__link {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.625;
    color: #626473;
    @media screen and (max-width: 575px) {
      display: none;
    }
  }
}

.empty-list {
  padding: 72px 0;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-family: 'Playfair Display', sans-serif;
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;
    font-feature-settings: 'case' on;
    color: #272835;
    text-align: center;
    margin-bottom: 24px;
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #626473;
    text-align: center;
    margin-bottom: 32px;
  }
}
