.manage-orders-table {
  margin-top: 0;
  margin-bottom: 40px;
  .title {
    margin: 50px 0 40px;
    font-family: 'Playfair Display', sans-serif;
    font-size: 60px;
    font-weight: 900;
    line-height: 1.25;
    color: #272835;
    text-align: center;
  }
  .row-content {
    min-height: 82px;
    grid-template-columns: minmax(110px, 1fr) 2fr 1.5fr 2fr 2fr 1.5fr 1.5fr !important;
    grid-gap: 5px 12px !important;
  }
  .center {
    justify-content: center;
    text-align: center;
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      text-align: left;
    }
  }
}