.promotional-medial-modal {
  width: 400px;
  padding: 16px;
  background-color: var(--theme-color--white);
  border-radius: var(--theme-borderRadius--modal);

  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;

    margin-bottom: 32px;
    flex: 1;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cancel-button {
    margin-right: 16px;
  }

  &__count {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blue);

    strong {
      font-weight: var(--theme-fontWeight--bold);
    }
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    height: 100%;

    border-radius: 0;
  }
}

.checkbox-list-item {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 12px 0;
  cursor: pointer;

  &__content {
    display: flex;
    align-items: center;


    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--blue);
    width: 100%;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &__input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ i {
      background-color: var(--theme-color--blue);
      border: none;
      &:before {
        display: block;
      }
    }
    &:disabled ~ i {
      background-color: #cccccc;
    }
  }

  &__checkbox {
    position: relative;
    height: 25px;
    width: 25px;
    border: 2px solid var(--theme-color--blueText);
    border-radius: 4px;
    &:before {
      display: none;
      content: '';
      position: absolute;
      top: 20%;
      left: 50%;
      width: 4px;
      height: 12px;
      border: solid var(--theme-color--white);
      border-width: 0 2px 2px 0;
      transform: rotate(40deg) translateX(-50%);
    }
  }
}
