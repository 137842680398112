$media-sm: 575px;
.entourage-modal-card {
    width: 444px;
  
    padding: 24px 34px 34px;
    background: var(--theme-color--white);
    box-shadow: var(--theme-boxShadow--modal);
  
    @media only screen and (max-width: #{$media-sm}) {
      width: 100%;
      padding: 12px 16px 16px;
    }
}
.entourage-modal{
  &__text{
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    text-align: center;
    color:#626473,
    }
}
  