.active-chat-wrapper {
  font-family: var(--theme-fontFamily--default);
  font-style: normal;

  min-height: 230px;
  min-width: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
}

.active-chat-loader {
  display: flex;
  justify-content: center;

  padding: 50px;
}

.active-chat-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;
}

.mobile-view {
  &.active-chat-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background: var(--theme-color--white);
  }
}
