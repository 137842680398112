$cs: '.calendar-slot';
$fc: '.fc';

#{$cs} {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#{$cs}__info {
  overflow: hidden;
  flex: 1;
  padding: 4px;

  &__title {
    font-weight: var(--theme-fontWeight--bold);
    font-size: 10px;
  }

  &__desc {
    letter-spacing: -0.1px;
  }
}


#{$cs}__before,
#{$cs}__after {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(#000000, 0.3);
}


#{$cs}__before {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}


#{$cs}__after {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}



//
// .fc override
//
// To hide driving time for events that are splitted between dates
#{$fc}-event {
  &-start:not(&-end) {
    #{$cs}__after {
      display: none;
    }
  }

  &-end:not(&-start) {
    #{$cs}__before {
      display: none;
    }
  }
}

#{$fc}-list {
  #{$cs}__before,
  #{$cs}__after,
  #{$cs}__info__desc {
    display: none;
  }

  #{$cs}__info {
    padding: 0;
  }
}