@import 'styles/_legacy/abstracts/_mixins.scss';

$drawer-width: 100%;
$header-height: 32px;

.drawer {
  position: absolute;
  top: 0;
  z-index: 3;
  opacity: 0;
  left: -$drawer-width;

  background-color: #ffffff;

  height: 100%;
  width: $drawer-width;
  padding-top: $header-height;

  transition: all ease-in .375s;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.25);

  @media all and (max-width: 767px) {
    height: auto;
  }

  &--active {
    left: 0;
    opacity: 1;
    height: 100%;
  }

  &__header {
    background-color: var(--theme-color--white);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: var(--theme-borderRadius--default) 0 0 0;

    height: $header-height;
    width: 100%;
    padding: 8px 12px;

    position: absolute;
    top: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--bold);
    font-size: 12px;
    line-height: 1.33;
    text-transform: uppercase;
    color: var(--theme-color--lightGrey);
    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;
    margin-right: 12px;
  }

  &__close-btn {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }

  &__inner {
    height: 100%;
    overflow-y: auto;

    @include customScrollbar();
  }
}
