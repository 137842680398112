$bn-p: '.bottom-navigation__panel';
$bn-p-height: 78px;
$bn-p-bg-gradient: linear-gradient(0deg, var(--theme-color--blue) -20%, #636583 180%);


#{$bn-p} {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: $bn-p-height;
  background: $bn-p-bg-gradient;
  z-index: 100001;
}