.keyword-search__aggregation {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 220px;
  margin-right: 24px;

  @media screen and (max-width: 575px) {
    padding: 10px 24px;
  }

  @media screen and (max-width: 450px) {
    padding: 10px 0;
  }

  &--item {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background: #1d1e2b;
      border-radius: 24px;
    }
  }

  h6 {
    font-weight: var(--theme-fontWeight--bold);
    font-size: 13px;
    line-height: 1.125em;
    text-transform: uppercase;
    color: var(--theme-color--ultraLightBlue);
    margin-bottom: 12px;
    margin-left: 12px;
  }

  p {
    font-weight: var(--theme-fontWeight--medium);
    font-size: 14px;
    line-height: 1.625em;
    color: var(--theme-color--lightGrey);
    text-transform: capitalize;
  }
}