$odc: '.od__countdown';

#{$odc} {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 0;

  &--item {
    margin: 0 4px;

    p {
      font-weight: var(--theme-fontWeight--bold);
      font-size: 36px;
      line-height: 2em;
      text-align: center;
      color: var(--theme-color--blue);
    }

    span {
      font-weight: var(--theme-fontWeight--bold);
      font-size: 11px;
      line-height: 1.125em;
      text-align: center;
      text-transform: uppercase;
      color: var(--theme-color--lightGrey);
    }
  }
}
