$color-arrow: #9495a7;
$color-arrow-active: #000000;

.table-filter-sort-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__title {
    padding-left: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: #9495a7;
    font-family: 'Montserrat', sans-serif;
  }

  &__controls {
    position: relative;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;

      width: 0;
      height: 0;
    }

    &:after {
      top: 1px;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $color-arrow;
    }
    
    &:before {
      bottom: 1px;
      
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $color-arrow;
    }


    &--desc {
      &:before {
        border-bottom: 5px solid $color-arrow-active;
      }
    }

    &--asc {
      &:after {
        border-top: 5px solid $color-arrow-active;
      }
    }
  }

  &--pointer {
    cursor: pointer;
  }
}
