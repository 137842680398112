.search-navigation {
  margin-top: 50px;
  margin-bottom: 32px;
  @media screen and (max-width: 575px) {
    margin-top: 32px;
  }
}

.search-navigation__list {
  display: flex;
  align-items: center;
  .search-navigation-item:not(:first-of-type) {
    padding-left: 24px;
  }
}

.search-navigation-item {
  display: flex;
  &:first-of-type {
    padding-right: 24px;
  }

  &__link {
    font-family: 'Playfair Display', sans-serif;
    font-weight: 900;
    font-size: 42px;
    line-height: 54px;
    font-feature-settings: 'case' on, 'liga' off;
    color: #9495a7;
    @media screen and (max-width: 575px) {
      font-size: 28px;
    }
    &.active {
      color: #272835;
    }
  }

  ._hint {
    position: static;
    @media screen and (max-width: 575px) {
      button {
        width: 18px;
        height: 18px;
      }
    }
  }

  + .search-navigation-item {
    border-left: 1px solid #9495a7;
  }
}
