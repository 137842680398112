//
// Absolute positioning
//
@mixin position-absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

//
// Fixed positioning
//
@mixin position-fixed($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: fixed;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

//
// Position center: vertical, horizontal
//
@mixin position-center($position: 'both') {
  @if $position == 'vertical' {
    @include position-absolute(50%, null, null, null);
    transform: translateY(-50%);
  }
  @else if $position == 'horizontal' {
    @include position-absolute(null, null, null, 50%);
    transform: translateX(-50%);
  }
  @else {
    @include position-absolute(50%, null, null, 50%);
    transform: translate(-50%, -50%);
  }
}

//
// Display flex
//
@mixin display-flex(
  $flex-direction: null,
  $align-items: null,
  $justify-content: null,
  $flex-wrap: null,
  $inline-flex: null
) {
  @if ($inline-flex) {
    display: inline-flex;
  }
  @else {
    display: flex;
  }
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-wrap: $flex-wrap;
}

//
// Set element width and height
//
@mixin size($width, $height: null) {
  @if($height) {
    width: $width;
    height: $height;
  } @else {
    width: $width;
    height: $width;
  }
}

@mixin lineClamp($line: 1) {
  overflow: hidden;
  display: flex;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  -webkit-line-clamp: $line;
}

@mixin customScrollbar() {
  ::-webkit-scrollbar {
    width: 3px;
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
  }
}

