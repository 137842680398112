.democratizing_section {
  background: rgba(55, 53, 72, 0.8);
  height: 100%;

  @media only screen and (max-width: 768px) {
    background-image: url('./bg.jpg'), linear-gradient(138deg, rgba(0, 0, 0, 0) 31%, #0d0e1c 106%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .sectionWrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  .gary {
    animation: gary 2s ease-in-out forwards;
    bottom: 0;
    left: -50px;
    opacity: 0;
    position: absolute;
  
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  
  .garyName {
    animation: garyName 2s ease-in-out forwards;
    animation-delay: 0.5s;
    bottom: 100px;
    left: 290px;
    opacity: 0;
    position: absolute;
    width: 365px;
  
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  
  .content {
    animation: fadeIn 1s ease-in-out forwards;
    animation-delay: 1s;
    color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 40px;
    width: 478px;
  
    @media only screen and (max-width: 768px) {
      width: 100%;
      position: relative;
      right: 0;
    }
  }
  
  .title {
    font-family: 'Playfair Display', sans-serif;
    font-size: 48px;
  
    @media only screen and (max-width: 768px) {
      font-size: 38px;
      text-align: center;
      line-height: 55px;
    }
  }  
  
  .titleBold {
    font-size: 75px;
    font-weight: 800;
  
    @media only screen and (max-width: 768px) {
      font-size: 48px;
    }
  }
  
  .desc {
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 22px;
  
    @media only screen and (max-width: 768px) {
      text-align: center;
      line-height: 25px;
      margin: 30px auto 0;
      width: 312px;
    }
  }
  
  .descSecond {
    margin-top: 20px;
    letter-spacing: 0.5px;
    line-height: 22px;
  
    @media only screen and (max-width: 768px) {
      text-align: center;
      line-height: 25px;
      margin: 20px auto 0;
      width: 312px;
    }
  }
  
  .playIcon {
    animation: fadeIn 1s ease-in-out forwards;
    animation-delay: 2s;
    align-items: center;
    background: #50526b;
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 82px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: all 200ms ease;
    transform: translate(-50%, -50%);
    width: 82px;
  
    @media only screen and (max-width: 768px) {
      display: none;
      height: 70px;
      left: 0;
      margin: 30px auto 0;
      position: relative;
      top: 0;
      transform: translate(0, 0);
      width: 70px;
    }
  
    &:hover {
      background: #ffffff;
      color: #50526b;
      transition: all 200ms ease;
    }
  
    &.playIconMobile {
      display: none;
  
      @media only screen and (max-width: 768px) {
        display: flex;
      }
    }
  }  
  
  @keyframes gary {
    0% {
      left: -100px;
      opacity: 0;
    }
  
    100% {
      left: -50px;
      opacity: 1;
    }
  }
  
  @keyframes garyName {
    0% {
      left: 340px;
      opacity: 0;
    }
  
    100% {
      left: 290px;
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    0% {
      transform: translate(-50%, -10px);
    }
  
    100% {
      transform: translate(-50%, 0);
    }
  }
  
  .arrows {
    cursor: pointer;
    transition: color 200ms ease;
  
    &:hover .arrow1,
    &:hover .arrow2 {
      color: #ffffff !important;
      transition: color 200ms ease;
    }
  }
  
  .arrow1,
  .arrow2 {
    color: rgba(255, 255, 255, 50%);
    bottom: 70px;
    display: flex;
    flex-direction: column;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -10px);
  }
  
  .arrow1 {
    animation: slideDown 1s ease-in-out forwards;
  }
  
  .arrow2 {
    animation: slideDown 1s ease-in-out forwards, fadeIn 1s ease-in-out forwards;
    animation-delay: 0.5s;
    bottom: 50px;
    color: rgba(255, 255, 255, 90%);
  }
  
  @media only screen and (max-width: 768px) {
    .arrow1 {
      bottom: 90px;
    }
    .arrow2 {
      bottom: 70px;
    }
  }

  &.lang_ES_section, &.lang_PT_section {
    .titleBold {
      font-size: 58px;
    }
  }
}

.videoBg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.videoModalIframe {
  @media only screen and (max-width: 768px) {
    height: 300px;
    width: 100%;
  }
}