$bn: '.bottom-navigation';

#{$bn} {
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (min-width: 1024px) and (orientation: landscape) and (pointer: coarse) {
    display: block;
  }

  @media only screen and (max-height: 420px) and (orientation: landscape) and (pointer: coarse) {
    display: none;
  }
}