$dsb: '.desktop-slider-block';
$pad: 40px;
$space: 0px;
$stroke: 4px;

#{$dsb} {
  position: relative;
  display: block;
  align-items: flex-start;
  justify-content: space-between;
  width: 50%;

  &__sliders {
    display: flex;
    flex-direction: column;
  }

  &__center-items {
    position: absolute;
    top: -65px;
    left: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    z-index: 3;
    pointer-events: none;

    @media only screen and (max-width: 1366px) {
      top: -45px;
    }

    &__button {
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
            
      // 32768 - its max  index for layer image and + 1
      z-index: 32768;
    }
    &__calendar-position
    {
      margin-top: 40px;
      margin-left: 60px;
      pointer-events: auto;
    }
  }
}
.slideImage{
  display:flex; 
  height:auto;
  width:50%;
}
.displayFlex{
  display:flex; 
}
.marginToRight{
  margin-right: 150px;
}
.vl-up-line{
  background: linear-gradient(to top,  #272835 0%, #b9b9c4 70.83%, #dedee6 97.83%);
	background: -moz-linear-gradient(to top, #272835 0%, #b9b9c4 70.83%, #dedee6 97.83%);
	background: -webkit-linear-gradient(to top, #272835 0%, #b9b9c4 70.83%, #dedee6 97.83%);
	background: -o-linear-gradient(to top,  #272835 0%, #b2b2b8 70.83%, #dedee6 97.83%);
  height: 300px;
  max-height: 300px;
  width: 1.5px;
}
.vl-down-line{
  background: linear-gradient(to bottom, #272835 0%, #b2b2b8 70.83%, #cccce3 97.83%);
	background: -moz-linear-gradient(to bottom, #272835 0%, #b2b2b8 70.83%, #cccce3 97.83%);
	background: -webkit-linear-gradient(to bottom, #272835 0%, #b2b2b8 70.83%, #cccce3 97.83%);
	background: -o-linear-gradient(to bottom, #272835 0%, #b2b2b8 70.83%, #cccce3 97.83%);
  height: 300px;
  max-height: 300px;
  position: relative;
  top:10px;
  width: 1.5px;
}
.vertical{
  writing-mode:tb-rl;
  -webkit-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform:rotate(180deg);
  transform: rotate(180deg);
  white-space: nowrap;
  display: flex;
  bottom: 0;
  height: auto;
  position: relative;
  top: 5px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
  font-family: "Playfair Display", sans-serif;
  align-content: center;
  justify-content: center;
  line-height: 22px;
  -webkit-letter-spacing: 0.4em;
  -moz-letter-spacing: 0.4em;
  -ms-letter-spacing: 0.4em;
  letter-spacing: 0.4em;
  text-transform: uppercase;
}
.lineCenter{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 750px;
  margin-top: 100px;
}