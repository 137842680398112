// TODO: deal with sass modules to import this variable
$bn-p-height: 78px;
$bn-o-h-height: 110px;

$bn-o: '.bottom-navigation__overlay';


#{$bn-o} {
  position: fixed;
  top: 0;
  left: 0;
  flex: 1;
  height: calc(100% - #{$bn-p-height});
  width: 100%;
  background: var(--theme-color--white);
  z-index: 100000;

  &__body {
    overflow-y: auto;
    height: calc(100% - #{$bn-o-h-height});
    margin-top: $bn-o-h-height;
    padding: 16px;
  }
}
