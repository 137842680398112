.resource-center {
  margin: 0 auto;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 32px;
    text-align: center;
  }

  p {
    margin-bottom: 56px;
    text-align: center;
    color: var(--theme-color--grey);
  }

  &__wrapper {
    display: flex;
    width: 100%;

    > * {
      &:first-of-type {
        flex: 1;
      }

      &:last-of-type {
        flex: 2;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;

    > * {
      font-size: 22px;
      font-family: var(--theme-fontFamily--primary);
      color: var(--theme-color--lightGrey);
      font-weight: var(--theme-fontWeight--black);

      &.active {
        color: var(--theme-color--blue);
      }

      & + * {
        margin-top: 32px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 18px;

        & + * {
          margin-top: 16px;
        }
      }
    }
    + * {
      padding-left: 32px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 16px;

    h1,
    p {
      margin-bottom: 16px;
    }

    &__wrapper {
      flex-direction: column;
      margin-bottom: 32px;

      & > * {
        flex: auto;
        width: 100%;
      }
    }

    &__nav {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: var(--theme-border--default);

      > * {
        padding: 8px;
        text-align: center;
        border: var(--theme-border--default);
        border-radius: var(--theme-borderRadius--default);

        & + * {
          margin-top: 8px;
        }

        &.active {
          border-color: var(--theme-color--blue);
        }
      }

      + * {
        padding-left: 0;
      }
    }
  }
}
