.chats-list-header {
  padding: 16px;

  h4 {
    font-family: var(--theme-fontFamily--primary);
    font-style: normal;
    font-weight: var(--theme-fontWeight--black);
    font-size: 23px;
    line-height: 1.26;

    color: var(--theme-color--black);
  }
}

.members-search {
  &__wrapper {
    position: relative;
  }

  &__input {
    background: var(--theme-color--white);
    border: 2px solid var(--theme-color--ultraLightBlue);
    box-sizing: border-box;
    border-radius: var(--theme-borderRadius--default);

    margin-top: 16px;
    padding: 10px 35px 10px 10px;

    width: 100%;
    height: 40px;
  }

  &__icon {
    position: absolute;

    bottom: 8px;
    right: 12px;

    path {
      fill: var(--theme-color--lightGrey);
    }
  }
}