.messages-list-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;

  flex: 1;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--theme-color--blueText);
  }
}