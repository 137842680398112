.payment-settings {
  &--button {
    button {
      display: flex;
      flex-direction: row-reverse;
      .button__spinner {
        position: initial;
        margin-right: 16px;
        path {
          fill: var(--theme-color--blue);
        }
      }
    }
  }

  &--description {
    margin-top: 18px;
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625em;
    color: var(--theme-color--grey);
  }
}
