.aficionados-list {
  &__wrapper {
    max-height: 484px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: var(--theme-color--blueText);
    }
  }
}
