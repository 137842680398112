$media-dt: 1240px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 575px;
$media-xs: 414px;

.participant-list {
  position: absolute;
  bottom: 40px;
  left: 40px;

  width: 300px;

  @media screen and (max-width: #{$media-lg}) {
    bottom: 120px;
  }

  @media screen and (max-width: #{$media-sm}) {
    bottom: 140px;
    left: 20px;

    width: 130px;
  }
}