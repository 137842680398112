.joinToTalent-email {
  $modal-media-sm: 725;
  $modal-media-xs: 426;

  @mixin modal-media($width) {
    @media only screen and (max-width: #{$width - 1px}) {
      @content;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;
    &_wr {
      max-height: 280px;
      padding-right: 15px;
      overflow-y: auto;
      @include modal-media($modal-media-sm) {
        padding-right: 0;
        overflow-y: auto;
        max-height: inherit;
      }
    }
    &_item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      @include modal-media($modal-media-sm) {
        flex-direction: column;
        padding-bottom: 24px;
        border-bottom: 1px solid get-colour('blue-text');
      }
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
      &:not(:first-of-type) {
        .joinToTalent-email__form_email {
          width: 90%;
        }
      }
    }
    &_name {
      flex: 0 0 200px;
      max-width: 200px;
      margin-right: 16px;

      .input-label {
        font-size: get-font-size('s-2');
      }
      @include modal-media($modal-media-sm) {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        right: 0;
        margin-bottom: 24px;
      }
    }
    &_email {
      flex: 1;
      .input-label {
        font-size: get-font-size('s-2');
      }
      @include modal-media($modal-media-sm) {
        width: 100%;
      }
    }
    .input {
      border-color: get-colour('blue-text');
      font-size: get-font-size('s-1');
      &:focus {
        border-color: get-colour('blue');
      }
      &-default {
        height: 56px;
        padding: 15px;
      }
    }
    .error-message {
      padding-top: 0;
    }
  }
  &__button {
    &--remove {
      align-self: flex-end;
      margin-left: 20px;
      margin-bottom: 20px;
      position: relative;
      background-color: rgba(get-colour('white'), 0);
      padding: 0;
      width: 14px;
      height: 14px;
      border: none;
      outline: none;
      cursor: pointer;
      @include modal-media($modal-media-sm) {
        position: absolute;
        right: 0;
        bottom: 45px;
        margin-left: 0;
        margin-bottom: 0;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 2px;
        background-color: get-colour('light-grey');
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    &--add {
      font-size: get-font-size('s-1');
      font-weight: get-font-weight('bold');
      color: get-colour('black');
      line-height: 1.625;
      margin-top: 24px;
      background-color: rgba(get-colour('white'), 0);
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
    }
    &--submit {
      align-self: flex-end;
      margin-top: auto;
    }
  }
}
