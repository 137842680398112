.messages-list-is-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px;

  font-size: 16px;
  line-height: 1.63;
  font-weight: var(--theme-fontWeight--medium);

  text-align: center;
  color: var(--theme-color--grey);
}
