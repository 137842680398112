$tib: '.talent-info-block';

#{$tib} {
  max-width: 624px;
  margin-top: 70px;

  .title {
    margin-top: 0;
    margin-bottom: 25px;
    color: var(--theme-color--blue);
    font-family: var(--theme-fontFamily--primary), sans-serif;
    font-weight: var(--theme-fontWeight--black);
    font-size: 24px;
    line-height: 1;
    font-feature-settings: 'case' on, 'liga' off;
    text-align: left;
  }
}