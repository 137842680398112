.video-chat-offering-form {
  .enable-in-you-services {
    .row-left {
      padding-top: 0;
    }
    &-radio {
      display: flex;
      align-items: center;
      label:not(:last-of-type) {
        margin-right: 45px;
        @media screen and (max-width: 767px) {
          margin-right: 0;
        }
      }
    }
  }

  &__buttons {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;

    .button {
      margin-right: 24px;
      margin-bottom: 24px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      width: 100%;
      flex-direction: column;

      .button {
        margin-right: 0;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}


