.section {
  background: rgba(55, 53, 72, 0.8);
  height: calc(100% + 220px);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    background-image: url('./bg.jpg'), linear-gradient(138deg, rgba(0, 0, 0, 0) 31%, #0d0e1c 106%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh !important;
  }
}

.videoBg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.sectionWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    height: 100vh !important;
    flex-shrink: 0;
  }
}

.boxes {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}

.sep {
  background: rgba(255, 255, 255, 0.8);
  height: 250px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.title {
  color: #ffffff;
  font-family: 'Playfair Display', sans-serif;
  font-size: 52px;
  font-weight: bold;
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    font-size: 31px;
    margin-bottom: 70px;
    margin-top: 80px;
    text-align: center;
  }
}

.hidden {
  display: none;
}

.titlesListContainer {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;

  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    padding-left: 5px;
    position: relative;
  }
}

.titlesList {
  font-size: 31px;
  text-align: left;
  position: relative;
}

.titlesItem {
  color: #9495a7;
  cursor: pointer;
  font-family: 'Playfair Display', sans-serif;
  font-weight: 800;
  line-height: 35px;
  position: relative;
  transition: color 200ms ease;
  padding: 20px;

  @media only screen and (max-width: 1024px) {
    font-size: 26px;
    line-height: 32px;
    padding: 10px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
    font-size: 21px;
    height: auto;
    line-height: 22px;
    margin-bottom: 40px;
  }

  &.active,
  &:hover {
    color: #ffffff;
    transition: color 200ms ease;

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
}

.chevronRight {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.chevronRightHidden {
  display: none !important;
}

.texts {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  padding-left: 20px;

  @media only screen and (max-width: 768px) {
    padding-left: 0;
  }
}

.imagesTitle {
  color: #ffffff;
  font-family: 'Playfair Display', sans-serif;
  font-size: 23px;
  font-weight: 800;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.closIcon {
  display: none;

  @media only screen and (max-width: 768px) {
    color: rgba(255, 255, 255, 0.8);
    display: block;
    position: absolute;
    right: 0;
    top: 3px;
  }
}

.inactiveInterval {
  @media only screen and (max-width: 768px) {
    color: #ffffff !important;
    display: block !important;
  }
}

.imagesText {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  line-height: 20px;
  padding: 12px 0;

  svg {
    flex-shrink: 0;
    color: #9495a7;
  }
}

.imagesDesc {
  color: #ffffff;
  margin-top: 20px;
}
