$ls: '.location-search';

#{$ls} {
  &__input-wrapper {
    position: relative;

    &__icon {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translate(0, -50%);
      fill: var(--theme-color--lightGrey);
    }

    &__input {
      padding-left: 44px;
      transition: border-color 0.2s;
    }
  }

  &__loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    color: var(--theme-color--lightGrey);
    font-family: var(--theme-fontFamily--default);
    font-size: 13px;
    line-height: 1.3;
    font-weight: var(--theme-fontWeight--bold);
    text-transform: uppercase;
  }

  &__set-location {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 8px;
      stroke: transparent;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      min-height: 72px;

      &__name {
        color: var(--theme-color--grey);
        font-family: var(--theme-fontFamily--default);
        font-size: 16px;
        line-height: 1.6;
        font-weight: var(--theme-fontWeight--medium);
      }

      &__counters {
        display: flex;

        &__counter {
          min-height: 20px;
          padding: 2px 6px;
          color: var(--theme-color--white);
          background-color: var(--theme-color--blueText);
          border-radius: var(--theme-borderRadius--default);
          font-size: 14px;
          line-height: 1;
          font-weight: var(--theme-fontWeight--medium);
          transition: background-color 0.2s;
          cursor: pointer;

          & + & {
            margin-left: 4px;
          }

          &:hover {
            background-color: var(--theme-color--grey);
          }
        }
      }
    }
  }
}

#{$ls}.dark {
  #{$ls}__input-wrapper {
    &__icon {
      fill: var(--theme-color--lightGrey);
    }

    &__input {
      color: var(--theme-color--ultraLightBlue);
      border-color: var(--theme-color--lightGrey);

      &:hover {
        border-color: var(--theme-color--ultraLightBlue) !important;
      }
    }
  }

  #{$ls}__set-location {
    color: var(--theme-color--ultraLightBlue);

    &__icon {
      path {
        fill: var(--theme-color--ultraLightBlue);
      }
    }
  }

  #{$ls}__list {
    &__item {
      &__name {
        color: var(--theme-color--ultraLightBlue);
      }

      &__counters {
        &__counter {
          background-color: #595b79;

          &:hover {
            background-color: var(--theme-color--blueText);
          }
        }
      }
    }
  }
}