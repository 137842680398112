$bn-o: '.bottom-navigation__overlay';

#{$bn-o}--search {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    cursor: pointer;

    &__title {
      flex: 1;
      text-align: left;
    }

    &__icon {
      width: 20px;
      height: auto;
      margin-right: 12px;

      path {
        fill: var(--theme-color--grey);
      }
      
  
      &__shevron--right {
        width: 20px;
        height: auto;
        margin-left: 24px;

        path {
          stroke: var(--theme-color--grey);
        }
      }
    }
  }
}