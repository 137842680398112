$first-column-width: 235px;
$default-column-width: 114px;

.notifications {

  .label {
    width: 250px;
    margin-top: 0;
    padding-top: 14px;
  }

  .form-row__item {

    &:first-child {
      margin-top: 0;
      margin-right: 80px;
    }

    &:last-child {
      margin-bottom: 32px;
    }
  }

  &-head {
    .notifications-row {
      margin-bottom: 20px;
    }
  }

  &-row {
    display: grid;
    grid-template-columns: $first-column-width repeat(3, $default-column-width);
    margin-bottom: 18px;
    align-items: center;
  }

  &-cell {
    justify-self: center;
    align-items: center;

    &__label {
      font-family: $font-family-default;
      font-weight: get-font-weight('medium');
      font-size: get-font-size('m-5');
      line-height: 1.44;
      color: get-colour('blue');
      text-transform: capitalize;
    }

    &--start {
      justify-self: flex-start;
    }

    &--center {
      justify-self: center;

      .checkboxCustom {
        padding-left: 20px;
      }
    }
  }



  &-cell-head {
    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: get-font-size('s-2');
    line-height: 1.28;
    text-align: center;
    text-transform: uppercase;
    color: get-colour('dark-grey');
  }

  &-submit-btn {
    margin-top: 8px;
    margin-bottom: 38px;

    @media screen and (max-width: 768px) {
      margin-top: 60px;
      margin-bottom: 0;
      width: 100%;
      button {
        min-height: 56px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 24px;
  }

  @media screen and (max-width: 768px) {
    margin: 16px;
  }
}
