// TODO: refactor CommercialEngagementsRequestForm subcomponents

$cerf: '.commercial-engagements-request-form';

#{$cerf} {
  margin-top: 64px;
  
  @media screen and (max-width: 425px) {
    margin-top: 30px;
  }

  // TODO: Add transition for all form elements globally
  input,
 
  textarea {
    transition: 0.2s border-color;
  }

  // Common components override
  .block-width-border {
    margin-bottom: 50px;

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  .react-tel-input {
    input:hover,
    input:focus {
      border-color: var(--theme-color--black);
    }
  }

  input + .error {
    margin-top: 4px;
  }
} 