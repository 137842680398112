.table-review {

  &__comment {
    font-family: var(--theme-fontFamily--default);
    font-weight: var(--theme-fontWeight--medium);
    font-size: 16px;
    line-height: 1.625;
    color: var(--theme-color--grey);
    overflow-wrap: break-word;

    padding-top: 4px;
  }
}