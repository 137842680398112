.ready-made-lesson {
  // 140px global-wrapper padding-top
  min-height: calc(100vh - 140px);

  &--terms {
    font-size: 13px;

    button {
      text-decoration: underline;
    }
  }

  .mobile-info {
    display: none;
  }

  .public-view-card--bordered {
    position: relative;

    .follow-button {
      position: absolute;
      top: 24px;
      right: 34px;
    }
  }

  .content {
    padding: 42px 34px;
  }

  .slider {
    .video-message-public {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .tbv-slider-actions-video-messages {
        margin-top: 0;
      }

      @media only screen and (max-width: 992px) {
        top: 55%;
        margin-bottom: 40px !important;

        .tbv-slider-slide-machine {
          transform: perspective(150px) rotateY(0deg);
        }
      }
      @media only screen and (max-width: 768px) {
        height: 400px;
        transform: translate(-50%, 0%);

        .tbv-slider-slide-machine {
          margin-top: 0;
        }
      }
    }
  }

  .public-view__price {
    align-items: flex-end;
    padding: 0;
    padding-bottom: 32px;
  }

  .public-view-description--one-line {
    padding-bottom: 32px;

    h4 {
      padding-bottom: 12px;
      color: var(--theme-color--blue);
      font-family: var(--theme-fontFamily--primary), sans-serif;
      font-weight: var(--theme-fontWeight--black);
      font-size: 24px;
      line-height: 1.3;
      font-feature-settings: 'case' on, 'liga' off;
    }

    &.public-view-description--gray {
      p {
        color: var(--theme-color--grey);
      }
    }

    div {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 1024px) {
        flex-direction: column;

        p {
          margin-bottom: 40px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .content {
      padding-top: 84px;
    }
  }

  @media only screen and (max-width: 1024px) {
    h1 {
      font-size: 32px;
      line-height: 54px;
      font-feature-settings: 'case' on;
    }

    .public-view-card--bordered {
      min-height: unset;
      height: auto;
    }

    .content {
      padding: 24px 0;
      position: inherit;
      overflow: visible;

      .follow-button {
        right: 0;
      }
    }

    .mobile-info {
      display: block;
      text-align: center;
    }

    .desktop-info {
      display: none;
    }

    .public-view__price {
      align-items: inherit;
      flex-direction: column;

      .price-wrapper {
        padding-bottom: 24px;
      }
    }

    .public-view-description--one-line {
      h4 {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .public-view__heading {
      padding-bottom: 0;
    }

    .public-view__price {
      flex-direction: column;
      text-align: center;
      align-items: center;

      .price-wrapper {
        padding-bottom: 24px;

        p {
          padding-bottom: 8px;
        }
      }
    }

    .public-view-description--one-line {
      text-align: center;
    }

    .content {
      padding-top: 16px;

      .follow-button {
        position: relative;
        align-self: center;
        top: 0;
        right: 0;
        margin-bottom: 24px;
      }
    }
  }
}