.account-menu-header {
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: get-font-weight('black');
  font-size: get-font-size('l-1');
  line-height: 75px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

  color: $blue;
  margin-bottom: 10px;
}

.register.change-password {
  display: flex;

  .data-field.terms .label,
  .label {
    width: 200px;
    margin-right: 67px;
    position: relative;
  }
  .save-container {
    width: 561px;
    margin-top: 52px;
    .button-wrap {
      width: max-content;
    }
  }
  .label-mobile {
    display: block;
    font-family: $font-family-default;
    font-weight: get-font-weight('bold');
    font-size: 13px;
    line-height: 18px;

    /* identical to box height, or 138% */

    text-transform: uppercase;

    /* light grey */

    color: #9495a7;
  }

  .button-wrap {
    margin: 0;
    button {
      width: 150px;
      height: 50px;
      min-width: unset;

      box-sizing: border-box;

      -webkit-border: none;
      -moz-border: none;
    }

    .error-message {
      margin-top: 5px;
      display: block;
    }
  }

  .data-field {
    margin-top: 18px;
    .label {
      margin-right: 67px;
    }
    .label-mobile {
      margin-bottom: 4px;
      text-align: left;
    }
    &.terms {
      a {
        margin-top: 10px;
        width: 154px;
        border-bottom: 1px solid #272835;
      }
    }
  }

  .change-password__tip {
    position: absolute;
    top: 70px;
    @include Small;
    color: $blue;
  }

  @media (max-width: 480px) {
    .data-field.terms .label {
      display: none;
    }
  }
}

.change-password-wrapper {
  margin: 0 auto;

  .data-field {
    position: relative;
    justify-content: normal;
    input {
      background: transparent;
    }
  }

  .tip-container {
    position: absolute;
    margin-top: 0;
    @include position-absolute(auto, auto, -44px, auto);
  }
}
