.collapse {
  > .collapse--title {
    padding: 16px 0;
    color: var(--theme-color--white);

    > button {
      padding: 0;
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      background: transparent;
      color: var(--theme-color--white);
      font-weight: var(--theme-fontWeight--semiBold);
      font-size: 14px;
      cursor: pointer;

      > svg {
        fill: var(--theme-color--white);
        margin-right: 10px;
        transition: transform .3s ease-in-out;
      }
    }

    p {
      margin: 10px 0;
      color: var(--theme-color--white);
      font-size: 14px;
    }
  }

  &--opened {
    > .collapse--title {
      > button {
        > svg {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &--bordered {
    border: 1px solid var(--theme-color--white);
    border-radius: var(--theme-borderRadius--default);
    padding: 0 16px;
  }
}