$r-and-m: '.rating-and-money';

#{$r-and-m} {
  max-width: 344px;
  width: 100%;

  @media only screen and (max-width: 575px) {
    max-width: 80%;
    width: 100%;
  }

  @media only screen and (max-width: 425px) {
    max-width: 100%;
  }

  &__content {
    display: flex;
    justify-content: space-between;

    &-inner {
      display: flex;

      svg + svg {
        margin-left: 4px;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .text {
      max-width: 90px;
      font-size: 14px;
      font-weight: var(--theme-fontWeight--bold);
      line-height: 1.4;
      text-transform: uppercase;
      color: var(--theme-color--grey);

      &--right {
        text-align: right;
      }
    }
  }

  &__money {
    font-weight: var(--theme-fontWeight--semiBold);
    font-size: 16px;
    line-height: 1.4;
  }

  // Common components override
  & ._price__value,
  & ._price__currency {
    font-weight: var(--theme-fontWeight--semiBold);
    font-size: 16px;
    line-height: 1.4;
  }
}